import { dietry_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IAddDietryTypesParams, IDeleteDietryTypesParams, IGetDietryTypesParams, IUpdateDietryTypesParams } from "../types/dietryTypes";


export const getDietryTypesList = async ({ page_number, per_page }: IGetDietryTypesParams) => {

    try {
        const params: { [key: string]: number } = {};

        if (page_number !== undefined) params['page_number'] = page_number;
        if (per_page !== undefined) params['per_page'] = per_page;

        const { data } = await axiosApi.get(dietry_routes.get_dietry_types_list, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}


export const postDietryType = async ({ name }: IAddDietryTypesParams) => {
    try {
        const { data } = await axiosApi.post(dietry_routes.post_dietry_type, { name });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const updateDietryType = async ({ id, name }: IUpdateDietryTypesParams) => {
    try {
        const { data } = await axiosApi.put(dietry_routes.put_dietry_type, { id, name });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const deleteDietryType = async ({ id }: IDeleteDietryTypesParams) => {
    try {
        const { data } = await axiosApi.delete(`${dietry_routes.delete_dietry_type}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data?.data;
    }
}