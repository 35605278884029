import { patient_admission_notifications_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";

export const getPatientAdmissionNotificationsByPatientAdmissionId = async (start_date: string, end_date: string, patient_admission_id: string
) => {
    try {
        const { data } = await axiosApi.get(
            `${patient_admission_notifications_routes.get_all_patient_admission_notificstions}?patient_admission_id=${patient_admission_id}&start_date=${start_date}&end_date=${end_date}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};