import { patient_admission_general_examination_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IPatientAdmissionGeneralExaminationTypes } from "../types/patientAdmissionGeneralExaminationTypes";

export const postPatientAdmissionGeneralExamination = async (values: IPatientAdmissionGeneralExaminationTypes) => {
    try {
        const { data } = await axiosApi.post(patient_admission_general_examination_routes.post_patient_admission_general_examination, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const getPatientAdmissionGeneralExaminationByPatientAdmissionId = async (
    patient_admission_id: string,
) => {
    try {
        const { data } = await axiosApi.get(`${patient_admission_general_examination_routes.get_patient_general_examination_by_patient_admission_id}?patient_admission_id=${patient_admission_id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const putPatientAdmissionGeneralExamination = async (values: any) => {
    try {
        const { data } = await axiosApi.put(patient_admission_general_examination_routes.put_patient_admission_general_examination, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const deletePatientAdmissionGeneralExamination = async (id: string) => {
    try {
        const { data } = await axiosApi.delete(`${patient_admission_general_examination_routes.delete_patient_admission_general_examination}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const postPatientAdmissionGeneralExaminationBatch = async (values: IPatientAdmissionGeneralExaminationTypes[]) => {
    try {
        const { data } = await axiosApi.post(patient_admission_general_examination_routes.post_patient_admission_general_examination_batch, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};
export const putPatientAdmissionGeneralExaminationBatch = async (values: IPatientAdmissionGeneralExaminationTypes[]) => {
    try {
        const { data } = await axiosApi.put(patient_admission_general_examination_routes.put_patient_admission_general_examination_batch, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};