import { patient_admission_prescription_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";

export const postPatientAdmissionPrescription = async (values: any) => {
    try {
        const { data } = await axiosApi.post(
            patient_admission_prescription_routes.post_patient_admission_prescription,
            values
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const getPatientAdmissionPrescriptionByPatientAdmissionId = async (patient_admission_id: string) => {
    try {
        const { data } = await axiosApi.get(
            `${patient_admission_prescription_routes.get_patient_prescription_by_patient_admission_id}?patient_admission_id=${patient_admission_id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const putPatientAdmissionPrescription = async (values: any) => {
    try {
        const { data } = await axiosApi.put(
            patient_admission_prescription_routes.put_patient_admission_prescription,
            values
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const deletePatientAdmissionPrescription = async (id: string) => {
    try {
        const { data } = await axiosApi.delete(
            `${patient_admission_prescription_routes.delete_patient_admission_prescription}/${id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const getPatientAdmissionPrescriptonsByDoctorId = async (
    doctor_id: string
) => {
    try {
        const { data } = await axiosApi.get(
            `${patient_admission_prescription_routes.get_patient_admission_prescription_by_doctor_id}?doctor_id=${doctor_id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}
