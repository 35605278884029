import axios from "axios";
import { SERVER_URL } from "../redux/api_path";

const axiosApi = axios.create({ baseURL: SERVER_URL });

axiosApi.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("tele_icu_token");
    if (token) {
      config.headers.Authorization = `${token}`;
    } else {
      localStorage.clear();
      delete axiosApi.defaults.headers.common.Authorization;
    }
    if (config?.data instanceof FormData) {
      config.headers = {
        "Content-Type": "multipart/form-data",
        ...config?.headers,
      };
    }
    return config;
  },
  (error) => {}
);

export default axiosApi;
