import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../pages/auth/Login";
import Dashboard from "../pages/dashboard/Dashboard";
import LoginWithMobile from "../pages/auth/LoginWithMobile";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import Location from "../pages/masters/location/Location";
import UserTypes from "../pages/masters/user_type/UserTypes";
import Users from "../pages/users/Users";
import Patients from "../pages/patients/Patients";
import Hospitals from "../pages/hospitals/Hospitals";
import Instructions from "../pages/instructions/Instructions";
import Activities from "../pages/activity/Activities";
import Reports from "../pages/reports/Reports";
import Settings from "../pages/settings/Settings";
import SingleHospitalProperty from "../pages/hospitals/SingleHospitalProperty";
import CreateHospitalProperty from "../pages/hospitals/CreateHospitalProperty";
import Discharge from "../pages/masters/discharge/Discharge";
import Dietry from "../pages/masters/dietry/Dietry";
import Investigation from "../pages/masters/investigation/Investigation";
import Document from "../pages/masters/document/Document";
import LineTube from "../pages/masters/linetube/LineTubeTypes";
import PastHistoryType from "../pages/masters/past_history/PastHistoryTypes";
import InvestigationSubType from "../pages/masters/investigation/InvestigationSub";
import PastHistorySubType from "../pages/masters/past_history/PastHistorySubTypes";
import PatientChildhoodDiseases from "../pages/masters/patient_form_masters/PatientChildhoodDiseases";
import PatientInjuries from "../pages/masters/patient_form_masters/PatientInjuries";
import PatientBehaviouralConditions from "../pages/masters/patient_form_masters/PatientBehaviouralConditions";
import PatientBloodDiseases from "../pages/masters/patient_form_masters/PatientBloodDiseases";
import PatientOtherConditions from "../pages/masters/patient_form_masters/PatientOtherConditions";
import { useSelector } from "react-redux";
import LockedContainer from "../containers/LayoutContainer";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import GeneralExaminationType from "../pages/masters/generalExaminationType/GeneralExaminationType";
import SystemicExaminationType from "../pages/masters/systemicExaminationType/SystemicExaminationType";
import HabitType from "../pages/masters/habitType/HabitType";
import ProvisionalDiagnosisType from "../pages/masters/provisionalDiagnosisType/ProvisionalDiagnosisType";
import MedicineType from "../pages/masters/medicine/MedicineType";
import MedicineSubType from "../pages/masters/medicine/MedicineSubType";
import UserRoles from "../pages/user_roles/UserRoles";
import Profile from "../pages/profile/Profile";
import DoctorSpeciality from "../pages/masters/doctorSpeciality/DoctorSpeciality";
import ReportMeasurementUnits from "../pages/masters/reportMeasurementUnitsType/ReportMeasurementUnitsType"
import DoctorProfiles from "../pages/DoctorProfiles/DoctorProfiles";
import DoctorsList from "../pages/DoctorProfiles/DoctorsList";

const RouteWrapper = () => {
  const { token } = useSelector((state: any) => state.user);
  const { user_info } = useSelector((state: any) => state.user);
  return (
    <Routes>
      <Route element={<LockedContainer isUserLoggedIn={Boolean(token)} />}>
        <Route element={<PublicRoute isUserLoggedIn={Boolean(token)} />}>
          <Route path="/" element={<Login />} />
          <Route path="/login-with-otp" element={<LoginWithMobile />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>

        <Route element={<PrivateRoute isUserLoggedIn={Boolean(token)} />}>
          <Route path="/hospitals" element={<Hospitals />} />
          <Route path="/profile" element={<Profile />} />
          {/* <Route
            path="/hospital-property/:id"
            element={<SingleHospitalProperty />}
          /> */}
          {/* <Route
            path="/create-hospital-property"
            element={<CreateHospitalProperty />}
          /> */}

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/location" element={<Location />} />
          <Route path="/user-types" element={<UserTypes />} />

          <Route path="/discharge" element={<Discharge />} />
          <Route path="/dietry" element={<Dietry />} />
          <Route path="/doctor-speciality" element={<DoctorSpeciality />} />
          <Route path="/report-measurement-units" element={<ReportMeasurementUnits />} />
          <Route path="/investigation" element={<Investigation />} />
          <Route path="/document" element={<Document />} />
          <Route path="/users" element={<Users />} />
          <Route path="/user-roles" element={<UserRoles />} />
          <Route path="/patients" element={<Patients />} />
          <Route path="/doctor-profile" element={<DoctorProfiles doctor={user_info} />} />
          <Route path="/doctors-list" element={<DoctorsList />} />
          <Route path="/instructions" element={<Instructions />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/line-tube-type" element={<LineTube />} />
          <Route path="/past-history-type" element={<PastHistoryType />} />
          <Route
            path="/patient-childhood-diseases"
            element={<PatientChildhoodDiseases />}
          />
          <Route
            path="/patient-blood-diseases"
            element={<PatientBloodDiseases />}
          />
          <Route path="/patient-injuries" element={<PatientInjuries />} />
          <Route
            path="/patient-other-conditions"
            element={<PatientOtherConditions />}
          />
          <Route
            path="/patient-behavioural-conditions"
            element={<PatientBehaviouralConditions />}
          />
          <Route
            path="/investigation-sub-type"
            element={<InvestigationSubType />}
          />
          <Route
            path="/past-history-sub-type"
            element={<PastHistorySubType />}
          />
          <Route
            path="/general-examination-type"
            element={<GeneralExaminationType />}
          />
          <Route
            path="/systemic-examination-type"
            element={<SystemicExaminationType />}
          />
          <Route path="/habit-type" element={<HabitType />} />
          <Route
            path="/provisional-diagnosis-type"
            element={<ProvisionalDiagnosisType />}
          />
          <Route path="/medicine-type" element={<MedicineType />} />
          <Route path="/medicine-sub-type" element={<MedicineSubType />} />
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};

export default RouteWrapper;
