import { ToastPosition, toast } from "react-toastify";

interface IToastOptions {
  position: ToastPosition;
  autoClose: number;
  hideProgressBar: boolean;
  closeOnClick: boolean;
  pauseOnHover: boolean;
  draggable: boolean;
  progress: undefined;
  theme: string | any;
}

export const SuccessToast = (message: string) => {
  const types = ['success', 'info', 'warning'];
  const randomType = types[Math.floor(Math.random() * types.length)];

  const toastOptions: IToastOptions = {
    position: 'top-right',
    autoClose: 500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark'
  };

  switch (randomType) {
    case 'success':
      return toast.success(message, toastOptions);
    case 'info':
      return toast.info(message, toastOptions);
    case 'warning':
      return toast.warning(message, toastOptions);
    default:
      return toast.success(message, toastOptions);
  }
};

export const ErrorToast = (message: string) => {
  return toast.error(message, {
    position: "top-right",
    autoClose: 500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};
