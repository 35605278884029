import axios from "axios";
import { hospital_property_routes, SERVER_URL } from "../api_path";
import axiosApi from "../../api/apiHelper";

let auth_token = localStorage.getItem("tele_icu_token");

export const hospital_property_by_hospital = async (id: string, country?: string, start_date = '', end_date = '', sort_order = '') => {
  try {
    let url = `${SERVER_URL}${hospital_property_routes.all_hospital_property_by_hospital}/${id}`
    const startDateTrimmed = start_date?.trim();
    const endDateTrimmed = end_date?.trim();
    if (startDateTrimmed && endDateTrimmed) url += `?start_date=${startDateTrimmed}&end_date=${endDateTrimmed}`;
    if (country) url += `?country=${country}`
    if (sort_order) url += startDateTrimmed && endDateTrimmed ? `& sort_order=${sort_order} ` : ` ? sort_order = ${sort_order} `;

    const { data } = await axiosApi.get(url);
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const hospital_property_by_id = async (id: string) => {
  try {
    let { data } = await axios.get(
      `${SERVER_URL}${hospital_property_routes.hospital_property_by_id}/${id}`,
      {
        headers: {
          authorization: auth_token,
        },
      }
    );

    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const create_hospital_property = async (fields: any) => {
  try {
    let { data } = await axios.post(
      `${SERVER_URL}${hospital_property_routes.create_hospital_property}`,
      fields,
      {
        headers: {
          authorization: auth_token,
        },
      }
    );

    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const deleteHospitalProperty = async (id: string) => {
  try {
    const { data } = await axiosApi.delete(`${hospital_property_routes?.delete_hospital_property}/${id}`);
    return data;
  } catch (error: any) {
    return error?.response?.data?.data;
  }
};

export const updateHospitalProperty = async (values: any) => {
  try {
    const { data } = await axiosApi.put(hospital_property_routes.update_hospital_property, values);
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }

};

export const hospital_property_with_patient_count = async (
  id: string,
  country_id?: string,
  state_id?: string,
  city_id?: string
) => {
  try {
    const queryParams = new URLSearchParams({
      country_id: country_id || '',
      state_id: state_id || '',
      city_id: city_id || ''
    });

    // Build the complete URL with query parameters
    const url = `${hospital_property_routes.all_hospital_property_with_count}/${id}?${queryParams.toString()}`;
    const { data } = await axiosApi.get(url);
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
}

export const hospital_property_with_discharge_count = async (
  id: string,
  country_id?: string,
  state_id?: string,
  city_id?: string
) => {
  try {
    const queryParams = new URLSearchParams({
      country_id: country_id || '',
      state_id: state_id || '',
      city_id: city_id || ''
    });
    const url = `${hospital_property_routes.all_hospital_property_with_discharge_count}/${id}?${queryParams.toString()}`;
    const { data } = await axiosApi.get(url);
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
}

