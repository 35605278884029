import Sidebar from "../../components/Sidebar/Sidebar";
import "../../assets/scss/teleicu/reports.scss";
import Header from "../../components/Header/Header";
import UsersListing from "./components/UsersListing";

const Users = () => {
  return (
    <>
      <Header HeaderName="USERS" />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <UsersListing />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
