import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
// import 'firebase/firestore';
// import 'firebase/auth';
// import 'firebase/storage';

// Firebase configuration
const firebaseApp = initializeApp({
  apiKey: "AIzaSyCMMBcIaUy6lJ7sKxuGCXFQF-CzWK48038",
  authDomain: "fir-notifications-c6ed9.firebaseapp.com",
  projectId: "fir-notifications-c6ed9",
  storageBucket: "fir-notifications-c6ed9.appspot.com",
  messagingSenderId: "709064866422",
  appId: "1:709064866422:web:ef0eda5ebdb9d18ec97d57",
  measurementId: "G-SJQMRC9D25",
});

// firebase.initializeApp(config);
export const messaging = getMessaging(firebaseApp);

// Export data storage service
// export const db = firebase.firestore();

// Export auth service
// export const auth = firebase.auth();

// Request for client token for recieving notifications
// export const requestFirebaseNotificationPermission = () =>
//   new Promise((resolve, reject) => {
//     messaging
//       .getToken()
//       .then((firebaseToken) => {
//         resolve(firebaseToken);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });

// When notification is recieved
// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });

export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey:
        "BA4jeDal3lgbjzLtAHXKlKAljanZhX7tk2IeXls9jj4JrmREIbmh9ZrdYjJZO-thKn9YeUSLd9NAaRUneu2jXfw",
      serviceWorkerRegistration,
    })
  );

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
