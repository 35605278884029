import React from "react";
import { Card, CardBody } from "reactstrap";
import PatientFamilyHistory from "./patientHistoryTables/PatientFamilyHistory";
import PatientBloodRelatives from "./patientHistoryTables/PatientBloodRelatives";
// import PatientConfidentialQuestions from "./patientHistoryTables/PatientConfidentialQuestions";
import PatientDrugAllergies from "./patientHistoryTables/PatientDrugAllergies";
import PatientMedication from "./patientHistoryTables/PatientMedication";
import PatientMedical from "./patientHistoryTables/PatientMedical";
import PatientChildhoodIllness from "./patientIllnessTables/PatientChildhoodIllness";
import PatientSurgical from "./patientHistoryTables/PatientSurgical";
import PatientBehaviouralIllness from "./patientIllnessTables/PatientBehaviouralIllness";
import PatientInjuries from "./patientIllnessTables/PatientInjuries";
const PatientDetailHistory: React.FC<any> = ({ patient, fetchPatientData }) => {
  return (
    <div
     
    >
      <Card>
        <CardBody>
          <PatientMedical
            patient={patient}
            fetchPatientData={fetchPatientData}
          />
          <br />
          <PatientSurgical
            patient={patient}
            fetchPatientData={fetchPatientData}
          />
          <br />
          <PatientMedication
            patient={patient}
            fetchPatientData={fetchPatientData}
          />
          <br />
          <PatientFamilyHistory
            patient={patient}
            fetchPatientData={fetchPatientData}
          />
          <br />
          <PatientBloodRelatives
            patient={patient}
            fetchPatientData={fetchPatientData}
          />
          <br />
          {/* <PatientConfidentialQuestions
            patient={patient}
            fetchPatientData={fetchPatientData}
          />
          <br /> */}
          <PatientChildhoodIllness
            patient={patient}
            fetchPatientData={fetchPatientData}
          />
          <br />
          <PatientBehaviouralIllness
            patient={patient}
            fetchPatientData={fetchPatientData}
          />
          <PatientInjuries
            patient={patient}
            fetchPatientData={fetchPatientData}
          />
          <br />
          <PatientDrugAllergies
            patient={patient}
            fetchPatientData={fetchPatientData}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default PatientDetailHistory;