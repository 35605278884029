import { useState, useEffect, useCallback } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import logo from "../../assets/images/logo-icu-sm.png";
import user1 from "../../assets/images/users/avatar-1.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../redux/types/patientTabTypes";
import SideHeader from "./SideHeader";
 
const PatientHeader = () => {
  const [menu, setMenu] = useState(false);
  const [header_state, setheader_state]: any = useState(
    Boolean(localStorage.getItem("header"))
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { patientMainTab } = useSelector(
    (state: any) => state.patientTab
  );
  const change_header_state = () => {
    setheader_state(!header_state);
    localStorage.setItem("header", JSON.stringify(!header_state));
  };
  const toggle = useCallback(
    (tab: string) => {
      if (patientMainTab !== tab) {
        if (location?.pathname !== "/patients") {
          navigate("/patients");
        }
        dispatch({
          type: ActionTypes.SET_Main_Patient_TAB,
          payload: {
            patientMainTab: tab,
          },
        });
      }
    },
    [patientMainTab, dispatch, location?.pathname, navigate]
  );
 
  const remove_patient_tab = useCallback(
    (tab: any) => {
      dispatch({
        type: ActionTypes.REMOVE_Patient_TAB,
        payload: tab,
      });
      if (tab?.id === patientMainTab) {
        toggle("1");
      }
    },
    [toggle, dispatch, patientMainTab]
  );
 
  useEffect(() => {
    if (document.body) {
      if (header_state === true) {
        document.body.classList.add("vertical-collpsed", "sidebar-enable");
      }
      if (header_state === false) {
        document.body.classList.remove("vertical-collpsed");
      }
    }
  }, [header_state]);
 
  return (<>
    <Nav tabs className="nav-tabs-custom nav-justified mr-2">
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={patientMainTab === "1" ? "active" : ""}
          onClick={() => {
            toggle("1");
          }}
        >
          <span className="d-none d-sm-block Patient-name">Patients</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={patientMainTab === "2" ? "active" : ""}
          onClick={() => {
            toggle("2");
            // dispatch({
            //   type: ActionTypes.SET_Patient_TAB,
            //   payload: undefined,
            // });
          }}
        >
          <span className="d-none d-sm-block Patient-name">
            {" "}
            Create
          </span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={patientMainTab === "3" ? "active" : ""}
          onClick={() => {
            toggle("3");
            // dispatch({
            //   type: ActionTypes.SET_Patient_TAB,
            //   payload: undefined,
            // });
          }}
        >
          <span className="d-none d-sm-block hospital-name">
            {" "}
            Admission
          </span>
        </NavLink>
      </NavItem>
    </Nav>
  </>
  );
};
 
export default PatientHeader;
 