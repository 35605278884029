import { ActionTypes, hospitalTabActions } from "../types/hospitalTabsTypes";

interface initialStateTypes {
  currentActiveTab: string | any;
  HospitalTab: any;
  HospitalTabs: [] | any;
}

const initialState: initialStateTypes = {
  currentActiveTab: "1",
  HospitalTab: null,
  HospitalTabs: [],
};

export const hospitalTabReducer = (state: initialStateTypes = initialState, actions: hospitalTabActions): initialStateTypes => {

  switch (actions.type) {

    case ActionTypes.ADD_HOSPITAL_TAB:
      const existingHospitalIndex = state.HospitalTabs.findIndex((hospital: { id: string, name: string }) => hospital?.id === actions?.payload?.id);
      if (existingHospitalIndex !== -1) {
        const updatedHospitalTabs = [...state.HospitalTabs];
        updatedHospitalTabs[existingHospitalIndex].name = actions?.payload?.name;
        return { ...state, HospitalTabs: updatedHospitalTabs };
      } else {
        return { ...state, HospitalTabs: [...state.HospitalTabs, actions?.payload] };
      };

    case ActionTypes.REMOVE_HOSPITAL_TAB:
      return {
        ...state,
        HospitalTabs: state.HospitalTabs.filter((htab: any) => {
          return htab?.id !== actions?.payload?.id;
        }),
      };

    case ActionTypes.SET_CURRENT_ACTIVE_TAB:
      return {
        ...state,
        currentActiveTab: actions?.payload?.id,
      };

    case ActionTypes.SET_HOSPITAL_TAB:
      return {
        ...state,
        HospitalTab: actions?.payload,
      };
    case ActionTypes.NULL_HOSPITAL_TABS:
      return {
        ...state,
        currentActiveTab: "1",
        HospitalTab: null,
        HospitalTabs: [],
      };
    default:
      return state;
  }
};
