import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import { TabContent, TabPane } from "reactstrap";
import AccessPolicyForm from "../../AccessPolicy/AccessPolicyForm";
import RegisterForm from "./RegisterForm";
import { register_user, update_user } from "../../../redux/actions/userAction";
import { useSelector } from "react-redux";
import { Console } from "console";

const UserForm: React.FC<{
  isFormOpen: boolean;
  setIsFormOpen: any;
  user?: any;
  mode?: string;
  fetch_users?: any;
}> = ({ isFormOpen, setIsFormOpen, user, mode, fetch_users }) => {
  const [access_policy, setaccess_policy]: any = useState([]);
  const { user_info } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (user?.user_access !== undefined && mode === "edit") {
      setaccess_policy(user?.user_access);
    } else {
      setaccess_policy([]);
    }
  }, [user, mode]);

  const [subTab, setsubTab] = useState("1");
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    gender: Yup.string()
      .oneOf(["male", "female"], "Invalid gender")
      .required("Gender is required"),
    date_of_birth: Yup.date().required("Date of birth is required"),
    city_id: Yup.string().required("City ID is required"),
    address_1: Yup.string().required("Address 1 is required"),
    address_2: Yup.string().required("Address 2 is required"),
    user_type_id: Yup.string().required("User type ID is required"),
    mobile: Yup.string().required("Mobile number is required"),
    landline: Yup.string().required("landline is required"),
    degree: Yup.string().required("Degree is required"),
    specialist: Yup.array().required("Specialist is required"),
    general_availability_slots: Yup.string().required(
      "General availability slots are required"
    ),
    password: user
      ? Yup.string().notRequired()
      : Yup.string()
        .required("Password is required")
        .matches(/\d/, "At least one number is required")
        .matches(/[A-Z]+/, "At least one capital letter is required")
        .matches(/[@#$%^&!*_]+/, "At least one special character is required")
        .min(6, "Password must be at least 6 characters long")
        .max(20, "Password must not exceed 20 characters"),

    hospital_property_id: Yup.string().required(
      "Hospital property ID is required"
    ),
    is_super_admin: Yup.boolean().required("Super admin is required"),
    is_admin: Yup.boolean().optional(),
    // reg_number: Yup.string().required("Reg Number is required"),
  });

  const getHospitalPropertyId = () => {
    let id = "";
    if (user_info?.is_super_admin === false && !user?.hospital_property?.id) {
      id = user_info?.hospital_property?.id;
    }
    else if (user?.hospital_property?.id) {
      id = user?.hospital_property?.id;
    }
    return id;
  }

  const getHospitalId = () => {
    let id = "";
    if (user_info?.is_super_admin === false && !user?.hospital_property?.hospital?.id) {
      id = user_info?.hospital_property?.hospital?.id;
    }
    else if (user?.hospital_property?.hospital?.id) {
      id = user?.hospital_property?.hospital?.id;
    }
    return id;
  }


  const initialValues = {
    name: user?.name ?? "",
    email: user?.email ?? "",
    gender: user?.gender ?? "",
    date_of_birth: user?.date_of_birth ?? "",
    city_id: user?.city?.id ?? "",
    address_1: user?.address_1 ?? "",
    address_2: user?.address_2 ?? "",
    user_type_id: user?.user_type?.id ?? "",
    mobile: user?.mobile ?? "",
    landline: user?.landline ?? "",
    degree: user?.degree ?? "",
    photo_url: user?.photo_url ?? "https://picsum.photos/200",
    specialist: user?.specialist ?? [],
    general_availability_slots: user?.general_availability_slots ?? "",
    hospital_property_id: getHospitalPropertyId(),
    hospital_id: getHospitalId(),
    is_super_admin: user?.is_super_admin === true ? true : false,
    is_amdin: user?.is_admin === true ? true : false,
    password: "",
    reg_number: user?.reg_number ?? "",
    service_start_date: user?.service_start_date ?? "",
  };

  const submitForm = async (values: any, resetForm: any) => {
    try {
      let response;
      if (user?.id) {
        const { password, ...rest } = values;
        response = await update_user({
          ...rest,
          user_access: access_policy,
          id: user.id,
        });
      } else {
        response = await register_user({
          ...values,
          user_access: access_policy,
        });
      }
      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        resetForm();
        setIsFormOpen(false);
        fetch_users();
        setaccess_policy([]);
      } else {
        if (response?.errors?.length > 0) {
          ErrorToast(response?.errors[0]?.msg);
        }
      }
    } catch (error) {
      ErrorToast(
        "An error occurred while submitting the form. Please try again."
      );
    }
  };

  return (
    <>
      {isFormOpen && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { resetForm }) => {
            submitForm(values, resetForm);
          }}
        >
          {({
            isValid,
            isSubmitting,
            resetForm,
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            dirty,
            setFieldValue,
          }) => (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  setFieldValue("user_access", access_policy);
                  if (errors) {
                    const firstError: any = Object.values(errors)[0];

                    if (Array.isArray(firstError)) {
                      const errorMessage = firstError.join(", ");
                      ErrorToast(errorMessage);
                    } else ErrorToast(firstError);
                  }
                  handleSubmit();
                }}
              >
                <div className="right-panel user-form-detail-panel">
                  <h4>{mode === "edit" ? "Edit User" : "Add User"}</h4>
                  <button
                    className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light "
                    onClick={() => setIsFormOpen(false)}
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                  <div className="inner-pan-box">
                    <ul className="nav-tabs-custom list-view-tab nav-justified nav nav-tabs">
                      <li className="nav-item" onClick={() => setsubTab("1")}>
                        <div
                          className={`nav-link ${subTab === "1" ? "active" : ""}`}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="d-none d-sm-block">Credentials</span>
                        </div>
                      </li>
                      <li className="nav-item" onClick={() => setsubTab("2")}>
                        <div
                          className={`nav-link ${subTab === "2" ? "active" : ""}`}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="d-none d-sm-block">Access Policy</span>
                        </div>
                      </li>
                    </ul>

                    <TabContent activeTab={subTab} className="text-muted mt-3">
                      <TabPane tabId="1">
                        <RegisterForm
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          user={user}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <AccessPolicyForm
                          access_policy={access_policy}
                          setaccess_policy={setaccess_policy}
                        />
                      </TabPane>
                    </TabContent>

                    <div className="col-12 text-center">
                      <button
                        className="btn btn-primary mr-2 medium-btn"
                        type="submit"
                      >
                        {user ? "Update" : "Add"}
                      </button>
                      <button
                        className="btn btn-secondary medium-btn"
                        onClick={() => {
                          resetForm();
                          setaccess_policy([]);
                        }}
                      >
                        Reset{" "}
                      </button>
                    </div></div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default UserForm;

