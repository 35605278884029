

import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';
import { formatDateToYYYYMMDD, formatDateToYYYYMMDDHHMM } from '../../../helpers/date_functions';
import { IMuiDatePickerProps } from './types';
import { useState } from 'react';
import { calculateAge } from '../../../helpers/helperFunctions';

const CustomMUIDatePicker = (props: IMuiDatePickerProps) => {
    const { setState, name, value, sx, type = "simple", useDateTimePicker, setAge, disabled } = props;
    const [selectedDateTime, setSelectedDateTime] = useState(value ? new Date(value) : new Date());

    const handleChange = (newDate: Date | null) => {
        if (newDate) {
            console.log("New Date/Time:", newDate);
            const formattedDateTime = formatDateToYYYYMMDDHHMM(newDate);
            console.log("Formatted Date/Time:", formattedDateTime);
            if (type === "formik") {
                setState(name, formattedDateTime);
            } else {
                setState(formattedDateTime);
            }
        }
    };

    return (
        <>
            {!useDateTimePicker &&
                <DatePicker
                    maxDate={props?.maxDate ? props?.maxDate : null}
                    format="dd-MM-yyyy"
                    sx={{
                        ...sx,
                        ".MuiOutlinedInput-input": {
                            color: "#fff",
                            height: "4px",
                            fontWeight: 400,
                            fontSize: "0.8125rem",
                            border: "none"
                        }
                    }}
                    value={parseISO(value)}
                    onChange={(newDate) => {
                        const formattedDate = formatDateToYYYYMMDD(newDate as Date);
                        if (type === "formik") {
                            setState(name, formattedDate);
                            if (name === "birth_date" && setAge) {
                                setState("age", "");
                                const calculatedAge = calculateAge(formattedDate);
                                setAge(Number(calculatedAge))
                                setState("age", calculatedAge);
                            }
                        } else {
                            setState(formattedDate);
                        }
                    }}
                    slotProps={{ textField: { fullWidth: true } }}
                    disabled={disabled}
                />}

            {useDateTimePicker && (
                <DateTimePicker
                    className=''
                    format="dd-MM-yyyy hh:mm a"
                    sx={{
                        ...sx,
                        ".MuiOutlinedInput-input": {
                            color: "#fff",
                            height: "4px",
                            fontWeight: 400,
                            fontSize: "0.8125rem",
                            border: "none"
                        }
                    }}
                    minDate={props?.minDate !== null ? props?.minDate : undefined}
                    maxDate={props?.maxDate !== null ? props?.maxDate : undefined}
                    value={selectedDateTime}
                    onChange={handleChange}
                    slotProps={{ textField: { fullWidth: true } }}
                    disabled={disabled}
                />
            )}
        </>
    )
}

export default CustomMUIDatePicker;