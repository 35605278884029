import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, CardBody, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

const ProgressiveReports = () => {
    useEffect(() => {
    }, []);
    return (
        <>
            <div className="row">
                <div className="col-12 col">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <div className="line">
                            <input type="text" className="form-control" placeholder="Start Date"></input>
                            <input type="text" className="form-control mr-2" placeholder="End Date"></input>

                            <button className="btn btn-primary mr-2">Search</button>
                            <button className="btn btn-secondary">Reset</button>
                        </div>
                        <div className="page-title-right d-flex">
                            <Dropdown>
                                {/* <DropdownToggle tag="button" className="btn btn-success">
                                    Export <i className="mdi mdi-chevron-down" />
                                </DropdownToggle> */}
                                <Button tag="button" className="btn btn-success">
                                    Export <i className="mdi mdi-chevron-down" />
                                </Button>
                                <DropdownMenu>
                                    <DropdownItem>CSV</DropdownItem>
                                    <DropdownItem>PDF</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <Card>
                        <CardTitle></CardTitle>
                        <CardBody>
                            <div className="table-responsive fixed-table-head">
                                <table className="table">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Sr No.</th>
                                            <th>Hospital Name</th>
                                            <th>Total Patient </th>
                                            <th colSpan={3} >Type Of Admission</th>
                                            <th colSpan={3}>Type Of Discharge</th>
                                            <th>Avg. Length Of Stay</th>
                                            {/* <th>Age</th>
                                <th>Gender</th>
                                <th>Previously Diagnosis</th>
                                <th>Admit Date</th>
                                <th>Discharge Date</th>
                                <th>Total Days</th>
                                <th>Type Of Discharge</th> */}


                                        </tr>
                                        <tr>
                                            <th rowSpan={2}></th>
                                            <th rowSpan={2}></th>
                                            <th rowSpan={2}></th>
                                            <th>CV Stroke</th>
                                            <th>Drug Poisoning</th>
                                            <th>Other</th>
                                            <th>Transfer To Wrap</th>
                                            <th>CRS Discharge Type</th>
                                            <th>Uneder Treatment</th>
                                            <th rowSpan={2}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>KD Hospital</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>1</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>2</td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default ProgressiveReports;