import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { GridActionsCellItem, GridColDef, GridRowModel } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import DataGridTable from "../../../components/DataGrid/DataGridTable";
import { deleteCountry, getCountryList, postCoutry, updateCountry } from "../../../redux/actions/countryAction";
import SaveIcon from "@mui/icons-material/Save";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import { ICountriesProps, Country, IDeleteCountryParams } from "../../../redux/types/countryTypes";
import { findTextInString } from "../../../helpers/helperFunctions";
import DeleteConfirmationModal from "../../DeletePopupComponent/DeletePopup";



const Countries = ({ isActiveTab }: ICountriesProps) => {
  const initialRows = useMemo(() => [{ id: "1", country: "" }], []);
  type Row = (typeof initialRows)[number];
  const [page_number, setPage_number] = useState(1);
  const [per_page, setPer_page] = useState(0);
  const [rows, setRows] = useState<any[]>(initialRows);
  const [rowId, setrowId] = useState(null);
  const [globalSearch, setGlobalSearch] = useState("");
  const handlePageChange = (params: any) => setPage_number(params.page);
  const handlePageSizeChange = (params: any) => setPer_page(params.pageSize);
  const processRowUpdate = (newRow: GridRowModel) => newRow;
  const onCellEditCommit = (params: any) => setrowId(params?.id);
  const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const addRow = () => {
    if (rows?.some((row) => row?.id === initialRows[0]?.id)) {
      ErrorToast("Please save last added row.")
      return rows;
    };
    setRows((prevRows) => [{ id: "1", country: "" }, ...prevRows])
  };

  const filteredRows = useMemo(() => {
    if (!Array.isArray(rows)) return [];
    return rows.filter(({ country }) => findTextInString(country, globalSearch));
  }, [rows, globalSearch]);
  
  /* UPDATE - ADD  COUNTRY */
  const handleUpdateCountry = useCallback(
    async ({ id, country }: Country) => {
      try {
        if (country.trim() === "")
          return ErrorToast("Country is required field!");
        let response;
        if (id === initialRows[0]?.id) response = await postCoutry({country});
        else response = await updateCountry({ id, country });

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          const { body } = await getCountryList({ per_page: per_page, page_number: page_number });
          if (body) setRows(body?.data);
          else setRows([]);
          setrowId(null);
        } else if (response?.statusCode === 400) ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [initialRows, per_page, page_number]
  );

  /* DELETE COUNTRY */
  const handleDeleteCountry = useCallback(
    async ({ id }: IDeleteCountryParams) => {
      try {
        if (id === initialRows[0]?.id) return ErrorToast("Record is not saved yet!");
        const response = await deleteCountry({ id });

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          const { body } = await getCountryList({ per_page: per_page, page_number: page_number });
          if (body) setRows(body?.data);
          else setRows([]);
        } else if (response?.statusCode === 400) ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [initialRows, per_page, page_number]
  );

  const handleDeleteConfirmation = useCallback(() => {
    if (deleteRowId) {
      handleDeleteCountry({ id: deleteRowId });
      setDeleteRowId(null);
      setIsDeleteModalOpen(false);
    }
  }, [deleteRowId, handleDeleteCountry]);

  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: "country",
        type: "TextField",
        flex: 1,
        headerName: "Country",
        filterable: true,
        sortable: true,
        editable: true,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        headerName: "actions",
        type: "actions",
        width: 150,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            color={"primary"}
            disabled={params?.id !== rowId}
            onClick={() =>
              handleUpdateCountry({
                id: params.row.id.toString(),
                country: params.row.country,
              })
            }
          />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            disabled={params?.id === initialRows[0]?.id}
            color="error"
            onClick={() => {
              setDeleteRowId(params.row.id.toString());
              toggleDeleteModal()
            }}
          />,
        ],
      },
    ],
    [toggleDeleteModal, handleUpdateCountry, initialRows, rowId]
  );

  useEffect(() => {
    async function fetchData() {
      const { body } = await getCountryList({
        per_page: per_page,
        page_number: page_number,
      });
      setRows(body?.data);
    }
    if (isActiveTab) {
      fetchData();
    }
  }, [isActiveTab, page_number, per_page]);




  return (
    <>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        pageName="country"
        onConfirm={handleDeleteConfirmation}
      />
      <DataGridTable
        pageSizeChange={handlePageSizeChange}
        pageChange={handlePageChange}
        addRow={addRow}
        columns={columns}
        rows={filteredRows}
        processRowUpdate={processRowUpdate}
        onCellEditCommit={onCellEditCommit}
        setGlobalSearch={setGlobalSearch}
      />

    </>
  );
};

export default Countries;
