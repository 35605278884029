import React, { useEffect, useState } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TabContent, TabPane } from "reactstrap";
import { save_token, update_user } from "../../redux/actions/userAction";
import { ErrorToast, SuccessToast } from "../../utils/toastNotifications";
import EditForm from "./EditForm";
import { useDispatch, useSelector } from "react-redux";

const ProfileEdit: React.FC<{
  isFormOpen: boolean;
  setIsFormOpen: any;
  user?: any;
}> = ({ isFormOpen, setIsFormOpen, user }) => {
  const dispatch: any = useDispatch();
  const { token } = useSelector((state: any) => state.user);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    gender: Yup.string()
      .oneOf(["male", "female"], "Invalid gender")
      .required("Gender is required"),
    date_of_birth: Yup.date().required("Date of birth is required"),
    city_id: Yup.string().required("City ID is required"),
    address_1: Yup.string().required("Address 1 is required"),
    address_2: Yup.string().required("Address 2 is required"),
    user_type_id: Yup.string().required("User type ID is required"),
    mobile: Yup.string().required("Mobile number is required"),
    landline: Yup.string().required("landline is required"),
    degree: Yup.string().required("Degree is required"),
    specialist: Yup.string().required("Specialist is required"),
    general_availability_slots: Yup.string().required(
      "General availability slots are required"
    ),
    password: user
      ? Yup.string().notRequired()
      : Yup.string()
        .required("Password is required")
        .matches(/\d/, "At least one number is required")
        .matches(/[A-Z]+/, "At least one capital letter is required")
        .matches(/[@#$%^&!*_]+/, "At least one special character is required")
        .min(6, "Password must be at least 6 characters long")
        .max(20, "Password must not exceed 20 characters"),

    hospital_property_id: Yup.string().required(
      "Hospital property ID is required"
    ),
  });

  const initialValues = {
    name: user?.name ?? "",
    email: user?.email ?? "",
    gender: user?.gender ?? "",
    date_of_birth: user?.date_of_birth ?? "",
    city_id: user?.city?.id ?? "",
    address_1: user?.address_1 ?? "",
    address_2: user?.address_2 ?? "",
    user_type_id: user?.user_type?.id ?? "",
    mobile: user?.mobile ?? "",
    landline: user?.landline ?? "",
    degree: user?.degree ?? "",
    photo_url: user?.photo_url ?? "https://picsum.photos/200",
    specialist: user?.specialist ?? "",
    general_availability_slots: user?.general_availability_slots ?? "",
    hospital_property_id: user?.hospital_property?.id ?? "",
    hospital_id: user?.hospital_property?.hospital?.id ?? "",
  };

  const submitForm = async (values: any, resetForm: any) => {
    try {
      let response;
      if (user?.id) {
        const { password, ...rest } = values;
        response = await update_user({
          ...rest,
          user_access: user?.access_policy,
          id: user.id,
        });
      }
      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        resetForm();
        setIsFormOpen(false);
        dispatch(save_token(response?.body?.updated_user, token));
      } else {
        if (response?.errors?.length > 0) {
          ErrorToast(response?.errors[0]?.msg);
        }
      }
    } catch (error) {
      ErrorToast(
        "An error occurred while submitting the form. Please try again."
      );
    }
  };

  return (
    <>
      {isFormOpen && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { resetForm }) => {
            submitForm(values, resetForm);
          }}
        >
          {({
            isValid,
            isSubmitting,
            resetForm,
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            dirty,
            setFieldValue,
          }) => (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();

                  if (errors) {
                    const firstError: any = Object.values(errors)[0];

                    if (Array.isArray(firstError)) {
                      const errorMessage = firstError.join(", ");
                      ErrorToast(errorMessage);
                    } else ErrorToast(firstError);
                  }
                  handleSubmit();
                }}
              >
                <div className="right-panel">
                  <h4>Edit Profile</h4>
                  <button
                    className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light "
                    onClick={() => setIsFormOpen(false)}
                  >
                    <i className="mdi mdi-close"></i>
                  </button>

                  <EditForm
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    user={user}
                    setFieldValue={setFieldValue}
                    values={values}
                  />

                  <div className="col-12 text-center mb-5">
                    <button
                      className="btn btn-primary mr-2 medium-btn"
                      type="submit"
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-secondary medium-btn"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Reset{" "}
                    </button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default ProfileEdit;
