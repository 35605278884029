import axiosApi from "../../api/apiHelper";
import { patient_admission_systemic_examination_routes } from "../api_path";
import { IPatientAdmissionSystemicExaminationTypes } from "../types/patientAdmissionSystemicExaminationTypes";

export const postPatientAdmissionSystemicExamination = async (values: IPatientAdmissionSystemicExaminationTypes) => {
    try {
        const { data } = await axiosApi.post(patient_admission_systemic_examination_routes.post_patient_admission_systemic_examination, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const getPatientAdmissionSystemicExaminationByPatientAdmissionId = async (
    patient_admission_id: string,
) => {
    try {
        const { data } = await axiosApi.get(`${patient_admission_systemic_examination_routes.get_patient_systemic_examination_by_patient_admission_id}?patient_admission_id=${patient_admission_id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const putPatientAdmissionSystemicExamination = async (values: any) => {
    try {
        const { data } = await axiosApi.put(patient_admission_systemic_examination_routes.put_patient_admission_systemic_examination, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const deletePatientAdmissionSystemicExamination = async (id: string) => {
    try {
        const { data } = await axiosApi.delete(`${patient_admission_systemic_examination_routes.delete_patient_admission_systemic_examination}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const postPatientAdmissionSystemicExaminationBatch = async (values: IPatientAdmissionSystemicExaminationTypes[]) => {
    try {
        const { data } = await axiosApi.post(patient_admission_systemic_examination_routes.post_patient_admission_systemic_examination_batch, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};
export const putPatientAdmissionSystemicExaminationBatch = async (values: IPatientAdmissionSystemicExaminationTypes[]) => {
    try {
        const { data } = await axiosApi.put(patient_admission_systemic_examination_routes.put_patient_admission_systemic_examination_batch, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};