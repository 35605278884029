import { Field, ErrorMessage } from "formik";
import { FC, useEffect, useState } from "react";
import { DatePicker } from '@mui/x-date-pickers';
import { getCityList } from "../../redux/actions/cityAction";
import { ICitiesOptions } from "../../redux/types/cityTypes";
import { getUserTypesList } from "../../redux/actions/userTypesAction";
import { IUserTypeOptions } from "../../redux/types/userTypeTypes";
import CustomMUIDatePicker from "../../components/UI/CustomMUIDatePicker";

const EditForm: FC<any> = ({ handleBlur, handleChange, values, setFieldValue }) => {
  const [all_cities, set_all_cities] = useState([]);
  const [all_user_types, set_all_user_types] = useState([]);

  const fetch_all_cities = async () => {
    const { body } = await getCityList({});
    if (body?.data) {
      const get_list_of_cities = body?.data?.map((e: ICitiesOptions) => ({
        city: e?.city,
        id: e?.id,
      }));
      set_all_cities(get_list_of_cities);
    } else {
      set_all_cities([]);
    }
  };

  const fetch_all_user_types = async () => {
    const { body } = await getUserTypesList({});
    if (body?.data) {
      const get_list_of_user_types = body?.data?.map((e: IUserTypeOptions) => ({
        user_type: e?.user_type,
        id: e?.id,
      }));
      set_all_user_types(get_list_of_user_types);
    } else {
      set_all_user_types([]);
    }
  };

  useEffect(() => {
    fetch_all_cities();
    fetch_all_user_types();
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Name</label>
          <Field
            name="name"
            className="form-control"
            type="text"
            placeholder="Enter Name"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage name="name" component="div" className="text-danger" />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Email</label>
          <Field
            name="email"
            className="form-control"
            type="text"
            placeholder="Enter Email"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage name="email" component="div" className="text-danger" />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Gender</label>
          <div className="form-check">
            <label className="form-check-label">
              <Field
                type="radio"
                name="gender"
                value="male"
                className="form-check-input"
                onBlur={handleBlur}
                onChange={handleChange}
                checked={values?.gender === "male"}
              />
              Male
            </label>
          </div>
          <div className="form-check">
            <label className="form-check-label">
              <Field
                type="radio"
                name="gender"
                value="female"
                className="form-check-input"
                onBlur={handleBlur}
                onChange={handleChange}
                checked={values?.gender === "female"}
              />
              Female
            </label>
          </div>
          <ErrorMessage name="gender" component="div" className="text-danger" />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Date of Birth</label>
          <CustomMUIDatePicker
            name="date_of_birth"
            type="formik"
            setState={setFieldValue}
            value={values?.date_of_birth}
          />
          <ErrorMessage
            name="date_of_birth"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">City</label>
          <Field
            name="city_id"
            as="select"
            className="form-control"
            onBlur={handleBlur}
            onChange={handleChange}
          >
            <option value="" label="Select a City" disabled />
            {all_cities.length > 0 ? (
              all_cities.map(({ city, id }: ICitiesOptions, index: number) => (
                <option key={index} value={id}>
                  {city}
                </option>
              ))
            ) : (
              <option value="" disabled>
                No Cities Found
              </option>
            )}
          </Field>
          <ErrorMessage
            name="city_id"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Address 1</label>
          <Field
            name="address_1"
            className="form-control"
            type="text"
            placeholder="Enter Address"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage
            name="address_1"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Address 2</label>
          <Field
            name="address_2"
            className="form-control"
            type="text"
            placeholder="Enter Address"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage
            name="address_2"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">User Type</label>
          <Field
            name="user_type_id"
            as="select"
            className="form-control"
            onBlur={handleBlur}
            onChange={handleChange}
          >
            <option value="" label="Select a User Type" disabled />
            {all_user_types.length > 0 ? (
              all_user_types.map(
                ({ user_type, id }: IUserTypeOptions, index: number) => (
                  <option key={index} value={id}>
                    {user_type}
                  </option>
                )
              )
            ) : (
              <option value="" disabled>
                No User Types Found
              </option>
            )}
          </Field>
          <ErrorMessage
            name="user_type_id"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Mobile</label>
          <Field
            name="mobile"
            className="form-control"
            type="text"
            placeholder="Enter Mobile"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage name="mobile" component="div" className="text-danger" />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Landline</label>
          <Field
            name="landline"
            className="form-control"
            type="text"
            placeholder="Enter Landline"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage
            name="landline"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Degree</label>
          <Field
            name="degree"
            className="form-control"
            type="text"
            placeholder="Enter Degree"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage name="degree" component="div" className="text-danger" />
        </div>
        <div className="col-12"></div>
        <div className="mb-3">
          <label className="form-label">Specialist</label>
          <Field
            name="specialist"
            className="form-control"
            type="text"
            placeholder="Enter Specialist"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage
            name="specialist"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">General Availability Slots</label>
          <Field
            name="general_availability_slots"
            className="form-control"
            type="time"
            placeholder="Enter General Availability Slots"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage
            name="general_availability_slots"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
    </div>
  );
};

export default EditForm;
