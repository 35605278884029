import React, { useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
} from "reactstrap";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { ErrorToast, SuccessToast } from "../../utils/toastNotifications";
import { useNavigate } from "react-router-dom";
import profile from "../../assets/images/login.png";
import {
  fetch_user_ip_address,
  login_log,
  login_mobile_otp,
  login_user_by_phone_number,
  save_token,
} from "../../redux/actions/userAction";
import { useDispatch } from "react-redux";

const LoginWithMobileSchema = Yup.object().shape({
  mobile_number: Yup.string().required("Mobile number is required"),
});

const LoginWithMobile = () => {
  const [Otp, setOtp] = useState("");
  const [otp_sent, setotp_sent] = useState(false);
  const [otp_error, setotp_error] = useState("");
  const navigate: any = useNavigate();
  const dispatch: any = useDispatch();

  const send_code = async (values: any) => {
    console.log(values);
    let code_sending = await login_mobile_otp(values.mobile_number);

    if (code_sending?.statusCode === 200) {
      setotp_sent(true);
      SuccessToast("Otp sent successfully");
    } else {
      console.log(code_sending);
      ErrorToast(code_sending?.errors[0]?.msg);
    }
  };

  const check_code = async (values: any) => {
    if (Otp.length < 4) {
      setotp_error("Otp is not valid");
      setTimeout(() => {
        setotp_error("");
      }, 2000);
      return;
    }
    let login_user_request = await login_user_by_phone_number(
      values.mobile_number,
      Otp
    );

    if (login_user_request?.statusCode === 200) {
      let request = await fetch_user_ip_address();
      await login_log({
        device: navigator.userAgent,
        ip_address: request?.query,
        city: request?.regionName,
        country: request?.country,
        user_id: login_user_request?.body?.id,
      });
      SuccessToast(login_user_request?.message);
      localStorage.setItem("tele_icu_token", login_user_request?.body?.token);
      dispatch(
        save_token(
          login_user_request?.body?.user,
          login_user_request?.body?.token
        )
      );
      navigate("/hospitals");
    } else {
      if (login_user_request?.errors?.length > 0) {
        ErrorToast(login_user_request?.errors[0]?.msg);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden mt-5">
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Link to="/" className="text-center login-img">
                      <img src={profile} alt="" className="img-fluid" />
                    </Link>
                    <Formik
                      initialValues={{
                        mobile_number: "",
                      }}
                      validationSchema={LoginWithMobileSchema}
                      onSubmit={otp_sent ? check_code : send_code}
                    >
                      {(formik) => {
                        const {
                          values,
                          handleChange,
                          handleSubmit,
                          errors,
                          touched,
                          handleBlur,
                          isValid,
                          dirty,
                        } = formik;
                        return (
                          <Form
                            className="form-horizontal"
                            onSubmit={handleSubmit}
                          >
                            <h4 className="card-title mb-3 mt-2">
                              Login With Mobile
                            </h4>
                            <div className="mb-3 email-box">
                              <Label className="form-label">
                                Mobile Number
                              </Label>
                              <Input
                                name="mobile_number"
                                className="form-control"
                                placeholder="Enter mobile number"
                                type="text"
                                value={values.mobile_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.mobile_number &&
                                touched.mobile_number && (
                                  <span className="error">
                                    {errors.mobile_number}
                                  </span>
                                )}
                            </div>
                            {otp_sent && (
                              <div className="mb-3">
                                <Label className="form-label">OTP</Label>
                                <OtpInput
                                  value={Otp}
                                  onChange={setOtp}
                                  numInputs={4}
                                  renderSeparator={
                                    <span style={{ margin: 5 }}> </span>
                                  }
                                  renderInput={(props) => <input {...props} />}
                                  containerStyle={{
                                    justifyContent: "center",
                                  }}
                                  inputStyle={{
                                    backgroundColor: "transparent",
                                    width: 95,
                                    color: "white",
                                    height: 30,
                                    borderRadius: 5,
                                    border: "1px solid #3e465f",
                                  }}
                                />
                                <span className="error">
                                  {otp_error && otp_error}
                                </span>
                              </div>
                            )}
                            <div className="mt-3 d-grid">
                              <button
                                type="submit"
                                className={
                                  dirty && isValid
                                    ? "btn btn-primary btn-block"
                                    : "btn btn-primary btn-block disabled-btn"
                                }
                                disabled={!(dirty && isValid)}
                              >
                                {otp_sent ? "Login" : "Send Otp"}
                              </button>
                            </div>
                            <div className="mt-4 text-center text-muted">
                              Did&apos;t Receive a Code ?
                              <Link
                                to="/forgot-password"
                                className="text-secondry"
                              >
                                &nbsp; Resend
                              </Link>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <Link to="/" className="text-secondry  mb-4 d-block">
                  Login With Email Address
                </Link>

                <p>
                  © {new Date().getFullYear()} Entel. Crafted With{" "}
                  <i className="mdi mdi-heart text-danger" /> By{" "}
                  <a
                    className="text-muted"
                    href="https://www.pixielit.com/"
                    title="Pixielit
                  Studios"
                  >
                    {" "}
                    Pixielit Studios.{" "}
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <p className="account-pages-footer">
          <Link className="text-muted" to="/">
            Privacy Policy{" "}
          </Link>{" "}
          &nbsp; &nbsp; | &nbsp; &nbsp;
          <Link className="text-muted" to="/">
            Terms & Conditions
          </Link>
        </p>
      </div>
    </React.Fragment>
  );
};

export default LoginWithMobile;
