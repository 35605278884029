import { patient_admission_clinical_notes_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";

export const postPatientAdmissionClinicalNotes = async (values: any) => {
    try {
        const { data } = await axiosApi.post(
            patient_admission_clinical_notes_routes.post_patient_admission_clinical_notes,
            values
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const getPatientAdmissionClinicalNotesByPatientAdmissionId = async (
    patient_admission_id: string,
) => {
    try {
        const { data } = await axiosApi.get(
            `${patient_admission_clinical_notes_routes.get_patient_clinical_notes_by_patient_admission_id}?patient_admission_id=${patient_admission_id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const putPatientAdmissionClinicalNotes = async (values: any) => {
    try {
        const { data } = await axiosApi.put(
            patient_admission_clinical_notes_routes.put_patient_admission_clinical_notes,
            values
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const putPatientAdmissionClinicalNotesIsRead = async (id: string, is_read: boolean) => {
    try {
        const { data } = await axiosApi.put(
            `${patient_admission_clinical_notes_routes.put_patient_admission_clinical_notes_is_read}`,
            { id,is_read } // Send the is_read status in the request body
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};


export const deletePatientAdmissionClinicalNotes = async (id: string) => {
    try {
        const { data } = await axiosApi.delete(
            `${patient_admission_clinical_notes_routes.delete_patient_admission_clinical_notes}/${id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const getPatientAdmissionClinicalNotesByDoctor = async (doctor_id: string) => {
    try {
        const { data } = await axiosApi.get(
            `${patient_admission_clinical_notes_routes.get_patient_admission_by_doctor_id}?doctor_id=${doctor_id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }

}

export const getPatientAdmissionClinicalnotesByDoctorId = async (
    doctor_id: string
) => {
    try {
        const { data } = await axiosApi.get(
            `${patient_admission_clinical_notes_routes.get_patient_admission_by_doctor_id}?doctor_id=${doctor_id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}