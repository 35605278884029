import React, { useCallback, useEffect, useState, useMemo } from "react";
import * as Yup from "yup";
import { Label, Collapse } from "reactstrap";
import { GridSearchIcon } from "@mui/x-data-grid";
import {
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ErrorToast, SuccessToast } from "../../../../utils/toastNotifications";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  findTextInString,
  formatDateToLocaleDateString,
} from "../../../../helpers/helperFunctions";
import { IPatientAdmissionDietryTypes } from "../../../../redux/types/patientAdmissionDietryTypes";
import {
  deletePatientAdmissionDietry,
  getPatientAdmissionDietryByPatientAdmissionId,
  postPatientAdmissionDietry,
  putPatientAdmissionDietry,
} from "../../../../redux/actions/patientAdmissionDietry";
import { getDietryTypesList } from "../../../../redux/actions/dietryAction";
import CustomMUIDatePicker from "../../../../components/UI/CustomMUIDatePicker";
import { useSelector } from "react-redux";
import DeleteConfirmationModal from "../../../../components/DeletePopupComponent/DeletePopup";

const Dietry = ({ selected_admission }: { selected_admission: any }) => {
  const [dietry, setDietry] = useState<IPatientAdmissionDietryTypes[]>([]);
  const [collapsedItems, setCollapsedItems] = useState<boolean[]>([]);
  const [isDietryOpen, setIsDietryOpen] = useState<boolean>(false);
  const [dietryTypes, setDietryTypes] =
    useState<{ name: string; id: string }[]>();
  const [searchText, setSearchText] = useState("");
  const [editData, setEditData] =
    useState<IPatientAdmissionDietryTypes | null>();
  const { user_info } = useSelector((state: any) => state.user);
  useEffect(() => {
    setCollapsedItems(new Array(dietry?.length).fill(true));
  }, [dietry]);
  const validationSchema = Yup.object({
    dietry_type_id: Yup.string().required("Dietary type is required"),
    date: Yup.date().required('Date is required').default(() => new Date()), 
    // day: Yup.string().required("Day is required"), 
    meal_name: Yup.string().required("Meal name is required"),
    // meal_start_time: Yup.string().required("Meal Start time is required"),
    // meal_end_time: Yup.string().required("Meal End time is required"),
    // meal_type: Yup.string().required("Meal type is required"),
    meal_plan: Yup.string().required("Meal plan is required"),
    // volume: Yup.string().required("Volume is required"),
    // calories: Yup.string().required("Calories is required"),
    // proteins: Yup.string().required("Proteins is required"),
    patient_admission_id: Yup.string(),
  });

    // State for delete confirmation modal
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);

  const initialValues = {
    dietry_type_id: editData?.dietry_type?.id || "",
    date: editData?.date ||new Date().toISOString(),
    day: editData?.day || "",
    meal_name: editData?.meal_name || "",
    meal_type: editData?.meal_type || "",
    meal_plan: editData?.meal_plan || "",
    meal_start_time: editData?.meal_start_time || "",
    meal_end_time: editData?.meal_end_time || "",
    volume: editData?.volume || "",
    calories: editData?.calories || "",
    proteins: editData?.proteins || "",
    patient_admission_id: editData?.patient_admission_id || "",
  };

  const getDietryByPatientAdmissionId = useCallback(async () => {
    if (selected_admission !== null) {
      const request = await getPatientAdmissionDietryByPatientAdmissionId(
        selected_admission?.id
      );
      if (request?.statusCode === 200) setDietry(request?.body?.data);
      else setDietry([]);
    }
  }, [selected_admission]);

  useEffect(() => {
    getDietryByPatientAdmissionId();
  }, [getDietryByPatientAdmissionId]);

  const toggleCollapse = (index: number) => {
    setCollapsedItems((prevState) =>
      prevState?.map((item, i) => (i === index ? !item : true))
    );
  };

  const handleDeleteDietry = useCallback(
    async (id: string) => {
      try {
        if (id.trim() === "" || !id) return ErrorToast("Try again!");
        const response = await deletePatientAdmissionDietry(id);

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          getDietryByPatientAdmissionId();
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [getDietryByPatientAdmissionId]
  );
  useEffect(() => {
    const getMasterDietryTypes = async () => {
      const { body } = await getDietryTypesList({});
      if (body)
        setDietryTypes(
          body.data.map((e: any) => ({ name: e?.name, id: e?.id }))
        );
      else setDietryTypes([]);
    };
    getMasterDietryTypes();
  }, []);
  const dietryTypesOptions = useMemo(
    () => dietryTypes?.filter(({ name }) => findTextInString(name, searchText)),
    [dietryTypes, searchText]
  );

  const submitForm = async (
    values: IPatientAdmissionDietryTypes,
    resetForm: () => void
  ) => {
    try {
      let response;
      if (!editData) {
        response = await postPatientAdmissionDietry({
          ...values,
          patient_admission_id: selected_admission?.id,
        });
      } else {
        response = await putPatientAdmissionDietry({
          ...values,
          id: editData?.id,
        });
      }

      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        getDietryByPatientAdmissionId();
        setIsDietryOpen(false);
        resetForm();
      } else {
        if (response?.errors?.length > 0) {
          ErrorToast(response?.errors[0]?.msg);
        }
      }
    } catch (error) {
      ErrorToast(
        "An error occurred while submitting the form. Please try again."
      );
    }
  };

  const openDeleteModal = (id: string) => {
    setCurrentDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setCurrentDeleteId(null);
    setIsDeleteModalOpen(false);
  };

  const confirmDelete = () => {
    if (currentDeleteId) {
      handleDeleteDietry(currentDeleteId);
      closeDeleteModal();
    }
  };

  return (
    <>
      {
        isDietryOpen && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values, { resetForm }) => {
              submitForm(values, resetForm);
            }}
          >
            {({
              isValid,
              isSubmitting,
              resetForm,
              handleSubmit,
              values,
              handleChange,
              errors,
              touched,
              handleBlur,
              dirty,
              setFieldValue,
            }) => (
              <>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (errors) {
                      const firstError = Object.values(errors)[0];

                      if (Array.isArray(firstError)) {
                        const errorMessage = firstError.join(", ");
                        ErrorToast(errorMessage);
                      } else ErrorToast(firstError);
                    }
                    handleSubmit();
                  }}
                >
                  <div className="right-panel">
                    <h4>Dietary</h4>
                    <button
                      className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light "
                      onClick={() => setIsDietryOpen(false)}
                    >
                      <i className="mdi mdi-close"></i>
                    </button>
                    <div className="row">
                      <div className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Diet Type</Label>
                          <Select
                            name="dietry_type_id"
                            MenuProps={{ autoFocus: false }}
                            value={values?.dietry_type_id}
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderWidth: "0 !important",
                              },
                              "& .MuiOutlinedInput-input": { padding: "0px" },
                              "& :focus": {
                                outline: "none !important",
                                border: "0 !important",
                                boxShadow: "none !important",
                              },
                              color: "#a6b0cf",
                              fontWeight: 400,
                              fontSize: "0.875rem",
                            }}
                            className="form-control"
                            onClose={() => setSearchText("")}
                            onBlur={handleBlur}
                            onChange={(e) => handleChange(e)}
                          >
                            <ListSubheader>
                              <TextField
                                size="small"
                                autoFocus
                                placeholder="Type to search..."
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <GridSearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key !== "Escape") {
                                    e.stopPropagation();
                                  }
                                }}
                              />
                            </ListSubheader>

                            <MenuItem value=" " disabled>
                              <em>Select Dietary Type</em>
                            </MenuItem>
                            {dietryTypesOptions &&
                              dietryTypesOptions?.length > 0 ? (
                              dietryTypesOptions?.map(
                                ({ id, name }: { id: string; name: string }) => (
                                  <MenuItem key={id} value={id}>
                                    {name}
                                  </MenuItem>
                                )
                              )
                            ) : (
                              <MenuItem disabled>No Dietary Found</MenuItem>
                            )}
                          </Select>
                          <ErrorMessage
                            name="dietry_type_id"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Date</Label>
                          <CustomMUIDatePicker
                            name="date"
                            type="formik"
                            value={values?.date}
                            setState={setFieldValue}
                          />
                          <ErrorMessage
                            name="date"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      {/*<div className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Day</Label>
                        <Field
                          name="day"
                          className="form-control"
                          type="text"
                          placeholder="Enter Day"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="day"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                     <div className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Meal Type</Label>
                        <Field
                          name="meal_type"
                          className="form-control"
                          type="text"
                          placeholder="Enter meal type"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="meal_type"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <Label className="form-label">Meal Start Time</Label>
                        <Field
                          name="meal_start_time"
                          className="form-control"
                          type="time"
                          placeholder="Enter Start Time"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="meal_start_time"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <Label className="form-label">Meal End Time</Label>
                        <Field
                          name="meal_end_time"
                          className="form-control"
                          type="time"
                          placeholder="Enter end Time"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="meal_end_time"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div> */}
                      <div className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Meal Name</Label>
                          <Field
                            name="meal_name"
                            className="form-control"
                            type="text"
                            placeholder="Enter Meal Name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="meal_name"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Instruction</Label>
                          <Field
                            name="meal_plan"
                            className="form-control"
                            type="text"
                            placeholder="Enter Meal Plan"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="meal_plan"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Volume</Label>
                          <Field
                            name="volume"
                            className="form-control"
                            type="text"
                            placeholder="Enter Volume"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="volume"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Calories</Label>
                          <Field
                            name="calories"
                            className="form-control"
                            type="text"
                            placeholder="Enter Calories"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="calories"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Proteins</Label>
                          <Field
                            name="proteins"
                            className="form-control"
                            type="text"
                            placeholder="Enter Proteins"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="proteins"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>


                      <div className="col-12 text-center">
                        <button
                          className="btn btn-primary mr-2 medium-btn"
                          type="submit"
                        >
                          {editData ? "Update" : "Add"}
                        </button>
                        <button
                          className="btn btn-secondary medium-btn"
                          onClick={() => resetForm()}
                        >
                          Reset{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        )
      }

      <div className="add-row-btn">
        {(true || user_info?.hospital_property?.id ===
          selected_admission?.hospital_property?.id) &&
          selected_admission?.admitted &&
          !selected_admission?.discharged && (
            <button
              className="btn btn-primary medium-btn mb-2"
              onClick={() => {
                setEditData(null);
                setIsDietryOpen(true);
              }}
            >
              Add
            </button>
          )}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="accordion" id="accordion">
            {dietry &&
              dietry.length > 0 &&
              dietry.map((singleDietry: any, index: number) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className={`accordion-button fw-medium ${collapsedItems[index] ? "collapsed" : ""
                        }`}
                      type="button"
                      onClick={() => toggleCollapse(index)}
                      style={{ cursor: "pointer" }}
                    >
                      {formatDateToLocaleDateString(singleDietry.date)} {singleDietry.day}
                      <span className="right-date">
                        Add On -{" "}
                        {formatDateToLocaleDateString(singleDietry?.created_on)}{" "}
                        {(true || user_info?.hospital_property?.id ===
                          selected_admission?.hospital_property?.id) &&
                          selected_admission?.admitted &&
                          !selected_admission?.discharged && (
                            <div className="gap-3">
                              <a className="text-success mr-2">
                                <i
                                  className="mdi mdi-pencil font-size-16"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setEditData(singleDietry);
                                    setIsDietryOpen(true);
                                  }}
                                ></i>
                              </a>
                              <a
                                className="text-danger"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    openDeleteModal(singleDietry.id);
                                }}
                              >
                                <i className="mdi mdi-delete font-size-16"></i>
                              </a>
                            </div>
                          )}
                      </span>
                    </button>
                  </h2>
                  <Collapse
                    isOpen={!collapsedItems[index]}
                    className="accordion-collapse"
                  >
                    <div className="accordion-body">
                      <div className="table-box">
                        <table className="table">
                          <thead className="table-light">
                            <tr>
                              <th>Diet Type</th>
                              <th>Meal Name</th>
                              <th>Instruction</th>
                              <th>Volume</th>
                              <th>Calories</th>
                              <th>Proteins</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{singleDietry?.dietry_type?.name}</td>
                              <td>{singleDietry?.meal_name}</td>
                              <td>{singleDietry?.meal_plan}</td>
                              <td>{singleDietry?.volume}</td>
                              <td>{singleDietry?.calories}</td>
                              <td>{singleDietry?.proteins}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Collapse>
                </div>
              ))}
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={closeDeleteModal}
        onConfirm={confirmDelete}
        pageName="dietary"
      />
    </>
  );
};

export default Dietry;
