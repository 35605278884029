import { report_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IGetGeneralReportsParams, IGetLineListingReportsParams } from "../types/reportsTypes";


export const getLineListingReports = async ({ sort_order, start_date, end_date, property_id }: IGetLineListingReportsParams) => {

    try {
        const params: { [key: string]: string } = {};

        if (sort_order !== undefined) params['sort_order'] = sort_order;
        if (property_id !== undefined) params['property_id'] = property_id;
        if (start_date !== undefined) params['start_date'] = start_date;
        if (end_date !== undefined) params['end_date'] = end_date;

        const { data } = await axiosApi.get(report_routes.line_listing_report, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}

export const getGeneralReports = async ({ year, property_id }: IGetGeneralReportsParams) => {

    try {
        const params: { [key: string]: string | number } = {};

        if (year !== undefined) params['year'] = year;
        if (property_id !== undefined) params['property_id'] = property_id;

        const { data } = await axiosApi.get(report_routes.general_reports, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}