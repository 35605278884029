import { ActionTypes, CountryListDispatchTypes, CountryType } from "../types/countryTypes";

interface initialStateTypes {
    countryList: CountryType[];
}

const initialState: initialStateTypes = {
    countryList: []
};

export const countryReducer = (state: initialStateTypes = initialState, actions: CountryListDispatchTypes): initialStateTypes => {
    switch (actions.type) {

        case ActionTypes.GET_COUNTRY_LIST_REQUESTED:
            return {
                ...state,
            };

        case ActionTypes.GET_COUNTRY_LIST_SUCCESS:
            return {
                ...state,
                countryList: actions?.payload,
            };

        case ActionTypes.GET_COUNTRY_LIST_FAILURE:
            return {
                ...state,
            };

        default:
            return state;
    }
};


