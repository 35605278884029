import React, { FC, useCallback, useEffect, useState, useMemo } from "react";
import CustomMUIDatePicker from "../../../../components/UI/CustomMUIDatePicker";
import { Label, Collapse } from "reactstrap";
import { GridSearchIcon } from "@mui/x-data-grid";
import {
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { SelectChangeEvent, OutlinedInput } from "@mui/material";
import {
  deletePatientAdmissionPrescription,
  getPatientAdmissionPrescriptionByPatientAdmissionId,
  postPatientAdmissionPrescription,
  putPatientAdmissionPrescription,
} from "../../../../redux/actions/patientAdmissionPrescription";
import { ErrorToast, SuccessToast } from "../../../../utils/toastNotifications";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getMedicineContentsList } from "../../../../redux/actions/medicineTypeAction";
import {
  findTextInString,
  formatDateToCustomDateString,
  formatDateToLocaleDateString,
} from "../../../../helpers/helperFunctions";
import { IPrescription } from "../../../../redux/types/patientAdmissionPrescriptionTypes";
import { useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { getMedicineTypesList } from "../../../../redux/actions/medicineTypeAction";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import DeleteConfirmationModal from "../../../../components/DeletePopupComponent/DeletePopup";
import { getDoctorListByRole } from "../../../../redux/actions/doctorAction";

const Prescription: FC<any> = ({ selected_admission }) => {
  const [prescriptions, setPrescriptions] = useState<IPrescription[]>([]);
  const [filteredPrescriptions, setFilteredPrescriptions] = useState<IPrescription[]>([]);
  const [collapsedItems, setCollapsedItems] = useState<boolean[]>([]);
  const [isPrescriptionOpen, setIsPrescriptionOpen] = useState<boolean>(false);
  const [medicineSubTypes, setMedicineSubTypes] =
    useState<{ name: string; id: string }[]>();
  const [searchText, setSearchText] = useState("");
  const [editData, setEditData] = useState<IPrescription | null>();
  const { user_info } = useSelector((state: any) => state.user);
  const [selectedFrequency, setSelectedFrequency] = useState("");
  const [timeCheck, setTimeCheck] = useState<string[]>([]);
  const [medicineTypesOptions, setMedicineTypesOptions] = useState<{ id: string; name: string }[]>([]);
  const [showFilterBox, setShowFilterBox] = useState(false)
  const [filters, setFilters] = useState({
    selectedDate: "",
    sort_order: " ",
  });
  // State for delete confirmation modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);
  const [doctorTypes, setDoctorTypes] = useState<{ id: string; name: string; }[]>([]);
  const [referDoctorTypes, setReferDoctorTypes] = useState<{ id: string; name: string; }[]>([]);
  const [referDoctorNotTypes, setReferDoctorNotTypes] = useState<{ id: string; name: string; }[]>([]);
  const [filterOption, setFilterOption] = useState<string>("all");
  const DocorCondiction = (editData !== null && editData?.prescribed_by?.id !== user_info?.id) ? true : false;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { body } = await getDoctorListByRole({ role: "doctor", hospital_id: user_info?.hospital_property?.hospital?.id });
        if (body) {
          setDoctorTypes(body?.data);
          const filteredReferDoctorTypes = body?.data?.filter((doctor: any) => doctor.id !== user_info.id);
          setReferDoctorNotTypes(filteredReferDoctorTypes);
          setReferDoctorTypes(body?.data);
        }
        else { setDoctorTypes([]); setReferDoctorTypes([]); }
      } catch (error) {
        console.error("Error fetching doctor types:", error);

      }
    };

    fetchData();
  }, []);

  const handleNameChange = (event: SelectChangeEvent<typeof timeCheck>) => {
    let isChecked = true;
    const selectedTimes = event.target.value as string[];
    const maxSelection = getMaxSelection(selectedFrequency);
    if (selectedTimes.length > maxSelection) {
      selectedTimes.splice(maxSelection);
    } else if (selectedTimes.length < maxSelection && timeCheck?.length <= maxSelection && timeCheck?.length > 0) {
      isChecked = false;
    }
    setTimeCheck(selectedTimes);
    if (selectedTimes?.length > 0 && isChecked) {
      if (selectedFrequency === "BD" && selectedTimes.length === 1) {
        const firstTime = selectedTimes[0];
        const firstIndex = timeRanges.findIndex((time) => time === firstTime);
        setTimeCheck((prevTimes) => [
          timeRanges[(firstIndex + 0) % timeRanges.length],
          timeRanges[(firstIndex + 12) % timeRanges.length]
        ]);
      } else if (selectedFrequency === "TDS" && selectedTimes.length === 1) {
        const firstTime = selectedTimes[0];
        const firstIndex = timeRanges.findIndex((time) => time === firstTime);
        setTimeCheck((prevTimes) => [
          // ...prevTimes,
          timeRanges[(firstIndex + 0) % timeRanges.length],
          timeRanges[(firstIndex + 8) % timeRanges.length],
          timeRanges[(firstIndex + 16) % timeRanges.length],
        ]);
      } else if (selectedFrequency === "QDS" && selectedTimes.length === 1) {
        const firstTime = selectedTimes[0];
        const firstIndex = timeRanges.findIndex((time) => time === firstTime);
        setTimeCheck((prevTimes) => [
          timeRanges[(firstIndex + 0) % timeRanges.length],
          timeRanges[(firstIndex + 4) % timeRanges.length],
          timeRanges[(firstIndex + 8) % timeRanges.length],
          timeRanges[(firstIndex + 12) % timeRanges.length],
        ]);
      }
    }
  };

  const handleFrequencyChange = (event: SelectChangeEvent<string>, setFieldValue: any) => {
    const selectedFreq = event.target.value;
    setSelectedFrequency(selectedFreq);
    if (selectedFreq === "Continues") {
      setTimeCheck(["Continues"]);
      setFieldValue("time", "Continues")
    } else {
      setTimeCheck([]);
    }
  };
  useEffect(() => {
    if (editData) {
      setTimeCheck(editData.time ? editData.time.split(', ') : []);
    }
  }, [editData]);


  const getMaxSelection = (frequency: string): number => {
    switch (frequency) {
      case "BD":
        return 2;
      case "TDS":
        return 3;
      case "QDS":
        return 4;
      default:
        return selectedFrequency === "OD" ? 1 : Infinity;
    }

  };

  const timeRanges = ["Continues"];
  timeRanges.push("12 AM");
  for (let hour = 1; hour <= 12; hour++) {
    const start = hour.toString().padStart(2, "0");
    const ampm = hour < 12 ? "AM" : "PM";
    timeRanges.push(`${start} ${ampm}`);
  }
  for (let hour = 1; hour <= 11; hour++) {
    const start = (hour + 0).toString().padStart(2, "0");
    timeRanges.push(`${start} PM`);
  }

  useEffect(() => {
    if (prescriptions && prescriptions.length > 0) {
      const newCollapsedItems = Array(prescriptions.length).fill(true);
      newCollapsedItems[0] = false;
      setCollapsedItems(newCollapsedItems);
    }
  }, [prescriptions]);

  const validationSchema = Yup.object({
    // medicine_type_id: Yup.string().trim().required("Medicine type is required field"),
    hours: Yup.string().optional(),
    frequency: Yup.string().optional(),
    time: Yup.string().required('Time is required field'),
    days: Yup.string().optional(),
    frequency_by_days: Yup.string().optional(),
    sos: Yup.string().optional(),
    route: Yup.string().optional(),
    prescribed_by_id: Yup.string().required('Doctor is required'),
    medicine_content_id: Yup.string()
      .trim()
      .required("Medicine Content is required"),
    start_date_time: Yup.string().optional(),
    end_date_time: Yup.string().when('start_date_time', (start: any, schema: any) =>
      schema.optional().test({
        test: function (end: any) {
          if (!start || !end) return true;
          return new Date(end) >= new Date(start);
        },
        message: 'End Date cannot be less than Start Date',
      })
    ),
    drug_instructions: Yup.string().optional(),
  });

  const initialValues = {
    medicine_type_id: editData?.medicine_type?.id || "",
    concentration: editData?.concentration || "",
    frequency: editData?.frequency || "",
    medicine_brand_name: editData?.medicine_brand_name || "",
    hours: editData?.hours || "",
    time: editData?.time || "",
    days: editData?.days || "",
    frequency_by_days: editData?.frequency_by_days || "",
    sos: editData?.sos || "",
    route: editData?.route || "",
    medicine_content_id: editData?.medicine_content?.id || "",
    prescribed_by_id: editData?.prescribed_by?.id || "",
    refer_doctor_id: editData?.refer_doctor?.id || "",
    start_date_time: editData?.start_date_time || new Date().toISOString().slice(0, 10),
    end_date_time: editData?.end_date_time || new Date().toISOString().slice(0, 10),
    drug_instructions: editData?.drug_instructions || "",
    morningDosage: editData?.morningDosage || 0,
    eveningDosage: editData?.eveningDosage || 0,
    afternoonDosage: editData?.afternoonDosage || 0,
    nightDosage: editData?.nightDosage || 0,
    doctor_refer_date: editData?.doctor_refer_date || "",
    doctor_refer_note: editData?.doctor_refer_note || "",
    assign_date: editData?.assign_date || new Date().toISOString().slice(0, 10),
  };

  const getPrescriptionsByPatientAdmissionId = useCallback(async () => {
    if (selected_admission !== null) {
      const request = await getPatientAdmissionPrescriptionByPatientAdmissionId(
        selected_admission?.id
      );
      if (request?.statusCode === 200) setPrescriptions(request?.body?.data);
      else setPrescriptions([]);
    }
  }, [selected_admission]);

  useEffect(() => {
    getPrescriptionsByPatientAdmissionId();
  }, [getPrescriptionsByPatientAdmissionId]);


  useEffect(() => {
    setFilteredPrescriptions(prescriptions);
  }, [prescriptions]);

  const toggleCollapse = (index: number) => {
    setCollapsedItems((prevState) =>
      prevState?.map((item, i) => (i === index ? !item : true))
    );
  };

  const handleDeletePrescription = useCallback(
    async (id: string) => {
      try {
        if (id?.trim() === "" || !id) return ErrorToast("Try again!");
        const response = await deletePatientAdmissionPrescription(id);

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          getPrescriptionsByPatientAdmissionId();
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [getPrescriptionsByPatientAdmissionId]
  );
  const getMedicineContents = async () => {
    try {
      const request = await getMedicineContentsList({
    
      });
 
      // Check if the request returned an array with data
      if (Array.isArray(request) && request.length > 0) {
        setMedicineSubTypes(request);
      } else {
        setMedicineSubTypes([]); // Set an empty array if no data was found
        console.error("No data found or response was not successful", request);
      }
    } catch (error: any) {
      setMedicineSubTypes([]); // Set an empty array if an error occurred
      console.error("An error occurred while fetching medicine contents:", error);
    }
  };

  const getMedicineTypes = async () => {
    const { body } = await getMedicineTypesList({});
    const updatedMedicineTypesList: { id: string; name: string }[] =
      body?.data?.map((e: any) => ({ name: e?.name, id: e?.id }));
    setMedicineTypesOptions(updatedMedicineTypesList);
  };

  useEffect(() => {
    getMedicineTypes();
    getMedicineContents();
  }, []);


  useEffect(() => {
    medicineSubTypesOptions({})
  }, [searchText])

  const medicineSubTypesOptions = (values: any) => {
    if (searchText.length > 2) {
      return medicineSubTypes?.filter(({ name }) => findTextInString(name, searchText)) || [];
    }
    if (values?.medicine_content_id) {
      const result = medicineSubTypes?.find(item => item.id === values?.medicine_content_id);
      return result ? [result] : [];
    }
    else if (searchText.length < 2) {
      return [];
    } else {
      return medicineSubTypes?.filter(({ name }) => findTextInString(name, searchText)) || [];
    }
  };

  const submitForm = async (values: IPrescription, resetForm: () => void) => {
    try {
      let response;
      if (!editData) {
        response = await postPatientAdmissionPrescription({
          ...values,
          patient_admission_id: selected_admission?.id,
          start_date_time: values.start_date_time,
          end_date_time: values.end_date_time,
          time: timeCheck.join('-'),
        });
      } else {
        response = await putPatientAdmissionPrescription({
          ...values,
          id: editData?.id,
          start_date_time: values.start_date_time,
          end_date_time: values.end_date_time,
          time: timeCheck.join('-'),
        });
        setIsPrescriptionOpen(false);
      }
      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        getPrescriptionsByPatientAdmissionId();
        // setIsPrescriptionOpen(false);
        setTimeCheck([])
        resetForm();
      } else {
        if (response?.errors?.length > 0) {
          ErrorToast(response?.errors[0]?.msg);
        }
      }
    } catch (error) {
      ErrorToast(
        "An error occurred while submitting the form. Please try again."
      );
    }
  };
  useEffect(() => {
    if (!isPrescriptionOpen) {
      setTimeCheck([]);
     }
  }, [isPrescriptionOpen]);

  const handleFilterApply = async () => {
    if (!filters.selectedDate) {
      ErrorToast("Please Select Date");
      return;
    }
    const filtered = prescriptions.filter((prescription) => {
      const prescriptionStartDate = new Date(prescription.start_date_time);
      const prescriptionEndDate = new Date(prescription.end_date_time);
      const selectedDateObj = new Date(filters.selectedDate);
      return prescriptionStartDate <= selectedDateObj && prescriptionEndDate >= selectedDateObj;
    });
    if (filters.sort_order === "ASC") {
      filteredPrescriptions.sort((a, b) => a.end_date_time.localeCompare(b.end_date_time));
    } else if (filters.sort_order === "DESC") {
      filteredPrescriptions.sort((a, b) => b.end_date_time.localeCompare(a.end_date_time));
    }
    setFilteredPrescriptions(filtered);
    setShowFilterBox(false);
  };
  const handleFilterChange = (name: string, value?: string) => {
    setFilters((prev_filters) => ({ ...prev_filters, [name]: value }));
  };
  const handleFilterReset = () => {
    setFilters({ selectedDate: "", sort_order: " " });
    getPrescriptionsByPatientAdmissionId();
  };

  const closeFilterBox = () => {
    setShowFilterBox(false);
    handleFilterReset();
  }

  const openDeleteModal = (id: string) => {
    setCurrentDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setCurrentDeleteId(null);
    setIsDeleteModalOpen(false);
  };

  const confirmDelete = () => {
    if (currentDeleteId) {
      handleDeletePrescription(currentDeleteId);
      closeDeleteModal();
    }
  };

  const getFilteredNotes = () => {
    if (filterOption === "my_notes") {
      return prescriptions.filter(note => note.prescribed_by?.id === user_info?.id);
    } else if (filterOption === "refer_notes") {
      return prescriptions.filter(note => note.refer_doctor?.id === user_info?.id);
    }
    return prescriptions;
  };

  return (
    <>
      <div className="add-row-btn">
        <div>
          <h4 className="tab-title">
            {filters?.selectedDate && `Selected Date: ${formatDateToLocaleDateString(filters?.selectedDate)}`}
          </h4>
        </div>
        <div >
          <div className="mr-2">
            <button
              type="button"
              onClick={() =>
                setShowFilterBox((showFilterBox) => !showFilterBox)
              }
              className="btn btn-outline-light"
            >
              <i className="mdi mdi-filter-outline"></i> Select Date
              <i className="mdi mdi-chevron-down" />
            </button>
            {showFilterBox && (
              <div className="filter-box">
                <button
                  className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light "
                  onClick={closeFilterBox}
                >
                  <i className="mdi mdi-close"></i>
                </button>
                <div className="row">
                  <div className="col-12 mb-3">
                    <Label className="form-label">Select Date</Label>
                    <CustomMUIDatePicker
                      name="selectedDate"
                      type="formik"
                      value={filters?.selectedDate}
                      setState={handleFilterChange}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <Label className="form-label">Filter By Order</Label>
                    <select
                      onChange={(event) => handleFilterChange("sort_order", event?.target.value)}
                      className="form-control"
                      name="sort_order"
                      value={filters.sort_order}
                    >
                      <option disabled value=" "> Select Order </option>
                      <option style={{ color: "black" }} value="ASC"> Ascending </option>
                      <option style={{ color: "black" }} value="DESC"> Decending </option>
                    </select>
                  </div>
                  <div className="col-12 text-center">
                    <button
                      type="button"
                      onClick={() => handleFilterApply()}
                      className="btn btn-info medium-btn mr-2"
                    >
                      Apply
                    </button>
                    <button
                      onClick={() => handleFilterReset()}
                      type="button"
                      className="btn btn-secondary medium-btn"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="filter-btn-group mb-2">
          <select
            className="form-control"
            value={filterOption}
            onChange={(e) => setFilterOption(e.target.value)}
          >
            <option value="all">All notes</option>
            <option value="my_notes">My notes</option>
            <option value="refer_notes">Refer notes</option>
          </select>
        </div>
        {(true ||
          user_info?.hospital_property?.id ===
          selected_admission?.hospital_property?.id) &&
          selected_admission?.admitted &&
          !selected_admission?.discharged && (
            <button
              className="btn btn-primary medium-btn mb-2"
              onClick={() => {
                setEditData(null);
                setIsPrescriptionOpen(true);
              }}
            >
              Add
            </button>
          )}
      </div>
      {isPrescriptionOpen && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { resetForm }) => {
            submitForm(values, resetForm);
          }}
        >
          {({
            isValid,
            isSubmitting,
            resetForm,
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            dirty,
            setFieldValue,
          }) => (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (errors) {
                    const firstError = Object.values(errors)[0];

                    if (Array.isArray(firstError)) {
                      const errorMessage = firstError.join(", ");
                      ErrorToast(errorMessage);
                    } else ErrorToast(firstError);
                  }
                  handleSubmit();
                }}
              >
                <div className="right-panel">
                  <h4>Prescription</h4>
                  <button
                    className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light "
                    onClick={() => setIsPrescriptionOpen(false)}
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <label className="form-label">Medicine Type</label>
                        <Field
                          disabled={DocorCondiction}
                          name="medicine_type_id"
                          as="select"
                          className="form-control"
                          value={values.medicine_type_id}
                          onBlur={handleBlur}
                          onChange={(e: any) => {
                            // handleFrequencyChange(e);
                            handleChange(e);
                          }}
                        >
                          <option value="" label="Select medicine type" />
                          {medicineTypesOptions &&
                            medicineTypesOptions.map((mdType) => (
                              <option key={mdType.id} value={mdType.id}>
                                {mdType.name}
                              </option>
                            ))}
                        </Field>
                        <ErrorMessage
                          name="medicine_type_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <Label className="form-label">Medicine Name</Label>
                        <Select
                          disabled={DocorCondiction}
                          name="medicine_content_id"
                          MenuProps={{ autoFocus: false }}
                          value={values?.medicine_content_id}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderWidth: "0 !important",
                            },
                            "& .MuiOutlinedInput-input": { padding: "0px" },
                            "& :focus": {
                              outline: "none !important",
                              border: "0 !important",
                              boxShadow: "none !important",
                            },
                            width: "100%",
                            color: "#a6b0cf",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          }}
                          className="form-control"
                          onClose={() => setSearchText("")}
                          onBlur={handleBlur}
                          onChange={(e: any) => handleChange(e)}
                        >
                          <ListSubheader>
                            <TextField
                              size="small"
                              disabled={DocorCondiction}
                              autoFocus
                              placeholder="Type to search..."
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <GridSearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => setSearchText(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                  e.stopPropagation();
                                }
                              }}
                            />
                          </ListSubheader>
                          <MenuItem value=" " disabled>
                            <em>Select Medicine Type</em>
                          </MenuItem>
                          {((Array(medicineSubTypesOptions(values)) || searchText.length > 2) && medicineSubTypesOptions(values)?.map(
                            ({ id, name }: { id: string; name: string }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            )
                          )
                          )}
                        </Select>
                        <ErrorMessage
                          name="medicine_content_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <Label className="form-label">Frequency by day</Label>
                        <Field
                          name="frequency"
                          as="select" // Use 'as' prop to render a select element
                          className="form-control"
                          onBlur={handleBlur}
                          disabled={DocorCondiction}
                          onChange={(e: any) => {
                            handleFrequencyChange(e, setFieldValue);
                            handleChange(e);
                          }}
                        >
                          <option value="" label="Select frequency" />
                          <option value="OD">OD</option>
                          <option value="BD">BD</option>
                          <option value="TDS">TDS</option>
                          <option value="QDS">QDS</option>
                          <option value="HS">HS</option>
                          <option value="Continues">Continues</option>
                          <option value="Other">Other</option>
                        </Field>
                        <ErrorMessage
                          name="frequency"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <Label className="form-label">Time</Label>
                        <FormControl className="form-control">
                          <Select
                            // className="form-control"
                            // labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            disabled={DocorCondiction}
                            multiple
                            value={Array.from(timeCheck)}
                            onChange={(e) => {
                              handleNameChange(e);
                              if (Array.isArray(e.target.value)) {
                                const selectedTimesString =
                                  e.target.value.join(", ");
                                setFieldValue("time", selectedTimesString);
                              } else {
                                setFieldValue("time", e.target.value);
                              }
                            }}
                            // input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {timeRanges.map((name) => (
                              <MenuItem key={name} value={name} className="form-control"
                                disabled={selectedFrequency !== 'Continues' && name === 'Continues'}
                              >
                                <Checkbox
                                  checked={timeCheck.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <ErrorMessage
                          name="time"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <Label className="form-label">Concentration</Label>
                        <Field
                          name="concentration"
                          className="form-control"
                          type="text"
                          placeholder="Enter concentration"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={DocorCondiction}
                        />
                        <ErrorMessage
                          name="concentration"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <Label className="form-label">SOS</Label>
                        <Field
                          name="sos"
                          className="form-control"
                          type="text"
                          placeholder="Enter SOS"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={DocorCondiction}
                        />
                        <ErrorMessage
                          name="sos"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <Label className="form-label">Route</Label>
                        <Field
                          name="route"
                          as="select" // Use 'as' prop to render a select element
                          className="form-control"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={DocorCondiction}
                        >
                          <option value="" label="Select route" />{" "}
                          {/* Placeholder option */}
                          <option value="IM">IM</option>
                          <option value="IV">IV</option>
                          <option value="Nasal">Nasal</option>
                          <option value="Oral">Oral</option>
                          <option value="S/C">RT</option>
                          <option value="S/C">S/C</option>
                        </Field>
                        <ErrorMessage
                          name="route"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <Label className="form-label">Prescribed By</Label>
                        <Field
                          name="prescribed_by_id"
                          as="select"
                          className="form-control"
                          onBlur={handleBlur}
                          disabled={DocorCondiction}
                          // onChange={handleChange}
                          onChange={(e: any) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select a Doctor</option>
                          {doctorTypes && doctorTypes.length > 0 && doctorTypes.map(doctor => (
                            <option key={doctor?.id} value={doctor?.id}>{doctor?.name}</option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="prescribed_by_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="mb-3">
                        <Label className="form-label">Start Date Time</Label>
                        <CustomMUIDatePicker
                          name="start_date_time"
                          type="formik"
                          value={values?.start_date_time || ""}
                          setState={setFieldValue}
                          disabled={DocorCondiction}
                        />
                        <ErrorMessage
                          name="start_date_time"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="mb-3">
                        <Label className="form-label">End Date Time</Label>
                        <CustomMUIDatePicker
                          name="end_date_time"
                          type="formik"
                          value={values?.end_date_time || ""}
                          setState={setFieldValue}
                          disabled={DocorCondiction}
                        />
                        <ErrorMessage
                          name="end_date_time"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <Label htmlFor="drug_instructions" className="form-label">Drug Instructions</Label>
                        <ReactQuill
                          id="drug_instructions"
                          value={values.drug_instructions}
                          onChange={(content, delta, source, editor) => setFieldValue('drug_instructions', content)}
                          readOnly={DocorCondiction}
                        />
                        <ErrorMessage name="drug_instructions" component="div" className="text-danger" />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="refer_doctor_id">Refer Doctor</label>
                      <Field
                        name="refer_doctor_id"
                        as="select"
                        className={`form-control ${errors.refer_doctor_id && touched.refer_doctor_id ? 'is-invalid' : ''}`}
                        disabled={DocorCondiction}
                        onChange={(e: any) => {
                          const selectedDoctorId = e.target.value;
                          setFieldValue('refer_doctor_id', selectedDoctorId);
                          handleChange(e);
                        }}
                      >
                        <option value="">Select a Refer Doctor</option>
                        {((editData === null || editData?.prescribed_by?.id == user_info?.id) ? referDoctorNotTypes : referDoctorTypes)?.map(doctor => (
                          <option key={doctor?.id} value={doctor?.id}>{doctor?.name}</option>
                        ))}
                      </Field>
                      <ErrorMessage name="refer_doctor_id" component="div" className="text-danger" />
                    </div>


                    <div className="mb-3">
                      <label htmlFor="assign_date">Refer Doctor Assign Date</label>
                      <CustomMUIDatePicker
                        useDateTimePicker={true}
                        name="assign_date"
                        type="formik"
                        setState={setFieldValue}
                        disabled={DocorCondiction}
                        value={values?.assign_date}
                      // disabled={(editData !== null && editData?.doctor?.id !== user_info?.id) ? true : false}
                      />
                      <ErrorMessage name="assign_date" component="div" className="text-danger" />
                    </div>
                    {editData !== null && (values?.refer_doctor_id !== null && values?.refer_doctor_id !== "") && (<>
                      <div className="mb-3">
                        <label htmlFor="doctor_refer_date">Refer Doctor Note Date</label>
                        <CustomMUIDatePicker
                          useDateTimePicker={true}
                          name="doctor_refer_date"
                          type="formik"
                          setState={setFieldValue}
                          value={values?.doctor_refer_date}
                          disabled={(editData !== null && editData?.refer_doctor?.id !== user_info?.id) ? true : false}
                          maxDate={new Date()}
                          minDate={new Date()}
                        />
                        <ErrorMessage name="doctor_refer_date" component="div" className="text-danger" />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="doctor_refer_note">Refer Doctor Note</label>
                        <ReactQuill
                          id="doctor_refer_note"
                          value={values.doctor_refer_note} // bind value to Formik's field value
                          onChange={(content, delta, source, editor) => setFieldValue('doctor_refer_note', content)}
                          readOnly={(editData !== null && editData?.refer_doctor?.id !== user_info?.id) ? true : false}
                        // className={`form-control ${errors.note && touched.note ? 'is-invalid' : ''}`}
                        />
                        <ErrorMessage name="doctor_refer_note" component="div" className="text-danger" />
                      </div>
                    </>)}
                    <div className="col-12 text-center">
                      <button
                        className="btn btn-primary mr-2 medium-btn"
                        type="submit"
                        disabled={(editData?.prescribed_by?.id !== user_info?.id && editData?.refer_doctor?.id !== user_info?.id && editData !== null) ? true : false}
                      >
                        {editData ? "Update" : "Add"}
                      </button>
                      <button
                        className="btn btn-secondary medium-btn"
                        onClick={() => resetForm()}
                        disabled={(editData === null) ? false : true}
                      >
                        Reset{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}

      <div className="row">
        <div className="col-12">
          <div className="accordion" id="accordion">
            {filteredPrescriptions && filteredPrescriptions.length === 0 ? (
              <div className="d-flex justify-content-center">
                <h4 className="mt-20 b-5">No Data Found</h4>
              </div>
            ) : (
              filteredPrescriptions &&
              getFilteredNotes().map((prescription: any, index: number) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className={`accordion-button fw-medium ${!collapsedItems[index] ? "" : "collapsed"
                        }`}
                      type="button"
                      onClick={() => toggleCollapse(index)}
                      style={{ cursor: "pointer" }}
                    >
                      {prescription?.medicine_type?.name}{" "}
                      {prescription?.medicine_content?.brand_name} -{" "}
                      {prescription?.concentration} - {prescription?.route} -{" "}
                      {prescription?.frequency} - ({prescription?.time})
                      <span className="right-date">
                        Added On - {" "}
                        {formatDateToLocaleDateString(prescription?.created_on)}{" "}
                        {(true ||
                          user_info?.hospital_property?.id ===
                          selected_admission?.hospital_property?.id) &&
                          selected_admission?.admitted &&
                          !selected_admission?.discharged && (
                            <div className="gap-3">
                              <a className="text-success mr-2">
                                <i
                                  className="mdi mdi-pencil font-size-16"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setEditData(prescription);
                                    setIsPrescriptionOpen(true);
                                  }}
                                ></i>
                              </a>
                              <a
                                className="text-danger"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  // handleDeletePrescription(prescription?.id);
                                  openDeleteModal(prescription?.id);
                                }}
                              >
                                <i className="mdi mdi-delete font-size-16"></i>
                              </a>
                            </div>
                          )}
                      </span>
                    </button>
                  </h2>
                  <Collapse
                    isOpen={!collapsedItems[index]}
                    className="accordion-collapse"
                  >
                    <div className="accordion-body">
                      <div className="row text-muted">
                        <div className="small-line">
                          <label className="title">Medicine :</label>
                          <p className="deatil">
                            {prescription?.medicine_type?.name}{" "}
                            {prescription?.medicine_content?.brand_name}
                          </p>
                        </div>
                        <div className="small-line">
                          <label className="title">Concentration :</label>
                          <p className="deatil">{prescription?.concentration}</p>
                        </div>
                        <div className="small-line">
                          <label className="title">Frequency By Days :</label>
                          <p className="deatil">
                            {prescription?.frequency || "-"}
                          </p>
                        </div>
                        <div className="small-line">
                          <label className="title">Time:</label>
                          <p className="deatil">({prescription?.time || "-"})</p>
                        </div>
                        <div className="small-line">
                          <label className="title">SOS :</label>
                          <p className="deatil">{prescription?.sos || "-"}</p>
                        </div>
                        <div className=" small-line">
                          <label className="title">Route :</label>
                          <p className="deatil">{prescription?.route || "-"}</p>
                        </div>
                        <div className="small-line">
                          <label className="title">Prescribed By :</label>
                          <p className="deatil">
                            {prescription?.prescribed_by?.name || "-"}
                          </p>
                        </div>
                        <div className=" small-line">
                          <label className="title">Start Date Time :</label>
                          <p className="deatil">
                            {formatDateToCustomDateString(
                              prescription?.start_date_time,
                              "dd/mm/yyyy"
                            ) || "-"}
                          </p>
                        </div>
                        <div className=" small-line">
                          <label className="title">End Date Time :</label>
                          <p className="deatil">
                            {formatDateToLocaleDateString(
                              prescription?.end_date_time
                            ) || "-"}
                          </p>
                        </div>
                        <div>
                          <label className="title">Drug Instructions :</label>
                          <p
                            className="deatil richtext-p-tag"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                prescription?.drug_instructions || "-"
                              ),
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={closeDeleteModal}
        onConfirm={confirmDelete}
        pageName="prescription"
      />
    </>
  );
};

export default Prescription;