import { state_routes } from "../api_path"
import axiosApi from "../../api/apiHelper";
import { IAddStateParams, IDeleteStateParams, IGetStateListParams, IGetStatesByCountry, IUpdateStateParams } from "../types/stateTypes";

export const getStateList = async ({ page_number, per_page }: IGetStateListParams) => {

    try {
        const params: { [key: string]: number } = {};

        if (page_number !== undefined) params['page_number'] = page_number;
        if (per_page !== undefined) params['per_page'] = per_page;

        const { data } = await axiosApi.get(state_routes.get_state_list, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}
export const postState = async ({ country_id, state }: IAddStateParams) => {
    try {
        const { data } = await axiosApi.post(state_routes.post_state, { country_id, state });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}

export const updateState = async ({ id, state, country_id }: IUpdateStateParams) => {
    try {
        const { data } = await axiosApi.put(state_routes.put_state, { id, state, country_id });

        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const deleteState = async ({ id }: IDeleteStateParams) => {
    try {
        const { data } = await axiosApi.delete(`${state_routes?.delete_state}/${id}`);

        return data;
    } catch (error: any) {
        return error?.response?.data?.data;
    }
};


export const getStatesByCountry = async ({ country_id, page_number, per_page }: IGetStatesByCountry) => {
    try {
        const { data } = await axiosApi.get(`${state_routes?.get_states_by_country}?country_id=${country_id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}