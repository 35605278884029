import { linetube_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IAddLineTubeTypesParams, IDeleteLineTubeTypesParams, IGetLineTubeTypesParams, IUpdateLineTubeTypesParams } from "../types/linetubeTypes";

export const getLinetubeTypesList = async ({ page_number, per_page }: IGetLineTubeTypesParams) => {

    try {
        const params: { [key: string]: number } = {};

        if (page_number !== undefined) params['page_number'] = page_number;
        if (per_page !== undefined) params['per_page'] = per_page;

        const { data } = await axiosApi.get(linetube_routes.get_linetube_types_list, { params });
        return data;
    } catch (error: any) {
        return error?.response.data;
    }
}


export const postLinetubeType = async ({ name }: IAddLineTubeTypesParams) => {
    try {
        const { data } = await axiosApi.post(linetube_routes.post_linetube_type, { name });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const updateLinetubeType = async ({ id, name }: IUpdateLineTubeTypesParams) => {
    try {
        const { data } = await axiosApi.put(linetube_routes.put_linetube_type, { id, name });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const deleteLinetubeType = async ({ id }: IDeleteLineTubeTypesParams) => {
    try {
        const { data } = await axiosApi.delete(`${linetube_routes.delete_linetube_type}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data?.data;
    }
}