import { ChangeEvent, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Input, Label } from "reactstrap";
import { getPatientBehaviouralConditionssList } from "../../../redux/actions/patientBehaviouralConditionAction";
import { getPatientChildhoodDiseasessList } from "../../../redux/actions/patientChildhoodDiseasesAction";
import { getPatientInjuriessList } from "../../../redux/actions/patientInjuriesAction";
import { getPatientOtherConditionssList } from "../../../redux/actions/patientOtherConditionsAction";
import { IDiseaseOptions } from "../../../pages/patients/CreatePatient";
import { v4 as uuidv4 } from "uuid";

const Illness: React.FC<any> = ({
  handlePrevious,
  dirty,
  isValid,
  subTabs,
  handleNext,
  errors,
  handleBlur,
  handleChange,
  values,
  touched,
  setFieldValue,
  subTabSecond,
}) => {
  // const [showHospitalAdmissions, setShowHospitalAdmissions] = useState(false);
  const [behavioral_health_conditions, setbehavioral_health_conditions] =
    useState<IDiseaseOptions[]>();
  const [patient_injuries, setpatient_injuries] = useState<IDiseaseOptions[]>();
  const [patient_other_conditions, setpatient_other_conditions] =
    useState<IDiseaseOptions[]>();
  const [childhood_diseases, setchildhood_diseases] =
    useState<IDiseaseOptions[]>();
  const handleAddDrugAllergiesEntry = () => setFieldValue('drug_allergies', [...values.drug_allergies, { id: uuidv4(), name: "", reaction: "" }]);
  const handleRemoveDrugAllergiesEntry = (index: number) => {
    const updatedDrugAllergies = [...values?.drug_allergies];
    updatedDrugAllergies.splice(index, 1);
    setFieldValue('drug_allergies', updatedDrugAllergies);
  };

  const handleChangeDrugAllergiesEntry = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    const updatedDrugAllergies = [...values.drug_allergies];
    updatedDrugAllergies[index][name] = value;
    setFieldValue('drug_allergies', updatedDrugAllergies);
  };
  /* Patient Behavioural Conditions */
  const fetchPatientBehaviouralConditionsList = async () => {
    const { body } = await getPatientBehaviouralConditionssList({});
    if (body) setbehavioral_health_conditions(body?.data);
    else setbehavioral_health_conditions([]);
  };
  /*  Patient Childhood Diseases */
  const fetchPatientChildhoodDiseasessList = async () => {
    const { body } = await getPatientChildhoodDiseasessList({});
    if (body) setchildhood_diseases(body?.data);
    else setchildhood_diseases([]);
  };
  const fetchPatientInjuriessList = async () => {
    const { body } = await getPatientInjuriessList({});
    if (body) setpatient_injuries(body?.data);
    else setpatient_injuries([]);
  };
  const fetchPatientOtherConditionssList = async () => {
    const { body } = await getPatientOtherConditionssList({});
    if (body) setpatient_other_conditions(body?.data);
    else setpatient_other_conditions([]);
  };

  useEffect(() => {
    if (subTabSecond === 2) {
      fetchPatientBehaviouralConditionsList();
      fetchPatientChildhoodDiseasessList();
      fetchPatientInjuriessList();
      fetchPatientOtherConditionssList();
    }
  }, [subTabSecond]);
  
  useEffect(() => {
    if (values?.hospital_admissions?.length === 0) {
      setFieldValue("hospital_admissions", [{ name: "", details: "" }]);
    }
    if (values?.surgical?.length === 0) {
      setFieldValue("surgical", [{ name: "", details: "" }]);
    }
    if (values?.medical?.length === 0) {
      setFieldValue("medical", [{ name: "", details: "" }]);
    }
  }, [values])

  const handleAddEntry = (fieldName: string) => {
    if (fieldName === "hospital_admissions")
      setFieldValue(fieldName, [...values.hospital_admissions, { id: uuidv4(), name: "", details: "" }]);
    if (fieldName === "medical")
      setFieldValue(fieldName, [...values.medical, { id: uuidv4(), name: "", details: "" }]);
    if (fieldName === "surgical")
      setFieldValue(fieldName, [...values.surgical, { id: uuidv4(), name: "", details: "" }]);
    else
      setFieldValue(fieldName, [
        ...values[fieldName],
        { id: uuidv4(), disease: { id: "", name: "" }, details: "" },
      ]);
  };

  const handleRemoveEntry = (fieldName: string, index: number) => {
    const updatedEntries = [...values[fieldName]];
    updatedEntries.splice(index, 1);
    setFieldValue(fieldName, updatedEntries);
  };

  const handleChangeEntry = (fieldName: string, event: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>, index: number, options: any) => {
    const { name: columnName, value } = event.target;
    const updatedEntries = [...values[fieldName]];
    if (fieldName === "hospital_admissions")
      updatedEntries[index][columnName] = value;
    else if (fieldName === "medical")
      updatedEntries[index][columnName] = value;
    else if (fieldName === "surgical")
      updatedEntries[index][columnName] = value;
    else {
      if (columnName === "disease") {
        const { id, name } = options?.find(
          ({ name }: { name: string }) => name === value
        );
        updatedEntries[index]["disease"] = { id, name };
      } else updatedEntries[index]["details"] = value;
    }
    setFieldValue(fieldName, updatedEntries);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="h4 hospital-card-title text-primary">
          Current & Past Illnesses
        </CardTitle>
        <div className="row">
          <div className="col-12">
            <h6>Systemic Illness</h6>
          </div>
          {values?.childhood_illness &&
            values?.childhood_illness.length > 0 &&
            values?.childhood_illness.map((_: any, index: number) => (
              <div className="row" key={index}>
                <div className="col-md-4">
                  <div className="mb-3">
                    <Label className="form-label">
                      Select Systemic Illness
                    </Label>
                    <select
                      className="form-control"
                      name="disease"
                      value={values?.childhood_illness[index]?.disease?.name}
                      onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                        handleChangeEntry(
                          "childhood_illness",
                          event,
                          index,
                          childhood_diseases
                        )
                      }
                    >
                      <option value="" disabled>
                        Select Systemic Illness
                      </option>
                      {childhood_diseases &&
                        childhood_diseases?.length > 0 &&
                        childhood_diseases?.map(
                          ({ id, name }: IDiseaseOptions) => (
                            <option key={id} value={name}>
                              {name}
                            </option>
                          )
                        )}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label className="form-label">Details</Label>
                    <Input
                      name="details"
                      onBlur={handleBlur}
                      className="form-control"
                      type="text"
                      value={values?.childhood_illness[index]?.details}
                      placeholder="Enter details"
                      onChange={(event) =>
                        handleChangeEntry(
                          "childhood_illness",
                          event,
                          index,
                          childhood_diseases
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-2 button-box">
                  {index === values?.childhood_illness?.length - 1 ? (
                    <button
                      className="btn btn-soft-primary waves-effect waves-light btn btn-secondary  round-btn"
                      type="button"
                      onClick={() => handleAddEntry("childhood_illness")}
                    >
                      <i className="mdi mdi-plus"></i>
                    </button>
                  ) : (
                    <button className="btn btn-soft-danger waves-effect waves-light btn btn-secondary round-btn"
                      type="button"
                      onClick={() =>
                        handleRemoveEntry("childhood_illness", index)
                      }
                    >
                      <i className="mdi mdi-delete-outline"></i>
                    </button>
                  )}
                </div>
              </div>
            ))}
          {/* END */}
          {errors?.childhood_illness && touched?.childhood_illness && (
            <span className="error">{errors?.childhood_illness}</span>
          )}
          <div className="col-12 mt-3">
            <h6>
              Drug Allergies: List all drugs allergies and indicate your
              reactions
            </h6>
          </div>
          {values?.drug_allergies && values?.drug_allergies.length > 0 && values?.drug_allergies.map((_: any, index: number) =>
          (<div className="row" key={index}>
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="form-label">Drug Name</Label>
                <Input
                  name="name"
                  className="form-control"
                  type="text"
                  value={values?.drug_allergies[index].name}
                  placeholder="Enter Name"
                  onChange={(event) => {
                    // handleChange(event);
                    handleChangeDrugAllergiesEntry(event, index);
                  }} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <Label className="form-label">Reaction</Label>
                <Input
                  name="reaction"
                  className="form-control"
                  type="text"
                  value={values?.drug_allergies[index].reaction}
                  placeholder="Enter Reaction"
                  onChange={(event) => {
                    // handleChange(event);
                    handleChangeDrugAllergiesEntry(event, index)
                  }}
                />
              </div>
            </div>
            <div className="col-md-2 button-box">
              {index === values?.drug_allergies?.length - 1 ? (
                <button
                  className="btn btn-soft-primary waves-effect waves-light btn btn-secondary  round-btn"
                  type="button"
                  onClick={() => handleAddDrugAllergiesEntry()}>
                  <i className="mdi mdi-plus"></i>
                </button>
              ) : (
                <button
                  className="btn btn-soft-danger waves-effect waves-light btn btn-secondary round-btn"
                  type="button"
                  onClick={() => handleRemoveDrugAllergiesEntry(index)} >
                  <i className="mdi mdi-delete-outline"></i>
                </button>
              )}
            </div>
          </div>))}
          {/* <div className="col-12">
            <h6>Does the patinet have any of the following conditions</h6>
          </div>
          {values?.other_conditions &&
            values?.other_conditions.length > 0 &&
            values?.other_conditions.map((_: any, index: number) => (
              <div className="row" key={index}>
                <div className="col-md-4">
                  <div className="mb-3">
                    <Label className="form-label">Select Other Disease</Label>
                    <select
                      className="form-control"
                      name="disease"
                      value={values?.other_conditions[index]?.disease?.name}
                      onChange={(event) =>
                        handleChangeEntry(
                          "other_conditions",
                          event,
                          index,
                          patient_other_conditions
                        )
                      }
                    >
                      <option value="" disabled>
                        Select Other Disease
                      </option>
                      {patient_other_conditions &&
                        patient_other_conditions?.length > 0 &&
                        patient_other_conditions?.map(
                          ({ id, name }: IDiseaseOptions) => (
                            <option key={id} value={name}>
                              {name}
                            </option>
                          )
                        )}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label className="form-label">Details</Label>
                    <Input
                      name="details"
                      className="form-control"
                      type="text"
                      value={values?.other_conditions[index]?.details}
                      placeholder="Enter details"
                      onChange={(event) =>
                        handleChangeEntry(
                          "other_conditions",
                          event,
                          index,
                          patient_other_conditions
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-2 button-box">
                  {index === values?.other_conditions?.length - 1 ? (
                    <button
                      className="btn btn-soft-primary waves-effect waves-light btn btn-secondary  round-btn"
                      type="button"
                      onClick={() => handleAddEntry("other_conditions")}
                    >
                      <i className="mdi mdi-plus"></i>
                    </button>
                  ) : (
                    <button
                      className="btn btn-soft-danger waves-effect waves-light btn btn-secondary round-btn"
                      type="button"
                      onClick={() =>
                        handleRemoveEntry("other_conditions", index)
                      }
                    >
                      <i className="mdi mdi-delete-outline"></i>
                    </button>
                  )}
                </div>
              </div>
            ))}
          {errors?.other_conditions && touched?.other_conditions && (
            <span className="error">{errors?.other_conditions}</span>
          )} */}
          <div className="col-12">
            <h6>
              Does the patient have any of the following behavioral health
              conditions
            </h6>
          </div>
          {values?.behavioural_illness &&
            values?.behavioural_illness.length > 0 &&
            values?.behavioural_illness.map((_: any, index: number) => (
              <div className="row" key={index}>
                <div className="col-md-4">
                  <div className="mb-3">
                    <Label className="form-label">
                      Select Behavioural Illness
                    </Label>
                    <select
                      className="form-control"
                      name="disease"
                      value={values?.behavioural_illness[index]?.disease?.name}
                      onChange={(event) =>
                        handleChangeEntry(
                          "behavioural_illness",
                          event,
                          index,
                          behavioral_health_conditions
                        )
                      }
                    >
                      <option value="" disabled>
                        Select Behavioural Illness
                      </option>
                      {behavioral_health_conditions &&
                        behavioral_health_conditions?.length > 0 &&
                        behavioral_health_conditions?.map(
                          ({ id, name }: IDiseaseOptions) => (
                            <option key={id} value={name}>
                              {name}
                            </option>
                          )
                        )}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label className="form-label">Details</Label>
                    <Input
                      name="details"
                      className="form-control"
                      type="text"
                      value={values?.behavioural_illness[index]?.details}
                      placeholder="Enter details"
                      onChange={(event) =>
                        handleChangeEntry(
                          "behavioural_illness",
                          event,
                          index,
                          behavioral_health_conditions
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-2 button-box">
                  {index === values?.behavioural_illness?.length - 1 ? (
                    <button
                      className="btn btn-soft-primary waves-effect waves-light btn btn-secondary  round-btn"
                      type="button"
                      onClick={() => handleAddEntry("behavioural_illness")}
                    >
                      <i className="mdi mdi-plus"></i>
                    </button>
                  ) : (
                    <button
                      className="btn btn-soft-danger waves-effect waves-light btn btn-secondary round-btn"
                      type="button"
                      onClick={() =>
                        handleRemoveEntry("behavioural_illness", index)
                      }
                    >
                      <i className="mdi mdi-delete-outline"></i>
                    </button>
                  )}
                </div>
              </div>
            ))}
          {errors?.behavioural_illness && touched?.behavioural_illness && (
            <span className="error">{errors?.behavioural_illness}</span>
          )}

          <div className="col-12">
            <h6>Injuries - Have you had any?</h6>
          </div>
          {values?.injury &&
            values?.injury.length > 0 &&
            values?.injury.map((_: any, index: number) => (
              <div className="row" key={index}>
                <div className="col-md-4">
                  <div className="mb-3">
                    <Label className="form-label">Select Injury</Label>
                    <select
                      className="form-control"
                      name="disease"
                      value={values?.injury[index]?.disease?.name}
                      onChange={(event) =>
                        handleChangeEntry(
                          "injury",
                          event,
                          index,
                          patient_injuries
                        )
                      }
                    >
                      <option value="" disabled>
                        Select Injury
                      </option>
                      {patient_injuries &&
                        patient_injuries?.length > 0 &&
                        patient_injuries?.map(
                          ({ id, name }: IDiseaseOptions) => (
                            <option key={id} value={name}>
                              {name}
                            </option>
                          )
                        )}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label className="form-label">Details</Label>
                    <Input
                      name="details"
                      className="form-control"
                      type="text"
                      value={values?.injury[index]?.details}
                      placeholder="Enter details"
                      onChange={(event) =>
                        handleChangeEntry(
                          "injury",
                          event,
                          index,
                          patient_injuries
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-2 button-box">
                  {index === values?.injury?.length - 1 ? (
                    <button
                      className="btn btn-soft-primary waves-effect waves-light btn btn-secondary  round-btn"
                      type="button"
                      onClick={() => handleAddEntry("injury")}
                    >
                      <i className="mdi mdi-plus"></i>
                    </button>
                  ) : (
                    <button
                      className="btn btn-soft-danger waves-effect waves-light btn btn-secondary round-btn"
                      type="button"
                      onClick={() => handleRemoveEntry("injury", index)}
                    >
                      <i className="mdi mdi-delete-outline"></i>
                    </button>
                  )}
                </div>
              </div>
            ))}
          {errors?.injury && touched?.injury && (
            <span className="error">{errors?.injury}</span>
          )}
          {/* <div className="col-12 mt-3">
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                checked={showHospitalAdmissions}
                onChange={(event) => {
                  const isChecked = event.target.checked;
                  // setShowHospitalAdmissions(isChecked);
                  if (isChecked && values.hospital_admissions.length === 0)
                    setFieldValue("hospital_admissions", [
                      { year: "", illness: "" },
                    ]);
                  else if (!isChecked) setFieldValue("hospital_admissions", []);
                }}
                id="hospitalAdmission"
              />
              <label className="form-check-label" htmlFor="hospitalAdmission">
                <h6>
                  Hospital Admissions - (Indicate year and reason admitted,
                  Exclude Pregnancies)
                </h6>
              </label>
            </div>
          </div> */}
        </div>

        <CardTitle className="h4 hospital-card-title text-primary">
          Hospital Admissions
        </CardTitle>

        {values?.medical && values?.medical?.length > 0 && (
          <div className="row">
            {values?.medical?.map((_: any, index: number) => (
              <div className="row" key={index}>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Medical</label>
                    <input
                      name="name"
                      className="form-control"
                      type="text"
                      placeholder="Enter Medical"
                      value={values?.medical[index].name}
                      onChange={(event) =>
                        handleChangeEntry(
                          "medical",
                          event,
                          index,
                          []
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <label className="form-label">Details</label>
                    <input
                      name="details"
                      className="form-control"
                      type="text"
                      placeholder="Enter Medical Details"
                      value={values?.medical[index].details}
                      onChange={(event) =>
                        handleChangeEntry(
                          "medical",
                          event,
                          index,
                          []
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-2 button-box">
                  {index === values?.medical?.length - 1 ? (
                    <button
                      className="btn btn-soft-primary waves-effect waves-light btn btn-secondary  round-btn"
                      type="button"
                      onClick={() => handleAddEntry("medical")}
                    >
                      <i className="mdi mdi-plus"></i>
                    </button>
                  ) : (
                    <button
                      className="btn btn-soft-danger waves-effect waves-light btn btn-secondary round-btn"
                      type="button"
                      onClick={() =>
                        handleRemoveEntry("medical", index)
                      }
                    >
                      <i className="mdi mdi-delete-outline"></i>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {errors?.medical && touched?.medical && (
          <span className="error">{errors?.medical}</span>
        )}

        {values?.surgical && values?.surgical?.length > 0 && (
          <div className="row">
            {values?.surgical?.map((_: any, index: number) => (
              <div className="row" key={index}>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Surgical</label>
                    <input
                      name="name"
                      className="form-control"
                      type="text"
                      placeholder="Enter Surgical"
                      value={values?.surgical[index].name}
                      onChange={(event) =>
                        handleChangeEntry(
                          "surgical",
                          event,
                          index,
                          []
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <label className="form-label">Details</label>
                    <input
                      name="details"
                      className="form-control"
                      type="text"
                      placeholder="Enter Surgical Details"
                      value={values?.surgical[index].details}
                      onChange={(event) =>
                        handleChangeEntry(
                          "surgical",
                          event,
                          index,
                          []
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-2 button-box">
                  {index === values?.surgical?.length - 1 ? (
                    <button
                      className="btn btn-soft-primary waves-effect waves-light btn btn-secondary  round-btn"
                      type="button"
                      onClick={() => handleAddEntry("surgical")}
                    >
                      <i className="mdi mdi-plus"></i>
                    </button>
                  ) : (
                    <button
                      className="btn btn-soft-danger waves-effect waves-light btn btn-secondary round-btn"
                      type="button"
                      onClick={() =>
                        handleRemoveEntry("surgical", index)
                      }
                    >
                      <i className="mdi mdi-delete-outline"></i>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {errors?.surgical && touched?.surgical && (
          <span className="error">{errors?.surgical}</span>
        )}
        <div className="col-12 button-box text-center">
          <button
            type="button"
            onClick={() => handlePrevious()}
            style={{ display: subTabSecond === 1 ? "none" : "" }}
            className={
              dirty && isValid
                ? "btn btn-primary mr-2 medium-btn"
                : "btn btn-primary mr-2 medium-btn disabled-btn"
            }
          >
            <i className="mdi mdi-chevron-left" /> Previous
          </button>
          <button
            type="button"
            style={{ display: subTabSecond === subTabs.length ? "none" : "" }}
            onClick={() => handleNext()}
            className={
              dirty && isValid
                ? "btn btn-primary mr-2 medium-btn"
                : "btn btn-primary mr-2 medium-btn disabled-btn"
            }
          >
            Next <i className="mdi mdi-chevron-right" />
          </button>
        </div>
      </CardBody>
    </Card>

  );
};

export default Illness;