import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Card, CardBody, CardTitle, Input, Label } from "reactstrap";
import { getPatientBloodDiseasessList } from "../../../redux/actions/patientBloodDiseasesAction";
import { IDiseaseOptions } from "../../../pages/patients/CreatePatient";
import { family_relation, substances_types } from "../../../helpers/constants";

const FamilyHistory: FC<any> = ({ handlePrevious, subTabSecond, dirty, isValid, subTabs, handleNext, setFieldValue, values }) => {

  const [blood_diseases, setblood_diseases] = useState<IDiseaseOptions[]>();

  /* Family History */
  const handleAddFamilyHistoryEntry = () => setFieldValue('family_history', [...values.family_history, { id: uuidv4(), relation: "", details: "" }]);
  const handleRemoveFamilyHistoryEntry = (index: number) => {
    const updatedFamilyHistory = [...values.family_history];
    updatedFamilyHistory.splice(index, 1);
    setFieldValue('family_history', updatedFamilyHistory);
  };

  const handleChangeFamilyHistoryEntry = (event: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    const updatedFamilyHistory = [...values.family_history];
    updatedFamilyHistory[index][name] = value;
    setFieldValue('family_history', updatedFamilyHistory);
  };

  /* Medication */
  const handleAddMedicationEntry = () => setFieldValue('medication', [...values.medication, { id: uuidv4(), name: "", strength: "", how_often: "" }]);
  const handleRemoveMedicationEntry = (index: number) => {
    const updatedMedication = [...values.medication];
    updatedMedication.splice(index, 1);
    setFieldValue('medication', updatedMedication);
  };

  const handleChangeMedicationEntry = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    const updatedMedication = [...values.medication];
    updatedMedication[index][name] = value;
    setFieldValue('medication', updatedMedication);
  };

  /* Drug Allergies: */

  /* const handleAddDrugAllergiesEntry = () => setFieldValue('drug_allergies', [...values.drug_allergies, { id: uuidv4(), name: "", reaction: "" }]);
  const handleRemoveDrugAllergiesEntry = (index: number) => {
    const updatedDrugAllergies = [...values?.drug_allergies];
    updatedDrugAllergies.splice(index, 1);
    setFieldValue('drug_allergies', updatedDrugAllergies);
  };

  const handleChangeDrugAllergiesEntry = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    const updatedDrugAllergies = [...values.drug_allergies];
    updatedDrugAllergies[index][name] = value;
    setFieldValue('drug_allergies', updatedDrugAllergies);
  }; */


  const fetchPatientBloodDiseasessList = async () => {
    const { body } = await getPatientBloodDiseasessList({});
    if (body) setblood_diseases(body?.data);
    else setblood_diseases([]);
  }


  useEffect(() => {
    if (subTabSecond === 3) {
      fetchPatientBloodDiseasessList()
    }
  }, [subTabSecond]);

  /* START OTHER CONDITIONS */
  const handleAddFamilyBloodDiseaseEntry = () => setFieldValue('family_blood_diseases', [...values.family_blood_diseases, { id: uuidv4(), disease: { id: "", name: "" }, details: "" }]);
  const handleRemoveFamilyBloodDiseaseEntry = (index: number) => {
    const updatedFamilyBloodDisease = [...values.family_blood_diseases];
    updatedFamilyBloodDisease.splice(index, 1);
    setFieldValue('family_blood_diseases', updatedFamilyBloodDisease);
  };
  const handleChangeFamilyBloodDiseaseEntry = (event: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    const singleIllnessObj = blood_diseases?.find(({ name }) => name === value);

    const updatedFamilyBloodDisease = [...values.family_blood_diseases];
    if (name === "disease") updatedFamilyBloodDisease[index]["disease"] = { id: singleIllnessObj?.id, name: singleIllnessObj?.name };
    else updatedFamilyBloodDisease[index]["details"] = value;
    setFieldValue('family_blood_diseases', updatedFamilyBloodDisease);
  };

  /* START CONFIDENTIAL */
  const handleAddConfidentialDiseaseEntry = () => setFieldValue('confidential_questions', [...values.confidential_questions, { id: uuidv4(), substances: "", quantity: "", frequency: "", how_long: "" }]);
  const handleRemoveConfidentialDiseaseEntry = (index: number) => {
    const updatedConfidentialDisease = [...values.confidential_questions];
    updatedConfidentialDisease.splice(index, 1);
    setFieldValue('confidential_questions', updatedConfidentialDisease);
  };
  const handleChangeConfidentialDiseaseEntry = (event: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    const updatedConfidentialDisease = [...values.confidential_questions];
    updatedConfidentialDisease[index][name] = value;
    setFieldValue('confidential_questions', updatedConfidentialDisease);
  };
  /* END CONFIDENTIAL */
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="h4 hospital-card-title text-primary">
            Family History
          </CardTitle>
          {values?.family_history && values?.family_history.length > 0 && values?.family_history.map((_: any, index: number) =>
          (<div className="row" key={index}>
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="form-label">Select Relation</Label>
                <select className="form-control" name="relation"
                  value={values?.family_history[index]?.relation}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                    handleChangeFamilyHistoryEntry(event, index);
                  }}>
                  <option value="" disabled>Select Relation</option>
                  {family_relation && family_relation?.length > 0 &&
                    family_relation.map(({ id, name }: IDiseaseOptions) =>
                      <option key={id} value={name}>{name}</option>)}
                </select>
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="mb-3">
                <Label className="form-label">Age</Label>
                <Input
                  name="age"
                  className="form-control"
                  type="text"
                  value={values?.family_history[index]?.age}
                  placeholder="Enter Age"
                  onChange={(event) => {
                    // handleChange(event);
                    handleChangeFamilyHistoryEntry(event, index)
                  }}
                />
              </div>
            </div> */}
            <div className="col-md-6">
              <div className="mb-3">
                <Label className="form-label">Details</Label>
                <Input
                  name="details"
                  className="form-control"
                  type="text"
                  value={values?.family_history[index]?.details}
                  placeholder="Enter Details"
                  onChange={(event) => {
                    // handleChange(event);
                    handleChangeFamilyHistoryEntry(event, index)
                  }}
                />
              </div>
            </div>
            <div className="col-md-2 button-box">
              {index === values?.family_history?.length - 1 ? (
                <button
                  className="btn btn-soft-primary waves-effect waves-light btn btn-secondary  round-btn"
                  type="button"
                  onClick={() => handleAddFamilyHistoryEntry()}>
                  <i className="mdi mdi-plus"></i>
                </button>
              ) : (
                <button
                  className="btn btn-soft-danger waves-effect waves-light btn btn-secondary round-btn"
                  type="button"
                  onClick={() => handleRemoveFamilyHistoryEntry(index)}
                >
                  <i className="mdi mdi-delete-outline"></i>
                </button>
              )}
            </div>
          </div>))}

          <div className="row">
            <div className="col-12 mt-3">
              <h6>Has any blood relatives ever had:</h6>
            </div>
            {values?.family_blood_diseases && values?.family_blood_diseases.length > 0 && values?.family_blood_diseases.map((_: any, index: number) =>
            (<div className="row" key={index}>
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="form-label">Select Blood Disease</Label>
                  <select className="form-control" name="disease"
                    value={values?.family_blood_diseases[index]?.disease?.name}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                      // handleChange(event);
                      handleChangeFamilyBloodDiseaseEntry(event, index);
                    }}>
                    <option value="" disabled>Select Blood Disease</option>
                    {blood_diseases && blood_diseases?.length > 0 &&
                      blood_diseases?.map(({ id, name }: IDiseaseOptions) =>
                        <option key={id} value={name}>{name}</option>)}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <Label className="form-label">Details</Label>
                  <Input
                    name="details"
                    className="form-control"
                    type="text"
                    value={values?.family_blood_diseases[index]?.details}
                    placeholder="Enter details"
                    onChange={(event) => handleChangeFamilyBloodDiseaseEntry(event, index)}
                  />
                </div>
              </div>
              <div className="col-md-2 button-box">
                {index === values?.family_blood_diseases?.length - 1 ? (
                  <button
                    className="btn btn-soft-primary waves-effect waves-light btn btn-secondary  round-btn"
                    type="button"
                    onClick={() => handleAddFamilyBloodDiseaseEntry()}
                  >
                    <i className="mdi mdi-plus"></i>
                  </button>
                ) : (
                  <button
                    className="btn btn-soft-danger waves-effect waves-light btn btn-secondary round-btn"
                    type="button"
                    onClick={() => handleRemoveFamilyBloodDiseaseEntry(index)}
                  >
                    <i className="mdi mdi-delete-outline"></i>
                  </button>
                )}
              </div>
            </div>))}
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <CardTitle className="h4 hospital-card-title text-primary">
            Social History
          </CardTitle>
          <div className="row">
            {/* <div className="col-md-6">
              <div className="mb-3">
                <Label className="form-label">Marital status</Label>
                <div className="d-block mt-2">
                  <div className="form-check d-inline-block mr-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios2"
                      id="exampleRadios1"
                      value="option1"
                      defaultChecked
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios1"
                    >
                      Single
                    </label>
                  </div>
                  <div className="form-check d-inline-block mr-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios2"
                      id="exampleRadios2"
                      value="option2"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios2"
                    >
                      Married
                    </label>
                  </div>
                  <div className="form-check d-inline-block mr-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios2"
                      id="exampleRadios3"
                      value="option3"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios2"
                    >
                      Separated
                    </label>
                  </div>
                  <div className="form-check d-inline-block mr-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios2"
                      id="exampleRadios4"
                      value="option4"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios2"
                    >
                      Divorced
                    </label>
                  </div>
                  <div className="form-check d-inline-block mr-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios2"
                      id="exampleRadios5"
                      value="option5"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios2"
                    >
                      Widowed
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <Label className="form-label">Living with</Label>
                <div className="d-block mt-2">
                  <div className="form-check d-inline-block mr-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios4"
                      id="exampleRadios1"
                      value="option1"
                      defaultChecked
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios1"
                    >
                      Husband
                    </label>
                  </div>
                  <div className="form-check d-inline-block mr-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios2"
                      id="exampleRadios2"
                      value="option2"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios4"
                    >
                      Wife
                    </label>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-check mb-2">
                <input className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck37"
                />
                <label
                  className="form-check-label"
                  htmlFor="defaultCheck37"
                >
                  Do you have dependents at home?
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-check mb-2">
                <input className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck39"
                />
                <label
                  className="form-check-label"
                  htmlFor="defaultCheck39"
                >
                  Is your sex life satisfactory?
                </label>
              </div>
            </div> */}
            <div className="col-12 mt-3">
              <h6>Please answer the following confidential questions</h6>
            </div>
            {values?.confidential_questions && values?.confidential_questions.length > 0 && values?.confidential_questions.map((_: any, index: number) =>
            (<div className="row" key={index}>
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="form-label">Select Substances Type</Label>
                  <select className="form-control" name="substances"
                    value={values?.confidential_questions[index]?.substances}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChangeConfidentialDiseaseEntry(event, index)}>
                    <option value="" disabled>Select Substances</option>
                    {substances_types && substances_types?.length > 0 &&
                      substances_types?.map(({ id, name }: IDiseaseOptions) =>
                        <option key={id} value={name}>{name}</option>)}
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <div className="mb-3">
                  <Label className="form-label">Quantity</Label>
                  <Input
                    name="quantity"
                    className="form-control"
                    type="text"
                    value={values?.confidential_questions[index]?.quantity}
                    placeholder="Enter quantity"
                    onChange={(event) => handleChangeConfidentialDiseaseEntry(event, index)}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="mb-3">
                  <Label className="form-label">Frequency</Label>
                  <Input
                    name="frequency"
                    className="form-control"
                    type="text"
                    value={values?.confidential_questions[index]?.frequency}
                    placeholder="Enter Frequency"
                    onChange={(event) => handleChangeConfidentialDiseaseEntry(event, index)}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="mb-3">
                  <Label className="form-label">How Long</Label>
                  <Input
                    name="how_long"
                    className="form-control"
                    type="text"
                    value={values?.confidential_questions[index]?.how_long}
                    placeholder="Enter How Long"
                    onChange={(event) => handleChangeConfidentialDiseaseEntry(event, index)}
                  />
                </div>
              </div>
              <div className="col-md-2 button-box">
                {index === values?.confidential_questions?.length - 1 ? (
                  <button
                    className="btn btn-soft-primary waves-effect waves-light btn btn-secondary  round-btn"
                    type="button"
                    onClick={() => handleAddConfidentialDiseaseEntry()}
                  >
                    <i className="mdi mdi-plus"></i>
                  </button>
                ) : (
                  <button
                    className="btn btn-soft-danger waves-effect waves-light btn btn-secondary round-btn"
                    type="button"
                    onClick={() => handleRemoveConfidentialDiseaseEntry(index)}
                  >
                    <i className="mdi mdi-delete-outline"></i>
                  </button>
                )}
              </div>
            </div>))}
            <div className="col-12 mt-3">
              <h6>Medications: List all you take including over the counter</h6>
            </div>
            {values?.medication && values?.medication.length && values?.medication.map((_: any, index: number) =>
            (<div className="row" key={index}>
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="form-label">Name</Label>
                  <Input
                    name="name"
                    className="form-control"
                    type="text"
                    placeholder="Enter Name"
                    value={values?.medication[index]?.name}
                    onChange={(event) => handleChangeMedicationEntry(event, index)} />
                </div>
              </div>



              <div className="col-md-6">
                <div className="mb-3">
                  <Label className="form-label">Details</Label>
                  <Input
                    name="details"
                    className="form-control"
                    type="text"
                    placeholder="Enter details"
                    value={values?.medication[index].details}
                    onChange={(event) => handleChangeMedicationEntry(event, index)}
                  />
                </div>
              </div>
              <div className="col-md-2 button-box">
                {index === values?.medication?.length - 1 ? (
                  <button
                    className="btn btn-soft-primary waves-effect waves-light btn btn-secondary  round-btn"
                    type="button"
                    onClick={() => handleAddMedicationEntry()}>
                    <i className="mdi mdi-plus"></i>
                  </button>
                ) : (
                  <button
                    className="btn btn-soft-danger waves-effect waves-light btn btn-secondary round-btn"
                    type="button"
                    onClick={() => handleRemoveMedicationEntry(index)} >
                    <i className="mdi mdi-delete-outline"></i>
                  </button>
                )}
              </div>
            </div>))}

            {/* <div className="col-12 mt-3">
              <h6>
                Drug Allergies: List all drugs allergies and indicate your
                reactions
              </h6>
            </div>
            {values?.drug_allergies && values?.drug_allergies.length > 0 && values?.drug_allergies.map((_: any, index: number) =>
            (<div className="row" key={index}>
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="form-label">Drug Name</Label>
                  <Input
                    name="name"
                    className="form-control"
                    type="text"
                    value={values?.drug_allergies[index].name}
                    placeholder="Enter Name"
                    onChange={(event) => {
                      // handleChange(event);
                      handleChangeDrugAllergiesEntry(event, index);
                    }} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <Label className="form-label">Reaction</Label>
                  <Input
                    name="reaction"
                    className="form-control"
                    type="text"
                    value={values?.drug_allergies[index].reaction}
                    placeholder="Enter Reaction"
                    onChange={(event) => {
                      // handleChange(event);
                      handleChangeDrugAllergiesEntry(event, index)
                    }}
                  />
                </div>
              </div>
              <div className="col-md-2 button-box">
                {index === values?.drug_allergies?.length - 1 ? (
                  <button
                    className="btn btn-soft-primary waves-effect waves-light btn btn-secondary  round-btn"
                    type="button"
                    onClick={() => handleAddDrugAllergiesEntry()}>
                    <i className="mdi mdi-plus"></i>
                  </button>
                ) : (
                  <button
                    className="btn btn-soft-danger waves-effect waves-light btn btn-secondary round-btn"
                    type="button"
                    onClick={() => handleRemoveDrugAllergiesEntry(index)} >
                    <i className="mdi mdi-delete-outline"></i>
                  </button>
                )}
              </div>
            </div>))} */}
          </div>
          <div className="col-12 button-box text-center">
            <button
              type="button"
              onClick={() => handlePrevious()}
              style={{
                display: subTabSecond === 1 ? "none" : "",
              }}
              className={
                dirty && isValid
                  ? "btn btn-primary mr-2 medium-btn"
                  : "btn btn-primary mr-2 medium-btn disabled-btn"
              }
            >
              <i className="mdi mdi-chevron-left" /> Previous
            </button>
            {/* <button
              type="button"
              style={{
                display: subTabSecond === subTabs.length ? "none" : "",
              }}
              onClick={() => handleNext()}
              className={
                dirty && isValid
                  ? "btn btn-primary mr-2 medium-btn"
                  : "btn btn-primary mr-2 medium-btn disabled-btn"
              }
            >
              Next <i className="mdi mdi-chevron-right" />
            </button> */}
            <button
              type="submit"
              style={{
                display: subTabSecond === 3 ? "" : "none",
              }}
              className={
                dirty && isValid
                  ? "btn btn-primary mr-2 medium-btn"
                  : "btn btn-primary mr-2 medium-btn disabled-btn"
              }
            >
              Create Patient
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default FamilyHistory;