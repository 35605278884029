import React, { useState, useEffect, useCallback } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  logoutUser,
  save_token,
  update_user_web_token,
} from "../../redux/actions/userAction";
import { getFirebaseToken } from "../../firebase";
import { ErrorToast, SuccessToast } from "../../utils/toastNotifications";
import "../../assets/scss/toggle_switch.css";
import { getNotificationsList } from "../../redux/actions/notificationsAction";
import { ISingleNotification } from "../../redux/types/notificationsTypes";
import { formatDateToCustomDateString, formatDateToLocaleDateString } from "../../helpers/helperFunctions";
import { useNavigate } from "react-router-dom";
import maleAvatar from "../../assets/images/users/male_avatar.png";
import femaleAvatar from "../../assets/images/users/female_avatar.png";
 
 
const SideHeader = () => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [notify_menu, setnotify_menu] = useState(false);
  const dispatch: any = useDispatch();
  const [token_check, settoken_check] = useState(false);
  const [current_browser_token, setcurrent_browser_token] = useState("");
  const { user_info, token } = useSelector((state: any) => state.user);
  const [notifications, setNotifications] = useState([]);
  // State for theme
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
 
  // const remove_token = async () => {
  //   let web_tokens_data = user_info?.web_token?.filter((d: any) => {
  //     return d !== current_browser_token;
  //   });
  //   let data = await update_user_web_token({ web_token: web_tokens_data });
  //   dispatch(save_token(data?.body?.updated_user, token));
 
  //   SuccessToast("Notifications disabled successfully");
  //   update_token_check();
  // };
 
 
  // const add_token = async () => {
  //   let web_tokens_data = user_info?.web_token;
  //   getFirebaseToken()
  //     .then(async (firebaseToken) => {
  //       setcurrent_browser_token(firebaseToken);
  //       let upload_data = [...web_tokens_data, firebaseToken.toString()];
  //       let data = await update_user_web_token({ web_token: upload_data });
  //       dispatch(save_token(data?.body?.updated_user, token));
 
  //       SuccessToast("Notifications enabled successfully");
  //       update_token_check();
  //       if (!firebaseToken) {
  //         ErrorToast("Please enable notification in browser");
  //       }
  //     })
  //     .catch((err) => {
  //       ErrorToast("Please enable notification in browser");
  //     });
  // };
  // const fetchNotifications = async () => {
  //   const params = user_info?.is_super_admin === false ? { hospital_property_id: user_info?.hospital_property?.id } : {}
  //   const { body } = await getNotificationsList(params);
  //   if (body?.data) {
  //     const latestNotifications = body?.data?.slice(0, 10);
  //     setNotifications(latestNotifications);
  //   }
  // };
 
  // useEffect(() => {
  //   fetchNotifications();
  //   getFirebaseToken()
  //     .then((firebaseToken) => {
  //       setcurrent_browser_token(firebaseToken);
  //       // if (!firebaseToken) {
  //       //   ErrorToast("Please enable notification in browser");
  //       // }
  //     })
  //     .catch((err) => {
  //       // ErrorToast("Please enable notification in browser");
  //     });
  // }, []);
 
  // let update_token_check = useCallback(() => {
  //   let web_tokens =
  //     user_info?.web_token === undefined || null ? [] : user_info?.web_token;
  //   let token_check = web_tokens.includes(current_browser_token?.toString());
  //   settoken_check(token_check);
  // }, [current_browser_token, user_info?.web_token]);
 
  // useEffect(() => {
  //   update_token_check();
  // }, [update_token_check]);
 
  // Function to toggle theme
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    document.body.setAttribute("data-layout-mode", newTheme);
  }
 
  useEffect(() => {
    document.body.classList.remove("light-theme", "dark-theme");
    document.body.classList.add(`${theme}-theme`);
    document.body.setAttribute("data-layout-mode", theme);
  }, [theme]);
 
  return (
    <div className="d-flex padding-top">
      <div className="dropdown d-none d-lg-inline-block ms-1">
        {/* <button
          type="button"
          className="btn header-item noti-icon "
          data-toggle="fullscreen"
        >
          <i className="bx bx-fullscreen" />
        </button> */}
        <div
          className="form-check form-switch d-lg-inline-block"
          style={{ marginTop: 7 }}
        >
          {/*  <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizesm"
            checked={token_check}
            onChange={(e) => {
              if (token_check === false) {
                add_token();
              } else {
                remove_token();
              }
            }}
          /> */}
          <label className="form-check-label"></label>
        </div>
      </div>
      <Dropdown
        isOpen={notify_menu}
        toggle={() => setnotify_menu(!notify_menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          {/* {notifications?.length > 0 && (<span className="badge bg-danger rounded-pill">{notifications?.length}</span>)}         */}
        </DropdownToggle>
 
        <DropdownMenu className="dropdown-menu-end notification-menu ">
          <div className="p-3">
            <div className="align-items-center row">
              <div className="col">
                <h6 className="m-0"> Notifications </h6>
              </div>
              <div className="col-auto">
                <a href="javascript:void(0);" className="small">
                  {" "}
                  View All
                </a>
              </div>
            </div>
          </div>
          <div>
            {/* <ul className="p-3">
              {notifications && notifications.length > 0 ? (
                notifications.map((notification: ISingleNotification, index: number) => (
                  <li key={index} className="text-muted">
                    <p className="m-0">
                      {notification?.notification_text}
                    </p>
                    <small>
                      <i className="mdi mdi-clock-outline"></i>
                      {formatDateToLocaleDateString(notification?.created_on)}
                    </small>
                  </li>
 
                ))) :
                (<li className="text-muted">
                  <p className="m-0">
                    No Notifications Received.
                  </p>
                </li>)}
 
            </ul> */}
          </div>
        </DropdownMenu>
      </Dropdown>
 
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user_info?.gender === "female" ? femaleAvatar : maleAvatar}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {user_info?.name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="button" onClick={() => navigate("/profile")}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Profile
          </DropdownItem>
 
          <DropdownItem tag="a" href="javascript:void(0);">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            Settings
          </DropdownItem>
 
          <DropdownItem tag="a" onClick={toggleTheme}>
            {theme === "light" ? <i className="bx bx-moon font-size-18 align-middle me-1" /> : <i className="bx bx-sun font-size-18 align-middle me-1" />}
            {theme === "light" ? "Dark Theme" : "Light Theme"}
          </DropdownItem>
 
          <div className="dropdown-divider" />
          <button
            className="dropdown-item"
            onClick={() => dispatch(logoutUser())}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </button>
        </DropdownMenu>
      </Dropdown>
      {/* <div className="dropdown d-inline-block">
        <button
          type="button"
          className="btn header-item noti-icon right-bar-toggle "
        >
          <i className="bx bx-cog bx-spin" />
        </button>
      </div> */}
    </div>
  );
};
 
export default SideHeader;
 