import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import logo from "../../assets/images/logo-icu-sm.png";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { RootState } from "../../redux/store";
import SideHeader from "./SideHeader";
import { ActionTypes } from "../../redux/types/reportTabsTypes";
import entelIconNew from "../../assets/images/Entel-Icon-New.png";
import PageName from "../PageSubHeaderNameComponent/PageName";

const ReportHeader = () => {
    const [headerState, setHeaderState] = useState(Boolean(localStorage.getItem("header")));
    const currentActiveTab = useSelector((state: RootState) => state.reportHeader.currentActiveTab);
    const dispatch = useDispatch();

    useEffect(() => {
        // Update local storage and body class based on Redux state
        localStorage.setItem("header", JSON.stringify(headerState));
        document.body.classList.toggle("vertical-collpsed", headerState);
    }, [headerState]);

    const toggle = (tab: string) => {
        if (currentActiveTab !== tab)
            dispatch({
                type: ActionTypes.SET_CURRENT_REPORT_ACTIVE_TAB,
                payload: tab,
            });
    };


    const changeHeaderState = () => {
        setHeaderState(!headerState);
        dispatch({
            type: ActionTypes.TOGGLE_HEADER_STATE,
            payload: "1"
        });
    }

    return (
        // <header className="topbar" id="page-topbar">
        //     <div className="navbar-header">
        <>
            {/* // <header className="topbar" id="page-topbar">
            //     <div className="navbar-header"> */}
            {/* <div className="report-header-padding d-flex"> */}
            {/* <div className="navbar-brand-box d-lg-none d-md-block">
                    <Link to="/" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={entelIconNew} alt="" height="22" />
                        </span>
                    </Link>

                    <Link to="/" className="logo logo-light">
                        <span className="logo-sm">
                            <img src={entelIconNew} alt="" height="22" />
                        </span>
                    </Link>
                </div>
                <button
                    type="button"
                    className="btn btn-sm px-3 font-size-16 header-item "
                    id="vertical-menu-btn"
                    onClick={changeHeaderState}
                >
                    <i className="fa fa-fw fa-bars" />
                </button> */}
            <div className="d-sm-flex align-items-center justify-content-between">
                <PageName title={"Reports"}/>
                <div className="page-title-right d-flex">
                    <Nav tabs className="nav-tabs-custom nav-justified mr-2">
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={currentActiveTab === "1" ? "active" : ""}
                                onClick={() => {
                                    toggle("1");
                                    dispatch({
                                        type: ActionTypes.SET_CURRENT_REPORT_ACTIVE_TAB,
                                        payload: "1",
                                    });
                                }}
                            >
                                <span className="d-none d-sm-block hospital-name">
                                    General
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={currentActiveTab === "2" ? "active" : ""}
                                onClick={() => {
                                    toggle("2");
                                    dispatch({
                                        type: ActionTypes.SET_CURRENT_REPORT_ACTIVE_TAB,
                                        payload: "2",
                                    });
                                }}
                            >
                                <span className="d-none d-sm-block hospital-name">
                                    {" "}
                                    Line Listing
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={currentActiveTab === "3" ? "active" : ""}
                                onClick={() => {
                                    toggle("3");
                                    dispatch({
                                        type: ActionTypes.SET_CURRENT_REPORT_ACTIVE_TAB,
                                        payload: "3",
                                    });
                                }}
                            >
                                <span className="d-none d-sm-block hospital-name">
                                    {" "}
                                    Progressive Report
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={currentActiveTab === "4" ? "active" : ""}
                                onClick={() => {
                                    toggle("4");
                                    dispatch({
                                        type: ActionTypes.SET_CURRENT_REPORT_ACTIVE_TAB,
                                        payload: "4",
                                    });
                                }}
                            >
                                <span className="d-none d-sm-block hospital-name">
                                    {" "}
                                    Age Range
                                </span>
                            </NavLink>
                        </NavItem>

                    </Nav>
                </div>
            </div>
        </>
    );
};

export default ReportHeader;