import {
  hospital_property_equipment_routes,
  hospital_property_icu_routes,
} from "../api_path";
import axiosApi from "../../api/apiHelper";

export const get_hospital_equipments_by_hospital_id = async ({
  hospital_property_id,
  page_number,
  per_page,
}: {
  hospital_property_id: string | undefined;
  page_number?: number;
  per_page?: number;
}) => {
  try {
    const params: { [key: string]: number } = {};

    if (page_number !== undefined) params["page_number"] = page_number;
    if (per_page !== undefined) params["per_page"] = per_page;

    const { data } = await axiosApi.get(
      `${hospital_property_equipment_routes.all_hospital_property_equipments}/${hospital_property_id}`,
      { params }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.errors;
  }
};

export const post_hospital_equipment = async ({
  name,
  amount,
  hospital_property_id,
}: {
  name: string;
  amount: number;
  hospital_property_id: string | undefined;
}) => {
  try {
    const { data } = await axiosApi.post(
      hospital_property_equipment_routes.create_hospital_property_equipment,
      { name, amount, hospital_property_id }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const update_hospital_equipment = async ({
  id,
  name,
  amount,
}: {
  id: string;
  name: string;
  amount: number;
}) => {
  try {
    const { data } = await axiosApi.put(
      hospital_property_equipment_routes.update_hospital_property_equipment,
      { id, name, amount }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const delete_hospital_equipment = async ({ id }: { id: string }) => {
  try {
    const { data } = await axiosApi.delete(
      `${hospital_property_equipment_routes.delete_hospital_property_equipment}/${id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.data;
  }
};

interface IAddIcuEquipmentParams {
  amount: number;
  equipment_id: string;
  icu_id: string;
  name: string;
}
export const postIcuEquipment = async ({
  icu_id,
  equipment_id,
  amount,
  name,
}: IAddIcuEquipmentParams) => {
  try {
    const { data } = await axiosApi.post(
      hospital_property_icu_routes.post_icu_equipment,
      { icu_id, equipment_id, amount, name }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const deleteIcuEquipment = async (id: string) => {
  try {
    const { data } = await axiosApi.delete(
      `${hospital_property_icu_routes.delete_icu_equipment}/${id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
