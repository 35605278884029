import { Card, CardBody, Collapse, Nav, NavItem, NavLink } from "reactstrap";
import SvgFilesIcon from "../SvgFiles/SvgFiles";
import DOMPurify from "dompurify";
import { formatDateToLocaleDateString } from "../../helpers/helperFunctions";
import { PatientData } from "../../pages/DoctorProfiles/DoctorProfiles";
interface PatientTableProps {
  data: PatientData[];
  handlePatientSelection: (id: string) => void;
  handleNavigatePatient: (patientAdmission: any) => void;
  type: 'clinical_note' | 'prescription';
}

interface AccordionSectionProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  data: any;
  toggleCollapse: (index: number) => void;
  collapsedItems: boolean[];
  type: 'prescription' | 'clinical_note';
}

interface TabFilterProps {
  readFilter: string;
  setReadFilter: (filter: string) => void;
  allCount: number,
  readCount: number,
  notReadCount: number,
}

export const PatientTable1: React.FC<PatientTableProps> = ({ data, handlePatientSelection, handleNavigatePatient, type }) => {
  return (
    <div className="row scroll-bar-patients doctor-list-view patient-list-view-section">
      <div className="col-md-12">
        <Card>
          <CardBody>
            <div className="">
              <table className="table table-bordered table-nowrap mb-0 table-list">
                <thead className="table-light">
                  <tr>
                    <th>Patient Info</th>
                    <th>Hospital</th>
                    <th>Diagnosis</th>
                    <th>Date</th>
                    <th>{type === "clinical_note" ? "Clinical Notes" : "Prescription"}</th>
                    <th>Contact Medical Officer</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 ? data.map((item: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <div className="patient-card-title hospital-card-title ">
                          <div className="icon-box">
                            <SvgFilesIcon iconname={item?.patient_admission?.patient?.sex || "Male"} />
                          </div>
                          <div className="right-detail">
                            {item?.patient_admission?.patient?.first_name} {item?.patient_admission?.patient?.middle_name} {item?.patient_admission?.patient?.last_name}
                            <p className="text-muted">
                              {item?.patient_admission?.patient?.address} {item?.patient_admission?.patient?.city?.city} {item?.patient_admission?.patient?.state?.state} {item?.patient_admission?.patient?.country?.country}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        {item?.patient_admission?.hospital_property?.landmark}
                      </td>
                      <td>
                        {item?.patient_admission?.provisionalDiagnoses[item?.patient_admission?.provisionalDiagnoses.length - 1]?.note}
                      </td>
                      <td>
                        {formatDateToLocaleDateString(item?.assign_date)}
                      </td>
                      <td>
                        <div className="clinical-icon">
                          <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(type === "clinical_note" ? item?.note || "-" : item?.drug_instructions || "-") }}></p>

                          <a onClick={() => handlePatientSelection(item?.patient_admission?.id)}>
                            <SvgFilesIcon iconname={"eye"} />
                          </a>
                        </div>
                      </td>

                      <td>
                        {item?.patient_admission?.admission_by_doctor?.mobile}
                      </td>
                      <td>
                        {item?.is_read ? (
                          <div className="complete-box">
                            <p>Complete</p>
                          </div>
                        ) : (
                          <div className="pending-box">
                            <p>Pending</p>
                          </div>
                        )}
                      </td>
                      <td>
                        <div className="clinical-icon-viewdetails">
                          <a onClick={() => handleNavigatePatient(item?.patient_admission)}>
                            <SvgFilesIcon iconname={"viewdetails"} />
                          </a>
                        </div>
                      </td>
                    </tr>
                  )) : (
                    <tr>
                      <td colSpan={8}>No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};


export const AccordionSection: React.FC<AccordionSectionProps> = ({
  isOpen,
  setIsOpen,
  data,
  toggleCollapse,
  collapsedItems,
  type,
}) => {
  if (!isOpen) return null;
  return (
    isOpen && (
      <div className="right-panel">
        <h4>Doctors All {type === 'prescription' ? 'Prescription' : 'Clinical Notes'}</h4>
        <button
          className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light"
          onClick={() => setIsOpen(false)}
        >
          <i className="mdi mdi-close"></i>
        </button>
        <div className="row">
          <div className="mb-3">
            <div className="accordion" id="accordion">
              {data.length > 0 ? (
                data.map((note: any, index: any) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`heading${index}`}>
                      <button
                        className={`accordion-button fw-medium ${collapsedItems[index] ? 'collapsed' : ''}`}
                        type="button"
                        onClick={() => toggleCollapse(index)}
                        style={{ cursor: 'pointer' }}
                        aria-expanded={!collapsedItems[index]} // Set aria-expanded for accessibility
                      >
                        <b>{type === 'prescription' ? 'Prescribe By -: ' : 'By Doctor -: '}</b> {type === 'prescription' ? note.prescribed_by?.name || 'Unknown Doctor' : note.doctor?.name || 'Unknown Doctor'}
                        {note.refer_doctor && (
                          <span className="right-date">
                            <b>Refer Doctor -:</b> {note.refer_doctor?.name || 'Unknown Doctor'}
                          </span>
                        )}

                      </button>
                    </h2>
                    <Collapse
                      isOpen={collapsedItems[index]}
                      className="accordion-collapse"
                    >
                      <div className="accordion-body">
                        <div className="dp-aco-field">
                          <p>
                            <b>{type === 'prescription' ? 'Prescribe By -: ' : 'By Doctor -: '}</b> {type === 'prescription' ? note.prescribed_by?.name || 'Unknown Doctor' : note.doctor?.name || 'Unknown Doctor'}
                            <b> Date -: </b> {formatDateToLocaleDateString(note.modified_on?.split('T')[0]) || formatDateToLocaleDateString(note.created_on?.split('T')[0])}
                          </p>
                          <b> Doctor Note -: </b>
                          <p className="detail richtext-p-tag" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(type === 'prescription' ? note.drug_instructions || '-' : note.note || '-') }}></p>
                        </div>
                        <div style={{ borderTop: "1px solid #e3e3e3", paddingTop: "10px" }}></div>
                        {note.doctor_refer_note && (
                          <div>
                            <p>
                              <b>Refer Doctor -:</b> {note.refer_doctor?.name || 'Unknown Doctor'}
                              <b> Date -: </b>{formatDateToLocaleDateString(note.modified_on?.split('T')[0]) || formatDateToLocaleDateString(note.created_on?.split('T')[0])}
                            </p>
                            <b> Refer Doctor Note -: </b>
                            <p className="detail richtext-p-tag" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(note.doctor_refer_note || '-') }}></p>
                          </div>
                        )}
                      </div>
                    </Collapse>
                  </div>
                ))
              ) : (
                <div>No Data</div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default AccordionSection;

export const TabFilter: React.FC<TabFilterProps> = ({ readFilter, setReadFilter, allCount, readCount, notReadCount }) => (
  <div className="toggle-btn-group mb-2">
    <Nav tabs className="nav-tabs-custom nav-justified">
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={readFilter === 'All' ? 'active' : ''}
          onClick={() => setReadFilter('All')}
        >
          All
          <span className="badge ">
            ({allCount})
          </span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={readFilter === 'read' ? 'active' : ''}
          onClick={() => setReadFilter('read')}
        >
          Read
          <span className="badge ">
            ({readCount})
          </span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={readFilter === 'not_read' ? 'active' : ''}
          onClick={() => setReadFilter('not_read')}
        >
          Not Read
          <span className="badge ">
            ({notReadCount})
          </span>
        </NavLink>
      </NavItem>
    </Nav>
  </div>
);