import { FC } from "react";

import { Card, CardBody, CardTitle } from "reactstrap";
import logo1 from "../../assets/images/logo-1.png";
import SvgFilesIcon from "../SvgFiles/SvgFiles";

const HospitalPropertyCardView: FC<any> = ({ hospital_property, handleEditHospitalProperty, handleDeleteHospitalProperty }) => {
  return (
    <>
      <div className="patient-title-box">
        <div className="icon-box">
          <SvgFilesIcon iconname="hospital" />
        </div>
        <h4>{hospital_property?.landmark.length > 20 ? `${hospital_property?.landmark.slice(0, 20)}...` : hospital_property?.landmark}</h4>
      </div>
      <div className="user-setting-box">
        <div className="edit-box" onClick={(e) => handleEditHospitalProperty(e, hospital_property)}>
          <SvgFilesIcon iconname={"edit"} />
        </div>
        <div className="delete-box" onClick={(e) => handleDeleteHospitalProperty(e, hospital_property)}>
          <SvgFilesIcon iconname={"delete"} />
        </div>
      </div>
      <div className="contact-box">
        <div className="common-box">
          <span>Contact Person</span>
          <p>{hospital_property?.contact_person || "-"}</p>
        </div>
        <div className="common-box">
          <span>Contact Number</span>
          <p>{hospital_property?.mobile || "-"}</p>
        </div>
      </div>
      <div className="address-box">
        <span>Address</span>
        <p>{hospital_property?.address || " "} {hospital_property?.city?.city || " "} {hospital_property?.state?.state || " "} {hospital_property?.country?.country || " "} {hospital_property?.zipcode || " "}</p>
      </div>
      <div className="status-box">
        <div className="discharge-box">
          <SvgFilesIcon iconname="active" />
          <p>Active</p>
        </div>
      </div>
    </>
  );
};

export default HospitalPropertyCardView;