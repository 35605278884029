import React, { useState, useEffect, FC } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { hospital_property_by_id } from "../../redux/actions/hospitalPropertyAction";
import { hospital_property_access } from "../../helpers/access_policies";
import logo1 from "../../assets/images/logo-1.png";
import HospitalHeader from "../../components/Header/HospitalHeader";
import HospitalPropertyEquipments from "./hospitalPropertyComponents/HospitalPropertyEquipments";
import HospitalPropertyIcus from "./hospitalPropertyComponents/HospitalPropertyIcus";
import moment from "moment";
import NoAccess from "../../components/NoAccess";
import { formatDateToLocaleDateString } from "../../helpers/helperFunctions";
import { Link } from "react-router-dom";
import SvgFilesIcon from "../../components/SvgFiles/SvgFiles";

const SingleHospitalProperty: FC<any> = ({ handleEditHospitalProperty, hospital_property_id, handleDeleteHospitalProperty, setSingleShowHospitalPropertyModal }) => {
  const [subTab, setsubTab] = useState("1");
  const [loader, setLoader] = useState(false);
  const { user_info } = useSelector((state: any) => state.user);
  const [hospital_property, sethospital_property]: any = useState(null);

  const subTabtoggle = (tab: string) => {
    if (subTab !== tab) setsubTab(tab);
  };

  useEffect(() => {
    if (hospital_property_id !== undefined) {
      setLoader(true);
      const fetch_hospital_property = async () => {
        const request = await hospital_property_by_id(hospital_property_id);
        if (request?.statusCode === 200) {
          sethospital_property(request?.body?.data);
        } else {
          sethospital_property(null);
        }
        setLoader(false);
      };

      fetch_hospital_property();
    }
  }, [hospital_property_id]);

  return (
    <React.Fragment>

      {loader && (
        <div className="loader-box">
          <span className="loader"></span>
        </div>
      )}
      {user_info?.user_access?.includes(
        hospital_property_access.read_hospital_property
      ) ? (
        <div className="row">
          <div className="col-12 col">
            <div className="page-title-box common-top-fix-title d-sm-flex align-items-center justify-content-between">

              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-sm-0 font-size-14">
                  <li className="breadcrumb-item">
                    <a href="/hospitals">Hospitals</a>
                  </li>
                  <li className="breadcrumb-item">
                    {hospital_property?.hospital?.name}
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >
                    {hospital_property?.landmark}
                  </li>
                </ol>
              </nav>
              <HospitalHeader />
              <div className="page-title-right d-flex">
                <a onClick={() => setSingleShowHospitalPropertyModal(false)} style={{ cursor: "pointer" }} className="back-btn" >
                  <i className="mdi mdi-keyboard-backspace"></i> Back
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 common-top-con">
            <Card className="overflow-hidden">
              <CardBody className="hospital-detail">
                <div className="row">
                  <div className="col-lg-12 col-xl-4 left-side">
                    <div className="bg-primary bg-soft hospital-icon-box left-inner p-3">
                      <div className="brand-logo patient-title-box">
                        <div className="icon-box">
                          <SvgFilesIcon iconname={"hospital"} />
                        </div>
                      </div>
                      <div className="brand-detail">
                        <div className="title">
                          <div className="d-flex align-items-center hospital-card-box justify-content-between mb-2">
                            <h4>{hospital_property?.landmark}</h4>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="user-setting-box">
                                <div className="edit-box" onClick={(e) => handleEditHospitalProperty(e, hospital_property)} style={{ cursor: 'pointer', marginRight: 10 }}>
                                  <SvgFilesIcon iconname={"edit"} />
                                </div>
                                <div className="delete-box" onClick={(e) => handleDeleteHospitalProperty(e, hospital_property)}>
                                  <SvgFilesIcon iconname={"delete"} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="mb-0">
                            {hospital_property?.address}{" "}
                            {hospital_property?.city?.city}, {" "}
                            {hospital_property?.state?.state}, {" "}
                            {hospital_property?.country?.country} -{" "}
                            {hospital_property?.zipcode}
                          </p>
                        </div>
                      </div>
                      <div className="address-line">
                        <div className="d1">
                          <label className="title">Reg No. :</label>
                          <p className="deatil mb-0">
                            {hospital_property?.registeration_no}
                          </p>
                        </div>
                        <div className="d1">
                          <label className="title"> Licence No. :</label>
                          <p className="deatil mb-0">
                            {hospital_property?.license_no}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-8">
                    <div className="right-side">
                      <div className="detail-line small-line">
                        <label className="title">Key Person : </label>
                        <p className="deatil mb-0">
                          {hospital_property?.contact_person}
                        </p>
                      </div>
                      <div className="detail-line small-line">
                        <label className="title"> Mobile :</label>
                        <p className="deatil mb-0">
                          {hospital_property?.mobile}
                        </p>
                      </div>
                      <div className="detail-line small-line">
                        <label className="title">Landline :</label>
                        <p className="deatil">
                          {hospital_property?.landline}
                        </p>
                      </div>
                      <div className="detail-line small-line">
                        <label className="title">Toll Free :</label>
                        <p className="deatil">45789</p>
                      </div>
                      <div className="detail-line small-line">
                        <label className="title">Emergency :</label>
                        <p className="deatil">
                          {hospital_property?.emergency_contact}
                        </p>
                      </div>
                      <div className="detail-line small-line">
                        <label className="title">Ambulance :</label>
                        <p className="deatil">
                          {hospital_property?.ambulance_contact}
                        </p>
                      </div>
                      <div className="detail-line small-line">
                        <label className="title"> Created By :</label>
                        <p className="deatil mb-0">
                          {hospital_property?.created_by?.name}
                        </p>
                      </div>
                      <div className="detail-line small-line">
                        <label className="title"> Created On :</label>
                        <p className="deatil mb-0">
                          {formatDateToLocaleDateString(hospital_property?.created_on)}
                        </p>
                      </div>

                      <div className="detail-line full-line">
                        <label className="title">Website :</label>
                        <p className="deatil">
                          <a
                            href={hospital_property?.website}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {hospital_property?.website}
                          </a>
                        </p>
                      </div>
                      {/* <div className="detail-line full">
                            <label className="title">Banner URL</label>
                            <p className="deatil"><a href="https://www.freepik.com/free-photos-vectors/hospital-banner">https://www.freepik.com/free-photos-vectors/hospital-banner</a> </p>
                          </div> */}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <div className="col-12 mb-3 nav-width">
              <Nav tabs className="nav-tabs-custom list-view-tab nav-justified ">
                {/* <NavItem>
                        <NavLink
                          className={subTab === "1" ? "active" : ""}
                          onClick={() => {
                            subTabtoggle("1");
                          }}
                        >
                          <span className="d-none d-sm-block">
                            Patient Detail
                          </span>
                        </NavLink>
                      </NavItem> */}
                <NavItem>
                  <NavLink
                    className={subTab === "1" ? "active" : ""}
                    onClick={() => {
                      subTabtoggle("1");
                    }}
                  >
                    <span className="d-none d-sm-block"> ICU Detail</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={subTab === "2" ? "active" : ""}
                    onClick={() => {
                      subTabtoggle("2");
                    }}
                  >
                    <span className="d-none d-sm-block">Inventory</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <div className="col-12">
              <TabContent activeTab={subTab} className="text-muted">
                {/* <TabPane tabId="1">
                        <HospitalPropertyPatients
                          hospital_property_id={hospital_property_id}
                        />
                      </TabPane> */}
                <TabPane tabId="1">
                  <HospitalPropertyIcus
                    subTab={subTab}
                    hospital_property_id={hospital_property_id}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <HospitalPropertyEquipments
                    subTab={subTab}
                    hospital_property_id={hospital_property_id}
                  />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      ) : (
        <NoAccess text="Hospital Property Details Access Restricted" />
      )}
    </React.Fragment>
  );
};

export default SingleHospitalProperty;
