import { FC } from "react";
import logo1 from "../../assets/images/logo-1.png";
import SvgFilesIcon from "../SvgFiles/SvgFiles";
 
const HospitalGridView: FC<any> = ({ hospital, handleEditHospital, handleDeleteHospital }) => {
  return (
    <>
      <td>
        <div className="patient-card-title hospital-card-title ">
          {/* <div className="left-logo">
            <img src={logo1} alt="hello"></img>
          </div> */}
          <div className="icon-box">
            <SvgFilesIcon iconname="hospital" />
          </div>
          <div className="right-detail">
            {hospital?.name}
            <p className="text-muted">
            {hospital?.address || " "} {hospital?.city?.city || " "} {hospital?.state?.state || " "} {hospital?.country?.country|| " "} {hospital?.zipcode || " "}
            </p>
          </div>
        </div>
      </td>
      <td> {hospital?.contact_person}</td>
      <td> {hospital?.mobile}</td>
      <td>
        {" "}
        {hospital?.active_properties_count === 0 ? (
          <>No active property</>
        ) : (
          <>
            <b>
              {hospital?.active_properties_count}/
              {hospital?.all_properties_count}
            </b>{" "}
            Active property
          </>
        )}
      </td>
      <td>
        <div className="discharge-box">
        <SvgFilesIcon iconname="active"/>
          <p>Active</p>
        </div>
        {/* <span className="text-success">Active</span> */}
      </td>
      <td /* className="d-flex align-items-center justify-content-between" */>
        <div className="user-setting-box">
          <div className="edit-box" onClick={(e) => handleEditHospital(e, hospital)}>
            <SvgFilesIcon iconname="edit" />
          </div>
          <div className="delete-box" onClick={(e) => handleDeleteHospital(e, hospital)}>
            <SvgFilesIcon  iconname="delete" />
          </div>
        </div>
      </td>
    </>
  );
};
 
export default HospitalGridView;