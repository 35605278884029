import { Formik, FormikValues } from "formik";
import React, { FC, useEffect, useState, useCallback, ChangeEvent } from "react";
import { Card, CardBody, CardTitle, Input, Label, Form } from "reactstrap";
import { getCityByStateList } from "../../redux/actions/cityAction";
import { getStatesByCountry } from "../../redux/actions/stateActions";
import { ErrorToast, SuccessToast } from "../../utils/toastNotifications";
import * as Yup from "yup";
import { getCountryList } from "../../redux/actions/countryAction";
import { create_hospital_property, updateHospitalProperty } from "../../redux/actions/hospitalPropertyAction";
import { useSelector } from "react-redux";
import { hospital_property_access } from "../../helpers/access_policies";
import NoAccess from "../../components/NoAccess";
import Header from "../../components/Header/Header";

const CreateHospitalPropertySchema = Yup.object().shape({
  landmark: Yup.string().required("Landmark is required"),
  registeration_no: Yup.string().required("Registeration Number is required"),
  license_no: Yup.string().required("License Number is required"),
  address: Yup.string().required("Address is required"),
  country_id: Yup.string().required("Country is required"),
  state_id: Yup.string().required("State is required"),
  city_id: Yup.string().required("City is required"),
  zipcode: Yup.string().matches(/^\d{6}$/, 'Please enter valid Zipcode (6 digits)').required("Zipcode is required"),
  contact_person: Yup.string().matches(/^[A-Za-z .]+$/, 'Please enter valid Contact Person').required("Contact Person Name is required"),
  mobile: Yup.string().matches(/^[56789][0-9]{9}$/, 'Please enter valid Mobile Number (10 digits)').required("Mobile number is required"),
  landline: Yup.string()
    .matches(/^\+?[0-9\s-]+$/, 'Please enter a valid landline number')
    .optional(),
  emergency_contact: Yup.string().matches(/^[56789][0-9]{9}$/, 'Please enter valid Mobile numebr(10 digits)').optional(),
  ambulance_contact: Yup.string()
    .matches(/^\+?[0-9\s-]+$/, 'Please enter a ambulance number').nullable().optional(),
  email: Yup.string().email('Invalid email').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Please enter valid email').optional(),
});

const initialValues = {
  landmark: "",
  registeration_no: "",
  license_no: "",
  address: "",
  city_id: "",
  state_id: "",
  country_id: "",
  zipcode: "",
  contact_person: "",
  mobile: "",
  landline: "",
  emergency_contact: "",
  ambulance_contact: "",
  email: "",
  logo_url: "",
  banner_url: "",
};

const CreateHospitalProperty: FC<any> = ({ showHospitalPropertyModal, setShowHospitalPropertyModal, hospitalPropertyMode, setHospitalPropertyMode, hospitalProperty, hospital }) => {
  const { currentActiveTab } = useSelector((state: any) => state.hospitalTab);
  const { user_info } = useSelector((state: any) => state.user);

  const [should_copy_location, set_should_copy_location] = useState(false);
  const [should_copy_contact, set_should_copy_contact] = useState(false);
  const [loader, setLoader] = useState(false);
  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([]);
  const [countries, setcountries] = useState([]);
  const [basic_info_ask, setbasic_info_ask] = useState(false);
  const [initial_values, set_initial_values] = useState(initialValues);

  const fetch_countries = async () => {
    const request = await getCountryList({});
    if (request?.statusCode === 200) {
      setcountries(
        request?.body?.data?.map((country: any) => {
          return {
            id: country?.id,
            country: country?.country,
          };
        })
      );
    } else {
      setcountries([]);
    }
  };

  useEffect(() => {
    fetch_countries();
  }, []);

  const fetch_states = useCallback(
    async (country_id: string, setFieldValue?: any) => {
      if (country_id !== undefined) {
        const request = await getStatesByCountry({
          country_id,
        });
        if (request?.statusCode === 200) {
          if (request?.body?.data.length === 0) {
            setFieldValue("state_id", "");
            setFieldValue("city_id", "");
          }
          setstates(
            request?.body?.data?.map((state: any) => {
              return {
                id: state?.id,
                state: state?.state,
              };
            })
          );
        } else {
          setstates([]);
          setFieldValue("state_id", "");
          setFieldValue("city_id", "");
        }
      }
    },
    []
  );

  const fetch_city = useCallback(
    async (state_id: string, setFieldValue?: any) => {
      if (state_id !== undefined) {
        const request = await getCityByStateList({
          state_id,
        });
        if (request?.statusCode === 200) {
          if (request?.body?.data.length === 0) {
            setFieldValue("city_id", "");
          }
          setcities(
            request?.body?.data?.map((city: any) => {
              return {
                id: city?.id,
                city: city?.city,
              };
            })
          );
        } else {
          setFieldValue("city_id", "");
          setcities([]);
        }
      }
    },
    []
  );

  const create_hospital_property_form = async (values: FormikValues) => {
    let request;
    if (hospitalPropertyMode === "Update") {
      request = await updateHospitalProperty({ hospital_id: hospital?.id, id: hospitalProperty?.id, ...values })
    } else {
      request = await create_hospital_property({ ...values, hospital_id: hospital?.id });
    };
    if (request?.statusCode === 200) {
      SuccessToast(request?.message);
      setShowHospitalPropertyModal(false);
      setHospitalPropertyMode("Create");
    } else {
      if (request?.errors?.length > 0) {
        ErrorToast(request?.errors[0]?.msg);
      }
    }
  };

  useEffect(() => {
    if (hospitalProperty && hospitalPropertyMode === "Update") {
      setLoader(true);
      set_initial_values({
        landmark: hospitalProperty?.landmark ? hospitalProperty?.landmark : "",
        registeration_no: hospitalProperty?.registeration_no ? hospitalProperty?.registeration_no : "",
        license_no: hospitalProperty?.license_no ? hospitalProperty?.license_no : "",
        address: hospitalProperty?.address ? hospitalProperty?.address : "",
        city_id: hospitalProperty?.city ? hospitalProperty?.city?.id : "",
        state_id: hospitalProperty?.state ? hospitalProperty?.state?.id : "",
        country_id: hospitalProperty?.country ? hospitalProperty?.country?.id : "",
        zipcode: hospitalProperty?.zipcode ? hospitalProperty?.zipcode : "",
        contact_person: hospitalProperty?.contact_person ? hospitalProperty?.contact_person : "",
        mobile: hospitalProperty?.mobile ? hospitalProperty?.mobile : "",
        landline: hospitalProperty?.landline ? hospitalProperty?.landline : "",
        emergency_contact: hospitalProperty?.emergency_contact ? hospitalProperty?.emergency_contact : "",
        ambulance_contact: hospitalProperty?.ambulance_contact ? hospitalProperty?.ambulance_contact : "",
        email: hospitalProperty?.email ? hospitalProperty?.email : "",
        logo_url: hospitalProperty?.logo_url ? hospitalProperty?.logo_url : "",
        banner_url: hospitalProperty?.banner_url ? hospitalProperty?.banner_url : "",
      });
      fetch_states(hospitalProperty?.country?.id);
      fetch_city(hospitalProperty?.state?.id);
      setLoader(false);
    } else {
      set_initial_values(initialValues)
    }
  }, [fetch_city, fetch_states, hospitalProperty, hospitalPropertyMode]);

  useEffect(() => {
    if (currentActiveTab !== hospital?.id && setShowHospitalPropertyModal) {
      setShowHospitalPropertyModal(false);
    }
  }, [currentActiveTab]);

  /* SAME AS COPY FOR BASICINFO */
  const handleBasicInfoChange = async (e: ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    setbasic_info_ask(e.target.checked);
    const fieldsToCopy = ["landmark", "license_no", "registeration_no"];
    
    if (e.target.checked) {
      fieldsToCopy.forEach((field) => setFieldValue(field, hospital?.[field] || ""));
    } else {
      if (hospitalPropertyMode === "Update") {
        fieldsToCopy.forEach((field) => setFieldValue(field, hospitalProperty?.[field] || ""));
      } else {
        fieldsToCopy.forEach((field) => setFieldValue(field, ""));
      }
    }
  };
  

  /* SAME AS COPY FOR CONTACT */
  const handleContactInfoChange = async (e: ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    set_should_copy_contact(e.target.checked);
    const fieldsToCopy = ["contact_person", "mobile", "landline", "emergency_contact", "ambulance_contact", "email"];
    
    if (e.target.checked) {
      fieldsToCopy.forEach((field) => setFieldValue(field, hospital?.[field] || ""));
    } else {
      if (hospitalPropertyMode === "Update") {
        fieldsToCopy.forEach((field) => setFieldValue(field, hospitalProperty?.[field] || ""));
      } else {
        fieldsToCopy.forEach((field) => setFieldValue(field, ""));
      }
    }
  };
  

  /* SAME AS COPY FOR LOCATION */
  const handleLocationInfoChange = async (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    set_should_copy_location(e.target.checked);
    const emptyFields = { address: "", country_id: "", state_id: "", city_id: "", zipcode: "" };
  
    const setFieldValues = async (obj: any) => {
      const countryId = obj?.country?.id;
      const stateId = obj?.state?.id;
  
      await fetch_states(countryId, setFieldValue);
      await fetch_city(stateId, setFieldValue);
  
      setFieldValue("address", obj?.address || "");
      setFieldValue("country_id", countryId || "");
      setFieldValue("state_id", stateId || "");
      setFieldValue("city_id", obj?.city?.id || "");
      setFieldValue("zipcode", obj?.zipcode || "");
    };
  
    if (e.target.checked) {
      await setFieldValues(hospital);
    } else {
      if (hospitalPropertyMode === "Update") {
        await setFieldValues(hospitalProperty);
      } else {
        Object.keys(emptyFields).forEach((field) => setFieldValue(field, ""));
      }
    }
  };
  

  return (
    <React.Fragment>
      {loader && (
        <div className="loader-box">
          <span className="loader"></span>
        </div>
      )}
      {user_info?.user_access?.includes(hospital_property_access.create_hospital_property)
        ? (
          <div style={{ height: "calc(100vh - 80px)", overflow: "unset", }}>
            <div className="row">
              <div className="col-12 col">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-sm-0 font-size-14">
                      <li className="breadcrumb-item">
                        <a href="/#">Hospitals</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="/#">{hospital?.name}</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {hospitalPropertyMode} Property
                      </li>
                    </ol>
                  </nav>

                  <div className="page-title-right d-flex">
                    <a onClick={() => { setShowHospitalPropertyModal(false); set_initial_values(initialValues); }} style={{ cursor: "pointer" }} className="back-btn"  >
                      <i className=" mdi mdi-keyboard-backspace"></i> Back
                    </a>
                  </div>
                </div>
              </div>
              <div className="hospital-detail-con">
                <Formik
                  initialValues={initial_values}
                  validationSchema={CreateHospitalPropertySchema}
                  onSubmit={create_hospital_property_form}
                  enableReinitialize
                >
                  {(formik) => {
                    const {
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                      handleBlur,
                      setFieldValue,
                      resetForm,
                    } = formik;
                    return (
                      <Form onSubmit={(e) => {
                        e.preventDefault();
                        if (errors) {
                          const firstError = Object.values(errors)[0];

                          if (Array.isArray(firstError)) {
                            const errorMessage = firstError.join(", ");
                            ErrorToast(errorMessage);
                          } else ErrorToast(firstError);
                        }
                        handleSubmit();
                      }}>
                        <Card>
                          <CardBody>
                            <CardTitle className="h4">Basic Info</CardTitle>
                            <div className="form-check check-box-right">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={basic_info_ask}
                                id="basic_info"
                                onChange={(e) => handleBasicInfoChange(e, setFieldValue)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="basic_info"
                              >
                                Same As Parent
                              </label>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Landmark
                                  </Label>
                                  <Input
                                    name="landmark"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Landmark"
                                    value={values.landmark}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.landmark && touched.landmark && (
                                    <span className="error">
                                      {errors.landmark}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Registration No.
                                  </Label>
                                  <Input
                                    name="registeration_no"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Registration No"
                                    value={values.registeration_no}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.registeration_no &&
                                    touched.registeration_no && (
                                      <span className="error">
                                        {errors.registeration_no}
                                      </span>
                                    )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Licence No.
                                  </Label>
                                  <Input
                                    name="license_no"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Licence No"
                                    value={values.license_no}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.license_no &&
                                    touched.license_no && (
                                      <span className="error">
                                        {errors.license_no}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody>
                            <CardTitle className="h4">
                              Location Info
                            </CardTitle>
                            <div className="form-check check-box-right">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={should_copy_location}
                                id="location_info"
                                onChange={(e) => handleLocationInfoChange(e, setFieldValue)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="location_info"
                              >
                                Same As Parent
                              </label>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Address
                                  </Label>
                                  <Input
                                    name="address"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Address"
                                    value={values?.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.address && touched.address && (
                                    <span className="error">
                                      {errors.address}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Country
                                  </Label>
                                  <select
                                    className="form-control"
                                    name="country_id"
                                    value={values.country_id}
                                    onChange={async (e: any) => {
                                      handleChange(e);
                                      if (e.target.value !== undefined) {
                                        await fetch_states(
                                          e?.target?.value,
                                          setFieldValue
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  >
                                    <option value={undefined}>
                                      Select Country
                                    </option>
                                    {countries?.map(
                                      (country: any, index: any) => {
                                        return (
                                          <option
                                            value={country.id}
                                            key={index}
                                          >
                                            {country?.country}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                  {errors.country_id &&
                                    touched.country_id && (
                                      <span className="error">
                                        {errors.country_id}
                                      </span>
                                    )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    State
                                  </Label>
                                  <select
                                    className="form-control"
                                    name="state_id"
                                    value={values.state_id}
                                    onChange={async (e: any) => {
                                      if (states.length === 0) {
                                        handleChange(undefined);
                                      }
                                      handleChange(e);
                                      if (e.target.value !== undefined) {
                                        await fetch_city(e?.target?.value, setFieldValue);
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  >
                                    <option value={undefined}>
                                      Select State
                                    </option>
                                    {states?.map(
                                      (state: any, index: any) => {
                                        return (
                                          <option
                                            value={state.id}
                                            key={index}
                                          >
                                            {state?.state}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                  {errors.state_id && touched.state_id && (
                                    <span className="error">
                                      {errors.state_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <Label className="form-label">City</Label>
                                  <select
                                    className="form-control"
                                    name="city_id"
                                    value={values.city_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option>Select City</option>
                                    {cities?.map(
                                      (city: any, index: number) => {
                                        return (
                                          <option
                                            value={city.id}
                                            key={index}
                                          >
                                            {city?.city}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                  {errors.city_id && touched.city_id && (
                                    <span className="error">
                                      {errors.city_id}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Zipcode
                                  </Label>
                                  <Input
                                    name="zipcode"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Zipcode"
                                    value={values.zipcode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.zipcode && touched.zipcode && (
                                    <span className="error">
                                      {errors.zipcode}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody>
                            <CardTitle className="h4">
                              Contact Info
                            </CardTitle>
                            <div className="form-check check-box-right">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={should_copy_contact}
                                id="contact_info"
                                onChange={(e) => handleContactInfoChange(e, setFieldValue)}
                              />
                              <label className="form-check-label" htmlFor="contact_info">
                                Same As Parent
                              </label>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Key Contact Person
                                  </Label>
                                  <Input
                                    name="contact_person"
                                    className="form-control"
                                    type="text"
                                    value={values.contact_person}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Person Name"
                                  />
                                  {errors.contact_person &&
                                    touched.contact_person && (
                                      <span className="error">
                                        {errors.contact_person}
                                      </span>
                                    )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Mobile Number
                                  </Label>
                                  <Input
                                    name="mobile"
                                    className="form-control"
                                    type="text"
                                    placeholder="+ 91 "
                                    value={values.mobile}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.mobile && touched.mobile && (
                                    <span className="error">
                                      {errors.mobile}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Landline Number
                                  </Label>
                                  <Input
                                    name="landline"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Landline"
                                    value={values.landline}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.landline && touched.landline && (
                                    <span className="error">{errors.landline}</span>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Emergency Contact
                                  </Label>
                                  <Input
                                    name="emergency_contact"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Emergency"
                                    value={values.emergency_contact}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.emergency_contact && touched.emergency_contact && (
                                    <span className="error">{errors.emergency_contact}</span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Ambulance Contact
                                  </Label>
                                  <Input
                                    name="ambulance_contact"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Ambulance"
                                    value={values.ambulance_contact}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.ambulance_contact && touched.ambulance_contact && (
                                    <span className="error">{errors.ambulance_contact}</span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Email
                                  </Label>
                                  <Input
                                    name="email"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Ambulance"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.email && touched.email && (
                                    <span className="error">
                                      {errors.email}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody>
                            <CardTitle>Branding</CardTitle>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <Label
                                    htmlFor="formFile"
                                    className="form-label"
                                  >
                                    Upload Logo
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    name="logo_url"
                                    // value={values.logo_url}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <Label
                                    htmlFor="formFile"
                                    className="form-label"
                                  >
                                    Upload Banner
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    name="banner_url"
                                    // value={values.banner_url}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody>
                            <CardTitle>Upload Document</CardTitle>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <Label
                                    htmlFor="formFile"
                                    className="form-label"
                                  >
                                    Document Name
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    id="formFile"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <Label
                                    htmlFor="formFile"
                                    className="form-label"
                                  >
                                    Upload Document
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 button-box">
                                <button
                                  className="btn btn-primary mr-2 medium-btn"
                                  type="button"
                                >
                                  Add
                                </button>
                                <button
                                  className="btn btn-secondary medium-btn"
                                  type="button"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <div className="row">
                          <div className="col-12 text-center margin-top-20">
                            <button
                              type="submit"
                              className="btn btn-primary mr-2 medium-btn"
                            >
                              Save
                            </button>
                            <button className="btn btn-secondary medium-btn ms-2" type="button"
                              onClick={() => {
                                if (showHospitalPropertyModal === true && setShowHospitalPropertyModal && hospitalPropertyMode === "Update") {
                                  setShowHospitalPropertyModal(false)
                                } else {
                                  resetForm();
                                  set_should_copy_contact(false);
                                  set_should_copy_location(false);
                                  setbasic_info_ask(false)
                                }
                              }}>
                              {showHospitalPropertyModal === true && hospitalPropertyMode === "Update" ? "Cancel" : "Reset"}
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>

          </div>

        ) : (
          <NoAccess text="Create Hospital Property Access Restricted" />
        )
      }
    </React.Fragment >
  );
};

export default CreateHospitalProperty;
