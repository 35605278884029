import { ActionTypes, patientTabActions } from "../types/patientTabTypes";

interface initialStateTypes {
  currentPatientActiveTab: string | any;
  patientTab: any;
  patientTabs: [] | any;
  patientMainTab: any;
  setPatientMainTab: any;
}

const initialState: initialStateTypes = {
  currentPatientActiveTab: "1",
  patientTab: null,
  patientTabs: [],
  patientMainTab: null,
  setPatientMainTab: null
};

export const patientTabReducer = (
  state: initialStateTypes = initialState,
  actions: patientTabActions
): initialStateTypes => {
  switch (actions.type) {
    case ActionTypes.SET_Main_Patient_TAB:
      return {
        ...state,
        patientMainTab: actions?.payload?.patientMainTab,
      };
    case ActionTypes.ADD_Patient_TAB:
      if (state.patientTabs.length >= 4) {
        state.patientTabs.shift();
        state.currentPatientActiveTab = state.patientTabs[0].id;
      }
      return {
        ...state,
        patientTabs: state.patientTabs.concat(actions.payload),
      };
    case ActionTypes.REMOVE_Patient_TAB:
      return {
        ...state,
        patientTabs: state.patientTabs.filter((htab: any) => {
          return htab?.id !== actions?.payload?.id;
        }),
      };
    case ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB:
      return {
        ...state,
        currentPatientActiveTab: actions?.payload?.id,
      };
    case ActionTypes.SET_Patient_TAB:
      return {
        ...state,
        patientTab: actions?.payload,
      };
    case ActionTypes.NULL_PATIENT_TABS:
      return {
        ...state,
        currentPatientActiveTab: "1",
        patientTab: null,
        patientTabs: [],
      };
    default:
      return state;
  }
};
