import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
 
interface Option {
  id: number | string;
  name?: string;
  country?: string;
  state?: string;
  city?: string;
  landmark?: string;
}
 
interface Props {
  label: string;
  options: Option[];
  value: number | string;
  onChange: (e: SelectChangeEvent<number | string>) => void;
  name: string;
  disabled?: boolean;
  autoSelect?: boolean;
}
 
export const DropdownField: React.FC<Props> = ({
  label,
  options,
  value,
  onChange,
  name,
  disabled = false,
  autoSelect = false,
}) => {
  const location = useLocation();
  const { user_info } = useSelector((state: any) => state.user);
 
  if (!user_info) return null;
  const getDisplayName = (option: Option) => {
    switch (label) {
      case 'Hospital Properties':
        return option.landmark;
      default:
        return option.name;
    }
  };
 
  if (autoSelect && user_info?.hospital_property && user_info?.hospital_property.hospital) {
    // auto-select hospital and hospital property based on user_info
    if (label === 'Hospital') {
      value = user_info?.hospital_property?.hospital?.id;
    } else if (label === 'Hospital Properties') {
      value = user_info?.hospital_property?.id;
    }
  }
 
  const shouldRenderDropdown = (dropdownLabel: string) => {
    if (user_info?.is_super_admin && (dropdownLabel === 'Hospital' || dropdownLabel === 'Hospital Properties')) return true;
    if (user_info?.is_admin && (dropdownLabel === 'Hospital' || dropdownLabel === 'Hospital Properties')) return true;
    if (user_info?.is_super_admin && (dropdownLabel === 'Country' || dropdownLabel === 'State' || dropdownLabel === 'City')) return true;
    if (user_info?.is_admin && (dropdownLabel === 'Country' || dropdownLabel === 'State' || dropdownLabel === 'City')) return true;
    if (!(user_info?.is_super_admin && user_info?.is_admin) && (dropdownLabel === 'Hospital Properties' && location.pathname === '/patients')) return true;
    return false;
  };
 
  if (!shouldRenderDropdown(label)) return null;
 
  return (
    <FormControl sx={{ m: 1, minWidth: 264 }} size="small" disabled={disabled}>
      <InputLabel className='dropdown-lbl' id={`${name}-label`}>
        Select {label}
      </InputLabel>
      <Select
        labelId={`${name}-label`}
        id={`${name}-select`}
        value={value}
        label={`Select ${label}`}
        onChange={onChange}
        name={name}
      >
        <MenuItem value="" disabled>
          <em>Select {label}</em>
        </MenuItem>
        {options && options.length > 0 ? (
          options.map((option, index) => (
            <MenuItem key={index} value={option.id}>
              {getDisplayName(option)}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            No {label.toLowerCase()}s found
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
 
export default DropdownField;