import { hospital_property_icu_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";

export const get_hospital_icus_by_hospital_property_id = async ({
  hospital_property_id,
  page_number,
  per_page,
}: {
  hospital_property_id: string | undefined;
  page_number?: number;
  per_page?: number;
}) => {
  try {
    const params: { [key: string]: number } = {};

    if (page_number !== undefined) params["page_number"] = page_number;
    if (per_page !== undefined) params["per_page"] = per_page;

    const { data } = await axiosApi.get(
      `${hospital_property_icu_routes.all_icus}/${hospital_property_id}`,
      { params }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.errors;
  }
};

export const post_hospital_property_icu = async ({
  type,
  name,
  capacity,
  hospital_property_id,
}: {
  type: string;
  name: string;
  capacity: number;
  hospital_property_id: string | undefined;
}) => {
  try {
    const { data } = await axiosApi.post(
      hospital_property_icu_routes.create_icu,
      { type, name, capacity, hospital_property_id }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const update_hospital_icu = async ({
  id,
  type,
  name,
  capacity,
}: {
  id: string;
  type: string;
  name: string;
  capacity: number;
}) => {
  try {
    const { data } = await axiosApi.put(
      hospital_property_icu_routes.update_icu,
      { id, type, name, capacity }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const delete_hospital_icu = async ({ id }: { id: string }) => {
  try {
    const { data } = await axiosApi.delete(
      `${hospital_property_icu_routes.delete_icu}/${id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.data;
  }
};

export const fetch_icu_by_id = async ({ id }: { id: string }) => {
  try {
    const { data } = await axiosApi.get(
      `${hospital_property_icu_routes.icu_by_id}/${id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.data;
  }
};
