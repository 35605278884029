import React, { useCallback, useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Card, CardBody, CardTitle, Collapse, Input, Label, Nav, NavItem, NavLink } from "reactstrap";
import { DateTimePicker } from "@mui/x-date-pickers";
import { getInvestigationSubTypesList, getInvestigationTypesList } from "../../../../redux/actions/investigation";
import { ErrorToast, SuccessToast } from "../../../../utils/toastNotifications";
import { getPatientAdmissionInvestigationByPatientAdmissionId, postPatientAdmissionInvestigation, putPatientAdmissionInvestigation } from "../../../../redux/actions/patientAdmissionInvestigationAction";
import CustomMUIDatePicker from "../../../../components/UI/CustomMUIDatePicker";
import { IPatientAdmissionInvestigationTypes } from "../../../../redux/types/IpatientAdmissionInvestigationTypes";
import { useSelector } from "react-redux";
import { getReportMeasurementUnitsTypesList } from "../../../../redux/actions/reportMeasurementUnitsTypeAction";
import { formatDateToCustomDateString } from "../../../../helpers/helperFunctions";
import PatientDocuments from "./patientDocuments";
import { getDocumentTypesList } from "../../../../redux/actions/documentAction";
import { postPatientDocuments } from "../../../../redux/actions/patientDocuments";

interface IDocumentType {
    id: string;
    name: string;
}

const Investigation: React.FC<any> = ({ selected_admission, per_page, page_number, patient }) => {
    const [isInvestigationOpen, setIsInvestigationOpen] = useState<boolean>(false);
    const [allMainInvestigation, setAllMainInvestigation] = useState<any[]>([]);
    const [allSubInvestigationNames, setAllSubInvestigationNames] = useState<any[]>([]);
    const [selectedSubInvestigation, setSelectedSubInvestigation] = useState<any[]>([]);
    const [documentTypes, setDocumentTypes] = useState<IDocumentType[]>([]);
    const [selectedMainInvestigation, setSelectedMainInvestigation] = useState<any[]>([]);
    const [formData, setFormData] = useState<any>([]);
    const [docValue, setDocValue] = useState("");
    const [initialValues, setInitialValues] = useState<any>({
        investigation_date: "",
        investigation_type_id: "",
    })
    const [accordianData, setaccordianData] = useState<any>([]);
    const [allInvestigationData, setAllInvestigationData] = useState<any[]>([]);
    const [collapsedItems, setCollapsedItems] = useState<{ [key: string]: boolean }>({});
    const [activeNavItem, setActiveNavItem] = useState(null);
    const { user_info } = useSelector((state: any) => state.user);
    const [uniteData, setUniteData] = useState<{ id: string; name: string }[]>([]);
    const [tab, setTab] = useState("report");

    const fetchData = async () => {
        try {
            const mainInvestigation = await getInvestigationTypesList({ per_page, page_number });
            const subInvestigation = await getInvestigationSubTypesList({ per_page, page_number });
            if (mainInvestigation?.statusCode === 200 && Array.isArray(mainInvestigation?.body?.data)) {
                setAllMainInvestigation(mainInvestigation?.body?.data);
            } else {
                setAllMainInvestigation([]);
            }
            if (subInvestigation?.statusCode === 200 && Array.isArray(subInvestigation?.body?.data)) {
                setAllSubInvestigationNames(subInvestigation.body.data);
            } else {
                setAllSubInvestigationNames([]);
            }

            const uniteBody = await getReportMeasurementUnitsTypesList({ per_page, page_number });
            if (uniteBody && uniteBody?.body?.data) {
                const updatedMedicineTypesList: { id: string; name: string }[] =
                    uniteBody?.body?.data?.map((e: any) => ({ name: e?.name, id: e?.id }));
                setUniteData(updatedMedicineTypesList);
            } else {
                setUniteData([]);
            }
            return mainInvestigation?.body?.data;
        } catch (error) {
            console.error("Error fetching investigation types:", error);
        }
    };

    const getInvestigationsByPatientAdmissionId = async (allInvestigationData: any) => {
        if (selected_admission !== null) {
            const request = await getPatientAdmissionInvestigationByPatientAdmissionId(selected_admission?.id);
            if (request?.statusCode === 200) {
                setAllInvestigationData(request?.body?.data);
                setaccordianData([]);
                await setDataFormateForAccirdianData(request?.body?.data, allInvestigationData);
            } else {
                setAllInvestigationData([]);
                setaccordianData([]);
            }
        }
    };

    const setDataFormateForAccirdianData = (investigationData: any, mainInvestigation: any) => {
        console.log([...allMainInvestigation]);
        let accordianDataJSON: any[] = [];
        mainInvestigation.forEach((mainReport: any) => {
            let nonBloodReportResult: any = { data: [] };
            let bloodReportResult: any = { headers: [], data: [] };
            let bloodReport: any = {}
            if (!bloodReport["name"]) {
                bloodReport["name"] = mainReport?.name;
                bloodReport["id"] = mainReport?.id;
            }
            investigationData.forEach((item: any) => {
                if (item?.investigation_type?.id === mainReport?.id) {
                    if (!bloodReportResult.headers.includes(item.investigation_date)) {
                        bloodReportResult.headers.push(item.investigation_date);
                    }
                    if (typeof item.investigation_Types === 'object') {
                        let typesArray = Object.values(item.investigation_Types);
                        typesArray.forEach((type: any) => {
                            let labelObject = bloodReportResult.data.find((label: any) => label.label === type.name);
                            if (!labelObject) {
                                setActiveNavItem(type.id);
                                labelObject = { id: type.id, label: type.name, values: [] };
                                bloodReportResult.data.push(labelObject);
                            }
                            let dateIndex = bloodReportResult.headers.indexOf(item.investigation_date);
                            while (labelObject.values.length < dateIndex) {
                                labelObject.values.push({});
                            }
                            let tabValue: any = {};
                            tabValue["date"] = item?.investigation_date;
                            tabValue["content"] = (type?.value + " " + (type?.unite || ''))
                            labelObject.values[dateIndex] = tabValue;
                        });
                    }
                    bloodReport["value"] = bloodReportResult;
                }
            });
            console.log(bloodReport);
            accordianDataJSON.push(bloodReport);
        });
        setaccordianData(accordianDataJSON);
    };

    useEffect(() => {
        async function getDocumentTypes() {

            try {
                const { body } = await getDocumentTypesList({
                    per_page: per_page,
                    page_number: page_number,
                });
                if (body) setDocumentTypes(body?.data);
                else setDocumentTypes([]);
            } catch (error) {
                console.error("Error fetching document types:", error);
            }
        }
        getDocumentTypes();
    }, []);

    useEffect(() => {
        if (selected_admission !== null) {
            const dataResponse = async () => {
                await fetchData().then((data) => {
                    setAllMainInvestigation(data)
                    getInvestigationsByPatientAdmissionId(data);
                });
            }
            dataResponse();
        }
    }, [selected_admission]);

    const validationSchema = Yup.object().shape({
        investigation_type_id: Yup.string().required("Investigation type is required"),
        investigation_date: Yup.date().nullable().required("Datepicker field is required"),
    });

    const handleReset = (resetForm: any) => {
        setFormData([]);
        setInitialValues({})
        setSelectedSubInvestigation([]);
        setSelectedMainInvestigation([]);
        resetForm({
            values: {
                investigation_type_id: "",
                investigation_date: "",
            },
        });
    };

    const documentUploadFunction = async (values: { investigation_date: any; doc_name: any; document_type: string; }) => {
        try {
            let file = docValue;
            const formData = new FormData();
            formData.append('file', file);
            let response;
            formData.append('patient_admission_id', selected_admission?.id);
            const responseDocument = await fetch(`${process.env.REACT_APP_SERVER_URL}/Upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    'patient_admission_id': selected_admission?.id
                }

            });
            const responseData = await responseDocument.json();
            if (responseData.status === 200) {
                response = await postPatientDocuments({
                    patient_admission_id: selected_admission?.id,
                    doc_date: values?.investigation_date,
                    doc_name: values?.doc_name,
                    document_type: documentTypes.find(doc => doc.id === values?.document_type),
                    doc_path: responseData?.url,
                });
            }
        } catch (error) {
            ErrorToast("Document Not Upload SucessFully")
        }
    }

    const submitForm = async (
        values: any,
        resetForm: () => void,
        setSubmitting: (isSubmitting: boolean) => void,
        setErrors: (errors: any) => void
    ) => {
        let investigationValue = getInvestigationValueFormat(values);
        try {
            let response;
            if (!values?.id) {
                response = await postPatientAdmissionInvestigation(investigationValue);
            } else {
                response = await putPatientAdmissionInvestigation({
                    ...investigationValue,
                    id: values?.id
                }
                );
            }

            if (response?.statusCode === 200) {
                getInvestigationsByPatientAdmissionId(allMainInvestigation);
                if (values?.document_type && !values?.id) {
                    documentUploadFunction(values);
                }
                SuccessToast(response?.message);
                setIsInvestigationOpen(false);
                setSelectedSubInvestigation([]);
                setSelectedMainInvestigation([]);
                resetForm();
            } else {
                if (response?.errors?.length > 0) {
                    ErrorToast(response?.errors[0]?.msg);
                }
            }
        } catch (error) {
            ErrorToast("An error occurred while submitting the form. Please try again.");
        }
    };

    const getInvestigationValueFormat = (value: any) => {
        let formatedValue: IPatientAdmissionInvestigationTypes = {};
        formatedValue.investigation_type_id = value?.investigation_type_id;
        formatedValue.investigation_date = value?.investigation_date;
        formData.forEach((subData: any) => {
            const valueData = value[subData?.id] ? value[subData?.id] : subData?.value;
            if (selectedMainInvestigation.length > 0 && selectedMainInvestigation[0]?.name?.toLowerCase().includes("blood")) {
                const unitedata = value[subData?.uniteId] ? value[subData?.uniteId] : subData?.unite;
                subData.value = (valueData || '');
                subData.uniteId = (unitedata || '');
                subData.unite = (uniteData.find((item: any) => item?.id === unitedata)?.name || '')
            } else {
                subData.value = (valueData || '');
            }
        });

        formatedValue.investigation_Types = formData;
        formatedValue.patient_admission_id = selected_admission?.id
        return formatedValue;
    };

    const handleMainInvestigationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedMainId = event.target.value;
        const filteredMainInvestigations = allMainInvestigation.filter((main: any) => main?.id === selectedMainId);
        setSelectedMainInvestigation(filteredMainInvestigations);
        const filteredSubInvestigations = allSubInvestigationNames.filter((sub: any) => sub.investigation_type?.id === selectedMainId);
        setSelectedSubInvestigation(filteredSubInvestigations);
        let subFieldArray: any = [];
        filteredSubInvestigations.forEach((subInv: any) => {
            let subFieldName: any = {};
            subFieldName["id"] = subInv.id;
            subFieldName["name"] = subInv.name;
            subFieldName["value"] = "";
            if (filteredMainInvestigations.length > 0 && filteredMainInvestigations[0]?.name?.toLowerCase().includes("blood")) {
                subFieldName["uniteId"] = `unite${subInv.id}`;
                subFieldName[`unite`] = "";
            }
            subFieldArray.push(subFieldName);
        });
        setFormData(subFieldArray);
    };

    const openFormPanel = () => {
        setInitialValues({
            investigation_date: new Date(),
            investigation_type_id: "",
        })
        setSelectedSubInvestigation([]);
        setSelectedMainInvestigation([]);
        setIsInvestigationOpen(true);
    };

    const toggleCollapse = (headingId: string) => {
        setCollapsedItems(prevState => ({
            ...prevState,
            [headingId]: !prevState[headingId],
        }));
    };

    const handleTabClick = (tabName: any) => {
        setTab(tabName);
    };

    const handleEdite = (investigation: any, date: string) => {
        const selectedMainId = investigation?.id;
        const filteredMainInvestigations = allMainInvestigation.filter((main: any) => main?.id === selectedMainId);
        setSelectedMainInvestigation(filteredMainInvestigations);
        const filteredSubInvestigations = allSubInvestigationNames.filter((sub: any) => sub.investigation_type?.id === selectedMainId);
        setSelectedSubInvestigation(filteredSubInvestigations);
        let subFieldArray: any = [];
        filteredSubInvestigations.forEach((subInv: any) => {
            let subFieldName: any = {};
            subFieldName["id"] = subInv.id;
            subFieldName["name"] = subInv.name;
            subFieldName["value"] = "";
            if (filteredMainInvestigations.length > 0 && filteredMainInvestigations[0]?.name?.toLowerCase().includes("blood")) {
                subFieldName["uniteId"] = `unite${subInv.id}`;
                subFieldName[`unite`] = "";
            }
            subFieldArray.push(subFieldName);
        });
        setFormData(subFieldArray);
        const editeData = allInvestigationData.find((item: any) => (item.investigation_date === date && item?.investigation_type?.id === investigation?.id));
        if (editeData) {
            let json: any = {};
            editeData?.investigation_Types?.forEach((data: any) => {
                json[data?.id] = data?.value;
                json[`${'unite'}${data?.id}`] = data?.uniteId?.replace("unite", "");
            });
            json["id"] = editeData?.id;
            json["investigation_type_id"] = investigation?.id;
            json["investigation_date"] = date;
            setInitialValues(json)
        }
        setIsInvestigationOpen(true);

    };

    return (
        <div>
            <div className="d-sm-flex align-items-center justify-content-between">
                <div className="page-title-right d-flex investigation-tab">
                    <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem >
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={tab === "report" ? "active" : ""}
                                onClick={() => handleTabClick("report")}
                            >
                                <span className="d-none d-sm-block hospital-name">
                                    Report
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem >
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={tab === "document" ? "active" : ""}
                                onClick={() => handleTabClick("document")}
                            >
                                <span className="d-none d-sm-block hospital-name">
                                    Document
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={tab === "graph" ? "active" : ""}
                                onClick={() => handleTabClick("graph")}
                            >
                                <span className="d-none d-sm-block hospital-name">
                                    Graph
                                </span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
            </div>
            {tab !== "document" &&
                <div className="add-row-btn">
                    {(true || user_info?.hospital_property?.id ===
                        selected_admission?.hospital_property?.id) &&
                        selected_admission?.admitted &&
                        !selected_admission?.discharged && (
                            <button
                                className="btn btn-primary medium-btn mb-2"
                                onClick={openFormPanel}
                            >
                                Add
                            </button>
                        )}
                </div>
            }
            {isInvestigationOpen && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm, setSubmitting, setErrors }) => {
                        submitForm(values, resetForm, setSubmitting, setErrors);
                    }}
                    initialErrors={{}}
                >
                    {({
                        isValid,
                        isSubmitting,
                        resetForm,
                        handleSubmit,
                        values,
                        handleChange,
                        errors,
                        touched,
                        handleBlur,
                        dirty,
                        setFieldValue,
                    }) => (
                        <>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if (errors) {
                                        const firstError = Object.values(errors)[0];
                                        if (Array.isArray(firstError)) {
                                            const errorMessage = firstError.join(", ");
                                            ErrorToast(errorMessage);
                                        } else if (typeof firstError === 'object') {
                                            ErrorToast("Unknown Error");
                                        } else if (typeof firstError === 'string') {
                                            ErrorToast(firstError);
                                        }
                                    }
                                    handleSubmit();
                                }
                                }
                            >
                                <div className="right-panel">
                                    <h4>Investigation</h4>
                                    <button
                                        className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light"
                                        onClick={() => setIsInvestigationOpen(false)}
                                    >
                                        <i className="mdi mdi-close"></i>
                                    </button>
                                    <div className="row">
                                        <div className="mb-3">
                                            <Label className="form-label">Investigation Date</Label>
                                            <CustomMUIDatePicker
                                                useDateTimePicker={true}
                                                name="investigation_date"
                                                type="formik"
                                                setState={setFieldValue}
                                                value={values?.investigation_date}
                                            />
                                            <ErrorMessage
                                                name="investigation_date"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label htmlFor="investigation_type_id">Investigation Type</Label>
                                            <Field
                                                as="select"
                                                id="investigation_type_id"
                                                className="form-control"
                                                onChange={(e: any) => {
                                                    e.preventDefault();
                                                    handleChange(e);
                                                    handleMainInvestigationChange(e);
                                                }}
                                                name="investigation_type_id">
                                                <option value="">Select an option</option>
                                                {allMainInvestigation.map((report: any) => {
                                                    return <option key={report.id} value={report.id}>{report.name}</option>


                                                })}
                                            </Field>
                                            {errors.investigation_type_id && <ErrorMessage name="investigation_type_id" component="div" className="text-danger" />}
                                        </div>
                                        {!values?.id && <><div className="mb-3">
                                            <label htmlFor="dr_name">Document Type</label>
                                            <Field
                                                name="document_type"
                                                as="select"
                                                className={`form-control ${errors.document_type && touched.document_type ? 'is-invalid' : ''}`}
                                                value={values?.document_type} // Set the value attribute to the selected value
                                            >
                                                <option value="" disabled>Select a Document Type</option>
                                                {documentTypes && documentTypes.length > 0 && documentTypes.map(doc => (
                                                    <option key={doc?.id} value={doc?.id}>{doc?.name}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="document_type" component="div" className="text-danger" />
                                        </div>
                                            <div className="mb-3">
                                                <label htmlFor="document_upload">Document Upload</label>
                                                <Field
                                                    name="document_upload"
                                                    type="file"
                                                    className={`form-control ${errors.document_upload && touched.document_upload ? 'is-invalid' : ''}`}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (e.target?.files && e.target?.files.length > 0) {
                                                            const file = e.target.files[0] as any;
                                                            const fileName = `${selected_admission?.id}-${file?.name?.replace(/\s/g, "")}`;
                                                            const fileType = file.type;

                                                            if (!['image/jpeg', 'image/png', 'image/jpg', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(fileType)) {
                                                                //     // If the file type is not allowed, display an error message
                                                                setFieldValue('doc_file', '');
                                                                ErrorToast('Only JPEG, PNG, JPG, PDF, DOCX, and Excel files are allowed.');
                                                                return; // Abort further processing
                                                            }

                                                            setFieldValue('doc_path', fileName);
                                                            setFieldValue('doc_name', file.name?.replace(/\s/g, ""));
                                                            setDocValue(file);
                                                        }
                                                        handleChange(e);
                                                    }} />
                                                <ErrorMessage name="document_upload" component="div" className="text-danger" />
                                            </div></>}
                                        {selectedSubInvestigation && <div>
                                            {(selectedMainInvestigation.length > 0 && selectedMainInvestigation[0]?.name?.toLowerCase().includes("blood")) && <table className="table table-hover">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Report</th>
                                                        <th>Value</th>
                                                        <th>Units</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {selectedSubInvestigation.map((subInvestigation, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <Label>{subInvestigation?.name}</Label>
                                                            </td>
                                                            <td>
                                                                <Field
                                                                    name={subInvestigation?.id}
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder=""
                                                                    onBlur={handleBlur}
                                                                    // value={initialValues[subInvestigation?.id] || ""}
                                                                    onChange={handleChange}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Field
                                                                    as="select"
                                                                    className="form-control"
                                                                    // value={values[`unite${ subInvestigation?.id } `] || ""}
                                                                    onChange={(e: any) => {
                                                                        e.preventDefault();
                                                                        handleChange(e);
                                                                    }}
                                                                    name={`unite${subInvestigation?.id}`}>
                                                                    <option value=""></option>
                                                                    {uniteData && uniteData.map((uData: any) => {
                                                                        return <option key={uData.id} value={uData.id}>{uData.name}</option>
                                                                    })}
                                                                </Field>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>}
                                        </div>}
                                        {(selectedMainInvestigation.length > 0 && !selectedMainInvestigation[0]?.name?.toLowerCase().includes("blood")) && selectedSubInvestigation.map((subInvestigation, index) => (
                                            <div key={index} className="mb-3">
                                                <Label>{subInvestigation?.name}</Label>
                                                <Field
                                                    name={subInvestigation?.id}
                                                    className="form-control"
                                                    type="text"
                                                    placeholder=""
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        ))}

                                        <div className="col-12 text-center">
                                            {/* <button type="button" className="btn btn-primary medium-btn" onClick={() => onSubmitData()} disabled={isSubmitting}>  */}
                                            <button
                                                className="btn btn-primary mr-2 medium-btn"
                                                type="submit"
                                            >
                                                Submit
                                            </button>
                                            <button type="button" className="btn btn-secondary medium-btn ms-2" onClick={() => handleReset(resetForm)}>
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            )}
            {tab === "report" &&
                <div style={{ paddingTop: "15px" }}>
                    <div className="col-12">
                        <div className="accordion" id="accordion">
                            {accordianData && accordianData.map((investigationData: any, index: any) => (
                                <React.Fragment key={index}>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={investigationData?.id}>
                                            <button
                                                className={`accordion-button fw-medium ${collapsedItems[investigationData?.id] ? "" : "collapsed"}`}
                                                type="button"
                                                onClick={() => toggleCollapse(investigationData?.id)}
                                                aria-expanded={collapsedItems[investigationData?.id] ? "true" : "false"}
                                            >{investigationData?.name}</button>
                                        </h2>
                                    </div>
                                    <Collapse isOpen={!collapsedItems[investigationData?.id]} className="accordion-collapse">
                                        <div className="accordion-body">
                                            {!investigationData?.name?.toLowerCase().includes("blood") ?
                                                <>
                                                    <div className="border-bottom card-header">
                                                        <Nav tabs className="nav nav-tabs nav-tabs-custom card-header-tabs ms-auto">
                                                            {investigationData?.value?.data.map((item: any) => (
                                                                <NavItem key={item.id}>
                                                                    <NavLink
                                                                        className={activeNavItem === item.id ? "active" : ""}
                                                                        onClick={() => setActiveNavItem(item.id)}
                                                                    >
                                                                        {item.label}
                                                                    </NavLink>
                                                                </NavItem>
                                                            ))}
                                                        </Nav>
                                                    </div>
                                                    <div className="custom-card-container" style={{ display: "flex" }}>
                                                        {investigationData?.value?.data.map((item: any, index: number) => (
                                                            <div className="radiology-card-box" >
                                                                <div key={item.id} style={{ display: (activeNavItem === item.id) ? "block" : "none" }}>

                                                                    {item?.values.map((subitem: any, index: number) => (
                                                                        <Card>
                                                                            <CardBody key={index}>
                                                                                <CardTitle className="h4 hospital-card-title text-primary ">
                                                                                    {formatDateToCustomDateString(subitem?.date, 'dd/mm/yyyy hh:mm am/pm')}
                                                                                    <a className="text-success mr-2">
                                                                                        <i
                                                                                            className="mdi mdi-pencil font-size-16"
                                                                                            onClick={(e) => {
                                                                                                handleEdite(investigationData, subitem?.date)
                                                                                            }}
                                                                                        ></i>
                                                                                    </a>
                                                                                </CardTitle>
                                                                                <p>{subitem?.content}</p>
                                                                            </CardBody>
                                                                        </Card>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className=" text-muted">
                                                        <div className="row text-muted">
                                                            <div className="full-line fixed-table-head">
                                                                <Card>
                                                                    <table className="table table-hover scrollable-table">
                                                                        <thead className="table-light">
                                                                            <tr>
                                                                                <th>Date</th>
                                                                                {investigationData?.value?.headers && investigationData?.value?.headers.map((header: any, index: any) => (
                                                                                    <th key={index}>{formatDateToCustomDateString(header, 'dd/mm/yyyy hh:mm am/pm')}<a className="text-success mr-2">
                                                                                        <i
                                                                                            className="mdi mdi-pencil font-size-16"
                                                                                            onClick={(e) => {
                                                                                                handleEdite(investigationData, header)
                                                                                            }}
                                                                                        ></i>
                                                                                    </a></th>
                                                                                ))}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {investigationData?.value?.data && investigationData?.value?.data.map((row: any, rowIndex: any) => (
                                                                                <tr key={rowIndex}>
                                                                                    <th>{row.label}</th>
                                                                                    {row.values.map((value: any, valueIndex: any) => (
                                                                                        <td key={valueIndex}>{value?.content || ''}</td>
                                                                                    ))}
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </Card>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </Collapse >
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>}
            {tab === "document" &&
                <div className="structure-box investigation-box">
                    <div style={{ paddingTop: "15px" }} className="inner-con-box">
                        <PatientDocuments patient={patient} selected_admission={selected_admission} />
                    </div></div>}

            {tab === "graph" &&
                <div style={{ paddingTop: "15px" }}>
                    Graph Section
                </div>}
        </div >
    );
};

export default Investigation;