import React, { useEffect, useState } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import { postRole, updateRole } from "../../../redux/actions/roleAction";
import RoleRegisterForm from "./RoleRegisterForm";
import AccessPolicyForm from "../../AccessPolicy/AccessPolicyForm";

const UserRolesForm: React.FC<{
  isFormOpen: boolean;
  setIsFormOpen: any;
  user_roles?: any;
  mode?: any;
  fetch_user_roless: any;
}> = ({ isFormOpen, setIsFormOpen, user_roles, mode, fetch_user_roless }) => {
  const [access_policy, setaccess_policy]: any = useState([]);
  const [user_role_info, setuser_role_info] = useState(user_roles);

  useEffect(() => {
    if (user_roles !== null && mode === "edit") {
      setuser_role_info(user_roles);
    } else {
      setuser_role_info(null);
    }
  }, [user_role_info, user_roles, mode]);

  useEffect(() => {
    if (user_role_info?.user_access !== undefined && mode === "edit") {
      setaccess_policy(user_role_info?.user_access);
    } else {
      setaccess_policy([]);
    }
  }, [user_role_info, mode]);

  const validationSchema = Yup.object({
    role: Yup.string().required("Role is required"),
  });

  const initialValues = {
    role: user_role_info?.role ?? "",
  };

  const submitForm = async (values: any, resetForm: any) => {
    try {
      let response;
      if (user_role_info?.id) {
        response = await updateRole({
          role: values.role,
          user_access: access_policy,
          id: user_role_info.id,
        });
      } else {
        response = await postRole({
          role: values.role,
          user_access: access_policy,
        });
      }
      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        resetForm();
        setIsFormOpen(false);
        setaccess_policy([]);
        fetch_user_roless();
      } else {
        if (response?.errors?.length > 0) {
          ErrorToast(response?.errors[0]?.msg);
        }
      }
    } catch (error) {
      ErrorToast(
        "An error occurred while submitting the form. Please try again."
      );
    }
  };

  return (
    <>
      {isFormOpen && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { resetForm }) => {
            submitForm(values, resetForm);
          }}
        >
          {({
            isValid,
            isSubmitting,
            resetForm,
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            dirty,
            setFieldValue,
          }) => (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  setFieldValue("user_roles_access", access_policy);
                  if (errors) {
                    const firstError: any = Object.values(errors)[0];

                    if (Array.isArray(firstError)) {
                      const errorMessage = firstError.join(", ");
                      ErrorToast(errorMessage);
                    } else ErrorToast(firstError);
                  }
                  handleSubmit();
                }}
              >
                <div className="right-panel user-form-detail-panel">
                  <h4>{mode === "edit" ? "Edit User" : "Add User"}</h4>
                  <button
                    className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light "
                    onClick={() => {
                      resetForm();
                      setaccess_policy([]);
                      setuser_role_info(null);
                      setIsFormOpen(false);
                    }}
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                  <div className="inner-pan-box">
                    <div className="form-common-scroll">
                      <RoleRegisterForm
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        show_password_field={!user_roles ? true : false}
                      />

                      <AccessPolicyForm
                        access_policy={access_policy}
                        setaccess_policy={setaccess_policy}
                      />
                    </div>
                    <div className="col-12 text-center mt-2">
                      <button
                        className="btn btn-primary mr-2 medium-btn"
                        type="submit"
                      >
                        {user_roles ? "Update" : "Add"}
                      </button>
                      <button
                        className="btn btn-secondary medium-btn"
                        onClick={() => {
                          resetForm();
                          setuser_role_info(null);
                          setaccess_policy([]);
                        }}
                      >
                        Reset{" "}
                      </button>
                    </div></div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default UserRolesForm;
