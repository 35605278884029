import { medicine_type_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";

export const getMedicineTypesList = async ({
  page_number,
  per_page,
}: {
  page_number?: number;
  per_page?: number;
}) => {
  try {
    const params: { [key: string]: number } = {};
    if (page_number !== undefined) params["page_number"] = page_number;
    if (per_page !== undefined) params["per_page"] = per_page;

    const { data } = await axiosApi.get(
      medicine_type_routes.get_medicine_types_list,
      { params }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.errors;
  }
};

export const postMedicineType = async ({
  name,
  description,
}: {
  name: string;
  description: string;
}) => {
  try {
    const { data } = await axiosApi.post(
      medicine_type_routes.post_medicine_type,
      { name, description }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const updateMedicineType = async ({
  id,
  name,
  description,
}: {
  id: string;
  name: string;
  description: string;
}) => {
  try {
    const { data } = await axiosApi.put(
      medicine_type_routes.put_medicine_type,
      { id, name, description }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const deleteMedicineType = async ({ id }: { id: string }) => {
  try {
    const { data } = await axiosApi.delete(
      `${medicine_type_routes.delete_medicine_type}/${id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.data;
  }
};

/* INVESTIGATION SUB TYPES */

export const getMedicineSubTypesList = async ({
  page_number,
  per_page,
}: {
  page_number?: number;
  per_page?: number;
}) => {
  try {
    const params: { [key: string]: number } = {};
    if (page_number !== undefined) params["page_number"] = page_number;
    if (per_page !== undefined) params["per_page"] = per_page;

    const { data } = await axiosApi.get(
      medicine_type_routes.get_medicine_sub_types_list,
      { params }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.errors;
  }
};

export const postMedicineSubType = async ({
  name,
  medicine_type_id,
  description,
}: {
  name: string;
  medicine_type_id: string;
  description: string;
}) => {
  try {
    const { data } = await axiosApi.post(
      medicine_type_routes.post_medicine_sub_type,
      { name, medicine_type_id, description }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const updateMedicineSubType = async ({
  id,
  name,
  medicine_type_id,
  description,
}: {
  id: string;
  name: string;
  medicine_type_id: string;
  description: string;
}) => {
  try {
    const { data } = await axiosApi.put(
      medicine_type_routes.put_medicine_sub_type,
      { id, name, medicine_type_id, description }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const deleteMedicineSubType = async ({ id }: { id: string }) => {
  try {
    const { data } = await axiosApi.delete(
      `${medicine_type_routes.delete_medicine_sub_type}/${id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.data;
  }
};

// MEDICINE CONTENTS

export const getMedicineContentsList = async ({
  page_number,
  per_page,
}: {
  page_number?: number;
  per_page?: number;
}) => {
  try {
    const params: { [key: string]: number } = {};
    if (page_number !== undefined) params["page_number"] = page_number;
    if (per_page !== undefined) params["per_page"] = per_page;

    const { data } = await axiosApi.get(
      medicine_type_routes.get_medicine_content_list,
      { params }
    );

    // Transform the response data
    const transformedData = data.body.data.map((item: any) => ({
      id: item.id,
      name: item.content_name,
      description: item.brand_name,
    }));

    return transformedData;
  } catch (error: any) {
    return error?.response?.data?.errors;
  }
};

export const postMedicineContentsList = async ({
  name,
  description,
  id,
}: {
  name: string;
  description: string;
  id: string;
}) => {
  try {
    const payload = {
      content_name: name,
      brand_name: description,
      id,
    };

    const { data } = await axiosApi.post(
      medicine_type_routes.post_medicine_content_list,
      payload
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const updateMedicineContentsList = async ({
  id,
  name,
  description,
}: {
  id: string;
  name: string;
  description: string;
}) => {
  try {
    const payload = {
      content_name: name,
      brand_name: description,
      id,
    };

    const { data } = await axiosApi.put(
      medicine_type_routes.put_medicine_content_list,
      payload
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const deleteMedicineContentsList = async ({ id }: { id: string }) => {
  try {
    const { data } = await axiosApi.delete(
      `${medicine_type_routes.delete_medicine_content_list}/${id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.data;
  }
};