import { Field, ErrorMessage } from "formik";
import { FC } from "react";

const RoleRegisterForm: FC<any> = ({ handleBlur, handleChange }) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Role</label>
          <Field
            name="role"
            className="form-control"
            type="text"
            placeholder="Enter Role"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage name="role" component="div" className="text-danger" />
        </div>
      </div>
    </div>
  );
};

export default RoleRegisterForm;
