import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ErrorToast,
  SuccessToast,
} from "../../../../../utils/toastNotifications";
import {
  putPatientAdmissionHourlyReport,
  getPatientAdmissionHourlyReportByCategory,
  postPatientAdmissionHourlyReport,
} from "../../../../../redux/actions/patientAdmissionHourlyReport";
import { v4 as uuidv4 } from 'uuid';
import SvgFilesIcon from "../../../../../components/SvgFiles/SvgFiles";
import DeleteConfirmationModal from "../../../../../components/DeletePopupComponent/DeletePopup";
 
const PatientAdmissionHRInput: React.FC<any> = ({
  selected_admission,
  selected_date,
}) => {
  const initialRows = useMemo(
    () =>
      Array.from({ length: 24 }).map((_, i) => {
        const startHour = (i % 24).toString().padStart(2, "0");
        const endHour = (i === 23) ? "24" : ((i % 24) + 1).toString().padStart(2, "0");
        const selected_time = `${startHour}:00-${endHour}:00`;
        return {
          id: uuidv4().toString(),
          datetime: new Date(),
          selected_time,
          blood: "",
          colloides_blood: "",
          crystallodies_1: "",
          crystallodies_2: "",
          feeding_tube: "",
          oral: "",
          hourly_total:"0" ,
          day_total: "0",
        };
      }),
    []
  );
  type Row = (typeof initialRows)[number];
  const [rows, setRows] = useState<Row[]>(initialRows);
  const [rowId, setRowId] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);
 
  const admission_hourly_report = useCallback(async () => {
    if (selected_admission !== null) {
      const date = selected_date
        ? new Date(selected_date?.substring(0, 10))
        : new Date();
      const tomorrow = new Date(date);
      tomorrow.setDate(date?.getDate() + 1);
      const yesterday = new Date(date);
      yesterday.setDate(date?.getDate() - 1);
      yesterday.setHours(23, 59, 59, 999);
      const request = await getPatientAdmissionHourlyReportByCategory(
        selected_admission?.id,
        "input",
        new Date(yesterday).toISOString(),
        new Date(tomorrow).toISOString()
      );
      if (request?.statusCode === 200) {
        const requestedData = request?.body?.data[0];
        setRowId(requestedData?.id);
        if (requestedData?.reportValues?.length > 0) {
          const reorderedArray = requestedData?.reportValues;
          recalculateTotals(reorderedArray);
                } else {
          setRows(initialRows);
          recalculateTotals(initialRows);
        }
      } else {
        setRowId('');
        setRows(initialRows);
        recalculateTotals(initialRows);
      }
    }
  }, [selected_date, selected_admission, initialRows]);
 
  useEffect(() => {
    admission_hourly_report();
  }, [selected_date, selected_admission, admission_hourly_report]);
 
  const handleAdmissionHourlyReport = async () => {
    if (!rowId) {
      let jsonData = {
        reportValues: rows,
        category: "input",
        patient_admission_id: selected_admission?.id,
        datetime: selected_date
      };
      const response = await postPatientAdmissionHourlyReport(jsonData);
      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        admission_hourly_report();
      }
    } else {
      const request = await putPatientAdmissionHourlyReport({ id: rowId, reportValues: rows });
      if (request?.statusCode === 200) {
        SuccessToast(request?.message);
        admission_hourly_report();
      } else {
        ErrorToast(request?.message);
      }
    }
  }
 
  const openDeleteModal = (id: string) => {
    setCurrentDeleteId(id);
    setIsDeleteModalOpen(true);
  };
 
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setCurrentDeleteId(null);
  };
 
  const confirmDelete = async () => {
    if (currentDeleteId) {
      const updatedRows = rows.map((row) =>
        row.id === currentDeleteId
          ? {
            ...row,
            blood: "",
            colloides_blood: "",
            crystallodies_1: "",
            crystallodies_2: "",
            feeding_tube: "",
            oral: "",
            hourly_total: "",
            day_total: "",
          }
          : row
      );
      recalculateTotals(updatedRows);
      const request = await putPatientAdmissionHourlyReport({ id: rowId, reportValues: updatedRows });
      if (request?.statusCode === 200) {
        SuccessToast("Row Deleted Successfully");
        admission_hourly_report();
      } else {
        ErrorToast(request?.message);
      }
    }
    closeDeleteModal();
  };
  useEffect(() => {
    recalculateTotals(rows); 
  }, [rows]);
 
  const recalculateTotals = (updatedRows: Row[]) => {
    let cumulativeDayTotal = 0; // Initialize cumulative day total
 
    updatedRows.forEach((row) => {
      // Calculate hourly total for the current row
      const hourlyTotal = (
        Number(row.crystallodies_1) +
        Number(row.crystallodies_2) +
        Number(row.blood) +
        Number(row.colloides_blood) +
        Number(row.feeding_tube) +
        Number(row.oral)
      ).toString();
 
      // Set the hourly total for the row
      row.hourly_total = hourlyTotal;
 
      // Calculate and set the cumulative day total
      cumulativeDayTotal += parseFloat(hourlyTotal) || 0;
      row.day_total = cumulativeDayTotal.toString();
    });
 
    setRows(updatedRows); // Update state with recalculated rows
  };
 
  const onChangeTextFields = useCallback((event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    const updatedRows = rows.map((row, i) => i === index ? {
      ...row,
      [name]: value.replace(/[^0-9.]/g, '') // Allow numbers and dots
      .replace(/(\..*)\./g, '$1') // Allow only one dot
      .replace(/(\.\d{2})\d+/g, '$1') // Allow up to two decimal places
    }:row)
    recalculateTotals(updatedRows);
  }, [rows]);
 
  return (
    <>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={closeDeleteModal}
        onConfirm={confirmDelete}
        pageName="patient admission HR Input"
      />
      <div className="report-save-all-btn">
        <button className="btn btn-primary mr-2 medium-btn" onClick={handleAdmissionHourlyReport}>
          Save All
        </button>
      </div>
      <div className="hourly-table-scroll">
        <table className="table table-hover fixed-table-top">
          <thead className="table-light">
            <tr>
              <th className="time-col">Time</th>
              <th>Crystallodies 1 (ML)</th>
              <th>Crystallodies 2 (ML)</th>
              <th>Blood (ML)</th>
              <th>Colloides (ML)</th>
              <th>Feeding Tube (ML)</th>
              <th>Oral (ML)</th>
              <th>Hourly Total (ML)</th>
              <th> Day Total (ML)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => {
                  const isRowEmpty =
                !row.blood && !row.colloides_blood && !row.crystallodies_1 && !row.crystallodies_2 && !row.feeding_tube &&  !row.oral;
               return(
              <tr key={index}>
                <td className="time-col">{row?.selected_time}</td>
                <td>
                  <input
                    type="text"
                    name="blood"
                    value={row?.blood}
                    onChange={(e) => onChangeTextFields(e, index)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="colloides_blood"
                    value={row?.colloides_blood}
                    onChange={(e) => onChangeTextFields(e, index)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="crystallodies_1"
                    value={row?.crystallodies_1}
                    onChange={(e) => onChangeTextFields(e, index)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="crystallodies_2"
                    value={row?.crystallodies_2}
                    onChange={(e) => onChangeTextFields(e, index)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="feeding_tube"
                    value={row?.feeding_tube}
                    onChange={(e) => onChangeTextFields(e, index)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="oral"
                    value={row?.oral}
                    onChange={(e) => onChangeTextFields(e, index)}
                  />
                </td>
                <td>{row?.hourly_total}</td>
                <td>{row?.day_total}</td>
                <td className="hospital-card-box">
                  <div className="user-setting-box">
                  <div
                          className={`delete-box ${isRowEmpty ? 'disabled delete-box-disable' : ''}`}
                          title={isRowEmpty ? 'No Data to Reset' : 'Reset'}
                          onClick={() => !isRowEmpty && openDeleteModal(row.id)}
                          style={{ pointerEvents: isRowEmpty ? 'none' : 'auto' }}
                        >
                      <i className="fas fa-redo"></i>
                    </div>
                  </div>
                </td>
              </tr>
            );
})}
          </tbody>
        </table>
      </div>
    </>
  );
};
 
export default PatientAdmissionHRInput;
 
 
 