// reportHeaderReducer.ts

import { ActionTypes, ReportHeaderAction } from "../types/reportTabsTypes";

export interface ReportHeaderState {
  isHeaderCollapsed: boolean;
  currentActiveTab: string;
}

const initialState: ReportHeaderState = {
  isHeaderCollapsed: localStorage.getItem("header") === "true",
  currentActiveTab: "1"
};

const reportHeaderReducer = (state = initialState, action: ReportHeaderAction): ReportHeaderState => {
  switch (action.type) {
    case ActionTypes.TOGGLE_HEADER_STATE:
      const newHeaderState = !state.isHeaderCollapsed;
      localStorage.setItem("header", JSON.stringify(newHeaderState));
      return {
        ...state,
        isHeaderCollapsed: newHeaderState
      };
    case ActionTypes.SET_CURRENT_REPORT_ACTIVE_TAB:
      return {
        ...state,
        currentActiveTab: action.payload
      };
    case ActionTypes.REMOVE_TAB:
      // If currentActiveTab is empty or undefined after removing the tab, fallback to "1"
      return {
        ...state,
        currentActiveTab: state.currentActiveTab ? state.currentActiveTab : "1"
      };
    default:
      return state;
  }
};

export default reportHeaderReducer;
