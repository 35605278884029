import { report_measurement_units_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IAddReportMeasurementUnitsTypesParams, IDeleteReportMeasurementUnitsTypesParams, IGetReportMeasurementUnitsTypesParams, IUpdateReportMeasurementUnitsTypesParams } from "../types/reportMeasurementUnitsTypes";


export const getReportMeasurementUnitsTypesList = async ({ page_number, per_page }: IGetReportMeasurementUnitsTypesParams) => {

    try {
        const params: { [key: string]: number } = {};

        if (page_number !== undefined) params['page_number'] = page_number;
        if (per_page !== undefined) params['per_page'] = per_page;

        const { data } = await axiosApi.get(report_measurement_units_routes.get_report_measurement_units_types_list, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}


export const postReportMeasurementUnitsType = async ({ name }: IAddReportMeasurementUnitsTypesParams) => {
    try {
        const { data } = await axiosApi.post(report_measurement_units_routes.post_report_measurement_units_type, { name });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const updateReportMeasurementUnitsType = async ({ id, name }: IUpdateReportMeasurementUnitsTypesParams) => {
    try {
        const { data } = await axiosApi.put(report_measurement_units_routes.put_report_measurement_units_type, { id, name });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const deleteReportMeasurementUnitsType = async ({ id }: IDeleteReportMeasurementUnitsTypesParams) => {
    try {
        const { data } = await axiosApi.delete(`${report_measurement_units_routes.delete_report_measurement_units_type}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data?.data;
    }
}