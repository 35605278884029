import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowModel } from "@mui/x-data-grid";
import { ErrorToast, SuccessToast } from "../../../../../utils/toastNotifications";
import NoRowsOverlay from "../../../../../components/DataGrid/NoRowsOverlay";
import { grid_styles } from "../../grid_styles";
import {
  getPatientAdmissionHourlyReportByCategory,
  postPatientAdmissionHourlyReport,
  putPatientAdmissionHourlyReport,
  deletePatientAdmissionHourlyReport,
} from "../../../../../redux/actions/patientAdmissionHourlyReport";
import { useSelector } from "react-redux";
import { Collapse } from "reactstrap";
import { getInfusionPumpsTypesList } from "../../../../../redux/actions/infusionPumpsAction";
import { v4 as uuidv4, validate as uuidValidate, version as uuidVersion, v4 } from 'uuid';
import DeleteConfirmationModal from "../../../../../components/DeletePopupComponent/DeletePopup";
import SvgFilesIcon from "../../../../../components/SvgFiles/SvgFiles";


const PatientAdmissionInfusionPump: React.FC<any> = ({
  selected_admission, selected_date
}) => {
  const initialRows: any = useMemo(
    () => [
      {
        id: "1",
        datetime: new Date(),
        infusion_id: "",
        concentration: "",
        rate: "",
        selected_time: "",
      },
    ],
    []
  );


  const [infusion_pump, setinfusion_pump] = useState<any[]>([]);
  type Row = (typeof initialRows)[number];
  const [rows, setRows] = useState<Row[]>(initialRows);
  const { user_info } = useSelector((state: any) => state?.user);
  const [rowId, setrowId] = useState(null);
  const [collapsedItems, setCollapsedItems] = useState<{ [key: string]: boolean }>({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);
  const timeOptions = Array.from({ length: 24 }).map((_, i) => {
    const startHour = (i % 24).toString().padStart(2, "0");
    const endHour = (i === 23) ? "24" : ((i % 24) + 1).toString().padStart(2, "0");
    const selected_time = `${startHour}:00-${endHour}:00`;
    return {
      value: selected_time,
      label: selected_time,
    };
  });

  const handleTimeChange = (event: string, rowId: string) => {
    let updatedRows = rows.map((row) => {
      if (row.id === rowId) {
        const infusionId = row.infusion_id;
        const sameInfusionRows = rows.filter((r) => r.infusion_id === infusionId && r.id !== rowId);
        const concentrationValue = sameInfusionRows.reduce((acc, curr) => {
          const concentration = Number(curr.concentration || 0);
          const rate = Number(curr.rate || 0);
          return concentration - rate;
        }, 0)?.toString();

        return { ...row, selected_time: event, concentration: concentrationValue || "" };
      }
      return row;
    });

    const emptyTimeObjects = updatedRows.filter((obj) => obj.selected_time === "");
    const nonEmptyTimeObjects = updatedRows.filter((obj) => obj.selected_time !== "");

    const reorderedArray = timeOptions
      .map((value) => nonEmptyTimeObjects.filter((obj) => obj.selected_time === value.value))
      .flat()
      .filter((row) => row !== undefined);

    const finalArray = emptyTimeObjects.concat(reorderedArray);
    setRows(finalArray);
  };

  const admission_hourly_report = useCallback(async () => {
    const infusion_pump = await getInfusionPumpsTypesList({});
    if (infusion_pump?.statusCode === 200) {
      setinfusion_pump(infusion_pump?.body?.data);
    }
    if (selected_admission) {
      const date = selected_date ? new Date(selected_date?.substring(0, 10)) : new Date();
      const tomorrow = new Date(date);
      tomorrow.setDate(date.getDate() + 1);
      const yesterday = new Date(date);
      yesterday.setDate(date.getDate() - 1);
      yesterday.setHours(23, 59, 59, 999);

      const request = await getPatientAdmissionHourlyReportByCategory(
        selected_admission?.id,
        "infusion_pump",
        yesterday.toISOString(),
        tomorrow.toISOString()
      );
      if (request?.statusCode === 200) {
        const filteredData = request?.body?.data[0];
        setRows(filteredData?.reportValues || initialRows);
        setrowId(filteredData?.id || null);
      } else {
        setRows(initialRows);
      }
    } else {
      const allRows = infusion_pump?.body?.data.map((pump: any) => ({
        id: uuidv4(),
        infusion_id: pump?.id,
        concentration: "",
        rate: "",
        selected_time: "",
      })) || [];
      setRows(allRows);
    }
  }, [selected_date, selected_admission, initialRows]);

  useEffect(() => {
    admission_hourly_report();
  }, [selected_date, selected_admission, admission_hourly_report]);

  const handleAdmissionHourlyReport = async () => {
    const filteredRows = rows.filter((row) => row.selected_time !== null && row.selected_time !== "");
    if (rows.some((row) => row.selected_time === "")) {
      ErrorToast("Please select time for all rows before saving.");
      return;
    }
    if (!rowId) {
      const jsonData = {
        reportValues: filteredRows,
        category: "infusion_pump",
        patient_admission_id: selected_admission?.id,
        datetime: selected_date
      };
      const response = await postPatientAdmissionHourlyReport(jsonData);
      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        admission_hourly_report();
      }
    } else {
      const request = await putPatientAdmissionHourlyReport({ id: rowId, reportValues: rows });
      if (request?.statusCode === 200) {
        SuccessToast(request?.message);
        admission_hourly_report();
      } else {
        ErrorToast(request?.message);
      }
    }
  };

  const addRow = (id: string) => {
    setRows((prevRows) => [
      ...prevRows,
      {
        id: uuidv4(),
        infusion_id: id,
        concentration: "",
        rate: "",
        selected_time: "",
      },
    ]);
  };

  const onChangeTextField = useCallback((e: any, id: string) => {
    const { name, value } = e.target;
    const updatedRows = rows.map((row) => {
      return (row?.id === id ? {
        ...row,
        [name]: value.replace(/[^0-9.]/g, '') // Allow numbers and dots
        .replace(/(\..*)\./g, '$1') // Allow only one dot
        .replace(/(\.\d{2})\d+/g, '$1') // Allow up to two decimal places
      } : row)
    });
    setRows(updatedRows);
  }, [rows]);

  const rowsRender = (inFusion: any) => {
    const selectedRows = [...rows];
    const filterdRows = selectedRows.filter((item) => (item.infusion_id === inFusion?.id));
    if (filterdRows.length === 0)
      return [{ id: false }];
    else return filterdRows
  };

  const handleDeleteHourlyReport = useCallback(
    async (id: string) => {
      try {
        const isRowSaved = rows.some(row => row.id === id && rowId);
        if (!isRowSaved) {
          return ErrorToast("Record is not saved yet!");
        }
        const updatedRows = rows.filter((row) => row.id !== id);
        const response = await putPatientAdmissionHourlyReport({ id: rowId, reportValues: updatedRows });
        if (response?.statusCode === 200) {
          SuccessToast("Row Deleted Successfully");
          admission_hourly_report();
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [rows, rowId, admission_hourly_report]
  );

  const openDeleteModal = (id: string) => {
    setCurrentDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setCurrentDeleteId(null);
  };

  const confirmDelete = () => {
    if (currentDeleteId) {
      handleDeleteHourlyReport(currentDeleteId);
    }
    closeDeleteModal();
  };

  const toggleCollapse = (headingId: string) => {
    setCollapsedItems(prevState => ({
      ...prevState,
      [headingId]: !prevState[headingId],
    }));
  };
  const shouldShowActions = () => {
    return (
      (user_info?.is_super_admin ||
        new Date(selected_date).toDateString() === new Date().toDateString()) &&
      selected_admission?.admitted &&
      !selected_admission?.discharged
    );
  };

  return (
    <>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={closeDeleteModal}
        onConfirm={confirmDelete}
        pageName="patient admission infusion pump"
      />
      {shouldShowActions() && (
        <div className="report-save-all-btn">
          <button className="btn btn-primary mr-2 medium-btn" onClick={handleAdmissionHourlyReport} >
            Save All
          </button>
        </div>
      )}
      <div className="col-12">
        <div className="accordion" id="accordion">
          {infusion_pump &&
            infusion_pump.map((InfusionPumpData: any, index: any) => (
              <React.Fragment key={index}>
                <div className="accordion-item">
                  <h2 className="accordion-header" id={InfusionPumpData?.id + "-" + index}>
                    <button
                      className={`accordion-button fw-medium ${collapsedItems[InfusionPumpData?.id + "-" + index] ? "" : "collapsed"}`}
                      type="button"
                      onClick={() => toggleCollapse(InfusionPumpData?.id + "-" + index)}
                      aria-expanded={collapsedItems[InfusionPumpData?.id + "-" + index] ? "true" : "false"}>
                      {InfusionPumpData?.name}
                      {collapsedItems[InfusionPumpData?.id + "-" + index] && (
                        <span className="right-5">
                          {(user_info?.is_super_admin || new Date(selected_date).toDateString() === new Date().toDateString()) && selected_admission?.admitted &&
                            !selected_admission?.discharged && (
                              <div className="gap-3">
                                <a className="text-success mr-2">
                                  <i className="mdi mdi-plus-circle font-size-24"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      addRow(InfusionPumpData?.id);
                                    }}
                                  ></i>
                                </a>
                              </div>
                            )}
                        </span>
                      )}
                    </button>
                  </h2>
                </div>
                <Collapse isOpen={collapsedItems[InfusionPumpData?.id + "-" + index]} className="accordion-collapse">
                  <div className="accordion-body">
                    <div className="hourly-table-scroll">
                      <table className="table table-hover fixed-table-top">
                        <thead className="table-light">
                          <tr>
                            <th>Time</th>
                            <th>Concentration</th>
                            <th>Rate</th>
                            {shouldShowActions() && <th>Actions</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {rowsRender(InfusionPumpData)[0]?.id &&
                            rowsRender(InfusionPumpData).map((row: Row, rowIndex: number) => {
                              const isRowEmpty =
                                !row.concentration && !row.rate;
                              return (
                                <tr key={rowIndex}>
                                  <td>
                                    <select
                                      id={`sort-order-select-${rowIndex}`}
                                      onChange={(e) =>
                                        handleTimeChange(e.target.value, row?.id)
                                      }
                                      className="form-control"
                                      name="selected_time"
                                      value={row?.selected_time}
                                    >
                                      <option disabled value="">Select Time</option>
                                      {timeOptions.map((option, index) => (
                                        <option key={index} value={option?.value}>
                                          {option?.label}
                                        </option>
                                      ))}
                                    </select>
                                  </td>
                                  <td><input type="text" name="concentration" value={row?.concentration} onChange={(e) => onChangeTextField(e, row?.id)} disabled={selected_admission?.discharged} /></td>
                                  <td><input type="text" name="rate" value={row?.rate} onChange={(e) => onChangeTextField(e, row?.id)} disabled={selected_admission?.discharged} /></td>
                                  {shouldShowActions() && (
                                    <td className="hospital-card-box">
                                      <div className="user-setting-box">
                                        <div
                                          className={`delete-box ${isRowEmpty ? 'disabled delete-box-disable' : ''}`}
                                          title={isRowEmpty ? 'No Data to Reset' : 'Reset'}
                                          onClick={() => !isRowEmpty && openDeleteModal(row.id)}
                                          style={{ pointerEvents: isRowEmpty ? 'none' : 'auto' }}
                                        >                                          <i className="fas fa-redo"></i>
                                        </div>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Collapse>
              </React.Fragment>
            ))}
        </div>
      </div>
    </>
  );

};
export default PatientAdmissionInfusionPump;

