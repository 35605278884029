import CustomMUIDatePicker from "../../../../components/UI/CustomMUIDatePicker";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Collapse } from "reactstrap";
import { ErrorToast, SuccessToast } from "../../../../utils/toastNotifications";
import { useSelector } from "react-redux";
import {
  deletePatientAdmissionClinicalNotes,
  getPatientAdmissionClinicalNotesByPatientAdmissionId,
  postPatientAdmissionClinicalNotes,
  putPatientAdmissionClinicalNotes,
} from "../../../../redux/actions/patientAdmissionClinicalNotes";
import { getDoctorListByRole } from "../../../../redux/actions/doctorAction";
import { formatDateToLocaleDateString } from "../../../../helpers/helperFunctions";
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import { getDoctorSpecialityTypesList } from "../../../../redux/actions/doctorSpecialityAction";
import { Label } from "@mui/icons-material";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import DeleteConfirmationModal from "../../../../components/DeletePopupComponent/DeletePopup";
import { NULL } from "sass";

export interface doctorList {
  id: string;
  name: string;
  specialist: string[];
}

interface referDoctorList {
  id: string;
  name: string;
}

export interface ClinicalNote {
  id: string;
  doctor?: {
    id: string;
    name: string;
    speciality: string;
  }
  refer_doctor?: {
    id: string;
    name: string;
    // speciality: string;
  }
  date: string;
  note: string;
  doctor_refer_date: string;
  doctor_refer_note: string;
  specialty_ids: string[];
  created_by?: any;
  created_on?: string,
  assign_date?: string,

}
export interface SpecialityTypes {
  id: string;
  name: string
}

const ClinicalNotes: React.FC<any> = ({ selected_admission }) => {
  const [collapsedItems, setCollapsedItems] = useState<boolean[]>([]);
  const [doctorTypes, setDoctorTypes] = useState<doctorList[]>([]);
  const [referDoctorTypes, setReferDoctorTypes] = useState<referDoctorList[]>([]);
  const [referDoctorNotTypes, setReferDoctorNotTypes] = useState<referDoctorList[]>([]);
  const [isClinicalNoteOpen, setIsClinicalNoteOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<ClinicalNote | null>(null);
  const [clinicalNoteData, setClinicalNoteData] = useState<ClinicalNote[]>([]);
  const [specialityoptions, setSpecialityOptions] = useState<{ value: string; label: string }[]>([]);
  const { user_info } = useSelector((state: any) => state.user);
  // State for delete confirmation modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);
  const [filterOption, setFilterOption] = useState<string>("all");

  useEffect(() => {
    if (clinicalNoteData && clinicalNoteData.length > 0) {
      const newCollapsedItems = Array(clinicalNoteData.length).fill(true);
      newCollapsedItems[0] = false; // Keep the first item open
      setCollapsedItems(newCollapsedItems);
    }
  }, [clinicalNoteData]);

  const initialValues = {
    id: editData?.id || "",
    doctor_id: editData?.doctor?.id || "",
    specialty_ids: editData?.specialty_ids || [] as string[],
    date: editData?.date || new Date().toISOString(),
    note: editData?.note || "",
    doctor_refer_date: editData?.doctor_refer_date || new Date().toISOString(),
    doctor_refer_note: editData?.doctor_refer_note || "",
    refer_doctor: editData?.refer_doctor?.id || "",
    assign_date: editData?.assign_date || new Date().toISOString(),
  };

  const getClinicalNotesByPatientAdmissionId = useCallback(async () => {
    if (selected_admission !== null) {
      try {
        const request = await getPatientAdmissionClinicalNotesByPatientAdmissionId(
          selected_admission.id
        );
        setClinicalNoteData(request?.body?.data);
        // else setClinicalNoteData([]);
      } catch (error) {
        console.error('Error fetching clinical notes:', error);
        setClinicalNoteData([]);
      }
    }
  }, [selected_admission]);

  const toggleCollapse = (index: number) => {
    setCollapsedItems((prevState) =>
      prevState?.map((item, i) => (i === index ? !item : true))
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { body } = await getDoctorListByRole({ role: "doctor", hospital_id: user_info?.hospital_property?.hospital?.id });
        if (body) {
          setDoctorTypes(body?.data);
          const filteredReferDoctorTypes = body?.data?.filter((doctor: any) => doctor.id !== user_info.id);
          setReferDoctorNotTypes(filteredReferDoctorTypes);
          setReferDoctorTypes(body?.data);
        }
        else { setDoctorTypes([]); setReferDoctorTypes([]); }
        const speciality = await getDoctorSpecialityTypesList({});
        if (speciality?.statusCode === 200) {
          const optionsAll = speciality?.body?.data?.map((spec: SpecialityTypes) => {
            return {
              value: spec?.id,
              label: spec?.name
            }
          })
          setSpecialityOptions(optionsAll)
        }
      } catch (error) {
        console.error("Error fetching doctor types:", error);
      }
    };
    fetchData();
  }, []);

  const handleAdmissionClinicalNotes = async ({
    id,
    specialty_ids,
    doctor_id,
    date,
    note,
    doctor_refer_date,
    doctor_refer_note,
    refer_doctor,
    assign_date
  }: {
    id: string;
    patient_admission_id: string;
    specialty_ids: string[];
    doctor_id: string;
    date: string;
    note: string;
    doctor_refer_date: string;
    doctor_refer_note: string;
    refer_doctor: any;
    assign_date: string;
  }) => {
    try {
      if (doctor_id.trim() === "")
        return ErrorToast("Dr. Name is required field!");
      if (date.trim() === "") return ErrorToast("Date is required field!");
      if (note.trim() === "") return ErrorToast("Note is required field!");
      let response;
      if (id) {
        response = await putPatientAdmissionClinicalNotes({
          id,
          patient_admission_id: selected_admission?.id,
          doctor_id,
          specialty_ids,
          date,
          note,
          doctor_refer_date,
          doctor_refer_note,
          refer_doctor,
          assign_date
        });
        setIsClinicalNoteOpen(false);
      } else {
        response = await postPatientAdmissionClinicalNotes({
          patient_admission_id: selected_admission?.id,
          specialty_ids,
          doctor_id,
          date,
          note,
          doctor_refer_date,
          doctor_refer_note,
          refer_doctor,
          assign_date
        });
        setIsClinicalNoteOpen(true);
      }
      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        getClinicalNotesByPatientAdmissionId();
        // setIsClinicalNoteOpen(false);
        setEditData(null);
        // Set the new item as open by default
        setCollapsedItems((prevState) => [false, ...prevState]);
      } else if (response?.statusCode === 400)
        ErrorToast(response?.errors[0]?.msg);
      else ErrorToast("Unknown Error Occurred!");
    } catch (error: any) {
      ErrorToast(error?.response?.data?.errors[0]?.msg);
    }
  };

  const handleDeleteClinicalNotes = useCallback(
    async (id: string) => {
      try {
        const response = await deletePatientAdmissionClinicalNotes(id);
        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          getClinicalNotesByPatientAdmissionId();
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [getClinicalNotesByPatientAdmissionId]
  );

  const validationSchema = Yup.object().shape({
    doctor_id: Yup.string().required('Doctor is required'),
    // speciality: Yup.string().required('Specialization is required'),
    date: Yup.date().required('Date is required').default(() => new Date()),
    note: Yup.string().required('Note is required'),
  });

  const handleReset = (resetForm: any) => {
    resetForm({
      values: {
        dr_name: "",
        speciality: "",
        date: "",
        note: "",
        specialty_ids: [],
        refer_doctor: "", 
      },
    });
  };

  useEffect(() => {
    getClinicalNotesByPatientAdmissionId();
  }, [getClinicalNotesByPatientAdmissionId]);

  const openDeleteModal = (id: string) => {
    setCurrentDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setCurrentDeleteId(null);
    setIsDeleteModalOpen(false);
  };

  const confirmDelete = () => {
    if (currentDeleteId) {
      handleDeleteClinicalNotes(currentDeleteId);
      closeDeleteModal();
    }
  };

  const getFilteredNotes = () => {
    if (filterOption === "my_notes") {
      return clinicalNoteData.filter(note => note.doctor?.id === user_info?.id);
    } else if (filterOption === "refer_notes") {
      return clinicalNoteData.filter(note => note.refer_doctor?.id === user_info?.id);
    }
    return clinicalNoteData;
  };

  return (
    <>
      <div className="add-row-btn">
        <div className="filter-btn-group mb-2">
          <select
            className="form-control"
            value={filterOption}
            onChange={(e) => setFilterOption(e.target.value)}
          >
            <option value="all">All notes</option>
            <option value="my_notes">My notes</option>
            <option value="refer_notes">Refer notes</option>
          </select>
        </div>
        {(true || user_info?.hospital_property?.id ===
          selected_admission?.hospital_property?.id) &&
          selected_admission?.admitted &&
          !selected_admission?.discharged && (
            <button
              className="btn btn-primary medium-btn mb-2"
              onClick={() => {
                setEditData(null);
                setIsClinicalNoteOpen(true);
              }}
            >Add</button>
          )}
      </div>
      {isClinicalNoteOpen && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { resetForm }) => {
            handleAdmissionClinicalNotes({
              id: values?.id,
              specialty_ids: Array.isArray(values?.specialty_ids) ? values?.specialty_ids : [values?.specialty_ids],
              patient_admission_id: selected_admission?.id,
              doctor_id: values?.doctor_id,
              date: values?.date,
              note: values?.note,
              doctor_refer_date: values?.doctor_refer_date,
              doctor_refer_note: values?.doctor_refer_note,
              refer_doctor: values?.refer_doctor,
              assign_date: values?.assign_date,
            });
            resetForm();
          }}
        >
          {({ resetForm,
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            setFieldValue,
          }) => (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (errors) {
                    const firstError = Object.values(errors)[0];
                    const errorMessage = Array.isArray(firstError) ? firstError[0] : firstError;
                    ErrorToast(errorMessage);
                  }
                  handleSubmit();
                }}
              >
                <div className="right-panel">
                  <h4>Clinical Notes</h4>
                  <button
                    className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light"
                    onClick={() => setIsClinicalNoteOpen(false)}>
                    <i className="mdi mdi-close"></i>
                  </button>
                  <div className="row">
                    <div className="mb-3">
                      <label htmlFor="date">Date</label>
                      <CustomMUIDatePicker
                        useDateTimePicker={true}
                        name="date"
                        type="formik"
                        setState={setFieldValue}
                        value={values?.date}
                        disabled={(editData !== null && editData?.doctor?.id !== user_info?.id) ? true : false}
                      />
                      <ErrorMessage name="date" component="div" className="text-danger" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="dr_name">Doctor</label>
                      <Field
                        name="doctor_id"
                        as="select"
                        disabled={(editData !== null && editData?.doctor?.id !== user_info?.id) ? true : false}
                        className={`form-control ${errors.doctor_id && touched.doctor_id ? 'is-invalid' : ''}`}
                        value={values.doctor_id} // Set the value attribute to the selected value
                        onChange={(e: any) => {
                          const selectedDoctorId = e.target.value;
                          const selectedDoctorObject = doctorTypes.find(doctor => doctor.id === selectedDoctorId);
                          if (selectedDoctorObject) {
                            setFieldValue('specialty_ids', selectedDoctorObject?.specialist
                            );
                          } else {
                            setFieldValue('specialty_ids', []);
                          }
                          handleChange(e);
                        }}
                      >
                        <option value="">Select a Doctor</option>
                        {doctorTypes && doctorTypes.length > 0 && doctorTypes.map(doctor => (
                          <option key={doctor?.id} value={doctor?.id}>{doctor?.name}</option>
                        ))}
                      </Field>
                      <ErrorMessage name="doctor_id" component="div" className="text-danger" />
                    </div>
                    <div className="mb-3 multi-select-control">
                      <label htmlFor="specialty">Specialty</label>
                      <Select
                        className={`form-control paddingZero`}
                        MenuProps={{ autoFocus: true }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "white",
                            },
                            "&:hover fieldset": {
                              borderColor: "white !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "white !important",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "0 !important",
                          },
                          "& :focus": {
                            outline: "none !important",
                            border: "0 !important",
                            boxShadow: "none !important",
                          },
                          width: "100%",
                          color: "#a6b0cf",
                          fontWeight: 400,
                          fontSize: "0.875rem",
                        }}
                        multiple
                        placeholder="Select Speciality"
                        labelId="speciality-label"
                        id="speciality"
                        value={Array.isArray(values.specialty_ids) ? values.specialty_ids : []}
                        disabled={(editData !== null && editData?.doctor?.id !== user_info?.id) ? true : false}
                        onChange={(event) => {
                          const selectedIds = event.target.value;
                          const selectedLabels = specialityoptions
                            .filter((option) => selectedIds.includes(option.value))
                            .map((option) => option.label);
                          handleChange({
                            target: {
                              name: "specialty_ids",
                              value: selectedIds,
                            },
                          });
                          setFieldValue("speciality", selectedLabels.join(", "));
                        }}
                        renderValue={(selected) => {
                          const selectedLabels = specialityoptions
                            .filter((option) => selected.includes(option.value))
                            .map((option) => option.label);
                          return selectedLabels.join(", ");
                        }}
                      >
                        {specialityoptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            <Checkbox checked={values?.specialty_ids?.includes(option.value)} />
                            <ListItemText primary={option.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="note">Note</label>
                      <ReactQuill
                        id="note"
                        value={values.note} // bind value to Formik's field value
                        onChange={(content, delta, source, editor) => setFieldValue('note', content)}
                        readOnly={(editData !== null && editData?.doctor?.id !== user_info?.id) ? true : false}
                      // className={`form-control ${errors.note && touched.note ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage name="note" component="div" className="text-danger" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="refer_doctor">Refer Doctor</label>
                      <Field
                        name="refer_doctor"
                        as="select"
                        className={`form-control ${errors.refer_doctor && touched.refer_doctor ? 'is-invalid' : ''}`}
                        disabled={(editData !== null && editData?.doctor?.id !== user_info?.id) ? true : false}
                        onChange={(e: any) => {
                          const selectedDoctorId = e.target.value;
                          setFieldValue('refer_doctor', selectedDoctorId);
                          handleChange(e);
                        }}
                      >
                        <option value="">Select a Refer Doctor</option>
                        {((editData === null || editData?.doctor?.id == user_info?.id) ? referDoctorNotTypes : referDoctorTypes)?.map(doctor => (
                          <option key={doctor?.id} value={doctor?.id}>{doctor?.name}</option>
                        ))}
                      </Field>
                      <ErrorMessage name="refer_doctor" component="div" className="text-danger" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="assign_date">Refer Doctor Assign Date</label>
                      <CustomMUIDatePicker
                        useDateTimePicker={true}
                        name="assign_date"
                        type="formik"
                        setState={setFieldValue}
                        value={values?.assign_date}
                        disabled={(editData !== null && editData?.doctor?.id !== user_info?.id) ? true : false} />
                      <ErrorMessage name="assign_date" component="div" className="text-danger" />
                    </div>
                    {
                      editData !== null && values?.refer_doctor !== null && (<><div className="mb-3">
                        <label htmlFor="doctor_refer_date">Refer Doctor Note Date</label>
                        <CustomMUIDatePicker
                          useDateTimePicker={true}
                          name="doctor_refer_date"
                          type="formik"
                          setState={setFieldValue}
                          value={values?.doctor_refer_date}
                          maxDate={new Date()}
                          minDate={new Date()}
                        />
                        <ErrorMessage name="doctor_refer_date" component="div" className="text-danger" />
                      </div>
                        <div className="mb-3">
                          <label htmlFor="doctor_refer_note">Refer Doctor Note</label>
                          <ReactQuill
                            id="doctor_refer_note"
                            value={values.doctor_refer_note} // bind value to Formik's field value
                            onChange={(content, delta, source, editor) => setFieldValue('doctor_refer_note', content)}
                          // className={`form-control ${errors.note && touched.note ? 'is-invalid' : ''}`}
                          />
                          <ErrorMessage name="doctor_refer_note" component="div" className="text-danger" />
                        </div></>)
                    }
                    <div className="col-12 text-center">
                      <button
                        className="btn btn-primary mr-2 medium-btn"
                        type="submit"
                        disabled={(editData?.doctor?.id !== user_info?.id && editData?.refer_doctor?.id !== user_info?.id && editData !== null) ? true : false}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary medium-btn ms-2"
                        onClick={() => handleReset(resetForm)}
                        disabled={(editData === null) ? false : true}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
      <div className="col-12">
        <div className="accordion" id="accordion">
          {clinicalNoteData &&
            getFilteredNotes()
              .map((clinic_note: any, index: number) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className={`accordion-button fw-medium ${!collapsedItems[index] ? "" : "collapsed"}`}
                      type="button"
                      onClick={() => toggleCollapse(index)}
                      style={{ cursor: "pointer" }}
                    >
                      Doctor - {clinic_note?.doctor?.name}
                      <span className="right-date">
                        Added On - {" "}
                        {formatDateToLocaleDateString(clinic_note?.date)}{" "}
                        {(true || user_info?.hospital_property?.id ===
                          selected_admission?.hospital_property?.id) &&
                          selected_admission?.admitted &&
                          !selected_admission?.discharged && (
                            <div className="gap-3">
                              <a className="text-success mr-2">
                                <i
                                  className="mdi mdi-pencil font-size-16"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setEditData(clinic_note);
                                    setIsClinicalNoteOpen(true);
                                  }}
                                ></i>
                              </a>
                              <a
                                className="text-danger"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  openDeleteModal(clinic_note.id);
                                }}
                              >
                                <i className="mdi mdi-delete font-size-16"></i>
                              </a>

                            </div>
                          )}
                      </span>
                    </button>
                  </h2>
                  <Collapse
                    isOpen={!collapsedItems[index]}
                    className="accordion-collapse"
                  >
                    <div className="accordion-body">
                      <div className="row text-muted ">
                        <div className="col-lg-3 small-line">
                          <label className="title">Date :</label>
                          <p className="deatil">
                            {formatDateToLocaleDateString(clinic_note?.date)}
                          </p>
                        </div>
                        <div className="col-lg-3 small-line">
                          <label className="title">Doctor :</label>
                          <p className="deatil">
                            {clinic_note?.doctor?.name}
                          </p>
                        </div>
                        <div className="col-lg-3 small-line">
                          <p><b>Speciality : </b></p>
                          <p className="deatil">
                            {clinic_note?.specialty_ids?.map((spec: string) => {
                              const Return = specialityoptions.find((item) => (item.value === spec))?.label
                              if (Return) return Return + ", "
                            })}
                          </p>
                        </div>
                        <div className="col-lg-12">
                          <label className="title">Clinic Note :</label>
                          <p className="deatil richtext-p-tag" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(clinic_note?.note || "-") }}>
                            {/* {clinic_note?.note || "-"} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              ))}
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={closeDeleteModal}
        onConfirm={confirmDelete}
        pageName="clinical note"
      />
    </>
  );
};

export default ClinicalNotes;

