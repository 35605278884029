import React from 'react'
const PageName = ({ title }: any) => {
    return (
        <>
            <nav aria-label="breadcrumb" className='page-name'>
                <ol className="breadcrumb mb-sm-0 font-size-14">
                    <li className="breadcrumb-item active" aria-current="page">
                        {title ? title : ""}
                    </li>
                </ol>
            </nav>
        </>
    )
}
 
export default PageName