import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import profile from "../../assets/images/login.png";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  fetch_user_ip_address,
  LoginUserParams,
  login_log,
  login_user_by_email_service,
  save_token,
  send_verification_email,
  verify_otp,
} from "../../redux/actions/userAction";
import { SuccessToast, ErrorToast } from "../../utils/toastNotifications";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";



const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Login = () => {
  const navigate = useNavigate();
  let dispatch: any = useDispatch();
  const [Otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [otp_sent, setotp_sent] = useState(false);
  const [otp_error, setotp_error] = useState("");
  const [timer, setTimer] = useState(60);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Function to decrement timer every second
    const countdown = () => {
      timerRef.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    };

    // Start countdown timer if OTP has been sent
    if (otp_sent && timer > 0) {
      countdown();
    }

    // Clear timer when component unmounts
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [otp_sent]);

  useEffect(() => {
    if (timer === 0) {
      setotp_sent(false);
      setOtp("");
      setTimer(30);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
  }, [timer]);

  const submitForm = async (values: LoginUserParams) => {
    let login_user_request = await login_user_by_email_service(values);

    if (login_user_request?.statusCode === 200) {
      setIsButtonDisabled(true);

      if (login_user_request?.body?.user?.is_active === false) {

        setIsLoading(true);
        setEmail(values?.email);
        await send_verification_email(values?.email);
        setotp_sent(true);

        setIsButtonDisabled(false);
        setIsLoading(false);
      } else {
        let request = await fetch_user_ip_address();
        await login_log({
          device: navigator.userAgent,
          ip_address: request?.query,
          city: request?.regionName,
          country: request?.country,
          user_id: login_user_request?.body?.id,
        });
        SuccessToast(login_user_request?.message);
        localStorage.setItem("tele_icu_token", login_user_request?.body?.token);
        dispatch(
          save_token(
            login_user_request?.body?.user,
            login_user_request?.body?.token
          )
        );
        navigate("/hospitals");
      }
    } else {
      if (login_user_request?.errors?.length > 0) {
        ErrorToast(login_user_request?.errors[0]?.msg);
      }
    }
  };

  const check_code = async () => {
    await verify_otp(email, Otp);
    try {
      SuccessToast("Validation Successfully");
      navigate("/");
    } catch {
      setotp_error("Invalid OTP");
    }
  };



  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        {/* <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link> */}
      </div>
      <div className="account-pages">
        <Container className="">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden mt-5">
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Link to="/" className="text-center login-img">
                      <img src={profile} alt="" className="img-fluid" />
                    </Link>
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      validationSchema={LoginSchema}
                      onSubmit={submitForm}
                    >
                      {(formik) => {
                        const {
                          values,
                          handleChange,
                          handleSubmit,
                          errors,
                          touched,
                          handleBlur,
                          isValid,
                          dirty,
                        } = formik;
                        return (
                          <Form
                            className="form-horizontal"
                            onSubmit={otp_sent ? check_code : handleSubmit}
                          >
                            {!otp_sent && (<><h4 className="card-title mb-3 mt-2">
                              Login With Email
                            </h4><div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter Email"
                                  type="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur} />
                                {errors.email && touched.email && (
                                  <span className="error">{errors.email}</span>
                                )}
                              </div><div className="mb-3">
                                <Label className="form-label">Password</Label>
                                <Input
                                  name="password"
                                  className="form-control"
                                  placeholder="Enter Password"
                                  type="password"
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur} />
                                {errors.password && touched.password && (
                                  <span
                                    className="error"
                                    style={{ marginTop: 3 }}
                                  >
                                    {errors.password}
                                  </span>
                                )}
                              </div></>)}

                            {otp_sent && (
                              <><h4 className="card-title mb-3 mt-2">Verify Email
                              </h4><div className="mb-3">
                                  <Label className="form-label">OTP</Label>
                                  <OTPInput
                                    value={Otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderSeparator={<span style={{ margin: 5 }} className="otp-separator"> </span>}
                                    renderInput={(props) => <input {...props} className="otp-input" />}
                                  />
                                  <span className="error">
                                    {otp_error && otp_error}
                                  </span>
                                  {otp_sent && timer > 0 && (
                                    <div className="mt-4 text-center text-muted">
                                      {`Time remaining: ${timer}s`}
                                    </div>
                                  )}
                                </div></>
                            )}

                            <div className="mt-3 d-grid">
                              <button
                                type="submit"
                                className={
                                  dirty && isValid
                                    ? "btn btn-primary btn-block"
                                    : "btn btn-primary btn-block disabled-btn"
                                }
                                disabled={!(dirty && isValid) || isButtonDisabled}
                              >
                                {isLoading ? "Sending OTP..." : (otp_sent ? "Verify Code" : "Login")}
                              </button>
                            </div>
                            <div className="mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock me-1" />
                                Forgot Your Password?
                              </Link>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <Link
                  to="/login-with-otp"
                  className="text-secondry  mb-4 d-block"
                >
                  Login With Mobile Number
                </Link>

                <p>
                  © {new Date().getFullYear()} Entel. Crafted With{" "}
                  <i className="mdi mdi-heart text-danger" /> By <a className="text-muted" href="https://www.pixielit.com/" title="Pixielit
                  Studios"> Pixielit Studios. </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <p className="account-pages-footer">
          <Link className="text-muted" to="/">
            Privacy Policy{" "}
          </Link>{" "}
          &nbsp; &nbsp; | &nbsp; &nbsp;
          <Link className="text-muted" to="/">
            Terms & Conditions
          </Link>
        </p>
      </div>
    </React.Fragment>
  );
};

export default Login;

