import { patient_admission_dietry_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";

export const postPatientAdmissionDietry = async (values: any) => {
    try {
        const { data } = await axiosApi.post(
            patient_admission_dietry_routes.post_patient_admission_dietry,
            values
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const getPatientAdmissionDietryByPatientAdmissionId = async (patient_admission_id: string) => {
    try {
        const { data } = await axiosApi.get(
            `${patient_admission_dietry_routes.get_patient_dietry_by_patient_admission_id}?patient_admission_id=${patient_admission_id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const putPatientAdmissionDietry = async (values: any) => {
    try {
        const { data } = await axiosApi.put(
            patient_admission_dietry_routes.put_patient_admission_dietry,
            values
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const deletePatientAdmissionDietry = async (id: string) => {
    try {
        const { data } = await axiosApi.delete(
            `${patient_admission_dietry_routes.delete_patient_admission_dietry}/${id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};
