import React, { useEffect, useState, useCallback } from "react";
import { Input, Label } from "reactstrap";
import { useSelector } from "react-redux";
import {
  getAdmissionById,
  putPatientAdmission,
} from "../../../../redux/actions/patientAction";
import { ErrorToast, SuccessToast } from "../../../../utils/toastNotifications";
import CustomMUIDatePicker from "../../../../components/UI/CustomMUIDatePicker";
import { formatDateToLocaleDateString } from "../../../../helpers/helperFunctions";

const PersonalDetails: React.FC<any> = ({
  selected_admission,
  setselected_admission,
}) => {
  const [update_enabled, setupdate_enabled] = useState(false);
  const { user_info } = useSelector((state: any) => state?.user);
  const [detail_info, setdetail_info] = useState({ admission_date: "", weight: "", sleeping_hours: "", bmi: "" });

  useEffect(() => {
    if (selected_admission && selected_admission?.admission_date) {
      setdetail_info({
        admission_date: selected_admission?.admission_date,
        weight: selected_admission?.weight || "",
        sleeping_hours: selected_admission?.sleeping_hours || "",
        bmi: selected_admission?.bmi || "",
      });
    } else {
      setdetail_info({ admission_date: "", weight: "", sleeping_hours: "", bmi: "" });
    }
  }, [selected_admission]);

  const { admission_date, sleeping_hours, weight, bmi } = detail_info;

  useEffect(() => {
    setupdate_enabled(false);
  }, [selected_admission]);

  const refresh_selected_admission = useCallback(async () => {
    let request = await getAdmissionById(selected_admission?.id);

    if (request?.statusCode === 200) {
      setselected_admission(request?.body);
    } else {
      setselected_admission(selected_admission);
    }
  }, [selected_admission, setselected_admission]);

  const onChangeValue = (e: any) => {
    setdetail_info({
      ...detail_info,
      [e.target.name]: e.target.value,
    });
  };

  const handleAdmissionDateChange = (selectedDate: string) => {
    setdetail_info({
      ...detail_info,
      admission_date: selectedDate,
    });
  }
  const onSubmitValue = async (e: any) => {
    e.preventDefault();
    let result = await putPatientAdmission({
      id: selected_admission?.id,
      admission_date: admission_date,
      sleeping_hours,
      weight,
      bmi,
    });

    if (result.statusCode === 200) {
      SuccessToast("Personal Details updated successfully");
    } else {
      ErrorToast("Server Error, Try Again");
    }
    setupdate_enabled(false);
    await refresh_selected_admission();
  };

  return (
    <>
      {update_enabled ? (
        <>
          <div className="row">
            <div className="col-3">
              <div className="mb-3">
                <Label className="form-label">Admission Date</Label>
                <CustomMUIDatePicker
                  name="admission_date"
                  type="simple"
                  setState={handleAdmissionDateChange}
                  value={admission_date}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="mb-3">
                <Label className="form-label">Weight</Label>
                <Input
                  name="weight"
                  className="form-control"
                  type="text"
                  placeholder="Weight"
                  value={weight}
                  onChange={onChangeValue}
                  defaultValue={selected_admission?.weight}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="mb-3">
                <Label className="form-label">Sleeping hrs</Label>
                <Input
                  name="sleeping_hours"
                  className="form-control"
                  type="text"
                  placeholder="Sleeping hrs"
                  value={sleeping_hours}
                  onChange={onChangeValue}
                  defaultValue={selected_admission?.sleeping_hours}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="mb-3">
                <Label className="form-label">BMI</Label>
                <Input
                  name="bmi"
                  className="form-control"
                  type="text"
                  placeholder="BMI"
                  value={bmi}
                  onChange={onChangeValue}
                  defaultValue={selected_admission?.bmi}
                />
              </div>
            </div>
          </div>
          <div className="col-12 text-center margin-top-20">
            <button
                className="btn btn-primary mr-2 medium-btn"
              onClick={(e) => onSubmitValue(e)}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary medium-btn ms-2"
              onClick={async (e) => {
                setupdate_enabled(false);
              }}
            >
              Cancel
            </button>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table">
                <thead className="table-light">
                  <tr>
                    <th>Admission Date</th>
                    <th>Weight</th>
                    <th>Sleeping hrs</th>
                    <th>BMI</th>
                    <th className="action">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{formatDateToLocaleDateString(selected_admission?.admission_date)}</td>
                    <td>{selected_admission?.weight}</td>
                    <td>{selected_admission?.sleeping_hours}</td>
                    <td>{selected_admission?.bmi}</td>
                    <td className="action">
                      <div className="gap-3">
                        <div className="text-success mr-2">
                          {user_info?.hospital_property?.id ===
                            selected_admission?.hospital_property?.id &&
                            selected_admission?.admitted &&
                            !selected_admission?.discharged && (
                              <i
                                className="mdi mdi-pencil font-size-16"
                                onClick={() => setupdate_enabled(true)}
                                style={{ cursor: "pointer" }}
                              ></i>
                            )}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PersonalDetails;
