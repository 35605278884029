import { patient_documents_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";

export const postPatientDocuments = async (values: any) => {
    try {
        const { data } = await axiosApi.post(
            patient_documents_routes.post_patient_documents,
            values
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const getPatientDocumentsByPatientAdmissionId = async (
    patient_admission_id: string,
) => {
    try {
        const { data } = await axiosApi.get(
            `${patient_documents_routes.get_patient_documents_by_patient_admission_id}?patient_admission_id=${patient_admission_id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const putPatientDocuments = async (values: any) => {
    try {
        const { data } = await axiosApi.put(
            patient_documents_routes.put_patient_documents,
            values
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const deletePatientDocuments = async (id: string) => {
    try {
        const { data } = await axiosApi.delete(
            `${patient_documents_routes.delete_patient_documents}/${id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};
