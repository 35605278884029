export const dg_styles = {
  boxShadow: 0,
  border: 0.01,
  color: "#a6b0cf",
  borderColor: "#32394e",
  scrollX: "auto",

  "& .MuiDataGrid-cell:hover": {
    color: "primary.main",
  },
  ".css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  ".css-levciy-MuiTablePagination-displayedRows": {
    paddingTop: 2,
    color: "#a6b0cf",
  },
  ".css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel": {
    paddingTop: 1.5,
    color: "#a6b0cf",
  },
  ".MuiDataGrid-columnHeader": {
    background: "#32394b",
    border: 0,
    textTransform: "capitalize",
  },
  ".MuiDataGrid-withBorderColor": {
    borderColor: "#32394b",
  },
  ".css-16c50h-MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select":
  {
    color: "#a6b0cf",
  },
};
