import { Country, IAddCoutryParams, IDeleteCountryParams } from "../types/countryTypes";
import { country_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";

interface IGetCountryListParams {
    per_page?: number,
    page_number?: number
}
export const getCountryList = async ({ page_number, per_page }: IGetCountryListParams) => {

    try {
        const params: { [key: string]: number } = {};

        if (page_number !== undefined) params['page_number'] = page_number;
        if (per_page !== undefined) params['per_page'] = per_page;

        const { data } = await axiosApi.get(country_routes.get_country_list, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}

export const postCoutry = async ({ country }: IAddCoutryParams) => {
    try {
        const { data } = await axiosApi.post(country_routes.post_country, { country });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const updateCountry = async ({ id, country }: Country) => {
    try {
        const { data } = await axiosApi.put(country_routes.put_country, { id, country });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const deleteCountry = async ({ id }: IDeleteCountryParams) => {
    try {
        const { data } = await axiosApi.delete(`${country_routes.delete_country}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data?.data;
    }
}