import { Field, ErrorMessage } from "formik";
import { FC, useEffect, useState } from "react";
import { getCityList } from "../../../redux/actions/cityAction";
import { ICitiesOptions } from "../../../redux/types/cityTypes";
import { getUserTypesList } from "../../../redux/actions/userTypesAction";
import { IUserTypeOptions } from "../../../redux/types/userTypeTypes";
import { all_hospitals_without_pagination } from "../../../redux/actions/hospitalAction";
import { hospital_property_by_hospital } from "../../../redux/actions/hospitalPropertyAction";
import { useSelector } from "react-redux";
import CustomMUIDatePicker from "../../../components/UI/CustomMUIDatePicker";
import { getDoctorSpecialityTypesList } from "../../../redux/actions/doctorSpecialityAction";
import { ErrorToast } from "../../../utils/toastNotifications";
import { Select, MenuItem, Checkbox, ListItemText } from "@mui/material";

export interface SpecialityTypes {
  id: string;
  name: string
}

const RegisterForm: FC<any> = ({ handleBlur, handleChange, user, values, setFieldValue }) => {
  const [all_cities, set_all_cities] = useState([]);
  const [all_user_types, set_all_user_types] = useState([]);
  const [all_hospitals, set_all_hospitals] = useState([]);
  const [all_hospitals_properties, set_all_hospitals_properties] = useState([]);
  const { user_info } = useSelector((state: any) => state.user);
  const field_should_show = !user ? true : false;
  const [page_number, setPage_number] = useState(1);
  const [per_page, setPer_page] = useState(0);
  const [specialityoptions, setSpecialityOptions] = useState<{ value: string; label: string }[]>([]);
  const [isDoctorUserType, setIsDoctorUserType] = useState(values?.reg_number ? true : false);
  

  const fetch_all_cities = async () => {
    const { body } = await getCityList({});
    if (body?.data) {
      const get_list_of_cities = body?.data?.map((e: ICitiesOptions) => ({
        city: e?.city,
        id: e?.id,
      }));
      set_all_cities(get_list_of_cities);
    } else {
      set_all_cities([]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const speciality = await getDoctorSpecialityTypesList({});
        if (speciality?.statusCode === 200) {
          const optionsAll = speciality?.body?.data?.map((spec: SpecialityTypes) => {
            return {
              value: spec?.id,
              label: spec?.name
            }
          })
          setSpecialityOptions(optionsAll)
        }
      } catch (error) {
        console.error("Error fetching doctor types:", error);

      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (user) {
      setFieldValue('is_admin', user.is_admin, false);
    }
  }, [user, setFieldValue]);

  const handleIsAdminChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setFieldValue('is_admin', isChecked, false);
  };

  const fetch_all_user_types = async () => {
    const { body } = await getUserTypesList({});
    if (body?.data) {
      const get_list_of_user_types = body?.data?.map((e: IUserTypeOptions) => ({
        user_type: e?.user_type,
        id: e?.id,
      }));
      set_all_user_types(get_list_of_user_types);
    } else {
      set_all_user_types([]);
    }
  };

  const fetch_hospitals = async () => {
    try {
      const request = await all_hospitals_without_pagination();
      if (request?.statusCode === 200) {
        set_all_hospitals(request?.body?.data);
      } else {
        set_all_hospitals([]);
      }
    } catch (error) {
      set_all_hospitals([]);
    }
  };

  const fetchHospitalProperties = async (selectedHospital: string) => {
    try {
      if (selectedHospital.trim() !== "") {
        const request = await hospital_property_by_hospital(selectedHospital);
        if (request?.statusCode === 200) {
          set_all_hospitals_properties(request?.body?.data);
        } else {
          set_all_hospitals_properties([]);
        }
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetch_all_cities();
    fetch_all_user_types();
    fetch_hospitals();
  }, []);

  useEffect(() => {
    if (values?.hospital_id?.trim() !== "") {
      fetchHospitalProperties(values?.hospital_id);
    }
  }, [values?.hospital_id]);


  return (
    <div className="row">
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Name</label>
          <Field
            name="name"
            className="form-control"
            type="text"
            placeholder="Enter Name"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage name="name" component="div" className="text-danger" />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Email</label>
          <Field
            name="email"
            className="form-control"
            type="text"
            placeholder="Enter Email"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage name="email" component="div" className="text-danger" />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Gender</label>
          <div className="form-check">
            <label className="form-check-label">
              <Field
                type="radio"
                name="gender"
                value="male"
                className="form-check-input"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              Male
            </label>
          </div>
        <div className="form-check">
            <label className="form-check-label">
              <Field
                type="radio"
                name="gender"
                value="female"
                className="form-check-input"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              Female
            </label>
          </div>
          <ErrorMessage name="gender" component="div" className="text-danger" />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Date of Birth</label>
          <CustomMUIDatePicker
            name="date_of_birth"
            type="formik"
            setState={setFieldValue}
            value={values?.date_of_birth}
            maxDate={new Date()}
          />
          <ErrorMessage
            name="date_of_birth"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">City</label>
          <Field
            name="city_id"
            as="select"
            className="form-control"
            onBlur={handleBlur}
            onChange={handleChange}
          >
            <option value="" label="Select a City" disabled />
            {all_cities.length > 0 ? (
              all_cities.map(({ city, id }: ICitiesOptions, index: number) => (
                <option key={index} value={id}>
                  {city}
                </option>
              ))
            ) : (
              <option value="" disabled>
                No Cities Found
              </option>
            )}
          </Field>
          <ErrorMessage
            name="city_id"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Address 1</label>
          <Field
            name="address_1"
            className="form-control"
            type="text"
            placeholder="Enter Address"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage
            name="address_1"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Address 2</label>
          <Field
            name="address_2"
            className="form-control"
            type="text"
            placeholder="Enter Address"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage
            name="address_2"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">User Type</label>
          <Field
            name="user_type_id"
            as="select"
            className="form-control"
            onBlur={handleBlur}
            onChange={(e: any) => {
              handleChange(e);
              const selectedUserType = e.target.value;
              const userType: any = all_user_types.find((item: any) => (item?.id === selectedUserType));
              if (userType && (userType.user_type).toLowerCase() === "doctor") setIsDoctorUserType(true);
              else setIsDoctorUserType(false)
            }}
          >
            <option value="" label="Select a User Type" disabled />
            {all_user_types.length > 0 ? (
              all_user_types.map(
                ({ user_type, id }: IUserTypeOptions, index: number) => (
                  <option key={index} value={id}>
                    {user_type}
                  </option>
                )
              )
            ) : (
              <option value="" disabled>
                No User Types Found
              </option>
            )}
          </Field>
          <ErrorMessage
            name="user_type_id"
            component="div"
            className="text-danger"
          />
        </div>
        {isDoctorUserType && (
          <div className="col-12">
            <div className="mb-3">
              <label className="form-label">Registration Number</label>
              <Field
                name="reg_number"
                className="form-control"
                type="text"
                value={values?.reg_number}
                placeholder="Enter Registration Number"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <ErrorMessage
                name="reg_number"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
        )}
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Service Start Date</label>
          <CustomMUIDatePicker
            name="service_start_date"
            type="formik"
            setState={setFieldValue}
            value={values?.service_start_date}

          />
          <ErrorMessage
            name="service_start_date"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Mobile</label>
          <Field
            name="mobile"
            className="form-control"
            type="text"
            placeholder="Enter Mobile"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage name="mobile" component="div" className="text-danger" />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Landline</label>
          <Field
            name="landline"
            className="form-control"
            type="text"
            placeholder="Enter Landline"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage
            name="landline"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">Degree</label>
          <Field
            name="degree"
            className="form-control"
            type="text"
            placeholder="Enter Degree"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage name="degree" component="div" className="text-danger" />
        </div>
        <div className="col-12"></div>
        {/* <div className="mb-3">
          <label className="form-label">Specialist</label>
          <Field
            name="specialist"
            className="form-control"
            type="text"
            placeholder="Enter Specialist"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage
            name="specialist"
            component="div"
            className="text-danger"
          />
        </div> */}
        <div className="mb-3 multi-select-control">
          <label htmlFor="specialty">Specialty</label>
          <Select
            className={`form-control paddingZero`}
            MenuProps={{ autoFocus: true }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white !important",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white !important",
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "0 !important",
              },
              "& :focus": {
                outline: "none !important",
                border: "0 !important",
                boxShadow: "none !important",
              },
              width: "100%",
              color: "#a6b0cf",
              fontWeight: 400,
              fontSize: "0.875rem",
            }}
            multiple
            placeholder="Select Speciality"
            labelId="speciality-label"
            id="speciality"
            value={Array.isArray(values.specialist) ? values.specialist : []}
            onChange={(event: { target: { value: any; }; }) => {
              const selectedIds = event.target.value;
              const selectedLabels = specialityoptions
                .filter((option) => selectedIds.includes(option.value))
                .map((option) => option.label);
              handleChange({
                target: {
                  name: "specialist",
                  value: selectedIds,
                },
              });
              setFieldValue("specialist", selectedIds);
            }}
            renderValue={(selected: string | string[]) => {
              const selectedLabels = specialityoptions
                .filter((option) => selected.includes(option.value))
                .map((option) => option.label);
              return selectedLabels.join(",");
            }}
          >
            {specialityoptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={values?.specialist?.includes(option.value)} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </div>
        <ErrorMessage
          name="specialist"
          component="div"
          className="text-danger"
        />
      </div>
      <div className="col-12">
        <div className="mb-3">
          <label className="form-label">General Availability Slots</label>
          <Field
            name="general_availability_slots"
            className="form-control"
            type="time"
            placeholder="Enter General Availability Slots"
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage
            name="general_availability_slots"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
      {field_should_show && (
        <div className="col-12">
          <div className="mb-3">
            <label className="form-label">Password</label>
            <Field
              name="password"
              className="form-control"
              type="password"
              placeholder="Enter Password"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-danger"
            />
          </div>
        </div>
      )}
      {user_info?.is_super_admin && (
        <>
          <div className="col-12">
            <div className="mb-3">
              <label className="form-label">Hospital</label>
              <Field
                name="hospital_id"
                as="select"
                className="form-control"
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option hidden>Select Hospital</option>
                {all_hospitals && all_hospitals?.length > 0 ? (
                  all_hospitals?.map(({ id, name }, index) => (
                    <option key={index} style={{ color: "#000" }} value={id}>
                      {name}
                    </option>
                  ))
                ) : (
                  <option style={{ color: "#000" }} value={undefined} disabled>
                    No Hospitals found
                  </option>
                )}
              </Field>
              <ErrorMessage
                name="hospital_id"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="mb-3">
              <label className="form-label">Hospital Property</label>
              <Field
                name="hospital_property_id"
                as="select"
                className="form-control"
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option hidden>Select Hospital Property</option>
                {all_hospitals_properties &&
                  all_hospitals_properties.length > 0 ? (
                  all_hospitals_properties.map(({ id, landmark }, index) => (
                    <option key={index} style={{ color: "#000" }} value={id}>
                      {landmark}
                    </option>
                  ))
                ) : (
                  <option style={{ color: "#000" }} disabled value={undefined}>
                    No Hospital Properties found
                  </option>
                )}
              </Field>
              <ErrorMessage
                name="hospital_property_id"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="is_super_admin"
                name="is_super_admin"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values?.is_super_admin}
              />
              <label className="form-check-label" style={{ marginLeft: "10px" }} htmlFor="is_super_admin">
                Is user is super admin?
              </label>
              <ErrorMessage
                name="is_super_admin"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
        </>
      )}

      {user_info?.is_admin || user_info?.is_super_admin && (
        <>
          <div className="col-12">
            <div className="mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="is_admin"
                name="is_admin"
                onChange={handleIsAdminChange}
                onBlur={handleBlur}
                checked={values?.is_admin}
              />
              <label className="form-check-label" style={{ marginLeft: "10px" }} htmlFor="is_admin">
                Is user is admin?
              </label>
              <ErrorMessage
                name="is_admin"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RegisterForm;

