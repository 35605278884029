import { patient_admission_line_tubes_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";

export const postPatientAdmissionLineTubes = async (values: any) => {
    try {
        const { data } = await axiosApi.post(
            patient_admission_line_tubes_routes.post_patient_admission_line_tubes,
            values
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const getPatientAdmissionLineTubesByPatientAdmissionId = async (
    patient_admission_id: string,
) => {
    try {
        const { data } = await axiosApi.get(
            `${patient_admission_line_tubes_routes.get_patient_line_tubes_by_patient_admission_id}?patient_admission_id=${patient_admission_id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const putPatientAdmissionLineTubes = async (values: any) => {
    try {
        const { data } = await axiosApi.put(
            patient_admission_line_tubes_routes.put_patient_admission_line_tubes,
            values
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const deletePatientAdmissionLineTubes = async (id: string) => {
    try {
        const { data } = await axiosApi.delete(
            `${patient_admission_line_tubes_routes.delete_patient_admission_line_tubes}/${id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};
