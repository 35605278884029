import React, { useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';

interface DischargeType {
  id: string;
  name: string;
  count: number;
}

interface Hospital1 {
  id: string;
  landmark: string;
  discharge_types: DischargeType[];
  under_treatment_count: number;
}

interface PatientTableProps {
  allDischargeTypes: Hospital1[];
  headers: any[];
}

export const PatientTable: React.FC<PatientTableProps> = ({ allDischargeTypes, headers }) => {
  const dischargeTypeHeaders = headers?.length > 0 && Array.from(
    new Set(
      headers.map(header => ({ name: header?.name, id: header?.id }))
    )
  ) || [];

  return (
    <Card className="card-common-dashboard mb-0">
      <CardBody>
        <table className="table table-hover fixed-table-top">
          <thead className="table-light">
            <tr>
              <th>Sr No.</th>
              <th>Hospital Property</th>
              {dischargeTypeHeaders.length > 0 && dischargeTypeHeaders?.map((header, index) => (
                <th key={index}>{header?.name}</th>
              ))
              }
              <th> Under Treatment</th>
            </tr>
          </thead>
          <tbody>
            {allDischargeTypes.length > 0 ? (
              allDischargeTypes?.map((hospital, index) => (
                <tr key={hospital?.id}>
                  <td>{index + 1}</td>
                  <td>{hospital?.landmark}</td>
                  {dischargeTypeHeaders && dischargeTypeHeaders.length > 0 && dischargeTypeHeaders?.map((header) => {
                    const dischargeType = hospital?.discharge_types?.find(dt => dt?.id === header?.id);
                    return (
                      <td key={header?.name}>
                        {dischargeType ? dischargeType.count : 0}
                      </td>
                    );
                  })}
                  <td>{hospital?.under_treatment_count}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={dischargeTypeHeaders.length + 3} className="text-center">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </CardBody>
    </Card >
  );
};
