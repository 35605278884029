import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DataGridTable from "../../../components/DataGrid/DataGridTable";
import {
    GridActionsCellItem,
    GridColDef,
    GridRowModel,
    GridCellParams,
} from "@mui/x-data-grid";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    IDeleteDoctorTypesParams,
    IUpdateDoctorTypesParams,
} from "../../../redux/types/doctorType";
import {
    deleteDoctorType,
    updateDoctorType,
    postDoctorType,
    getDoctorTypesList,
} from "../../../redux/actions/doctorAction";
import { findTextInString } from "../../../helpers/helperFunctions";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header/Header";
import { Checkbox, FormControl, ListItemText, MenuItem, Select } from "@mui/material";
import { getDoctorSpecialityTypesList } from "../../../redux/actions/doctorSpecialityAction";
import { use } from "echarts";
import DeleteConfirmationModal from "../../../components/DeletePopupComponent/DeletePopup";
export interface SpecialityTypes {
    id: string;
    name: string
}
const Doctor = () => {
    const navigate = useNavigate();
    const initialRows = useMemo(() => [
        {
            id: "1",
            name: "",
            email: "",
            phone_number: "",
            reg_number: "",
            specialty_ids: [],
        }], []);
    type Row = (typeof initialRows)[number];
    const [page_number, setPage_number] = useState(1);
    const [per_page, setPer_page] = useState(0);
    const [loadingOptions, setLoadingOptions] = useState(true);
    const [rows, setRows] = useState<any[]>(initialRows);
    const [rowId, setrowId] = useState(null);
    const [globalSearch, setGlobalSearch] = useState("");
    let latestRowsRef = useRef(rows);
    // const dataGridRef = useRef(null);
    // const selectRef = useRef<HTMLSelectElement>(null);
    const handlePageChange = (params: any) => setPage_number(params.page);
    const handlePageSizeChange = (params: any) => setPer_page(params.pageSize);
    const processRowUpdate = (newRow: GridRowModel) => newRow;
    const [specialityoptions, setSpecialityOptions] = useState<{ value: string; label: string }[]>([]);
    const onCellEditCommit = (params: any) => setrowId(params?.id);
    const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
 
    // const addRow = () => {
    //     if (rows?.some((row) => row?.id === initialRows[0]?.id)) {
    //         ErrorToast("Please save last added row.");
    //         return rows;
    //     }
    //     setRows((prevRows) => [{
    //         id: "1",
    //         name: "",
    //         email: "",
    //         phone_number: "",
    //         reg_number: "",
    //         specialty_ids: [],
    //     }, ...prevRows]);
    // };
 
    const addRow = () => {
        ErrorToast("Please Add Doctor in Users section in Application");
    };
 
    /* UPDATE - ADD Doctor*/
    const handleUpdateDoctor = useCallback(
        async ({
            id,
            name,
            email,
            phone_number,
            reg_number,
            specialty_ids }: IUpdateDoctorTypesParams) => {
            try {
                if (name.trim() === "") return ErrorToast("Doctor is required field!");
                let response;
                if (id === initialRows[0]?.id)
                    response = await postDoctorType(
                        {
                            name,
                            email,
                            phone_number,
                            reg_number,
                            specialty_ids,
                        });
                else response = await updateDoctorType({
                    id,
                    name,
                    email,
                    phone_number,
                    reg_number,
                    specialty_ids,
                });
 
                if (response?.statusCode === 200) {
                    SuccessToast(response?.message);
                    const { body } = await getDoctorTypesList({
                        per_page: per_page,
                        page_number: page_number,
                    });
                    if (body) setRows(body?.data);
                    else setRows([]);
                    setrowId(null);
                } else if (response?.statusCode === 400)
                    ErrorToast(response?.errors[0]?.msg);
                else ErrorToast("Unknown Error Occurred!");
            } catch (error: any) {
                ErrorToast(error?.response?.data?.errors[0]?.msg);
            }
        },
        [initialRows, per_page, page_number]
    );
    // const focusSelect = () => {
    //     if (selectRef.current) {
    //         selectRef.current.focus();
    //     }
    // };
    useEffect(() => {
        const getDoctors = async () => {
            try {
                setLoadingOptions(true);
                const specialityResponse = await getDoctorSpecialityTypesList({});
                if (specialityResponse?.statusCode === 200) {
                    const optionsAll = specialityResponse?.body?.data?.map((spec: SpecialityTypes) => ({
                        value: spec?.id,
                        label: spec?.name,
                    })) || [];
                    setSpecialityOptions(optionsAll);
                }
 
                const doctorsResponse = await getDoctorTypesList({
                    per_page: per_page,
                    page_number: page_number,
                });
 
                if (doctorsResponse?.body) {
                    setRows(doctorsResponse.body.data);
                } else {
                    setRows([]);
                }
            } catch (error: any) {
                ErrorToast(error?.response?.data?.errors[0]?.msg || "An error occurred.");
            } finally {
                setLoadingOptions(false);
                // focusSelect();
            }
        };
 
        getDoctors();
    }, [page_number, per_page]);
 
    useEffect(() => {
        latestRowsRef.current = [...rows]
        console.log(...rows)
    }, [rows])
    /* DELETE Doctor */
    const handleDeleteDoctor = useCallback(
        async ({ id }: IDeleteDoctorTypesParams) => {
            try {
                if (id === initialRows[0]?.id)
                    return ErrorToast("Record is not saved yet!");
                const response = await deleteDoctorType({ id });
 
                if (response?.statusCode === 200) {
                    SuccessToast(response?.message);
                    const { body } = await getDoctorTypesList({
                        per_page: per_page,
                        page_number: page_number,
                    });
                    if (body) setRows(body?.data);
                    else setRows([]);
                } else if (response?.statusCode === 400)
                    ErrorToast(response?.errors[0]?.msg);
                else ErrorToast("Unknown Error Occurred!");
            } catch (error: any) {
                ErrorToast(error?.response?.data?.errors[0]?.msg);
            }
        },
        [initialRows, per_page, page_number]
    );
 
    const handleDeleteConfirmation = useCallback(() => {
        if (deleteRowId) {
            handleDeleteDoctor({ id: deleteRowId });
            setDeleteRowId(null);
            setIsDeleteModalOpen(false);
        }
    }, [deleteRowId, handleDeleteDoctor]);
 
    const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);
 
    const columns = useMemo(() => [
        {
            field: "name",
            headerName: "Name",
            type: "TextField",
            flex: 1,
            filterable: true,
            sortable: true,
            editable: true,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "email",
            headerName: "Email",
            type: "TextField",
            flex: 1,
            filterable: true,
            sortable: true,
            editable: false,
            align: "left",
            headerAlign: "left",
            renderCell: (params: GridCellParams) => {
                const handleDoubleClick = () => {
                    ErrorToast("Please Change Email in Users section");
                };
       
                return (
                    <div onDoubleClick={handleDoubleClick}>
                        {params.value as string}
                    </div>
                );
            },
        },
        {
            field: "phone_number",
            headerName: "Phone Number",
            type: "TextField",
            flex: 1,
            filterable: true,
            sortable: true,
            editable: true,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "reg_number",
            headerName: "Reg Number",
            type: "TextField",
            flex: 1,
            filterable: true,
            sortable: true,
            editable: true,
            align: "left",
            headerAlign: "left",
        },
        {
            field: 'specialty_ids',
            headerName: 'Speciality',
            flex: 1,
            sortable: true,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params: { value: any; row: { id: string; }; }) => {
                const handleChange = (selectedSpecialtyIds: string[], rowId: string) => {
                    const updatedRows = [...latestRowsRef.current].map((row) => {
                        if (row.id === rowId) {
                            return { ...params.row, specialty_ids: selectedSpecialtyIds };
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    onCellEditCommit(params);
                };
 
                if (loadingOptions) {
                    return <div>Loading options...</div>;
                }
 
                return (
                    <div style={{ width: "100%" }}>
                        <Select
                            // ref={selectRef}
                            MenuProps={{
                                autoFocus: true,
                            }}
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderWidth: "0 !important",
                                },
                                "& :focus": {
                                    outline: "none !important",
                                    border: "0 !important",
                                    boxShadow: "none !important",
                                },
                                width: "100%",
                                color: "#a6b0cf",
                                fontWeight: 400,
                                fontSize: "0.875rem",
                            }}
                            multiple
                            placeholder="Select Speciality"
                            labelId="speciality-label"
                            id="speciality"
                            value={params.value || []}
                            onChange={(event) => handleChange(event.target.value, params?.row?.id)}
                        >
                            {specialityoptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    <Checkbox checked={params?.value?.includes(option.value)} />
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                );
            },
        },
        {
            field: "actions",
            headerName: "actions",
            type: "actions",
            width: 150,
            getActions: (params: { id: string | null; row: { id: { toString: () => any; }; name: any; email: any; phone_number: any; reg_number: any; specialty_ids: any; }; }) => [
                <GridActionsCellItem
                    icon={<SaveIcon />}
                    label="Save"
                    color={"primary"}
                    disabled={params?.id !== rowId}
                    onClick={() =>
                        handleUpdateDoctor({
                            id: params.row.id.toString(),
                            name: params.row.name,
                            email: params.row.email,
                            phone_number: params.row.phone_number,
                            reg_number: params.row.reg_number,
                            specialty_ids: params.row.specialty_ids,
                        })
                    }
                />,
 
                // <GridActionsCellItem
                //     icon={<DeleteIcon />}
                //     label="Delete"
                //     disabled={params?.id === initialRows[0]?.id}
                //     color="error"
                //     onClick={() => {
                //         setDeleteRowId(params.row.id.toString());
                //         toggleDeleteModal();
                //       }}
                // />,
            ],
        },
    ], [loadingOptions, rowId, toggleDeleteModal]);
 
 
    const filteredRows = useMemo(
        () => rows?.filter(({ name }) => findTextInString(name, globalSearch)),
        [rows, globalSearch]
    );
 
 
    return (
        <>
            <Header HeaderName="Master Pages" />
            {/* <Sidebar /> */}
            <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                toggle={toggleDeleteModal}
                pageName="doctor"
                onConfirm={handleDeleteConfirmation}
            />
            <div className="row">
                <div className="col-12 col">
                    <div className="page-title-box master-title">
                        <h4 className="mb-sm-0 font-size-18">Doctor</h4>
                    </div>
                </div>
                <div className="col-12">                
                    <DataGridTable
                        columns={columns}
                        rows={filteredRows}
                        processRowUpdate={processRowUpdate}
                        pageSizeChange={handlePageSizeChange}
                        pageChange={handlePageChange}
                        addRow={addRow}
                        onCellEditCommit={onCellEditCommit}
                        setGlobalSearch={setGlobalSearch}
                    />
                </div>
            </div>
        </>
    );
};
 
export default Doctor;
 