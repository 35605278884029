import { doctor_speciality_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IAddDoctorSpecialityTypesParams, IDeleteDoctorSpecialityTypesParams, IGetDoctorSpecialityTypesParams, IUpdateDoctorSpecialityTypesParams } from "../types/doctorSpecialityTypes";


export const getDoctorSpecialityTypesList = async ({ page_number, per_page }: IGetDoctorSpecialityTypesParams) => {

    try {
        const params: { [key: string]: number } = {};

        if (page_number !== undefined) params['page_number'] = page_number;
        if (per_page !== undefined) params['per_page'] = per_page;

        const { data } = await axiosApi.get(doctor_speciality_routes.get_doctor_speciality_types_list, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}


export const postDoctorSpecialityType = async ({ name }: IAddDoctorSpecialityTypesParams) => {
    try {
        const { data } = await axiosApi.post(doctor_speciality_routes.post_doctor_speciality_type, { name });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const updateDoctorSpecialityType = async ({ id, name }: IUpdateDoctorSpecialityTypesParams) => {
    try {
        const { data } = await axiosApi.put(doctor_speciality_routes.put_doctor_speciality_type, { id, name });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const deleteDoctorSpecialityType = async ({ id }: IDeleteDoctorSpecialityTypesParams) => {
    try {
        const { data } = await axiosApi.delete(`${doctor_speciality_routes.delete_doctor_speciality_type}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data?.data;
    }
}