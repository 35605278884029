import { patient_admission_instruction_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IGetInstructionsByHospitalPropertyParams, IPostInstructionByPatientId, ISingleInstruction } from "../types/patientAdmissionInstructionsTypes";

export const postPatientAdmissionInstructions = async (values: any) => {
    try {
        const { data } = await axiosApi.post(patient_admission_instruction_routes.post_patient_admission_instruction, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const getPatientAdmissionInstructionsByPatientId = async (patient_id: string, is_completed: boolean) => {
    try {
        const { data } = await axiosApi.get(`${patient_admission_instruction_routes.get_instructions_by_patient_id}?patient_id=${patient_id}&is_completed=${is_completed}`);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const getPatientAdmissionInstructionsByHospitalPropertyId = async ({ sort_order, start_date, end_date, hospital_property_id, is_completed }: IGetInstructionsByHospitalPropertyParams) => {
    try {
        const params: { [key: string]: string | boolean } = {};

        if (sort_order !== undefined) params['sort_order'] = sort_order;
        if (hospital_property_id !== undefined) params['hospital_property_id'] = hospital_property_id;
        if (start_date !== undefined) params['start_date'] = start_date;
        if (end_date !== undefined) params['end_date'] = end_date;
        if (is_completed !== undefined) params['is_completed'] = is_completed;

        const queryString = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');

        const { data } = await axiosApi.get(`${patient_admission_instruction_routes.get_instructions_by_hospital_property_id}?${queryString}`);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const putPatientAdmissionInstructions = async (values: IPostInstructionByPatientId) => {
    try {
        const { data } = await axiosApi.put(patient_admission_instruction_routes.put_patient_admission_instruction, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const deletePatientAdmissionInstructions = async (id: string) => {
    try {
        const { data } = await axiosApi.delete(`${patient_admission_instruction_routes.delete_patient_admission_instruction}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};
