import { patient_admission_routes, patient_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IPatientAdmissionParams, IPatientParams } from "../types/patientTypes";
import { hospital_by_id } from "./hospitalAction";

export const postPatient = async (values: IPatientParams) => {
  try {
    const { data } = await axiosApi.post(patient_routes.post_patient, values);
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const updatePatient = async (values: any) => {
  try {
    const { data } = await axiosApi.put(patient_routes.update_patient, values);
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const all_patients_without_pagination = async (
  start_date = "",
  end_date = "",
  sort_order = ""
) => {
  try {
    let url = patient_routes.all_patient;

    const startDateTrimmed = start_date?.trim();
    const endDateTrimmed = end_date?.trim();

    if (startDateTrimmed && endDateTrimmed)
      url += `?start_date=${startDateTrimmed}&end_date=${endDateTrimmed}`;

    if (sort_order)
      url +=
        startDateTrimmed && endDateTrimmed
          ? `&sort_order=${sort_order}`
          : `?sort_order=${sort_order}`;

    const { data } = await axiosApi.get(url);
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const all_patients_by_property_id = async (property_id: string) => {
  try {
    let { data } = await axiosApi.get(
      `${patient_routes.all_patient}?property_id=${property_id}`
    );

    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const all_admitted_patients_by_hospital_property_id = async (
  property_id: string | undefined
) => {
  try {
    let { data } = await axiosApi.get(
      `${patient_routes.all_patient}?current_admit_hospital_property_id=${property_id}`
    );

    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const getPatientById = async (patient_id: string) => {
  try {
    const { data } = await axiosApi.get(
      `${patient_routes?.get_patient_by_id}/${patient_id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const searchPatient = async (searchBy: string, search: string) => {
  try {
    const { data } = await axiosApi.get(
      `${patient_routes.search_patient}?${searchBy}=${search}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const putPatientPropertyAccess = async (
  patient_id: string,
  property_access: string[]
) => {
  try {
    const values = { id: patient_id, property_access };
    const { data } = await axiosApi.put(
      patient_routes.put_patient_property_access,
      values
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const postPatientAdmission = async (values: IPatientAdmissionParams) => {
  try {
    const { data } = await axiosApi.post(
      patient_admission_routes.post_patient_admission,
      values
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const getAdmissionsListByPatientId = async (patient_id: string) => {
  try {
    const { data } = await axiosApi.get(
      `${patient_routes?.all_admission_by_patient_id}?patient_id=${patient_id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const getAdmissionById = async (id: string) => {
  try {
    const { data } = await axiosApi.get(
      `${patient_admission_routes.patient_admission_by_id}?id=${id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const getAdmittedPatientsByHospitalProperty = async (
  hospital_property_id: string | undefined
) => {
  try {
    const { data } = await axiosApi.get(
      `${patient_admission_routes.patient_admission_by_hospital_property}?hospital_property_id=${hospital_property_id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const getPatientsByHospital = async (hospital_id: string, country_id?: string,
  state_id?: string,
  city_id?: string) => {
  try {
    const { data } = await axiosApi.get(
      `${patient_admission_routes.patient_admission_by_hospital_id}?hospital_id=${hospital_id}` +
      (country_id ? `&country_id=${country_id}` : '') +
      (state_id ? `&state_id=${state_id}` : '') +
      (city_id ? `&city_id=${city_id}` : '')
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
}
export const getPatientsByHospitalForCounts = async (
  hospital_property_id: string | undefined,
  country_id?: string,
  city_id?: string,
  state_id?: string
) => {
  try {
    const { data } = await axiosApi.get(
      `${patient_admission_routes.patient_admission_by_hospital_count}?hospital_id=${hospital_property_id}` +
      (country_id ? `&country_id=${country_id}` : '') +
      (state_id ? `&state_id=${state_id}` : '') +
      (city_id ? `&city_id=${city_id}` : '')
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const getPatientsByHospitalProperty = async (
  hospital_property_id: string | undefined
) => {
  try {
    const { data } = await axiosApi.get(
      `${patient_admission_routes.patients_by_hospital_property}?hospital_property_id=${hospital_property_id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};


export const getPatientsByHospitalPropertyForChart = async (
  hospital_property_id: string,
  fromDate: string,
  toDate: string
) => {
  try {
    const { data } = await axiosApi.get(
      `${patient_admission_routes.patients_by_hospital_property_forchart}?hospital_property_id=${hospital_property_id}&fromDate=${fromDate}&toDate=${toDate}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const getPatientsByHospitalForChart = async (
  hospital_id: string,
  fromDate: string,
  toDate: string,
  country_id?: string,
  city_id?: string,
  state_id?: string
) => {
  try {
    const { data } = await axiosApi.get(
      `${patient_admission_routes.patients_by_hospital_forchart}?hospital_id=${hospital_id}&fromDate=${fromDate}&toDate=${toDate}` +
      (country_id ? `&country_id=${country_id}` : '') +
      (city_id ? `&city_id=${city_id}` : '') +
      (state_id ? `&state_id=${state_id}` : '')
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const putPatientAdmission = async (values: any) => {
  try {
    const { data } = await axiosApi.put(
      patient_admission_routes.put_patient_admission,
      values
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
