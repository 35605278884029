import { LinearProgress, Select, MenuItem } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowModel,
} from "@mui/x-data-grid";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import NoRowsOverlay from "../../../../components/DataGrid/NoRowsOverlay";
import { ErrorToast, SuccessToast } from "../../../../utils/toastNotifications";
import { grid_styles } from "../grid_styles";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { IDiseaseOptions } from "../../CreatePatient";
import { getAdmissionById } from "../../../../redux/actions/patientAction";
import { getProvisionalDiagnosisTypesList } from "../../../../redux/actions/provisionalDiagnosisTypeAction";
import {
  deletePatientAdmissionProvisionalDiagnosis,
  getPatientAdmissionProvisionalDiagnosisByPatientAdmissionId,
  postPatientAdmissionProvisionalDiagnosis,
  putPatientAdmissionProvisionalDiagnosis,
} from "../../../../redux/actions/patientAdmissionProvisionalDiagnosis";
import { IPatientAdmissionProvisionalDiagnosisTypes } from "../../../../redux/types/patientAdmissionProvisionalDiagnosisTypes";
import DeleteConfirmationModal from "../../../../components/DeletePopupComponent/DeletePopup";

const ProvisionalDiagnosis: React.FC<any> = ({
  selected_admission,
  setselected_admission,
}) => {
  const initialRows = useMemo(
    () => [
      {
        id: "1",
        diagnosis_type: { id: "", name: " " },
        note: "",
        // document_name: "",
        // document_type: "pdf",
      },
    ],
    []
  );

  type Row = (typeof initialRows)[number];
  const { user_info } = useSelector((state: any) => state?.user);

  const [rows, setRows] = useState<any[]>(initialRows);
  const [rowId, setrowId] = useState(null);
  const [diagnosis_types, set_diagnosis_types] = useState([]);
  const isCellEditable = true;
  const handleRowUpdateError = (error: any) => { };

  const processRowUpdate = (newRow: GridRowModel) => newRow;
  const onCellEditCommit = (params: any) => setrowId(params?.id);
  // State for delete confirmation modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);

  const addRow = () => {
    if (rows?.some((row) => row?.id === initialRows[0]?.id)) {
      ErrorToast("Please save last added row.");
      return rows;
    }
    setRows((prevRows) => [
      {
        id: "1",
        diagnosis_type: { id: "", name: " " },
        note: "",
        // document_name: "",
        // document_type: "pdf",
      },
      ...prevRows,
    ]);
  };

  const getProvisionalDiagnosiss = useCallback(async () => {
    if (selected_admission !== null) {
      const { body } = await getProvisionalDiagnosisTypesList({});
      if (body) set_diagnosis_types(body?.data);
      else set_diagnosis_types([]);
    }
  }, [selected_admission]);

  useEffect(() => {
    getProvisionalDiagnosiss();
  }, [getProvisionalDiagnosiss]);

  const getProvisionalDiagnosisByPatientAdmissionId = useCallback(async () => {
    if (selected_admission !== null) {
      const request =
        await getPatientAdmissionProvisionalDiagnosisByPatientAdmissionId(
          selected_admission?.id
        );
      if (request?.statusCode === 200) setRows(request?.body?.data);
      else setRows([]);
    }
  }, [selected_admission]);

  useEffect(() => {
    getProvisionalDiagnosisByPatientAdmissionId();
  }, [getProvisionalDiagnosisByPatientAdmissionId]);

  const refresh_selected_admission = useCallback(async () => {
    let request = await getAdmissionById(selected_admission?.id);

    if (request?.statusCode === 200) {
      setselected_admission(request?.body);
    } else {
      setselected_admission(selected_admission);
    }
  }, [selected_admission, setselected_admission]);

  const handleUpdateDiagnosisExamination = useCallback(
    async ({
      id,
      diagnosis_type_id,
      note,
      // document_type,
      // document_name,
    }: IPatientAdmissionProvisionalDiagnosisTypes) => {
      try {
        if (!selected_admission?.id?.trim())
          throw new Error("Please select a hospital first!");
        if (!diagnosis_type_id?.trim())
          throw new Error("Examination type is a required field!");
        if (!note?.trim()) throw new Error("Detail is a required field!");
        // if (!document_name?.trim())
        //   throw new Error("Document Name is a required field!");

        const isInitialRowId = id === initialRows[0]?.id;

        const values = {
          patient_admission_id: selected_admission?.id,
          diagnosis_type_id,
          note,
          // document_name,
          // document_type,
          id: isInitialRowId ? undefined : id,
        };

        const response = isInitialRowId
          ? await postPatientAdmissionProvisionalDiagnosis(values)
          : await putPatientAdmissionProvisionalDiagnosis(values);

        if (response?.statusCode === 200) {
          await refresh_selected_admission();
          SuccessToast(
            `Patient Admission Provisional Diagnosis ${isInitialRowId ? "Added" : "Updated"
            } Successfully!`
          );
          setrowId(null);
        } else if (response?.statusCode === 400) {
          ErrorToast(response?.errors[0]?.msg);
        } else {
          ErrorToast("Unknown Error Occurred!");
        }
      } catch (error: any) {
        ErrorToast(error?.message);
      }
    },
    [initialRows, selected_admission?.id, refresh_selected_admission]
  );
  const handleDeleteDiagnosisExamination = useCallback(
    async (id: string) => {
      try {
        const response = await deletePatientAdmissionProvisionalDiagnosis(id);
        if (response?.statusCode === 200) {
          await refresh_selected_admission();
          SuccessToast(
            "Patient Admission Provisional Diagnosis Deleted Successfully!"
          );
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.message);
      }
    },
    [refresh_selected_admission]
  );

  const openDeleteModal = (id: string) => {
    setCurrentDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setCurrentDeleteId(null);
    setIsDeleteModalOpen(false);
  };

  const confirmDelete = () => {
    if (currentDeleteId) {
      handleDeleteDiagnosisExamination(currentDeleteId);
      closeDeleteModal();
    }
  };

  const columns: any = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: "diagnosis_type",
        flex: 1,
        headerName: "Diagnosis",
        filterable: true,
        sortable: true,
        editable: !selected_admission?.discharged,
        align: "left",
        headerAlign: "left",
        renderCell: (params: any) => {
          const handleChange = (selectedDiagnosisTypeId: string) => {
            setrowId(params.id);
            const selectedDiagnosisType = diagnosis_types?.find(
              ({ id }) => id === selectedDiagnosisTypeId
            );
            if (!selectedDiagnosisType) return;

            const { id, name } = selectedDiagnosisType;

            const rowIndex = rows?.findIndex((row) => row?.id === params?.id);
            if (rowIndex === -1) return;

            const isParamsIdOne = params.id === 1;
            const row = params.row || {};

            const updatedRow = {
              ...rows[rowIndex],
              diagnosis_type: { id, name },
              document_name:
                rows[rowIndex]?.document_name?.trim() !== "" && isParamsIdOne
                  ? rows[rowIndex]?.document_name
                  : row?.document_name,
              document_type:
                rows[rowIndex]?.document_type?.trim() !== "" && isParamsIdOne
                  ? rows[rowIndex]?.document_type
                  : row?.document_type,
              note:
                rows[rowIndex]?.note?.trim() !== "" && isParamsIdOne
                  ? rows[rowIndex]?.note
                  : row?.note,
            };

            const updatedRows = [
              ...rows.slice(0, rowIndex),
              updatedRow,
              ...rows.slice(rowIndex + 1),
            ];
            setRows(updatedRows);
          };
          return (
            <div style={{ width: "100%" }}>
              <Select
                MenuProps={{ autoFocus: false }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: "0 !important",
                  },
                  "& :focus": {
                    outline: "none !important",
                    border: "0 !important",
                    boxShadow: "none !important",
                  },
                  width: "100%",
                  color: "#a6b0cf",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                }}
                renderValue={() =>
                  params?.value?.name?.trim() === ""
                    ? "Select Diagnosis Type"
                    : params?.value?.name
                }
                value={params?.value?.name || params?.row?.diagnosis_type?.name}
                onChange={(event) => handleChange(event.target.value)}
                disabled={
                  !(
                    true ||
                    user_info?.hospital_property?.id ===
                    selected_admission?.hospital_property?.id
                  ) ||
                  !selected_admission?.admitted ||
                  selected_admission?.discharged
                }
              >
                <MenuItem value=" " disabled>
                  <em>Select Provisional Diagnosis Type</em>
                </MenuItem>
                {diagnosis_types && diagnosis_types?.length > 0 ? (
                  diagnosis_types?.map(({ name, id }: IDiseaseOptions) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Diagnosis Types Found</MenuItem>
                )}
              </Select>
            </div>
          );
        },
      },
      {
        field: "note",
        headerName: "Detail",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: !selected_admission?.discharged,
        align: "left",
        headerAlign: "left",
      },
      // {
      //   field: "document_name",
      //   type: "TextField",
      //   flex: 1,
      //   headerName: "Document Name",
      //   filterable: true,
      //   sortable: true,
      //   editable: isCellEditable,
      //   align: "left",
      //   headerAlign: "left",
      // },
      // {
      //   field: "document",
      //   type: "TextField",
      //   flex: 1,
      //   filterable: true,
      //   sortable: true,
      //   editable: isCellEditable,
      //   align: "left",
      //   headerAlign: "left",
      //   renderCell: (params: any) => {
      //     const handleFileChange = (
      //       event: React.ChangeEvent<HTMLInputElement>
      //     ) => {
      //       event.target.value = "";
      //     };

      //     return (
      //       <div style={{ width: "100%" }}>
      //         <input
      //           type="file"
      //           accept="image/*"
      //           style={{ display: "none" }}
      //           id={`fileUpload_${params.id}`}
      //           onChange={handleFileChange}
      //         />
      //         <label htmlFor={`fileUpload_${params.id}`}>Upload File</label>
      //       </div>
      //     );
      //   },
      // },
      {
        field: "actions",
        headerName: "actions",
        type: "actions",
        width: 150,
        getActions:
          user_info?.hospital_property?.id ===
            selected_admission?.hospital_property?.id &&
            selected_admission?.admitted &&
            !selected_admission?.discharged
            ? (params: any) => [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                color={"primary"}
                disabled={params?.id !== rowId}
                onClick={() =>
                  handleUpdateDiagnosisExamination({
                    id: params.row.id.toString(),
                    note: params.row.note,
                    // document_name: params.row.document_name,
                    // document_type: params.row.document_type,
                    diagnosis_type_id: params.row.diagnosis_type?.id,
                  })
                }
              />,

              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                color="error"
                disabled={params?.id === initialRows[0]?.id}
                onClick={() =>
                  openDeleteModal(
                    params?.row?.id?.toString(),
                  )
                }
              />,
            ]
            : (params: any) => [],
      },
    ],
    [
      diagnosis_types,
      openDeleteModal,
      handleUpdateDiagnosisExamination,
      initialRows,
      isCellEditable,
      rowId,
      rows,
      selected_admission?.admitted,
      selected_admission?.discharged,
      selected_admission?.hospital_property?.id,
      user_info?.hospital_property?.id,
    ]
  );

  return (
    <>
      <div className="add-row-btn">
        {(true || user_info?.hospital_property?.id ===
          selected_admission?.hospital_property?.id) &&
          selected_admission?.admitted &&
          !selected_admission?.discharged && (
            <button
              className="btn btn-primary mb-3"
              type="button"
              onClick={() => addRow()}
            >
              Add Row
            </button>
          )}
      </div>
      <div className="row">
        <div className="col-12 structure-box">
          <div className="inner-con-box" style={{ height: 250, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight={true}
              editMode="cell"
              rowHeight={35}
              columnHeaderHeight={35}
              disableRowSelectionOnClick={true}
              pagination={true}
              processRowUpdate={processRowUpdate}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 5, page: 0 },
                },
              }}
              slots={{
                noRowsOverlay: NoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              paginationMode="client"
              sx={grid_styles}
              onCellEditStart={onCellEditCommit}
              onProcessRowUpdateError={handleRowUpdateError}
            />
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={closeDeleteModal}
        onConfirm={confirmDelete}
        pageName="diagnosis"
      />
    </>
  );
};

export default ProvisionalDiagnosis;
