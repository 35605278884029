import axiosApi from "../../api/apiHelper";
import { patient_admission_provisional_diagnosis_routes } from "../api_path";
import { IPatientAdmissionProvisionalDiagnosisTypes } from "../types/patientAdmissionProvisionalDiagnosisTypes";

export const postPatientAdmissionProvisionalDiagnosis = async (values: IPatientAdmissionProvisionalDiagnosisTypes) => {
    try {
        const { data } = await axiosApi.post(patient_admission_provisional_diagnosis_routes.post_patient_admission_provisonal_diagnosis, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const getPatientAdmissionProvisionalDiagnosisByPatientAdmissionId = async (
    patient_admission_id: string,
) => {
    try {
        const { data } = await axiosApi.get(`${patient_admission_provisional_diagnosis_routes.get_patient_provisonal_diagnosis_by_patient_admission_id}?patient_admission_id=${patient_admission_id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const putPatientAdmissionProvisionalDiagnosis = async (values: any) => {
    try {
        const { data } = await axiosApi.put(patient_admission_provisional_diagnosis_routes.put_patient_admission_provisonal_diagnosis, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const deletePatientAdmissionProvisionalDiagnosis = async (id: string) => {
    try {
        const { data } = await axiosApi.delete(`${patient_admission_provisional_diagnosis_routes.delete_patient_admission_provisonal_diagnosis}/${id}`)
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};
