export const verbal_scale = [
  { id: "101", name: "No Pain" },
  { id: "102", name: "Mild Pain" },
  { id: "103", name: "Moderate Pain" },
  { id: "104", name: "Severe Pain" },
  { id: "105", name: "Worst Possible" },
];

export const sleep = [
  { id: "201", name: "No Pain" },
  { id: "202", name: "Independent" },
  { id: "203", name: "Sound Sleep" },
];

export const stairs = [
  { id: "301", name: "Unable" },
  { id: "302", name: "Needs Help (verbal physical carrying aid)" },
  { id: "303", name: "Independent" },
];

export const mobility = [
  { id: "401", name: "Immobile" },
  { id: "402", name: "Wheelchair independent, including corners" },
  { id: "403", name: "Independent (but may use aid like stick)" },
  { id: "404", name: "Walk with help of one person (verbal or physical)" },
];

export const family_relation = [
  { id: "501", name: "Maternity Site" },
  { id: "502", name: "Paternity Site" },
  { id: "503", name: "Spouse" },
  { id: "504", name: "Sibling" },
];

export const substances_types = [
  { id: "601", name: "Alcohol" },
  { id: "602", name: "Tobacco" },
  { id: "603", name: "Drugs" },
  { id: "604", name: "Smoke" },
];

export const all_months_name = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const years = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];

export const ERROR_MESSAGES = {
  noHospitalProperty: "Please select hospital property",
  noFiltersSelected: "Please select any of the filters",
  noRecordsFound: "No Records Found!",
  missingDates: "Please select both of the dates!",
  emptyDates: "Start Date and End Date cannot be empty.",
  endDateLessThanStartDate: "End Date cannot be less than Start Date.",
};
