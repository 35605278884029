import React from "react";
import logo1 from "../../assets/images/logo-1.png";
import SvgFilesIcon from "../SvgFiles/SvgFiles";
 
const HospitalPropertyGridView: React.FC<any> = ({ hospital_property, handleEditHospitalProperty, handleDeleteHospitalProperty }) => {
 
  return (
    <>
      <td>
        <div className="patient-card-title hospital-card-title ">
          {/* <div className="left-logo">
            <img src={logo1} alt="hello"></img>
          </div> */}
          <div className="icon-box">
            <SvgFilesIcon iconname="hospital" />
          </div>
          <div className="right-detail">
            {hospital_property?.landmark}
            <p className="text-muted">
            {hospital_property?.address || " "} {hospital_property?.city?.city || " "} {hospital_property?.state?.state || " "} {hospital_property?.country?.country|| " "} {hospital_property?.zipcode|| " "}
            </p>
          </div>
        </div>
      </td>
      <td> {hospital_property?.contact_person}</td>
      <td> {hospital_property?.mobile}</td>
      <td>
        <div className="discharge-box">
          <SvgFilesIcon iconname="active" />
          <p>Active</p>
        </div>
      </td>
      <td className="">
        <div className="user-setting-box">
          <div className="edit-box" onClick={(e) => handleEditHospitalProperty(e, hospital_property)}>
            <SvgFilesIcon iconname="edit" />
          </div>
          <div className="delete-box" onClick={(e) => handleDeleteHospitalProperty(e, hospital_property)}>
            <SvgFilesIcon iconname="delete" />
          </div>
        </div>
      </td>
    </>
  );
};
 
export default HospitalPropertyGridView;