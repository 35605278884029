// reportHeaderTypes.ts
export enum ActionTypes {
    TOGGLE_HEADER_STATE = "toggle_header_state",
    SET_CURRENT_REPORT_ACTIVE_TAB = "set_current_report_active_tab",
    REMOVE_TAB = "remove_tab" // New action type for removing a tab
}

export interface ToggleHeaderStateAction {
    type: ActionTypes.TOGGLE_HEADER_STATE;
}

export interface SetCurrentActiveTabAction {
    type: ActionTypes.SET_CURRENT_REPORT_ACTIVE_TAB;
    payload: string;
}

export interface RemoveTabAction {
    type: ActionTypes.REMOVE_TAB;
    payload: string
}

export type ReportHeaderAction =
    | ToggleHeaderStateAction
    | SetCurrentActiveTabAction
    | RemoveTabAction; // Include the new action interface here
