// import React, { useState } from "react";
import { Card, CardBody, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import DashedLinenew from "../AllCharts/apex/dashedLinenew";
import { IAgeRangeProps } from "../../redux/types/reportsTypes";


const AgeRange = ({ subTab, subTabtoggle }: IAgeRangeProps) => {

  return (
    <div className="row">
      <div className="col-12 col">
        <div className="page-title-box age-range-top-box align-items-center justify-content-between">
          <div className="line">
          <div className="row">
                            <div className="col-3 mb-3">
            <input type="text" className="form-control" placeholder="Start Date"></input>
            </div>
            <div className="col-3 mb-3">
            <input type="text" className="form-control" placeholder="End Date"></input>
            </div>
            <div className="col-3 mb-3">
            <input type="text" className="form-control" placeholder="select Year"></input>
            </div>
            <div className="col-3 mb-3">
            <button className="btn btn-primary">Create Graph</button>
            <div className="page-title-right age-tange-rignt-box d-flex">
            <Nav tabs className="nav-tabs-custom list-view-tab nav-justified">
              <NavItem>
                <NavLink
                  className={subTab === "1" ? "active" : ""}
                  onClick={() => {
                    subTabtoggle("1");
                  }}
                >
                  <span className="d-none d-sm-block">
                    <i className="mdi mdi-chart-bar"></i>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={subTab === "2" ? "active" : ""}
                  onClick={() => {
                    subTabtoggle("2");
                  }}
                >
                  <span className="d-none d-sm-block">
                    {" "}
                    <i className="mdi mdi-format-list-bulleted"></i>
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
            </div>
           </div>
        </div>
      </div>
      </div>
      <div className="col-12 col scroll-bar">
        <TabContent activeTab={subTab} className="text-muted">
          <TabPane tabId="1">
            <Card>
              <CardBody>
                <CardTitle className="h4 hospital-card-title">
                </CardTitle>
                <DashedLinenew dataColors='["--bs-success"]' />
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId="2">
            <Card>
              <CardBody>
                <CardTitle className="h4 hospital-card-title">
                </CardTitle>
                <div className="table-responsive">
                  <table className="table">
                    <thead className="table-light">
                      <tr>
                        <th>Age Range </th>
                        <th>Patient Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0-10</td>
                        <td>5</td>
                      </tr>
                      <tr>
                        <td>11-20</td>
                        <td>3</td>
                      </tr>
                      <tr>
                        <td>21-30</td>
                        <td>9</td>
                      </tr>
                      <tr>
                        <td>31-40</td>
                        <td>13</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default AgeRange;
