import { DataGrid, GridActionsCellItem, GridColDef, GridRowModel } from "@mui/x-data-grid";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import NoRowsOverlay from "../../../components/DataGrid/NoRowsOverlay";
import { LinearProgress } from "@mui/material";
import { hospital_equipment_styles } from "./hospital_equipment_grid";
import {
  get_hospital_equipments_by_hospital_id,
  update_hospital_equipment,
  delete_hospital_equipment,
  post_hospital_equipment,
} from "../../../redux/actions/hospitalPropertyEquipmentAction";
import DeleteConfirmationModal from "../../../components/DeletePopupComponent/DeletePopup";

const HospitalPropertyEquipments: FC<any> = ({ hospital_property_id, subTab }) => {
  const initialRows = useMemo(() => [{ id: "1", name: "", amount: 0 }], []);
  const [page_number, setPage_number] = useState(1);
  const [per_page, setPer_page] = useState(0);
  const [rows, setRows] = useState<any[]>(initialRows);
  const [rowId, setrowId] = useState(null);
  const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // const handlePageChange = (params: any) => setPage_number(params.page);
  // const handlePageSizeChange = (params: any) => setPer_page(params.pageSize);
  const addRow = () => {
    if (rows?.some((row) => row?.id === initialRows[0]?.id)) {
      ErrorToast("Please save last added row.");
      return rows;
    }
    setRows((prevRows) => [{ id: "1", name: "", amount: 0 }, ...prevRows]);
  };
  const processRowUpdate = (newRow: GridRowModel) => newRow;
  const onCellEditCommit = (params: any) => setrowId(params?.id);

  const hospital_equipments = useCallback(async () => {
    const request = await get_hospital_equipments_by_hospital_id({ hospital_property_id, per_page: per_page, page_number: page_number });
    if (request?.statusCode === 200) {
      setRows(request?.body?.data);
    } else {
      setRows([]);
    }
  }, [hospital_property_id, per_page, page_number]);

  /* UPDATE - ADD INVESTIGATION TYPE*/
  const handleUpdateEquipment = useCallback(
    async ({
      id,
      name,
      amount,
    }: {
      id: string;
      name: string;
      amount: number;
    }) => {
      try {
        if (name.trim() === "") return ErrorToast("Name is required field!");

        let response;
        if (id === initialRows[0]?.id)
          response = await post_hospital_equipment({
            name,
            amount,
            hospital_property_id: hospital_property_id,
          });
        else response = await update_hospital_equipment({ id, name, amount });

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          hospital_equipments();
          setrowId(null);
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [initialRows, hospital_equipments, hospital_property_id]
  );

  /* DELETE InvestigationType */
  const handleDeleteEquipment = useCallback(
    async ({ id }: { id: string }) => {
      try {
        if (id === initialRows[0]?.id)
          return ErrorToast("Record is not saved yet!");
        const response = await delete_hospital_equipment({ id });

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          hospital_equipments();
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [initialRows, hospital_equipments]
  );

  const handleDeleteConfirmation = useCallback(() => {
    if (deleteRowId) {
      handleDeleteEquipment({ id: deleteRowId });
      setDeleteRowId(null);
      setIsDeleteModalOpen(false);
    }
  }, [deleteRowId, handleDeleteEquipment]);

  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);


  const columns = useMemo<GridColDef<any>[]>(
    () => [
      {
        field: "name",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: true,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "amount",
        type: "number",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: true,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        headerName: "actions",
        type: "actions",
        width: 150,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            color={"primary"}
            disabled={params?.id !== rowId}
            onClick={() =>
              handleUpdateEquipment({
                id: params.row.id.toString(),
                name: params.row.name,
                amount: params.row.amount,
              })
            }
          />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            disabled={params?.id === initialRows[0]?.id}
            onClick={() => {
              setDeleteRowId(params.row.id.toString());
              toggleDeleteModal();
            }}
          />,
        ],
      },
    ],
    [toggleDeleteModal, handleUpdateEquipment, initialRows, rowId]
  );

  useEffect(() => {
    hospital_equipments();
  }, [hospital_equipments, subTab]);
  const handleRowUpdateError = (error: any) => { };

  return (
    <>
    <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        pageName="Hospital Property Equipment"
        onConfirm={handleDeleteConfirmation}
      />

      <Card className="">
        <CardBody className="">
          <CardTitle className="h4 mb-3">Equipment</CardTitle>
          <button className="btn btn-info add-patient-details medium-btn" onClick={() => addRow()}>
            Add
          </button>
          <div style={{ height: 400, width: "100%", overflowX: "hidden" }}>
            <DataGrid
              rows={rows}
              rowHeight={45}
              columnHeaderHeight={45}
              columns={columns}
              editMode="cell"
              disableRowSelectionOnClick={true}
              pagination={true}
              processRowUpdate={processRowUpdate}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 5, page: 0 },
                },
              }}
              slots={{
                noRowsOverlay: NoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              paginationMode="client"
              sx={hospital_equipment_styles}
              onCellEditStart={onCellEditCommit}
              onProcessRowUpdateError={handleRowUpdateError}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default HospitalPropertyEquipments;
