export const SERVER_URL = `${process.env.REACT_APP_SERVER_URL}/teleicu/api`;
// export const SERVER_URL = `https://entelteleicuapi.aiappstore.in/teleicu/api`;
console.log(process.env.REACT_APP_SERVER_URL);

export const user_routes = {
  login_user_by_email: "/user/login-user-by-email",
  login_user_by_phone_number: "/user/login-user-by-phone-number",
  register: `${SERVER_URL}/user/register-user`,
  login_mobile_otp: "/user/login-mobile-otp",
  update_user: `${SERVER_URL}/user/update-user`,
  reset_password: "/user/reset-password",
  delete_user: "/user/delete-user",
  verify_email: "/user/verify-email",
  all_users: "/user/all-users",
  user_by_id: "/user/user-by-id",
  send_verification_email: "/user/send-verification-email",
  forgot_password_email: "/user/forgot-password-email",
  forgot_password_code_check: "/user/forgot-password-code-check",
  create_login_log: "/user/create-login-log",
  update_user_web_token: "/user/update-user-web-token",
  verify_otp:"/user/verify-email-code",
  get_all_users_by_role:"/user/get-all-users-by-role",
};

export const hospital_routes = {
  create_hospital: "/hospital/create-hospital",
  hospital_by_id: "/hospital/hospital-by-id",
  all_hospitals: "/hospital/all-hospitals",
  update_hospital: "/hospital/update-hospital",
  delete_hospital: "/hospital/delete-hospital",
};

export const hospital_property_routes = {
  all_hospital_property_by_hospital: "/hospital-property/all-hospital-properties",
  all_hospital_property_with_count: "hospital-property/all-hospital-properties-by-hospital-with-patient-count",
  all_hospital_property_with_discharge_count: "hospital-property/all-hospital-properties-by-hospital-with-dischargetype-count",
  hospital_property_by_id: "/hospital-property/hospital-property-by-id",
  create_hospital_property: "/hospital-property/create-hospital-property",
  update_hospital_property: "/hospital-property/update-hospital-property",
  delete_hospital_property: "/hospital-property/delete-hospital-property",
};

export const hospital_property_equipment_routes = {
  all_hospital_property_equipments:
    "/hospital-property/all-hospital-property-equipments",
  create_hospital_property_equipment:
    "/hospital-property/create-hospital-property-equipment",
  update_hospital_property_equipment:
    "/hospital-property/update-hospital-property-equipments",
  delete_hospital_property_equipment:
    "/hospital-property/delete-hospital-property-equipments",
};

export const hospital_property_icu_routes = {
  create_icu: "/icu/create-icu",
  all_icus: "/icu/all-icus-by-hospital-property",
  update_icu: "/icu/update-icu",
  delete_icu: "/icu/delete-icu",
  icu_by_id: "/icu//icu-by-id",
  post_icu_equipment: `${SERVER_URL}/icu/add-icu-equipment`,
  delete_icu_equipment: `${SERVER_URL}/icu/remove-icu-equipment`,
};

export const country_routes = {
  get_country_list: `${SERVER_URL}/master/country/all-countries`,
  post_country: `${SERVER_URL}/master/country/create-country`,
  delete_country: `${SERVER_URL}/master/country/delete-country`,
  put_country: `${SERVER_URL}/master/country/update-country`,
};

export const state_routes = {
  get_state_list: `${SERVER_URL}/master/state/all-states`,
  post_state: `${SERVER_URL}/master/state/create-state`,
  delete_state: `${SERVER_URL}/master/state/delete-state`,
  put_state: `${SERVER_URL}/master/state/update-state`,
  get_states_by_country: `${SERVER_URL}/master/state/all-states-by-country`,
};

export const city_routes = {
  get_city_list: `${SERVER_URL}/master/city/all-cities`,
  post_city: `${SERVER_URL}/master/city/create-city`,
  delete_city: `${SERVER_URL}/master/city/delete-city`,
  put_city: `${SERVER_URL}/master/city/update-city`,
  get_city_by_state: `${SERVER_URL}/master/city/all-cities-by-state`,
};

export const user_types_routes = {
  get_user_type_list: `${SERVER_URL}/master/user-type/all-user-types`,
  post_user_type: `${SERVER_URL}/master/user-type/create-user-type`,
  delete_user_type: `${SERVER_URL}/master/user-type/delete-user-type`,
  put_user_type: `${SERVER_URL}/master/user-type/update-user-type`,
  get_user_type_by_id: `${SERVER_URL}/master/user-type/user-type-by-id`,
};

export const role_routes = {
  get_role_list: `${SERVER_URL}/master/role/all-roles`,
  post_role: `${SERVER_URL}/master/role/create-role`,
  delete_role: `${SERVER_URL}/master/role/delete-role`,
  put_role: `${SERVER_URL}/master/role/update-role`,
  get_role_by_id: `${SERVER_URL}/master/role/role-by-id`,
};

export const dietry_routes = {
  get_dietry_types_list: `${SERVER_URL}/master/dietry/all-dietry-types`,
  post_dietry_type: `${SERVER_URL}/master/dietry/create-dietry-type`,
  delete_dietry_type: `${SERVER_URL}/master/dietry/delete-dietry-type`,
  put_dietry_type: `${SERVER_URL}/master/dietry/update-dietry-type`,
  get_dietry_type_by_id: `${SERVER_URL}/master/dietry/dietry-type-by-id`,
};

export const doctor_speciality_routes = {
  get_doctor_speciality_types_list: `${SERVER_URL}/master/doctor-speciality/all-doctor-speciality-types`,
  post_doctor_speciality_type: `${SERVER_URL}/master/doctor-speciality/create-doctor-speciality-type`,
  delete_doctor_speciality_type: `${SERVER_URL}/master/doctor-speciality/delete-doctor-speciality-type`,
  put_doctor_speciality_type: `${SERVER_URL}/master/doctor-speciality/update-doctor-speciality-type`,
  get_doctor_speciality_type_by_id: `${SERVER_URL}/master/doctor-speciality/doctor-speciality-type-by-id`,
};

export const report_measurement_units_routes = {
  get_report_measurement_units_types_list: `${SERVER_URL}/master/report-measurement-units/all-report-measurement-units-types`,
  post_report_measurement_units_type: `${SERVER_URL}/master/report-measurement-units/create-report-measurement-units-type`,
  delete_report_measurement_units_type: `${SERVER_URL}/master/report-measurement-units/delete-report-measurement-units-type`,
  put_report_measurement_units_type: `${SERVER_URL}/master/report-measurement-units/update-report-measurement-units-type`,
  get_report_measurement_units_type_by_id: `${SERVER_URL}/master/report-measurement-units/report-measurement-units-type-by-id`,
};

export const infusion_pumps_routes = {
  get_infusion_pumps_types_list: `${SERVER_URL}/master/infusion-pump/all-infusion-pumps-types`,
  post_infusion_pumps_type: `${SERVER_URL}/master/infusion-pump/create-infusion-pump-type`,
  delete_infusion_pumps_type: `${SERVER_URL}/master/infusion-pump/delete-infusion-pump-type`,
  put_infusion_pumps_type: `${SERVER_URL}/master/infusion-pump/update-infusion-pump-type`,
  get_infusion_pumps_type_by_id: `${SERVER_URL}/master/infusion-pump/infusion-pump-type-by-id`,
};

export const doctor_routes = {
  get_doctor_list: `${SERVER_URL}/master/doctor-list/all-doctors`,
  post_doctor_type: `${SERVER_URL}/master/doctor-list/create-doctor`,
  delete_doctor_type: `${SERVER_URL}/master/doctor-list/delete-doctor`,
  put_doctor_type: `${SERVER_URL}/master/doctor-list/update-doctor`,
  get_doctor_type_by_id: `${SERVER_URL}/master/doctor-list/doctor-by-id`,
};

export const general_examination_type_routes = {
  get_general_examination_types_list: `${SERVER_URL}/master/general-examination-type/all-general-examination-types`,
  post_general_examination_type: `${SERVER_URL}/master/general-examination-type/create-general-examination-type`,
  delete_general_examination_type: `${SERVER_URL}/master/general-examination-type/delete-general-examination-type`,
  put_general_examination_type: `${SERVER_URL}/master/general-examination-type/update-general-examination-type`,
  get_general_examination_type_by_id: `${SERVER_URL}/master/general-examination-type/general-examination-type-by-id`,
};

export const provisional_diagnosis_type_routes = {
  get_provisional_diagnosis_types_list: `${SERVER_URL}/master/provisional-diagnosis-type/all-provisional-diagnosis-types`,
  post_provisional_diagnosis_type: `${SERVER_URL}/master/provisional-diagnosis-type/create-provisional-diagnosis-type`,
  delete_provisional_diagnosis_type: `${SERVER_URL}/master/provisional-diagnosis-type/delete-provisional-diagnosis-type`,
  put_provisional_diagnosis_type: `${SERVER_URL}/master/provisional-diagnosis-type/update-provisional-diagnosis-type`,
  get_provisional_diagnosis_type_by_id: `${SERVER_URL}/master/provisional-diagnosisn-type/provisional-diagnosis-type-by-id`,
};

export const systemic_examination_type_routes = {
  get_systemic_examination_types_list: `${SERVER_URL}/master/systemic-examination-type/all-systemic-examination-types`,
  post_systemic_examination_type: `${SERVER_URL}/master/systemic-examination-type/create-systemic-examination-type`,
  delete_systemic_examination_type: `${SERVER_URL}/master/systemic-examination-type/delete-systemic-examination-type`,
  put_systemic_examination_type: `${SERVER_URL}/master/systemic-examination-type/update-systemic-examination-type`,
  get_systemic_examination_type_by_id: `${SERVER_URL}/master/systemic-examination-type/systemic-examination-type-by-id`,
};

export const habit_type_routes = {
  get_habit_types_list: `${SERVER_URL}/master/habit-type/all-habit-types`,
  post_habit_type: `${SERVER_URL}/master/habit-type/create-habit-type`,
  delete_habit_type: `${SERVER_URL}/master/habit-type/delete-habit-type`,
  put_habit_type: `${SERVER_URL}/master/habit-type/update-habit-type`,
  get_habit_type_by_id: `${SERVER_URL}/master/habit-type/habit-type-by-id`,
};

export const medicine_type_routes = {
  get_medicine_types_list: `${SERVER_URL}/master/medicine-type/all-medicine-types`,
  post_medicine_type: `${SERVER_URL}/master/medicine-type/create-medicine-type`,
  delete_medicine_type: `${SERVER_URL}/master/medicine-type/delete-medicine-type`,
  put_medicine_type: `${SERVER_URL}/master/medicine-type/update-medicine-type`,
  get_medicine_type_by_id: `${SERVER_URL}/master/medicine-type/medicine-type-by-id`,
 
  get_medicine_sub_types_list: `${SERVER_URL}/master/medicine-sub-type/all-medicine-sub-types`,
  post_medicine_sub_type: `${SERVER_URL}/master/medicine-sub-type/create-medicine-sub-type`,
  delete_medicine_sub_type: `${SERVER_URL}/master/medicine-sub-type/delete-medicine-sub-type`,
  put_medicine_sub_type: `${SERVER_URL}/master/medicine-sub-type/update-medicine-sub-type`,
  get_medicine_sub_type_by_id: `${SERVER_URL}/master/medicine-sub-type/medicine-sub-type-by-id`,
 
  get_medicine_content_list: `${SERVER_URL}/master/medicine-content/all-medicine-contents`,//
  post_medicine_content_list: `${SERVER_URL}/master/medicine-content/create-medicine-content`,
  put_medicine_content_list: `${SERVER_URL}/master/medicine-content/update-medicine-content`,
  delete_medicine_content_list: `${SERVER_URL}/master/medicine-content/delete-medicine-content`,
  // get_medicine_content_list_by_id: `${SERVER_URL}/master/medicine-content/all-medicine-contents`
};

export const discharge_routes = {
  get_discharge_types_list: `${SERVER_URL}/master/discharge/all-discharge-types`,
  post_discharge_type: `${SERVER_URL}/master/discharge/create-discharge-type`,
  delete_discharge_type: `${SERVER_URL}/master/discharge/delete-discharge-type`,
  put_discharge_type: `${SERVER_URL}/master/discharge/update-discharge-type`,
  get_discharge_type_by_id: `${SERVER_URL}/master/discharge/discharge-type-by-id`,
};

export const document_routes = {
  get_document_types_list: `${SERVER_URL}/master/document/all-document-types`,
  post_document_type: `${SERVER_URL}/master/document/create-document-type`,
  delete_document_type: `${SERVER_URL}/master/document/delete-document-type`,
  put_document_type: `${SERVER_URL}/master/document/update-document-type`,
  get_document_type_by_id: `${SERVER_URL}/master/document/document-type-by-id`,
};

export const linetube_routes = {
  get_linetube_types_list: `${SERVER_URL}/master/line-tube/all-line-tube-types`,
  post_linetube_type: `${SERVER_URL}/master/line-tube/create-line-tube-type`,
  delete_linetube_type: `${SERVER_URL}/master/line-tube/delete-line-tube-type`,
  put_linetube_type: `${SERVER_URL}/master/line-tube/update-line-tube-type`,
  get_linetube_type_by_id: `${SERVER_URL}/master/line-tube/line-tube-type-by-id`,
};

export const investigation_routes = {
  get_investigation_types_list: `${SERVER_URL}/master/investigation/all-investigation-types`,
  post_investigation_type: `${SERVER_URL}/master/investigation/create-investigation-type`,
  delete_investigation_type: `${SERVER_URL}/master/investigation/delete-investigation-type`,
  put_investigation_type: `${SERVER_URL}/master/investigation/update-investigation-type`,
  get_investigation_type_by_id: `${SERVER_URL}/master/investigation/investigation-type-by-id`,
  /* INVESTIGATION SUB TYPES */
  get_investigation_sub_types_list: `${SERVER_URL}/master/investigation-sub-type/all-investigation-sub-types`,
  post_investigation_sub_type: `${SERVER_URL}/master/investigation-sub-type/create-investigation-sub-type`,
  delete_investigation_sub_type: `${SERVER_URL}/master/investigation-sub-type/delete-investigation-sub-type`,
  put_investigation_sub_type: `${SERVER_URL}/master/investigation-sub-type/update-investigation-sub-type`,
  get_investigation_sub_type_by_id: `${SERVER_URL}/master/investigation-sub-type/investigation-sub-type-by-id`,
};

export const past_history_routes = {
  get_past_history_types_list: `${SERVER_URL}/master/past-history-type/all-past-history-types`,
  post_past_history_type: `${SERVER_URL}/master/past-history-type/create-past-history-type`,
  delete_past_history_type: `${SERVER_URL}/master/past-history-type/delete-past-history-type`,
  put_past_history_type: `${SERVER_URL}/master/past-history-type/update-past-history-type`,
  get_past_history_type_by_id: `${SERVER_URL}/master/past-history-type/past-history-type-by-id`,

  /* PAST HISTORY SUB TYPES */
  get_past_history_sub_types_list: `${SERVER_URL}/master/past-history-sub-type/all-past-history-sub-types`,
  post_past_history_sub_type: `${SERVER_URL}/master/past-history-sub-type/create-past-history-sub-type`,
  delete_past_history_sub_type: `${SERVER_URL}/master/past-history-sub-type/delete-past-history-sub-type`,
  put_past_history_sub_type: `${SERVER_URL}/master/past-history-sub-type/update-past-history-sub-type`,
  get_past_history_sub_type_by_id: `${SERVER_URL}/master/past-history-sub-type/past-history-sub-type-by-id`,
};

export const patient_form_routes = {
  get_patient_childhood_diseases: `${SERVER_URL}/master/patient/all-patient-childhood-diseases`,
  post_patient_childhood_diseases: `${SERVER_URL}/master/patient/create-patient-childhood-diseases`,
  delete_patient_childhood_diseases: `${SERVER_URL}/master/patient/delete-patient-childhood-diseases`,
  put_patient_childhood_diseases: `${SERVER_URL}/master/patient/update-patient-childhood-diseases`,
  get_patient_childhood_diseases_by_id: `${SERVER_URL}/master/patient/patient-childhood-diseases-by-id`,

  get_patient_blood_diseases: `${SERVER_URL}/master/patient/all-patient-blood-diseases`,
  post_patient_blood_diseases: `${SERVER_URL}/master/patient/create-patient-blood-diseases`,
  delete_patient_blood_diseases: `${SERVER_URL}/master/patient/delete-patient-blood-diseases`,
  put_patient_blood_diseases: `${SERVER_URL}/master/patient/update-patient-blood-diseases`,
  get_patient_blood_diseases_by_id: `${SERVER_URL}/master/patient/patient-blood-diseases-by-id`,

  get_patient_behavioural_conditions: `${SERVER_URL}/master/patient/all-patient-behavioural-conditions`,
  post_patient_behavioural_conditions: `${SERVER_URL}/master/patient/create-patient-behavioural-conditions`,
  delete_patient_behavioural_conditions: `${SERVER_URL}/master/patient/delete-patient-behavioural-conditions`,
  put_patient_behavioural_conditions: `${SERVER_URL}/master/patient/update-patient-behavioural-conditions`,
  get_patient_behavioural_conditions_by_id: `${SERVER_URL}/master/patient/patient-behavioural-conditions-by-id`,

  get_patient_other_conditions: `${SERVER_URL}/master/patient/all-patient-other-conditions`,
  post_patient_other_conditions: `${SERVER_URL}/master/patient/create-patient-other-conditions`,
  delete_patient_other_conditions: `${SERVER_URL}/master/patient/delete-patient-other-conditions`,
  put_patient_other_conditions: `${SERVER_URL}/master/patient/update-patient-other-conditions`,
  get_patient_other_conditions_by_id: `${SERVER_URL}/master/patient/patient-other-conditions-by-id`,

  get_patient_injuries: `${SERVER_URL}/master/patient/all-patient-injuries`,
  post_patient_injuries: `${SERVER_URL}/master/patient/create-patient-injuries`,
  delete_patient_injuries: `${SERVER_URL}/master/patient/delete-patient-injuries`,
  put_patient_injury: `${SERVER_URL}/master/patient/update-patient-injury`,
  get_patient_injury_by_id: `${SERVER_URL}/master/patient/patient-injury-by-id`,
};

export const patient_routes = {
  post_patient: `${SERVER_URL}/patient/create-patient`,
  all_patient: `${SERVER_URL}/patient/all-patients`,
  get_patient_by_id: `${SERVER_URL}/patient/patient-by-id`,
  search_patient: `${SERVER_URL}/patient/search-patient`,
  put_patient_property_access: `${SERVER_URL}/patient/update-patient-property-access`,
  update_patient: `${SERVER_URL}/patient/update-patient`,
  all_admission_by_patient_id: `${SERVER_URL}/patient/all-patient-admissions-by-patient`,
};

export const patient_admission_routes = {
  post_patient_admission: `${SERVER_URL}/patient/create-patient-admission`,
  put_patient_admission: `${SERVER_URL}/patient/update-patient-admission`,
  patient_admission_by_id: `${SERVER_URL}/patient/patient-admission-by-id`,
  patient_admission_by_hospital_id: `${SERVER_URL}/patient/all-patient-admissions-by-hospital`,
  patient_admission_by_hospital_property: `${SERVER_URL}/patient/all-patient-admissions-by-hospital-property`,
  patient_admission_by_hospital_count: `${SERVER_URL}/patient/all-patient-admissions-by-hospital-counts`,
  patients_by_hospital_property: `${SERVER_URL}/patient/all-patient-by-hospital-property`,
  patients_by_hospital_property_forchart: `${SERVER_URL}/patient/all-patient-by-hospital-property-for-chart`,
  patients_by_hospital_forchart: `${SERVER_URL}/patient/all-patient-by-hospital-for-chart`,

  
};

export const patient_admission_hourly_report_routes = {
  post_patient_admission_hourly_report: `${SERVER_URL}/patient/create-patient-admission-hourly-report`,
  put_patient_admission_hourly_report: `${SERVER_URL}/patient/update-patient-admission-hourly-report`,
  put_patient_admissio_hourly_report_batch: `${SERVER_URL}/patient/update-parient-admission-hourly-report-batch`,
  delete_patient_admission_hourly_report: `${SERVER_URL}/patient/delete-patient-admission-hourly-report`,
  patient_hourly_report_by_category: `${SERVER_URL}/patient/all-patient-admissions-hourly-report-by-category`,
  post_patient_admissio_hourly_report_batch: `${SERVER_URL}/patient/create-parient-admission-hourly-report-batch`,
};

export const patient_admission_line_tubes_routes = {
  post_patient_admission_line_tubes: `${SERVER_URL}/patient/create-patient-admission-line-tubes`,
  put_patient_admission_line_tubes: `${SERVER_URL}/patient/update-patient-admission-line-tubes`,
  delete_patient_admission_line_tubes: `${SERVER_URL}/patient/delete-patient-admission-line-tubes`,
  get_patient_line_tubes_by_patient_admission_id: `${SERVER_URL}/patient/all-patient-admissions-line-tubes`,
};

export const patient_admission_clinical_notes_routes = {
  post_patient_admission_clinical_notes: `${SERVER_URL}/patient/create-patient-admission-clinical-notes`,
  put_patient_admission_clinical_notes: `${SERVER_URL}/patient/update-patient-admission-clinical-notes`,
  delete_patient_admission_clinical_notes: `${SERVER_URL}/patient/delete-patient-admission-clinical-notes`,
  get_patient_clinical_notes_by_patient_admission_id: `${SERVER_URL}/patient/all-patient-admissions-clinical-notes`,
 get_patient_admission_by_doctor_id: `${SERVER_URL}/patient/get-patient-admission-clinical-notes-by-doctor`,
 put_patient_admission_clinical_notes_is_read: `${SERVER_URL}/patient/update-patient-admission-clinical-notes-is_read`,
};

export const patient_admission_prescription_routes = {
  post_patient_admission_prescription: `${SERVER_URL}/patient/create-patient-admission-prescription`,
  put_patient_admission_prescription: `${SERVER_URL}/patient/update-patient-admission-prescription`,
  delete_patient_admission_prescription: `${SERVER_URL}/patient/delete-patient-admission-prescription`,
  get_patient_prescription_by_patient_admission_id: `${SERVER_URL}/patient/all-patient-admissions-prescription`,
  get_patient_admission_prescription_by_doctor_id: `${SERVER_URL}/patient/get-patient-admissions-prescriptions-by-doctor`,
};

export const patient_admission_dietry_routes = {
  post_patient_admission_dietry: `${SERVER_URL}/patient/create-patient-admission-dietry`,
  put_patient_admission_dietry: `${SERVER_URL}/patient/update-patient-admission-dietry`,
  delete_patient_admission_dietry: `${SERVER_URL}/patient/delete-patient-admission-dietry`,
  get_patient_dietry_by_patient_admission_id: `${SERVER_URL}/patient/all-patient-admissions-dietry`,
};

export const patient_admission_observation_routes = {
  post_patient_admission_observation: `${SERVER_URL}/patient/create-patient-admission-observation`,
  put_patient_admission_observation: `${SERVER_URL}/patient/update-patient-admission-observation`,
  delete_patient_admission_observation: `${SERVER_URL}/patient/delete-patient-admission-observation`,
  get_patient_observation_by_patient_admission_id: `${SERVER_URL}/patient/all-patient-admissions-observation`,
};

export const patient_admission_instruction_routes = {
  post_patient_admission_instruction: "/instructions/create-instruction",
  put_patient_admission_instruction: "/instructions/update-instruction",
  delete_patient_admission_instruction: "/instructions/delete-instruction",
  get_instructions_by_patient_id: "/instructions/all-instructions-by-patient",
  get_instructions_by_hospital_property_id:
    "/instructions/all-instructions-by-hospital-property",
};

export const patient_admission_general_examination_routes = {
  post_patient_admission_general_examination: `${SERVER_URL}/patient/create-patient-admission-general-examination`,
  put_patient_admission_general_examination: `${SERVER_URL}/patient/update-patient-admission-general-examination`,
  delete_patient_admission_general_examination: `${SERVER_URL}/patient/delete-patient-admission-general-examination`,
  get_patient_general_examination_by_patient_admission_id: `${SERVER_URL}/patient/all-patient-admissions-general-examination`,
  post_patient_admission_general_examination_batch:`${SERVER_URL}/patient/create-patient-admission-general-examination-batch`,
  put_patient_admission_general_examination_batch: `${SERVER_URL}/patient/update-patient-admission-general-examination-batch`
};

export const patient_admission_systemic_examination_routes = {
  post_patient_admission_systemic_examination: `${SERVER_URL}/patient/create-patient-admission-systemic-examination`,
  put_patient_admission_systemic_examination: `${SERVER_URL}/patient/update-patient-admission-systemic-examination`,
  delete_patient_admission_systemic_examination: `${SERVER_URL}/patient/delete-patient-admission-systemic-examination`,
  get_patient_systemic_examination_by_patient_admission_id: `${SERVER_URL}/patient/all-patient-admissions-systemic-examination`,
  put_patient_admission_systemic_examination_batch:`${SERVER_URL}/patient/update-patient-admission-systemic-examination-batch`,
  post_patient_admission_systemic_examination_batch:`${SERVER_URL}/patient/create-patient-admission-systemic-examination-batch`,
};

export const patient_admission_habits_routes = {
  post_patient_admission_habits: `${SERVER_URL}/patient/create-patient-admission-habits`,
  put_patient_admission_habits: `${SERVER_URL}/patient/update-patient-admission-habits`,
  delete_patient_admission_habits: `${SERVER_URL}/patient/delete-patient-admission-habits`,
  get_patient_habits_by_patient_admission_id: `${SERVER_URL}/patient/all-patient-admissions-habits`,
};

export const patient_admission_provisional_diagnosis_routes = {
  post_patient_admission_provisonal_diagnosis: `${SERVER_URL}/patient/create-patient-admission-provisional-diagnosis`,
  put_patient_admission_provisonal_diagnosis: `${SERVER_URL}/patient/update-patient-admission-provisional-diagnosis`,
  delete_patient_admission_provisonal_diagnosis: `${SERVER_URL}/patient/delete-patient-admission-provisional-diagnosis`,
  get_patient_provisonal_diagnosis_by_patient_admission_id: `${SERVER_URL}/patient/all-patient-admissions-provisional-diagnosis`,
};

export const report_routes = {
  general_reports: `${SERVER_URL}/reports/general`,
  line_listing_report: `${SERVER_URL}/reports/line-listing`,
};

export const notifications_routes = {
  get_all_notificstions: `${SERVER_URL}/notifications/all-notifications`,
};

export const patient_admission_notifications_routes = {
  get_all_patient_admission_notificstions: `${SERVER_URL}/patient/all-patient-admission-notifications`,
};

export const patient_admission_investigation_routes = {
  post_patient_admission_investigation: `${SERVER_URL}/patient/create-patient-admission-investigation`,
  put_patient_admission_investigation: `${SERVER_URL}/patient/update-patient-admission-investigation`,
  // delete_patient_admission_investigation: `${SERVER_URL}/patient/delete-patient-admission-general-examination`,
  get_patient_investigation_by_patient_admission_id: `${SERVER_URL}/patient/all-patient-admissions-investigation`,
};

export const patient_documents_routes = {
  post_patient_documents: `${SERVER_URL}/patient/create-patient-documents`,
  put_patient_documents: `${SERVER_URL}/patient/update-patient-documents`,
  delete_patient_documents: `${SERVER_URL}/patient/delete-patient-documents`,
  get_patient_documents_by_patient_admission_id: `${SERVER_URL}/patient/all-patient-documents`,
};