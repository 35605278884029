import React, { useEffect, useState } from "react";
import CustomMUIDatePicker from "../../components/UI/CustomMUIDatePicker";
import {
    Card,
    CardBody,
    CardTitle,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Input,
    Label,
    Button,
} from "reactstrap";
import { IGetLineListingReportsTypes } from "../../redux/types/reportsTypes";
import {
    calculateAge,
    calculateTotalDays,
    formatDateToLocaleDateString,
} from "../../helpers/helperFunctions";
import { getLineListingReports } from "../../redux/actions/reports";
import { ErrorToast } from "../../utils/toastNotifications";
import { all_hospitals_without_pagination } from "../../redux/actions/hospitalAction";
import { hospital_property_by_hospital } from "../../redux/actions/hospitalPropertyAction";
import { ERROR_MESSAGES } from "../../helpers/constants";
import { useSelector } from "react-redux";

const LineListingReports = () => {
    const initialFilters = {
        start_date: "",
        end_date: "",
        property_id: "",
        sort_order: "",
    };
    const { user_info } = useSelector((state: any) => state.user);

    const [lineListingArray, setLineListingArray] = useState([]);
    const [filters, setFilters] = useState(initialFilters);
    const [all_hospitals, set_all_hospitals] = useState([]);
    const [all_hospitals_properties, set_all_hospitals_properties] = useState([]);
    const [selectedHospital, setSelectedHospital] = useState("");

    const fetchLineListingReportsData = async () => {
        const { body } = await getLineListingReports({});
        if (body?.data) setLineListingArray(body?.data);
        else setLineListingArray([]);
    };

    const handleSearchDateFeature = async () => {
        const { start_date, end_date } = filters;

        if ((start_date && !end_date) || (!start_date && end_date)) {
            ErrorToast(ERROR_MESSAGES.missingDates);
            return;
        }

        if (start_date && end_date) {
            if (!start_date.trim() || !end_date.trim()) {
                ErrorToast(ERROR_MESSAGES.emptyDates);
                return;
            }

            const startDateObj = new Date(start_date);
            const endDateObj = new Date(end_date);

            if (endDateObj < startDateObj) {
                ErrorToast(ERROR_MESSAGES.endDateLessThanStartDate);
                return;
            }
        }
        const updatedFilters: any = {};

        Object.entries(filters).forEach(([key, value]) => {
            if (value !== "") {
                updatedFilters[key] = value;
            }
        });
        if (
            selectedHospital !== "" &&
            filters.property_id === "" &&
            all_hospitals_properties.length > 0
        ) {
            ErrorToast(ERROR_MESSAGES.noHospitalProperty);
            return;
        }

        if (
            user_info?.is_super_admin === false &&
            filters.property_id !== "" &&
            (!start_date.trim() || !end_date.trim())
        ) {
            ErrorToast(ERROR_MESSAGES.noFiltersSelected);
            return;
        }
        if (Object.entries(updatedFilters).length === 0) {
            ErrorToast(ERROR_MESSAGES.noFiltersSelected);
            return;
        }

        const request = await getLineListingReports(updatedFilters);
        if (request?.statusCode === 200) {
            setLineListingArray(request?.body?.data);
        } else {
            setLineListingArray([]);
        }
    };

    const handleFilterChange = (name: string, value?: string) => {
        setFilters((prev_filters) => ({ ...prev_filters, [name]: value }));
    };

    const fetch_hospitals = async () => {
        try {
            const request = await all_hospitals_without_pagination();
            if (request?.statusCode === 200) {
                set_all_hospitals(request?.body?.data);
            } else {
                set_all_hospitals([]);
            }
        } catch (error) {
            console.error("Error fetch_hospitals:", error);
            set_all_hospitals([]);
        }
    };

    const fetchHospitalProperties = async (selectedHospital: string) => {
        try {
            if (selectedHospital.trim() !== "") {
                const request = await hospital_property_by_hospital(selectedHospital);
                if (request?.statusCode === 200) {
                    set_all_hospitals_properties(request?.body?.data);
                    setFilters({ ...filters, property_id: request?.body?.data[0]?.id });
                } else {
                    set_all_hospitals_properties([]);
                    setFilters({ ...filters, property_id: "" });
                }
            }
        } catch (error) {
            console.error("Error : fetchHospitalProperties", error);
        }
    };

    useEffect(() => {
        fetchHospitalProperties(selectedHospital);
    }, [selectedHospital]);

    useEffect(() => {
        fetchLineListingReportsData();
        fetch_hospitals();
    }, []);

    useEffect(() => {
        if (user_info?.is_super_admin === false) {
            setFilters({ ...filters, property_id: user_info?.hospital_property?.id });
        }
    }, [user_info]);

    return (
        <div className="row">
            <div className="col-12 col">
                <div className="page-title-box report-top-box align-items-center justify-content-between">
                    <div className="line">
                        <div className="row">
                            <div className="col-3 mb-3">
                                <Label className="form-label mr-5"> Start Date </Label>{" "}
                                <CustomMUIDatePicker
                                    name="start_date"
                                    type="formik"
                                    value={filters?.start_date}
                                    setState={handleFilterChange}
                                />
                            </div>
                            <div className="col-3 mb-3">
                                <Label className="form-label mr-5"> End Date </Label>{" "}
                                <CustomMUIDatePicker
                                    name="end_date"
                                    type="formik"
                                    value={filters?.end_date}
                                    setState={handleFilterChange}
                                />
                            </div>
                            {user_info?.is_super_admin && (
                                <>
                                    <div className="col-2 mb-2">
                                        <Label className="form-label mr-5 d-flex"> Select Hospital </Label>{" "}
                                        <select
                                            style={{ width: "100% !important" }}
                                            className="form-control"
                                            name="hospitals"
                                            value={selectedHospital}
                                            onChange={(e) => setSelectedHospital(e.target.value)}
                                        >
                                            <option hidden>Select Hospital</option>
                                            {all_hospitals && all_hospitals.length > 0 ? (
                                                all_hospitals.map(({ id, name }, index) => (
                                                    <option key={index} style={{ color: "#000" }} value={id}>
                                                        {name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option
                                                    style={{ color: "#000" }}
                                                    value={undefined}
                                                    disabled
                                                >
                                                    No Hospitals found
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-2 mb-2">
                                        <Label className="form-label mr-5 d-flex"> Select Hospital Property</Label>{" "}
                                        <select
                                            value={filters?.property_id}
                                            className="form-control"
                                            name="property_id"
                                            style={{ width: "100% !important" }}
                                            onChange={(e) => handleFilterChange("property_id", e.target.value)}
                                        >
                                            <option hidden>Select Hospital Proeprty</option>
                                            {all_hospitals_properties &&
                                                all_hospitals_properties.length > 0 ? (
                                                all_hospitals_properties.map(({ id, landmark }, index) => (
                                                    <option key={index} style={{ color: "#000" }} value={id}>
                                                        {landmark}
                                                    </option>
                                                ))
                                            ) : (
                                                <option
                                                    style={{ color: "#000" }}
                                                    disabled
                                                    value={undefined}
                                                >
                                                    No Hospital Properties found
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </>
                            )}
                            <div className="col-2 mb-2 combo-btn-box">
                                <button
                                    className="btn btn-primary mr-2"
                                    onClick={() => handleSearchDateFeature()}
                                >
                                    Search
                                </button>
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        setSelectedHospital("");
                                        setFilters(initialFilters);
                                    }}
                                >
                                    Reset
                                </button>
                            </div>
                           
                        </div>
                    </div>
                    <div className="page-title-right d-flex">
                        {/* <Dropdown>
                            <DropdownToggle tag="button" className="btn btn-success">
                                Export <i className="mdi mdi-chevron-down" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>CSV</DropdownItem>
                                <DropdownItem>PDF</DropdownItem>
                            </DropdownMenu>
                        </Dropdown> */}
                    </div>
                </div>
            </div>
            <div className="col-12">
                <Card>
                    <CardTitle></CardTitle>
                    <CardBody>
                        <div className="report-text-right">
                            <select
                                className="form-control"
                                name="sort_order"
                                onChange={(e) => handleFilterChange("sort_order", e.target.value)}
                            >
                                <option style={{ color: "#000" }} hidden>
                                    Sort by
                                </option>
                                <option style={{ color: "#000" }} value="ASC">
                                    Ascending
                                </option>
                                <option style={{ color: "#000" }} value="DESC">
                                    Descending
                                </option>
                            </select>
                            <Dropdown>
                                {/* <DropdownToggle tag="button" className="btn btn-success">
                                    Export <i className="mdi mdi-chevron-down" />
                                </DropdownToggle> */}
                                <Button tag="button" className="btn btn-success">
                                    Export <i className="mdi mdi-chevron-down" />
                                </Button>
                                <DropdownMenu>
                                    <DropdownItem>CSV</DropdownItem>
                                    <DropdownItem>PDF</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className="table-responsive fixed-table-head">
                            <table className="table">
                                <thead className="table-light">
                                    <tr>
                                        <th>Hospital</th>
                                        <th>Patient Name</th>
                                        <th>Age</th>
                                        <th>Gender</th>
                                        {/* <th>Previously Diagnosis</th> */}
                                        <th>Admit Date</th>
                                        <th>Discharge Date</th>
                                        <th>Total Days</th>
                                        {/* <th>Type of discharge</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {lineListingArray && lineListingArray?.length > 0 ? (
                                        lineListingArray?.map(
                                            (
                                                singleLine: IGetLineListingReportsTypes,
                                                index: number
                                            ) => (
                                                <tr key={index}>
                                                    <td>
                                                        {singleLine?.hospital_property?.hospital?.name}
                                                    </td>
                                                    <td>
                                                        {singleLine?.first_name} {singleLine?.last_name}
                                                    </td>
                                                    <td>{calculateAge(singleLine?.birth_date)}</td>
                                                    <td>{singleLine?.sex}</td>
                                                    <td>{formatDateToLocaleDateString(singleLine?.admission_date) ?? "-"}</td>
                                                    <td>{formatDateToLocaleDateString(singleLine?.discharge_date) ?? "-"}</td>
                                                    <td>
                                                        {calculateTotalDays(
                                                            singleLine?.admission_date,
                                                            singleLine?.discharge_date
                                                        ) ?? "-"}
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    ) : (
                                        <tr>
                                            <td colSpan={7} style={{ textAlign: "center" }}>
                                                No Rows Available
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div >
    );
};

export default LineListingReports;
