import { all_months_name } from "./constants";
 
export const findTextInString = (text: string, searchText: string): boolean => {
  const lowerCaseText = text?.toLowerCase();
  const lowerCaseSearchText = searchText?.toLowerCase();
  return lowerCaseText?.includes(lowerCaseSearchText);
};
 
export const removeEmptyFieldsFromPatientDiseaseCategory = (
  items: { disease: { id: string; name: string }; details: string }[]
) => {
  return items.filter((item) => {
    const { disease, details } = item;
    return (
      disease?.id.trim() !== "" ||
      disease?.name.trim() !== "" ||
      details.trim() !== ""
    );
  });
};
 
 
export const getChartColorsArray = (colors: any) => {
  colors = JSON.parse(colors);
  return colors.map(function (value: string) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
 
      if (color.indexOf("#") !== -1)
        color = color.replace(" ", "");
      if (color) return color;
      else return newValue;
    } else {
      var val = value.split(',');
      if (val.length === 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
};
 
 
export const calculateAge = (birth_date: any) => {
 
  const date1 = new Date(birth_date);
  const date2 = new Date();
 
  const yearDiff = date2.getFullYear() - date1.getFullYear();
  const monthDiff = date2.getMonth() - date1.getMonth();
  const months = yearDiff * 12 + monthDiff;
 
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;
 
  if ((years + remainingMonths) > 0) {
    return `${years}.${remainingMonths}`;
  } else {
    return '0.1';
  }
}
 
export const calculateTotalDays = (admissionDate: string, dischargeDate: string) => {
  if (!admissionDate || !dischargeDate) {
    return null;
  }
  if (!admissionDate || !dischargeDate) {
    return null;
  }
 
  const oneDay = 24 * 60 * 60 * 1000;
  const startDate = new Date(admissionDate).getTime();
  const endDate = new Date(dischargeDate).getTime();
  const totalDays = Math.round(Math.abs((endDate - startDate) / oneDay));
 
  return totalDays;
}
 
export const calculatePatientsByMonth = (data: Record<string, number[]>): Record<string, number> => {
  const result: Record<string, number> = {};
 
  for (const month of all_months_name) {
    result[month] = 0;
  }
 
  for (const [month, values] of Object.entries(data)) {
    result[month] = values.length;
  }
 
  return result;
};

export const calculateExperienceYears = (startDate:any) => {
  const start = new Date(startDate);
  const today = new Date();

  if (!isNaN(start.getTime())) { // Check if the start date is valid
    const years = today.getFullYear() - start.getFullYear();
    const months = today.getMonth() - start.getMonth();
    const days = today.getDate() - start.getDate();

    // Adjust the year difference if the current date is before the anniversary of the start date
    if (months < 0 || (months === 0 && days < 0)) {
      return years - 1;
    }
    return years;
  } else {
    return null; // Handle invalid date
  }
};
 
export const formatDateToLocaleDateString = (dateString: string) => {
  const formattedDateString = formatDateToCustomDateString(dateString, "dd/mm/yyyy");
  if (formattedDateString) {
    return formattedDateString;
  } else {
    return null; // Or handle the case where formatting fails
  }
};
 
export const formatDateToCustomDateString = (dateString: string, format: string) => {
  const date = new Date(dateString);
 
  if (!isNaN(date.getTime())) { // Check if the date is valid
    const day = date.getDate();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString(); // Convert year to string
    const hours = date.getHours();
    const minutes = date.getMinutes();
 
    const lowercaseFormat = format.toLowerCase();
 
    if (lowercaseFormat === 'dd/mm/yyyy') {
      /* 20/01/2024 */
      return `${day.toString().padStart(2, '0')}/${month}/${year}`;
    } else if (lowercaseFormat === 'mm/dd/yyyy') {
      /* 01/20/2024 */
      return `${month}/${day.toString().padStart(2, '0')}/${year}`;
    } else if (lowercaseFormat === 'yyyy/mm/dd') {
      /* 2024/01/20 */
      return `${year}/${month}/${day.toString().padStart(2, '0')}`;
    } else if (lowercaseFormat === 'yy/mmm/dd') {
      /* 24/Jan/20 */
      return `${year.substring(2)}/${getMonthAbbreviation(date.getMonth() + 1)}/${day.toString().padStart(2, '0')}`;
    } else if (lowercaseFormat === 'mmm/dd/yy') {
      /* Jan/20/24 */
      return `${getMonthAbbreviation(date.getMonth() + 1)}/${day.toString().padStart(2, '0')}/${year.substring(2)}`;
    } else if (lowercaseFormat === 'yyyy/mmm/dd') {
      /* 2024/Jan/20 */
      return `${year}/${getMonthAbbreviation(date.getMonth() + 1)}/${day.toString().padStart(2, '0')}`;
    } else if (lowercaseFormat === 'dd/mmm/yyyy') {
      /* 20/Jan/2024 */
      return `${day.toString().padStart(2, '0')}/${getMonthAbbreviation(date.getMonth() + 1)}/${year}`;
    } else if (lowercaseFormat === 'dd/mm/yyyy hh:mm am/pm') {
      /* 20/Jan/2024 10:21 AM */
      const formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')} ${hours >= 12 ? 'PM' : 'AM'}`;
      return `${day.toString().padStart(2, '0')}/${month}/${year} ${formattedTime}`;
    } else {
      return null; // Handle unknown format
    }
  } else {
    return null; // Handle invalid date
  }
};
 
const getMonthAbbreviation = (month: number) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return months[month - 1];
};


