export const patient_access = {
  read_patient: "read_patient",
  update_patient: "update_patient",
  view_all_patients: "view_all_patients",
  delete_patient: "delete_patient",
  create_patient: "create_patient",
  admit_patient: "admit_patient",
  discharge_patient: "discharge_patient",
};

export const hospital_access = {
  read_hospital: "read_hospital",
  update_hospital: "update_hospital",
  view_all_hospitals: "view_all_hospitals",
  delete_hospital: "delete_hospital",
  create_hospital: "create_hospital",
};

export const hospital_property_access = {
  read_hospital_property: "read_hospital_property",
  update_hospital_property: "update_hospital_property",
  view_all_hospital_properties: "view_all_hospital_properties",
  delete_hospital_property: "delete_hospital_property",
  create_hospital_property: "create_hospital_property",
};

export const master_pages_access = {
  view_all_masters: "view_all_masters",
  update_masters: "update_masters",
  delete_masters: "delete_masters",
  create_masters: "create_masters",
};

export const access_policies = {
  ...patient_access,
  ...hospital_access,
  ...hospital_property_access,
  ...master_pages_access,
};
