import React, { useState, useEffect, useCallback } from "react";
import {
  getAdmissionById,
  putPatientAdmission,
} from "../../../../redux/actions/patientAction";
import { ErrorToast, SuccessToast } from "../../../../utils/toastNotifications";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';
import { IconButton } from "@mui/material";
 
const Odp: React.FC<any> = ({ selected_admission, setselected_admission }) => {
  const [odp_note, setodp_note] = useState(selected_admission?.odp_note);
  const [update_enabled, setupdate_enabled] = useState(false);
  const { user_info } = useSelector((state: any) => state?.user);
 
  useEffect(() => {
    setupdate_enabled(false);
    setodp_note(selected_admission?.odp_note)
  }, [selected_admission]);
 
  const refresh_selected_admission = useCallback(async () => {
    let request = await getAdmissionById(selected_admission?.id);
 
    if (request?.statusCode === 200) {
      setselected_admission(request?.body);
    } else {
      setselected_admission(selected_admission);
    }
  }, [selected_admission, setselected_admission]);
 
  const handleCancel = () => {
    setupdate_enabled(false);
  };
 
  const handleSave = async () => {
    try {
      let result = await putPatientAdmission({
        id: selected_admission?.id,
        odp_note: odp_note,
      });
 
      if (result.statusCode === 200) {
        toast.dismiss()
        SuccessToast("Odp Note updated successfully");
      } else {
        ErrorToast("Server Error, Try Again");
      }
 
      setupdate_enabled(false);
 
      await refresh_selected_admission();
    } catch (error) {
      console.error('Error occurred while saving:', error);
      ErrorToast("Error occurred, please try again");
    }
  };
 
  return (
    <div className="row">
      <div className="col-12">
        {update_enabled ? (
          <div className="mb-3">
            <label htmlFor="odpNote">Note</label>
            <ReactQuill
              id="odpNote"
              value={odp_note}
              onChange={setodp_note}
            />
            <div className="col-12 text-center margin-top-20">
              <button
                className="btn btn-primary mr-2 medium-btn"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary medium-btn ms-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <>
            {user_info?.hospital_property?.id ===
              selected_admission?.hospital_property?.id &&
              selected_admission?.admitted &&
              !selected_admission?.discharged && (
                <div className="edit-icon-container">
                  <div className="edit-icon-circle">
                    <IconButton
                      onClick={() => setupdate_enabled(true)}
                      className="edit-icon-button"
                    >
                      <EditIcon style={{ fontSize: 25 }} />
                    </IconButton>
                  </div>
                </div>
              )}
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selected_admission?.odp_note) }}></div>
          </>
        )}
      </div>
    </div>
  );
};
 
export default Odp;