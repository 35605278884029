import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";

const LayoutContainer = ({ isUserLoggedIn }: { isUserLoggedIn: boolean }) => {
  return (
    <>
      {isUserLoggedIn && <Sidebar />}

      <Outlet />
    </>
  );
};

export default LayoutContainer;
