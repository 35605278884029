import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from "reactstrap";
 
interface DeleteConfirmationModalProps {
  isOpen: boolean;
  toggle: () => void;
  pageName: string; // New prop for the page name
  onConfirm: () => void;
}
 
const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  isOpen,
  toggle,
  pageName,
  onConfirm,
}) => {
  const confirmationMessage = `Are you sure want to delete ${pageName} record?`;
 
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>Confirm</ModalHeader>
      <ModalBody>{confirmationMessage}</ModalBody>
      <ModalFooter className="justify-content-center">
        <Row>
          <Col className=" text-center">
            <Button color="danger" onClick={onConfirm}>
              Delete
            </Button>
          </Col>
          <Col className="text-center">
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};
 
export default DeleteConfirmationModal;