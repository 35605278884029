import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import NoRowsOverlay from "../DataGrid/NoRowsOverlay";
import LinearProgress from "@mui/material/LinearProgress";
import { dg_styles } from "./datagridstyles";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import SvgFilesIcon from "../SvgFiles/SvgFiles";
const DataGridTable: React.FC<any> = ({
  columns,
  rows,
  addRow = () => { },
  pageChange = () => { },
  pageSizeChange = () => { },
  processRowUpdate = () => { },
  onCellEditCommit = () => { },
  setGlobalSearch = () => { },
}) => {
  const handleRowUpdateError = (error: any) => { };

  return (
    <>
      {/* <div className="d-flex justify-content-end"> */}
      <div className="page-title-right master-search  patient-search-box">
        <div className="search-box-wrapper">
          <div className="search-box-icon">
            <SvgFilesIcon iconname={"search"} />
            <input
              onChange={(e) => setGlobalSearch(e.target.value)}
              type="text"
              className="form-control mr-2"
              placeholder="Search..."
            />

            <button
              className="btn btn-primary mb-3"
              type="button"
              onClick={() => addRow()}
            >
              Add Row
            </button>
          </div>
        </div>
      </div>





      {/* </div> */}

      {/* <div style={{ height: 560, width: "100%" }}> */}
      <div className="row">
        <div className="col col-12 grid-height">
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="cell"
            rowHeight={45}
            columnHeaderHeight={45}
            disableRowSelectionOnClick={true}
            pagination={true}
            processRowUpdate={processRowUpdate}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            slots={{
              noRowsOverlay: NoRowsOverlay,
              loadingOverlay: LinearProgress,
            }}
            paginationMode="client"
            sx={dg_styles}
            onCellEditStart={onCellEditCommit}
            onProcessRowUpdateError={handleRowUpdateError}
          />
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default DataGridTable;
