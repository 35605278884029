import { habit_type_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";

export const getHabitTypesList = async ({
  page_number,
  per_page,
}: {
  page_number?: number;
  per_page?: number;
}) => {
  try {
    const params: { [key: string]: number } = {};

    if (page_number !== undefined) params["page_number"] = page_number;
    if (per_page !== undefined) params["per_page"] = per_page;

    const { data } = await axiosApi.get(
      habit_type_routes.get_habit_types_list,
      { params }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.errors;
  }
};

export const postHabitType = async ({
  name,
  description,
}: {
  name: string;
  description: string;
}) => {
  try {
    const { data } = await axiosApi.post(habit_type_routes.post_habit_type, {
      name,
      description,
    });
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const updateHabitType = async ({
  id,
  name,
  description,
}: {
  id: string;
  name: string;
  description: string;
}) => {
  try {
    const { data } = await axiosApi.put(habit_type_routes.put_habit_type, {
      id,
      name,
      description,
    });
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const deleteHabitType = async ({ id }: { id: string }) => {
  try {
    const { data } = await axiosApi.delete(
      `${habit_type_routes.delete_habit_type}/${id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.data;
  }
};
