import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { getChartColorsArray } from "../../../helpers/helperFunctions";
 
const Doughnut = ({ dataColors, chartData, }) => {
  const doughnutEChartColors = getChartColorsArray(dataColors);
 
  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      x: "left",
      // data: legendData,
      textStyle: {
        color: ["#8791af"],
      },
    },
    color: doughnutEChartColors,
    series: [
      { 
        name: "Total Patients",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: "30",
              fontWeight: "bold",
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data:chartData,
      },
    ],
  };
 
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "200px" }} option={options} />
    </React.Fragment>
  );
};
 
export default Doughnut;