import { infusion_pumps_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IAddInfusionPumpsTypesParams, IDeleteInfusionPumpsTypesParams, IGetInfusionPumpsTypesParams, IUpdateInfusionPumpsTypesParams } from "../types/infusionPumpsTypes";


export const getInfusionPumpsTypesList = async ({ page_number, per_page }: IGetInfusionPumpsTypesParams) => {

    try {
        const params: { [key: string]: number } = {};

        if (page_number !== undefined) params['page_number'] = page_number;
        if (per_page !== undefined) params['per_page'] = per_page;

        const { data } = await axiosApi.get(infusion_pumps_routes.get_infusion_pumps_types_list, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}


export const postInfusionPumpsType = async ({ name }: IAddInfusionPumpsTypesParams) => {
    try {
        const { data } = await axiosApi.post(infusion_pumps_routes.post_infusion_pumps_type, { name });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const updateInfusionPumpsType = async ({ id, name }: IUpdateInfusionPumpsTypesParams) => {
    try {
        const { data } = await axiosApi.put(infusion_pumps_routes.put_infusion_pumps_type, { id, name });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const deleteInfusionPumpsType = async ({ id }: IDeleteInfusionPumpsTypesParams) => {
    try {
        const { data } = await axiosApi.delete(`${infusion_pumps_routes.delete_infusion_pumps_type}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data?.data;
    }
}