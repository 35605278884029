import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useSelector } from "react-redux";
import maleAvatar from "../../assets/images/users/male_avatar.png";
import femaleAvatar from "../../assets/images/users/female_avatar.png";
import ProfileEdit from "./ProfileEdit";
import { formatDateToLocaleDateString } from "../../helpers/helperFunctions";

const Profile = () => {
  const { user_info } = useSelector((state: any) => state.user);
  const [filterOpen, setfilterOpen] = useState<boolean>(false);
  return (
    <>
      <Header HeaderName="Profile" />
      <Sidebar />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  {/* <nav aria-label="">
                    <ol className="breadcrumb mb-sm-0 font-size-14">
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Profile
                      </li>
                    </ol>
                  </nav> */}
                  <div className="d-flex"></div>
                  <div className="page-title-right d-flex">
                    <button
                      type="submit"
                      className="btn btn-info medium-btn mr-2"
                      onClick={() => setfilterOpen(true)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
              <ProfileEdit
                setIsFormOpen={setfilterOpen}
                isFormOpen={filterOpen}
                user={user_info}
              />
              <div className="col-12 col">
                <Card className="p-0">
                  <CardBody className="p-0">
                    <div className="p-0">
                      <div className="profile-page-bg">
                        <div className="profile-image">
                          <img
                            src={user_info?.gender === "female" ? femaleAvatar : maleAvatar}
                            style={{
                              width: 150,
                              height: 150,
                              borderRadius: 75,
                              paddingBottom: 1,
                            }}
                          ></img>
                        </div>
                        <h3 className="mt-3">{user_info?.name} </h3>
                        <span>(DOB - {formatDateToLocaleDateString(user_info?.date_of_birth)})</span>
                        <span className="badge-soft-warning me-1 badge bg-secondary gender-bage">
                          {user_info?.gender === "male" ? "Male" : "Female"}
                        </span>
                        <div className="line mt-3">
                          <label>Email : {user_info?.email} </label>
                          <div className="inline">
                            <label>Mobile : {user_info?.mobile}</label>
                            <label>Land Line : {user_info?.landline}</label>
                          </div>
                        </div>
                      </div>
                      <div className="profile-detail">
                        <h5>Address</h5>
                        <p></p>
                        <p>
                          <span className="count">1</span>
                          {user_info?.address_1}
                        </p>
                        <p>
                          <span className="count">2</span>
                          {user_info?.address_2}
                        </p>
                        <h5>Education</h5>
                        <p>
                          <label>Degree : </label> {user_info?.degree}
                        </p>
                        <p>
                          <label>Specialist : </label> {user_info?.specialist}
                        </p>
                        <p>
                          <label>General Availability Slots : </label>{" "}
                          {user_info?.general_availability_slots}
                        </p>
                        <h5>Hospital Detail</h5>
                        <p>
                          <label> Hospital: </label>{" "}
                          {user_info?.hospital_property?.hospital?.name}
                        </p>
                        <p className="mb-0">
                          <label> Hospital Property: </label>{" "}
                          {user_info?.hospital_property?.landmark}
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
