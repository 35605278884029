import React, { ChangeEvent, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import { IGeneralReportsProps } from "../../redux/types/reportsTypes";
import { hospital_property_by_hospital } from "../../redux/actions/hospitalPropertyAction";
import { all_hospitals_without_pagination } from "../../redux/actions/hospitalAction";
import { ErrorToast } from "../../utils/toastNotifications";
import { getGeneralReports } from "../../redux/actions/reports";
import { calculatePatientsByMonth, getChartColorsArray } from "../../helpers/helperFunctions";
import { ERROR_MESSAGES, all_months_name, years } from "../../helpers/constants";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const GeneralReports = ({ subTab, subTabtoggle }: IGeneralReportsProps) => {
    const initialFilters = { year: null, property_id: "" };

    const [filters, setFilters] = useState(initialFilters);
    const [all_hospitals, set_all_hospitals] = useState([]);
    const { user_info } = useSelector((state: any) => state.user);
    const [all_hospitals_properties, set_all_hospitals_properties] = useState([]);
    const [selectedHospital, setSelectedHospital] = useState("");
    const dataColors = '["--bs-warning","--bs-primary", "--bs-success"]';
    const apaexlineColumnColors = getChartColorsArray(dataColors);
    const [series, set_series] = useState<{ name: string; data: number[] }[]>([
        {
            name: "Admitted",
            data: [],
        },
        {
            name: "Discharge",
            data: [],
        },
        {
            name: "Critical",
            data: [],
        },
    ]);

    const options = {
        chart: { toolbar: { show: false, }, },
        plotOptions: { bar: { horizontal: false, columnWidth: "45%", endingShape: "rounded", }, },
        dataLabels: { enabled: false, },
        stroke: { show: true, width: 2, colors: ["transparent"], },
        colors: apaexlineColumnColors,
        xaxis: { categories: all_months_name, },
        yaxis: { title: { text: "Number Of Patients", }, },
        grid: { borderColor: "#f1f1f1", },
        fill: { opacity: 1, },
    };
    const fetch_hospitals = async () => {
        try {
            const request = await all_hospitals_without_pagination();
            if (request?.statusCode === 200) {
                set_all_hospitals(request?.body?.data);
            } else {
                set_all_hospitals([]);
            }
        } catch (error) {
            console.error("Error fetch_hospitals:", error);
            set_all_hospitals([]);
        }
    };

    const fetchHospitalProperties = async (selectedHospital: string) => {
        try {
            if (selectedHospital.trim() !== "") {
                const request = await hospital_property_by_hospital(selectedHospital);
                if (request?.statusCode === 200) {
                    set_all_hospitals_properties(request?.body?.data);
                    if (request?.body?.data?.length > 0) {
                        setFilters({ ...filters, property_id: request?.body?.data[0]?.id })
                    }
                } else {
                    set_all_hospitals_properties([]);
                    setFilters({ ...filters, property_id: "" })
                }
            }
        } catch (error) {
            console.error("Error : fetchHospitalProperties", error);
        }
    };

    useEffect(() => {
        fetchHospitalProperties(selectedHospital);
    }, [selectedHospital]);

    useEffect(() => {
        fetch_hospitals();
    }, []);

    const handleFilterInputChange = (
        event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    };

    const handleCreateGraph = async () => {
        const updatedFilters: any = {};
        Object.entries(filters).forEach(([key, value]) => {
            if (value !== "" && value !== null) {
                updatedFilters[key] = value;
            }
        });
        if (
            selectedHospital !== "" &&
            filters.property_id === "" &&
            all_hospitals_properties.length > 0
        ) {
            ErrorToast(ERROR_MESSAGES.noHospitalProperty);
            return;
        }
        if (user_info?.is_super_admin === false && filters.property_id !== "" && filters.year === null) {
            ErrorToast(ERROR_MESSAGES.noFiltersSelected);
            return;
        }
        if (Object.entries(updatedFilters).length === 0) {
            ErrorToast(ERROR_MESSAGES.noFiltersSelected);
            return;
        }
        const request = await getGeneralReports(updatedFilters);
        if (request?.statusCode === 200) {
            const { admitted, discharged, all_patients } = request?.body;
            const admittedData = calculatePatientsByMonth(admitted);
            const dischargedData = calculatePatientsByMonth(discharged);
            const allPatientsData = calculatePatientsByMonth(all_patients);
            set_series([
                {
                    name: "Admitted",
                    data: Object.values(admittedData),
                },
                {
                    name: "Discharge",
                    data: Object.values(dischargedData),
                },
                {
                    name: "All Patients",
                    data: Object.values(allPatientsData),
                },
            ]);
        } else {
            ErrorToast(ERROR_MESSAGES.noRecordsFound);
        }
    };
    useEffect(() => {
        if (user_info?.is_super_admin === false) {
            setFilters({ ...filters, property_id: user_info?.hospital_property?.id })
        }
    }, [user_info]);

    return (
        <div className="row">
            <div className="col-12 col">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <div className="line">
                        {user_info?.is_super_admin &&
                            (<>
                                <select className="form-control" name="hospitals" onChange={(e) => setSelectedHospital(e.target.value)}          >
                                    <option hidden>Select Hospital</option>
                                    {all_hospitals && all_hospitals.length > 0 ? (
                                        all_hospitals.map(({ id, name }, index) => (
                                            <option key={index} style={{ color: "#000" }} value={id}>
                                                {name}
                                            </option>
                                        ))
                                    ) : (
                                        <option style={{ color: "#000" }} value={undefined} disabled>
                                            No Hospitals found
                                        </option>
                                    )}
                                </select>
                                <select
                                    className="form-control"
                                    name="property_id"
                                    value={filters?.property_id}
                                    onChange={(e) => handleFilterInputChange(e)}
                                >
                                    <option hidden>Select Hospital Property</option>
                                    {all_hospitals_properties &&
                                        all_hospitals_properties.length > 0 ? (
                                        all_hospitals_properties.map(({ id, landmark }, index) => (
                                            <option key={index} style={{ color: "#000" }} value={id}>
                                                {landmark}
                                            </option>
                                        ))
                                    ) : (
                                        <option style={{ color: "#000" }} disabled value={undefined}>
                                            No Hospital Properties found
                                        </option>
                                    )}
                                </select>
                            </>)
                        }
                        <select
                            className="form-control"
                            id="year"
                            name="year"
                            // placeholder="select Year"
                            onChange={(e) => handleFilterInputChange(e)}
                        >
                            <option hidden>Select Year</option>
                            {years.map((year, index) => (
                                <option key={index} style={{ color: "#000" }} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        <button
                            className="btn btn-primary"
                            onClick={() => handleCreateGraph()}
                        >
                            Create Graph
                        </button>
                    </div>
                    <div className="page-title-right d-flex">
                        <Nav tabs className="nav-tabs-custom list-view-tab nav-justified">
                            <NavItem>
                                <NavLink
                                    className={subTab === "1" ? "active" : ""}
                                    onClick={() => subTabtoggle("1")}
                                >
                                    <span className="d-none d-sm-block">
                                        {" "}
                                        <i className="mdi mdi-chart-bar"></i>{" "}
                                    </span>{" "}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={subTab === "2" ? "active" : ""}
                                    onClick={() => subTabtoggle("2")}
                                >
                                    <span className="d-none d-sm-block">
                                        {" "}
                                        <i className="mdi mdi-format-list-bulleted"></i>
                                    </span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>
            </div>
            <div className="col-12 col scroll-bar">
                <TabContent activeTab={subTab} className="text-muted">
                    <TabPane tabId="1">
                        <Card>
                            <CardBody>
                                <CardTitle className="h4 hospital-card-title"></CardTitle>
                                <ReactApexChart options={options} series={series} type="bar" height={550} />
                            </CardBody>
                        </Card>
                    </TabPane>
                    <TabPane tabId="2">
                        <Card>
                            <CardBody>
                                <CardTitle className="h4 hospital-card-title"></CardTitle>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className="table-light">
                                            <tr>
                                                <th>Month</th>
                                                {series.map((item) => (
                                                    <th key={item.name}>{item.name} Patient</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {series[0].data.map((count, index) => (
                                                <tr key={all_months_name[index]}>
                                                    <td>{all_months_name[index]}</td>
                                                    {series.map((item) => (
                                                        <td key={item.name}>{item.data[index]}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </TabPane>
                </TabContent>
            </div>
        </div>
    );
};

export default GeneralReports;
