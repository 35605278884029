import { role_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import {
  IAddRoleParams,
  IDeleteRoleParams,
  IGetRolesParams,
  IUpdateRoleParams,
} from "../types/roleTypes";

export const getRolesList = async ({
  page_number,
  per_page,
  start_date,
  end_date,
  sort_order,
}: IGetRolesParams) => {
  try {
    const params: { [key: string]: number | string } = {};

    if (page_number !== undefined) params["page_number"] = page_number;
    if (per_page !== undefined) params["per_page"] = per_page;
    if (start_date !== undefined && start_date.trim() !== "")
      params["start_date"] = start_date;
    if (end_date !== undefined && end_date.trim() !== "")
      params["end_date"] = end_date;
    if (sort_order !== undefined && sort_order.trim() !== "")
      params["sort_order"] = sort_order;

    const { data } = await axiosApi.get(role_routes.get_role_list, { params });
    return data;
  } catch (error: any) {
    return error?.response?.data?.errors;
  }
};

export const postRole = async ({ role, user_access }: IAddRoleParams) => {
  try {
    const { data } = await axiosApi.post(role_routes.post_role, {
      role,
      user_access,
    });
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const updateRole = async ({
  id,
  role,
  user_access,
}: IUpdateRoleParams) => {
  try {
    const { data } = await axiosApi.put(role_routes.put_role, {
      id,
      role,
      user_access,
    });
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const deleteRole = async (id: string) => {
  try {
    const { data } = await axiosApi.delete(`${role_routes.delete_role}/${id}`);
    return data;
  } catch (error: any) {
    return error?.response?.data?.data;
  }
};
