

import { Formik, FormikValues } from "formik";
import React, { useState, useEffect, useCallback, FC } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardTitle,
  Input,
  Label,
  UncontrolledAccordion,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import {
  post_hospital_property_icu,
  update_hospital_icu,
  delete_hospital_icu,
  get_hospital_icus_by_hospital_property_id,
} from "../../../redux/actions/hospitalPropertyIcuAction";
import { MenuItem, Select } from "@mui/material";
import {
  deleteIcuEquipment,
  get_hospital_equipments_by_hospital_id,
  postIcuEquipment,
} from "../../../redux/actions/hospitalPropertyEquipmentAction";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import DeleteConfirmationModal from "../../../components/DeletePopupComponent/DeletePopup";

const IcuSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  name: Yup.string().required("Name is required"),
  capacity: Yup.number().required("Capacity is required"),
});

const HospitalPropertyIcus: FC<any> = ({ hospital_property_id, subTab }) => {
  const [open, setOpen] = useState(false);
  const [icus, seticus] = useState([]);
  const [icuId, setIcuId] = useState<string>("");
  const [equipmentOptions, setEquipmentOptions] = useState<any>([]);
  const [addEquipment, setAddEquipment] = useState<{ name: string; equipment_id: string; amount: number; }>({
    equipment_id: equipmentOptions[0]?.id,
    amount: 0,
    name: equipmentOptions[0]?.name,
  });
  const [form_state, setform_state] = useState("");
  const [initialValue, setinitialValue] = useState({
    type: "",
    name: "",
    capacity: 0,
  });
  const [plus_icon, setPlus_icon] = useState(false);
  const [edit_icon, setEdit_icon] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteType, setDeleteType] = useState<'icu' | 'equipment' | null>(null);


  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);
  const submit_icu_form = async (
    values: FormikValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    let request;
    if (form_state === "update")
      request = await update_hospital_icu({
        type: values.type,
        name: values.name,
        capacity: values.capacity,
        id: icuId,
      });
    else
      request = await post_hospital_property_icu({
        type: values.type,
        name: values.name,
        capacity: values.capacity,
        hospital_property_id,
      });

    if (request?.statusCode === 200) {
      SuccessToast(request?.message);
      setOpen(false);
      fetch_icus();
    } else {
      if (request?.errors?.length > 0) {
        ErrorToast(request?.errors[0]?.msg);
      }
    }
    resetForm();
  };

  const deleteItem = async (id: string, type: 'icu' | 'equipment') => {
    let response;

    if (type === 'icu') {
      response = await delete_hospital_icu({ id });
    } else if (type === 'equipment') {
      response = await deleteIcuEquipment(id);
    }

    if (response?.statusCode === 200) {
      SuccessToast(`${type === 'icu' ? 'ICU' : 'Equipment'} deleted successfully`);
      fetch_icus(); // Assuming this refreshes the ICU list
    } else {
      if (response?.errors?.length > 0) {
        ErrorToast(response?.errors[0]?.msg);
      }
    }
  };

  const handleDeleteConfirmation = useCallback(() => {
    if (deleteRowId && deleteType) {
      deleteItem(deleteRowId, deleteType); // Pass the type and id
      setDeleteRowId(null);
      setDeleteType(null); // Clear the type after confirmation
      setIsDeleteModalOpen(false);
    }
  }, [deleteRowId, deleteType]);



  const fetch_equipments_by_hospital_property_id = useCallback(async () => {
    const response = await get_hospital_equipments_by_hospital_id({ hospital_property_id });
    if (response?.statusCode === 200) setEquipmentOptions(response?.body?.data);
    else setEquipmentOptions([]);
  }, [hospital_property_id]);

  /* UPDATE ICU DETAILS */
  const update_icu = async (icu: any) => {
    setIcuId(icu?.id);
    fetch_equipments_by_hospital_property_id();
    setinitialValue({
      type: icu?.type,
      name: icu?.name,
      capacity: icu?.capacity,
    });
    setform_state("update");
    fetch_icus();
    setOpen(true);
  };

  const fetch_icus = useCallback(async () => {
    const request = await get_hospital_icus_by_hospital_property_id({ hospital_property_id });
    if (request?.statusCode === 200) {
      seticus(request?.body?.data);
    } else {
      seticus([]);
    }
  }, [hospital_property_id]);

  useEffect(() => {
    fetch_icus();
  }, [fetch_icus, subTab]);

  useEffect(() => {
    if (subTab === "2") {
      setOpen(false);
    }
  }, [subTab])

  const handleAddEquiment = useCallback(async () => {
    try {
      if (addEquipment?.amount === null || isNaN(addEquipment?.amount) || addEquipment?.amount === 0)
        return ErrorToast("Capacity is required field!");
      if (addEquipment?.equipment_id === null || !addEquipment?.equipment_id || addEquipment?.equipment_id.trim() === "")
        return ErrorToast("Equipment is required field!");
      const response = await postIcuEquipment({ icu_id: icuId, equipment_id: addEquipment.equipment_id, amount: addEquipment.amount, name: addEquipment?.name });

      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        setAddEquipment({ equipment_id: "", amount: 0, name: "" });
        fetch_icus();
        setOpen(false);
      } else if (response?.statusCode === 400)
        ErrorToast(response?.errors[0]?.msg);
      else ErrorToast("Unknown Error Occurred!");
    } catch (error: any) {
      ErrorToast(error?.response?.data?.errors[0]?.msg);
    }
  }, [addEquipment, icuId, fetch_icus]);

  return (
    <>
      <Card className="">
        <CardBody className="">
          <CardTitle className="h4 mb-3"> ICU Detail</CardTitle>
          <button
            className="btn btn-info add-patient-details medium-btn"
            onClick={() => {
              setOpen(true);
              setform_state("");
              setPlus_icon(false);
              setEdit_icon(false);
              setinitialValue({
                type: "",
                name: "",
                capacity: 0,
              });
            }}
          >
            Add
          </button>
          <div className="row">
            <div className="col-12">
              {icus?.length > 0 ? (
                icus?.map((icu: any, index: number) => {
                  return (
                    <UncontrolledAccordion key={index}>
                      <AccordionItem>
                        <AccordionHeader targetId={icu?.id}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <p style={{ marginTop: 10 }}>
                              Type: {icu?.type}, Name: {icu?.name}, Capacity:{" "}
                              {icu?.capacity} Beds{" "}
                            </p>
                            <div
                              className="gap-1"
                              style={{
                                marginRight: 30,
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div className="text-success mr-2">
                                <i
                                  className="mdi mdi-plus font-size-16"
                                  onClick={(event) => {
                                    event.stopPropagation(); // Prevent accordion toggle
                                    update_icu(icu);
                                    setPlus_icon(true);
                                  }}
                                ></i>
                              </div>
                              <div className="text-success mr-2">
                                <i
                                  className="mdi mdi-pencil font-size-16"
                                  onClick={(event) => {
                                    event.stopPropagation(); // Prevent accordion toggle
                                    update_icu(icu);
                                    setEdit_icon(true);
                                  }}
                                ></i>
                              </div>
                              <div className="text-danger">
                                <i
                                  className="mdi mdi-delete font-size-16"
                                  onClick={(event) => {
                                    event.stopPropagation(); // Prevent accordion toggle
                                    setDeleteRowId(icu?.id.toString());
                                    setDeleteType('icu'); // Set type to ICU
                                    toggleDeleteModal();
                                  }}
                                ></i>
                              </div>
                            </div>
                          </div>
                        </AccordionHeader>
                        <AccordionBody accordionId={icu?.id}>
                          <table className="table table-bordered table-hover">
                            <thead className="table-light table-nowrap">
                              <tr>
                                <th>Equipment Name</th>
                                <th>Number of Equipment</th>
                                <th>Actions</th>
                                <th>User Name</th>
                                <th>Date Added</th>
                              </tr>
                            </thead>
                            <tbody>
                              {icu?.icu_equipments?.length > 0 &&
                                icu?.icu_equipments.map((icu_equipment: any) => {
                                  return (
                                    <tr key={icu_equipment?.id}>
                                      <td>{icu_equipment?.equipment?.name}</td>
                                      <td>{icu_equipment?.amount}</td>
                                      <td>
                                        <div className="text-danger" style={{ cursor: "pointer" }}>
                                          <i
                                            className="mdi mdi-delete font-size-16"
                                            onClick={(event) => {
                                              event.stopPropagation(); // Prevent accordion toggle
                                              setDeleteRowId(icu_equipment?.id);
                                              setDeleteType('equipment'); // Set type to Equipment
                                              toggleDeleteModal();
                                            }}
                                          ></i>
                                        </div>
                                      </td>
                                      <td>
                                        {icu_equipment?.created_by && (
                                          <div>{icu_equipment?.created_by?.name}</div>
                                        )}
                                      </td>
                                      <td>
                                        {new Date(
                                          icu_equipment?.created_on
                                        ).toLocaleDateString()}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </AccordionBody>
                      </AccordionItem>
                    </UncontrolledAccordion>
                  );
                })
              ) : (
                <div>No ICUs Found</div>
              )}
            </div>
          </div>
        </CardBody>
      </Card>

      <div className="right-panel" style={{ display: open ? "unset" : "none" }}>
        <div className="row">
          <div className="col-12">
            <h4 className="h4 card-title mb-3">
              {plus_icon ? '' : 'ICU Detail'}
            </h4>
            <button
              className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light btn btn-secondary"
              onClick={() => {
                setOpen(false);
                setPlus_icon(false);
                setEdit_icon(false);
              }}
            >
              <i className="mdi mdi-close"></i>
            </button>
          </div>
        </div>
        <Formik
          initialValues={initialValue}
          enableReinitialize={true}
          validationSchema={IcuSchema}
          onSubmit={submit_icu_form}
        >
          {(formik) => {
            const {
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              isValid,
              dirty,
            }: any = formik;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-6">
                    {!plus_icon && (
                      <div className="mb-3">
                        <Label className="form-label">ICU Type</Label>
                        <select
                          className="form-control"
                          value={values.type}
                          name="type"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>NICU</option>
                          <option>ICU</option>
                        </select>
                        {errors?.type && touched?.type && (
                          <span className="error" style={{ marginTop: 3 }}>
                            {errors?.type}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    {!plus_icon && (
                      <div className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input
                          name="name"
                          className="form-control"
                          type="text"
                          placeholder="Enter Ward Name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name && (
                          <span className="error" style={{ marginTop: 3 }}>
                            {errors.name}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    {!plus_icon && (
                      <div className="mb-3">
                        <Label className="form-label">Capacity</Label>
                        <Input
                          name="capacity"
                          className="form-control"
                          type="number"
                          placeholder="Enter Capacity"
                          value={values.capacity}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.capacity && touched.capacity && (
                          <span className="error" style={{ marginTop: 3 }}>
                            {errors.capacity}
                          </span>
                        )}
                      </div>
                    )}

                  </div>
                  {/* SHOW ONLY IN EDIT STATE */}
                  {form_state === "update" && (
                    <>
                      <div>
                        {!edit_icon && (
                          <>
                            <div className="col-12">
                              <h4 className="h4 card-title mb-3">Add Equipment</h4>
                            </div>
                            <div className="col-5">
                              <div className="mb-3">
                                <Label className="form-label">Select Equipment</Label>
                                <Select
                                  name="equipment_id"
                                  MenuProps={{ autoFocus: false }}
                                  className="form-control"
                                  sx={{
                                    "& .MuiOutlinedInput-input": {
                                      padding: "0 !important",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderWidth: "0 !important",
                                    },
                                    color: "#a6b0cf",
                                  }}
                                  onChange={(e: any) => {
                                    const parsed_data: any = JSON.parse(e.target.value);
                                    setAddEquipment({
                                      ...addEquipment,
                                      equipment_id: parsed_data?.id,
                                      name: parsed_data?.name,
                                    });
                                  }}
                                >
                                  {equipmentOptions &&
                                    equipmentOptions?.map((item: any) => {
                                      let JSONItem: any = JSON.stringify(item);
                                      return (
                                        <MenuItem key={item?.id} value={JSONItem}>
                                          {item?.name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-5">
                              <div className="mb-3">
                                <Label className="form-label">Capacity</Label>
                                <Input
                                  name="amount"
                                  className="form-control"
                                  type="text"
                                  value={addEquipment.amount === 0 ? '' : addEquipment.amount}
                                  onChange={(e) =>
                                    setAddEquipment({
                                      ...addEquipment,
                                      amount: e.target.value === '' ? 0 : parseInt(e.target.value),
                                    })
                                  }
                                  placeholder="Enter capacity"
                                />
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="button-box">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleAddEquiment();
                                  }}
                                  className="btn btn-primary"
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                  {!plus_icon && (
                    <div className="col-12 button-box text-center">
                      <button
                        type="submit"
                        className={
                          dirty && isValid
                            ? "btn btn-primary mr-2 medium-btn"
                            : "btn btn-primary mr-2 medium-btn disabled-btn"
                        }
                        disabled={!(dirty && isValid)}
                        onClick={() => {
                          setPlus_icon(false);
                          setEdit_icon(false);
                        }}
                      >
                        {form_state === "update" ? "Update" : "Add"}
                      </button>
                      <button
                        className="btn btn-secondary medium-btn"
                        type="button"
                      >
                        Reset
                      </button>
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        pageName={deleteType === 'icu' ? 'ICU' : 'Equipment'}
        onConfirm={handleDeleteConfirmation}
      />

    </>
  );
};

export default HospitalPropertyIcus;
