import { userAction, ActionTypes } from "../types/hospitalTabsTypes";

interface initialStateTypes {
  user_info: null | any;
  token: string | null;
}

const initialState: initialStateTypes = {
  user_info: null,
  token: localStorage.getItem("tele_icu_token"),
};

export const userReducer = (state: initialStateTypes = initialState, actions: userAction): initialStateTypes => {
  switch (actions.type) {

    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        user_info: actions.payload.user,
        token: actions.payload.token,
      };

    case ActionTypes.LOGOUT_SUCCESS:
      localStorage.removeItem("tele_icu_token");
      return {
        ...state,
        user_info: null,
        token: null
      };

    default:
      return state;
  }
};
