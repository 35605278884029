import { doctor_routes, user_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IAddDoctorTypesParams, IDeleteDoctorTypesParams, IGetDoctorTypesParams, IGetUserRoleParams, IUpdateDoctorTypesParams } from "../types/doctorType";


export const getDoctorTypesList = async ({ page_number, per_page }: IGetDoctorTypesParams) => {

    try {
        const params: { [key: string]: number } = {};

        if (page_number !== undefined) params['page_number'] = page_number;
        if (per_page !== undefined) params['per_page'] = per_page;

        const { data } = await axiosApi.get(doctor_routes.get_doctor_list, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}


export const postDoctorType = async ({ name, email, phone_number, specialty_ids, reg_number }: IAddDoctorTypesParams) => {
    try {
        const { data } = await axiosApi.post(doctor_routes.post_doctor_type, { name, email, phone_number, specialty_ids, reg_number });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const updateDoctorType = async ({ id, name, email, phone_number, specialty_ids, reg_number }: IUpdateDoctorTypesParams) => {
    try {
        const { data } = await axiosApi.put(doctor_routes.put_doctor_type, { id, name, email, phone_number, specialty_ids, reg_number });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const deleteDoctorType = async ({ id }: IDeleteDoctorTypesParams) => {
    try {
        const { data } = await axiosApi.delete(`${doctor_routes.delete_doctor_type}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data?.data;
    }
}

export const getDoctorListByRole = async ({ role, hospital_id }: IGetUserRoleParams) => {
    try {
        const params: { [key: string]: string } = {};

        if (hospital_id !== undefined) params['hospital_id'] = hospital_id;
        if (role !== undefined) params['role'] = role;


        const { data } = await axiosApi.get(user_routes.get_all_users_by_role, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}