import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GridActionsCellItem, GridColDef, GridRowModel } from "@mui/x-data-grid";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { getGeneralExaminationTypesList, postGeneralExaminationType, updateGeneralExaminationType, deleteGeneralExaminationType } from "../../../redux/actions/generalExaminationTypeAction";
import { findTextInString } from "../../../helpers/helperFunctions";
import Header from "../../../components/Header/Header";
import DataGridTable from "../../../components/DataGrid/DataGridTable";
import DeleteConfirmationModal from "../../../components/DeletePopupComponent/DeletePopup";

const GeneralExaminationType = () => { 
  const initialRows = useMemo(() => [{ id: "1", name: "", description: "" }], []);
  type Row = (typeof initialRows)[number];
  const [page_number, setPage_number] = useState(1);
  const [per_page, setPer_page] = useState(0);
  const [rows, setRows] = useState<any[]>(initialRows);
  const [rowId, setRowId] = useState<string | null>(null);
  const [globalSearch, setGlobalSearch] = useState("");
  const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handlePageChange = (params: any) => setPage_number(params.page);
  const handlePageSizeChange = (params: any) => setPer_page(params.pageSize);
  const processRowUpdate = (newRow: GridRowModel) => newRow;
  const onCellEditCommit = (params: any) => setRowId(params?.id);

  const addRow = () => {
    if (rows?.some((row) => row?.id === initialRows[0]?.id)) {
      ErrorToast("Please save the last added row.");
      return rows;
    }
    setRows((prevRows) => [{ id: "1", name: "", description: "" }, ...prevRows]);
  };

  const handleUpdateGeneralExamination = useCallback(
    async ({ id, name, description }: { id: string; name: string; description: string }) => {
      try {
        if (name.trim() === "") return ErrorToast("Name is required field!");
        let response;
        if (id === initialRows[0]?.id)
          response = await postGeneralExaminationType({ name, description });
        else response = await updateGeneralExaminationType({ id, name, description });

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          const { body } = await getGeneralExaminationTypesList({ per_page, page_number });
          if (body) setRows(body?.data);
          else setRows([]);
          setRowId(null);
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [initialRows, per_page, page_number]
  );

  const handleDeleteGeneralExamination = useCallback(
    async ({ id }: { id: string }) => {
      try {
        if (id === initialRows[0]?.id)
          return ErrorToast("Record is not saved yet!");
        const response = await deleteGeneralExaminationType({ id });

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          const { body } = await getGeneralExaminationTypesList({ per_page, page_number });
          if (body) setRows(body?.data);
          else setRows([]);
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [initialRows, per_page, page_number]
  );

  const handleDeleteConfirmation = useCallback(() => {
    if (deleteRowId) {
      handleDeleteGeneralExamination({ id: deleteRowId });
      setDeleteRowId(null);
      setIsDeleteModalOpen(false);
    }
  }, [deleteRowId, handleDeleteGeneralExamination]);

  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: "name",
        headerName: "Name",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: true,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "description",
        headerName: "Description",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: true,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        headerName: "actions",
        type: "actions",
        width: 150,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            color={"primary"}
            disabled={params?.id !== rowId}
            onClick={() =>
              handleUpdateGeneralExamination({
                id: params.row.id.toString(),
                name: params.row.name,
                description: params.row.description,
              })
            }
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            disabled={params?.id === initialRows[0]?.id}
            color="error"
            onClick={() => {
              setDeleteRowId(params.row.id.toString());
              toggleDeleteModal();
            }}
          />,
        ],
      },
    ],
    [handleUpdateGeneralExamination, initialRows, rowId, toggleDeleteModal]
  );

  useEffect(() => {
    async function getGeneralExaminations() {
      const { body } = await getGeneralExaminationTypesList({ per_page, page_number });
      if (body) setRows(body?.data);
      else setRows([]);
    }
    getGeneralExaminations();
  }, [page_number, per_page]);

  const filteredRows = useMemo(
    () => rows?.filter(({ name }) => findTextInString(name, globalSearch)),
    [rows, globalSearch]
  );

  return (
    <>
      <Header HeaderName="Master Pages" />
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        pageName="General Examination"
        onConfirm={handleDeleteConfirmation}
      />
      <div className="row">
        <div className="col-12 col">
          <div className="page-title-box master-title">
            <h4 className="mb-sm-0 font-size-18">General Examination</h4>
          </div>
        </div>
        <div className="col-12">
          <DataGridTable
            columns={columns}
            rows={filteredRows}
            processRowUpdate={processRowUpdate}
            pageSizeChange={handlePageSizeChange}
            pageChange={handlePageChange}
            addRow={addRow}
            onCellEditCommit={onCellEditCommit}
            setGlobalSearch={setGlobalSearch}
          />
        </div>
      </div>
    </>
  );
};

export default GeneralExaminationType;
