import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody } from "reactstrap";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowModel,
  GridSearchIcon,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import DataGridTable from "../../../components/DataGrid/DataGridTable";
import SaveIcon from "@mui/icons-material/Save";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import {
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { findTextInString } from "../../../helpers/helperFunctions";
import {
  IDeletePastHistorySubTypesParams,
  IPastHistoryOptions,
  IUpdatePastHistorySubTypesParams,
} from "../../../redux/types/pastHistoryTypes";
import {
  deletePastHistorySubType,
  getPastHistorySubTypesList,
  getPastHistoryTypesList,
  postPastHistorySubType,
  updatePastHistorySubType,
} from "../../../redux/actions/pastHistoryAction";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header/Header";
import DeleteConfirmationModal from "../../../components/DeletePopupComponent/DeletePopup";

const PastHistorySubType = () => {
  const navigate = useNavigate();
  const initialRows = useMemo(
    () => [{ id: "1", past_history_type: { id: "", name: "" }, name: "" }],
    []
  );
  type Row = (typeof initialRows)[number];
  const [rows, setRows] = useState<any[]>(initialRows);
  const [page_number, setPage_number] = useState(1);
  const [rowId, setrowId] = useState(null);
  const [globalSearch, setGlobalSearch] = useState("");

  const [per_page, setPer_page] = useState(0);
  const [PastHistoryTypesOptions, setPastHistoryTypesOptions] = useState<
    IPastHistoryOptions[]
  >([]);
  const [searchText, setSearchText] = useState("");
  const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const addRow = () => {
    if (rows?.some((row) => row?.id === initialRows[0]?.id)) {
      ErrorToast("Please save last added row.");
      return rows;
    }
    setRows((prevRows) => [
      { id: "1", past_history_type: { id: "", name: " " }, name: "" },
      ...prevRows,
    ]);
  };

  useEffect(() => {
    async function fetchPastHistoryTypesList() {
      const { body } = await getPastHistoryTypesList({});
      const updatedPastHistorySubTypesList: IPastHistoryOptions[] =
        body?.data?.map((e: any) => ({ name: e?.name, id: e?.id }));
      setPastHistoryTypesOptions(updatedPastHistorySubTypesList);
    }
    async function fetchPastHistorySubTypesList() {
      const { body } = await getPastHistorySubTypesList({
        per_page: per_page,
        page_number: page_number,
      });
      if (body) setRows(body?.data);
      else setRows([]);
    }
    fetchPastHistoryTypesList();
    fetchPastHistorySubTypesList();
  }, [initialRows, page_number, per_page]);

  const handleUpdatePastHistorySubType = useCallback(
    async ({
      id,
      name,
      past_history_type_id,
    }: IUpdatePastHistorySubTypesParams) => {
      try {
        if (!past_history_type_id || past_history_type_id.trim() === "")
          return ErrorToast("Past History Type is required field!");
        if (name.trim() === "")
          return ErrorToast("Past history sub type name is required field!");
        let response;
        if (id === initialRows[0]?.id)
          response = await postPastHistorySubType({
            name,
            past_history_type_id,
          });
        else
          response = await updatePastHistorySubType({
            id,
            name,
            past_history_type_id,
          });

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          const { body } = await getPastHistorySubTypesList({
            per_page,
            page_number,
          });
          if (body) setRows(body?.data);
          else setRows([]);
          setrowId(null);
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [initialRows, per_page, page_number]
  );

  const handleDeletePastHistorySubType = useCallback(
    async ({ id }: IDeletePastHistorySubTypesParams) => {
      try {
        if (id === initialRows[0]?.id)
          return ErrorToast("Record is not saved yet!");
        const response = await deletePastHistorySubType({ id });

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          const { body } = await getPastHistorySubTypesList({});
          if (body) setRows(body?.data);
          else setRows([]);
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error OCcureed!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [initialRows]
  );

  const filteredRows = useMemo(
    () =>
      rows?.filter(
        ({ name, past_history_type }) =>
          findTextInString(name, globalSearch) ||
          findTextInString(past_history_type?.name, globalSearch)
      ),
    [rows, globalSearch]
  );

  const displayedOptions = useMemo(
    () =>
      PastHistoryTypesOptions?.filter(({ name }) =>
        findTextInString(name, searchText)
      ),
    [PastHistoryTypesOptions, searchText]
  );

  const handleDeleteConfirmation = useCallback(() => {
    if (deleteRowId) {
      handleDeletePastHistorySubType({ id: deleteRowId });
      setDeleteRowId(null);
      setIsDeleteModalOpen(false);
    }
  }, [deleteRowId, handleDeletePastHistorySubType]);

  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);


  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: "past_history_type",
        flex: 1,
        headerName: "Past History Type",
        filterable: true,
        sortable: true,
        editable: false,
        align: "left",
        headerAlign: "left",
        renderCell: (params: any) => {
          const handleChange = (selectedPastHistoryTypeId: string) => {
            setrowId(params.id);
            const selectedPastHistoryType = PastHistoryTypesOptions?.find(
              (option) => option.id === selectedPastHistoryTypeId
            );
            if (!selectedPastHistoryType) return;

            const rowIndex = rows?.findIndex((row) => row?.id === params?.id);
            if (rowIndex === -1) return;

            const isParamsIdOne = params.id === 1;
            const row = params.row || {};

            const updatedRow = {
              ...rows[rowIndex],
              past_history_type: selectedPastHistoryType,
              name:
                rows[rowIndex]?.name?.trim() !== "" && isParamsIdOne
                  ? rows[rowIndex]?.name
                  : row?.name,
            };
            const updatedRows = [
              ...rows.slice(0, rowIndex),
              updatedRow,
              ...rows.slice(rowIndex + 1),
            ];
            setRows(updatedRows);
          };
          return (
            <div style={{ width: "100%" }}>
              <Select
                MenuProps={{ autoFocus: false }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: "0 !important",
                  },
                  "& :focus": {
                    outline: "none !important",
                    border: "0 !important",
                    boxShadow: "none !important",
                  },
                  width: "100%",
                  color: "#a6b0cf",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                }}
                renderValue={() =>
                  params.value?.name.trim() === ""
                    ? "Select Past History Type"
                    : params.value?.name
                }
                onClose={() => setSearchText("")}
                value={params?.value?.id}
                onChange={(event) => handleChange(event.target.value)}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    autoFocus
                    placeholder="Type to search..."
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <GridSearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                <MenuItem value=" " disabled>
                  <em>Select Past History Type</em>
                </MenuItem>
                {displayedOptions && displayedOptions?.length > 0 ? (
                  displayedOptions?.map(({ name, id }: IPastHistoryOptions) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Past History Found</MenuItem>
                )}
              </Select>
            </div>
          );
        },
        valueOptions: PastHistoryTypesOptions,
      },
      {
        headerName: "Name",
        field: "name",
        type: "TextField",
        flex: 1,
        editable: true,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        headerName: "actions",
        type: "actions",
        width: 150,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            color="primary"
            disabled={params?.id !== rowId}
            onClick={() =>
              handleUpdatePastHistorySubType({
                id: params?.row?.id?.toString(),
                past_history_type_id:
                  params?.row?.past_history_type.id.toString(),
                name: params?.row?.name,
              })
            }
          />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            disabled={params?.id === initialRows[0]?.id}
            color="error"
            onClick={() => {
              setDeleteRowId(params.row.id.toString());
              toggleDeleteModal()
            }}

          />,

          // <GridActionsCellItem
          //   icon={<FileCopyIcon />}
          //   label="Duplicate User"
          //   color="info"
          //   onClick={duplicateUser(params.id)}
          //   showInMenu
          // />,
        ],
      },
    ],
    [
      PastHistoryTypesOptions,
      displayedOptions,
      toggleDeleteModal,
      handleUpdatePastHistorySubType,
      initialRows,
      rowId,
      rows,
    ]
  );

  const handlePageChange = (params: any) => {
    setPage_number(params.page);
  };

  const handlePageSizeChange = (params: any) => {
    setPer_page(params.pageSize);
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    return newRow;
  };

  const onCellEditCommit = (params: any) => {
    setrowId(params.id);
  };

  return (
    <>
      <Header HeaderName="Master Pages" />
      {/* <Sidebar /> */}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        pageName="pasthistory subtype"
        onConfirm={handleDeleteConfirmation}
      />
      <div className="row">
        <div className="col-12 col">
          <div className="page-title-box master-title">
            <h4 className="mb-sm-0 font-size-18">Past History Sub Types</h4>
          </div>
        </div>
        <div className="col-12">
          <DataGridTable
            columns={columns}
            rows={filteredRows}
            processRowUpdate={processRowUpdate}
            pageSizeChange={handlePageSizeChange}
            pageChange={handlePageChange}
            addRow={addRow}
            onCellEditCommit={onCellEditCommit}
            setGlobalSearch={setGlobalSearch}
          />
        </div>
      </div>
    </>
  );
};

export default PastHistorySubType;
