export enum ActionTypes {
  USER_SUCCESS = "user_success",
  LOGIN_SUCCESS = "login_success",
  ADD_HOSPITAL_TAB = "add_hospital_tab",
  REMOVE_HOSPITAL_TAB = "remove_hospital_tab",
  SET_CURRENT_ACTIVE_TAB = "set_current_active_tab",
  SET_HOSPITAL_TAB = "set_hospital_tab",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  NULL_HOSPITAL_TABS = "NULL_HOSPITAL_TABS"
}

interface userSuccessActionTypes {
  type: ActionTypes.USER_SUCCESS;
  payload: any;
}

interface loginSuccessActionTypes {
  type: ActionTypes.LOGIN_SUCCESS;
  payload: {
    user: any;
    token: string;
  };
}

interface logoutSuccessActionTypes {
  type: ActionTypes.LOGOUT_SUCCESS;
}

interface addHospitalTabActionTypes {
  type: ActionTypes.ADD_HOSPITAL_TAB;
  payload: {
    id: string;
    name: string;
  };
}

interface removeHospitalTabActionTypes {
  type: ActionTypes.REMOVE_HOSPITAL_TAB;
  payload: {
    id: string;
    name: string;
  };
}

interface setCurrentActiveTabActionTypes {
  type: ActionTypes.SET_CURRENT_ACTIVE_TAB;
  payload: {
    id: string;
  };
}
interface setNullHospitalTabs {
  type: ActionTypes.NULL_HOSPITAL_TABS;
}

interface setHospitalTabActionTypes {
  type: ActionTypes.SET_HOSPITAL_TAB;
  payload: {
    id: string;
    name: string;
  };
}
export type userAction = userSuccessActionTypes | loginSuccessActionTypes | logoutSuccessActionTypes;

export type hospitalTabActions =
  | addHospitalTabActionTypes
  | removeHospitalTabActionTypes
  | setCurrentActiveTabActionTypes
  | setHospitalTabActionTypes | setNullHospitalTabs
