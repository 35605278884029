import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-icu-sm.png";
import logoLightPng from "../../assets/images/logo-icu.png";
import SideHeader from "./SideHeader";
import entelIconNew from "../../assets/images/Entel-Icon-New.png";
import entelLogoNew from "../../assets/images/Entel-Logo-New.png";


export interface IHeaderProps {
  HeaderName?: string,
}

const Header = (props: IHeaderProps) => {
  const [header_state, setheader_state]: any = useState(
    Boolean(localStorage.getItem("header"))
  );

  const change_header_state = () => {
    setheader_state(!header_state);
    localStorage.setItem("header", JSON.stringify(!header_state));
  };

  useEffect(() => {
    if (document.body) {
      if (header_state === true) {
        document.body.classList.add("vertical-collpsed", "sidebar-enable");
      }
      if (header_state === false) {
        document.body.classList.remove("vertical-collpsed");
      }
    }
  }, [header_state]);

  // const fetch_notifications = async () => {
  //   try {
  //     let result = await listAll();
  //     if (result?.success) {
  //       setnotifications(result?.data?.notifications);
  //       console.log('notifications', result?.data);
  //       setnotification_count(result?.data?.unread_notifications?.length);
  //       setunread_notifications(result?.data?.unread_notifications);
  //     } else {
  //       setnotifications([]);
  //       setunread_notifications([]);
  //     }
  //   } catch (err) {
  //     setnotifications([]);
  //   }
  // };

  // const remove_token = async () => {
  //   let web_tokens_data = JSON.parse(userObj?.web_token)?.filter((d) => {
  //     return d !== current_browser_token;
  //   });
  //   let data = await update_web_token(
  //     { web_tokens: web_tokens_data },
  //     userObj?.user_id
  //   );
  //   let user = await viewRow(userObj?.user_id);
  //   setUserDetails({ ...userObj, web_token: user?.data?.web_token });
  //   NotificationManager.success('Notifications disabled successfully');
  //   update_token_check();
  // };

  // const add_token = async () => {
  //   let web_tokens_data = JSON.parse(userObj?.web_token);
  //   getFirebaseToken()
  //     .then(async (firebaseToken) => {
  //       setcurrent_browser_token(firebaseToken);
  //       let upload_data = [...web_tokens_data, firebaseToken.toString()];
  //       let data = await update_web_token(
  //         { web_tokens: upload_data },
  //         userObj?.user_id
  //       );
  //       let user = await viewRow(userObj?.user_id);
  //       setUserDetails({ ...userObj, web_token: user?.data?.web_token });
  //       NotificationManager.success('Notifications enabled successfully');
  //       update_token_check();
  //       if (!firebaseToken) {
  //         NotificationManager.info('Please enable notification in browser');
  //       }
  //     })
  //     .catch((err) => {
  //       NotificationManager.info('Please enable notification in browser');
  //     });
  // };

  // useEffect(() => {
  //   fetch_notifications();
  //   getFirebaseToken()
  //     .then((firebaseToken) => {
  //       setcurrent_browser_token(firebaseToken);
  //       if (!firebaseToken) {
  //         NotificationManager.info('Please enable notification in browser');
  //       }
  //     })
  //     .catch((err) => {
  //       NotificationManager.info('Please enable notification in browser');
  //     });
  // }, []);

  // let update_token_check = () => {
  //   let userObj = getUserDetails();
  //   let web_tokens =
  //     userObj?.web_token === undefined || null
  //       ? []
  //       : JSON.parse(userObj?.web_token);
  //   let token_check = web_tokens.includes(current_browser_token?.toString());
  //   settoken_check(token_check);
  // };

  return (
    <header className="topbar" id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box d-lg-none d-md-block">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={entelIconNew} alt="" height="22" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={entelLogoNew} alt="" height="22" />
              </span>
            </Link>
          </div>
          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 header-item "
            id="vertical-menu-btn"
            data-toggle="collapse"
            onClick={change_header_state}
          >
            <i className="fa fa-fw fa-bars" />
          </button>
          <nav aria-label="breadcrumb"><ol className="breadcrumb mb-sm-0 font-size-14"><li className="breadcrumb-item active" aria-current="page"> {props.HeaderName}</li></ol></nav>
        </div>
        <SideHeader />
      </div>
    </header>
  );
};

export default Header;
