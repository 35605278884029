import { patient_admission_investigation_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IPatientAdmissionInvestigationTypes } from "../types/IpatientAdmissionInvestigationTypes"; 

export const putPatientAdmissionInvestigation = async (values: IPatientAdmissionInvestigationTypes) => {
    try {
        const { data } = await axiosApi.put(patient_admission_investigation_routes.put_patient_admission_investigation, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const postPatientAdmissionInvestigation = async (values: IPatientAdmissionInvestigationTypes) => {
    try {
        const { data } = await axiosApi.post(patient_admission_investigation_routes.post_patient_admission_investigation, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const getPatientAdmissionInvestigationByPatientAdmissionId = async (
    patient_admission_id: string,
) => {
    try {
        const { data } = await axiosApi.get(`${patient_admission_investigation_routes.get_patient_investigation_by_patient_admission_id}?patient_admission_id=${patient_admission_id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};