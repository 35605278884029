import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Routing from "./router/Routing";
// Import scss
import "./assets/scss/theme.scss";
import { ToastContainer, useToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import RouteWrapper from "./router/RouteWrapper";
import { getFirebaseToken, onForegroundMessage } from "./firebase";
import { SuccessToast } from "./utils/toastNotifications";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
const App = () => {
  onForegroundMessage()
    .then((payload: any) => {
      let title = payload?.notification?.title;
      let body = payload?.notification?.body;
      SuccessToast(title);
      // NotificationManager.info(body, title, 5000);
    })
    .catch((err) =>
      console.log("An error occured while retrieving foreground message. ", err)
    );

  // useEffect(() => {
  //   getFirebaseToken()
  //     .then((firebaseToken) => {
  //       console.log("Firebase token: ", firebaseToken);
  //       if (firebaseToken) {
  //         // setShowNotificationBanner(false);
  //       }
  //     })
  //     .catch((err) =>
  //       console.error("An error occured while retrieving firebase token. ", err)
  //     );
  // }, []);

  // const handleGetFirebaseToken = () => {
  //   getFirebaseToken()
  //     .then((firebaseToken) => {
  //       if (firebaseToken) {
  //         setShowNotificationBanner(false);
  //       }
  //     })
  //     .catch((err) =>
  //       console.error('An error occured while retrieving firebase token. ', err)
  //     );
  // };
  let newTheme: any = localStorage.getItem("theme") ? localStorage.getItem("theme") : 'dark';
  if (document.body) document.body.setAttribute("data-layout-mode", newTheme);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ToastContainer />
          {/* <Routing /> */}
          <RouteWrapper />
        </LocalizationProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
