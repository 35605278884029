import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames"; // Import classNames for conditional class assignment

import logo from "../../assets/images/logo-icu-sm.png";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoicu from "../../assets/images/logo-icu.png";
import entel from "../../assets/images/entel-logo.webp";
import entelicon from "../../assets/images/entel-logo-icon.png";
import entelIconNew from "../../assets/images/Entel-Icon-New.png";
import entelLogoNew from "../../assets/images/Entel-Logo-New.png";
import entelLightLogoNew from "../../assets/images/Entel-Logo-New-Light.png";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../redux/types/patientTabTypes";

const Sidebar = () => {
  const { user_info } = useSelector((state: any) => state.user);
  const location = useLocation(); // Get the current location
  const navigate = useNavigate(); // Use navigate to programmatically navigate
  const [activeItem, setActiveItem] = useState("/dashboard"); // Initialize active item to '/dashboard'
  const [loader, setLoader] = useState(false); // State for managing the loader
  const dispatch: any = useDispatch();
  useEffect(() => {
    // Update activeItem state when location changes
    setActiveItem(location.pathname);
    setLoader(false); // Hide loader when the location changes
  }, [location]);

  const handleNavigation = (path: string) => {
    if (path === "/patients") {
      dispatch({
        type: ActionTypes.SET_Main_Patient_TAB,
        payload: {
          patientMainTab: "1",
        },
      });
    }
    setLoader(true);
    navigate(path);
  };

  return (
    <>
      {loader && (
        <div className="loader-box">
          <span className="loader"></span>
        </div>
      )}
      <div className="vertical-menu sidebar-background">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={entelIconNew} alt="" height="22" />

            </span>
            <span className="logo-lg">
              <img src={entelLogoNew} className="dark-logo-image" alt="" height="45" />
              <img src={entelLightLogoNew} className="light-logo-image" alt="" height="45" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightSvg} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="19" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className={classNames({ active: activeItem === "/dashboard" })}>
                <a onClick={() => handleNavigation("/dashboard")}>
                  <i className="mdi mdi-view-dashboard-outline"></i>
                  <span>Dashboards</span>
                </a>
              </li>
              <li className={classNames({ active: activeItem === "/patients" })}>
                <a onClick={() => handleNavigation("/patients")}>
                  <i className="mdi mdi-human-wheelchair"></i>
                  <span>Patients</span>
                </a>
              </li>
              {(user_info?.is_super_admin || user_info?.is_admin) && (
                <>
                  <li className={classNames({ active: activeItem === "/users" })}>
                    <a onClick={() => handleNavigation("/users")}>
                      <i className="mdi mdi-account-group-outline"></i>
                      <span>Users</span>
                    </a>
                  </li>
                  <li className={classNames({ active: activeItem === "/user-roles" })}>
                    <a onClick={() => handleNavigation("/user-roles")}>
                      <i className="mdi mdi-account-group-outline"></i>
                      <span>User Roles</span>
                    </a>
                  </li>
                  <li className={classNames({ active: activeItem === "/instructions" })}>
                    <a onClick={() => handleNavigation("/instructions")}>
                      <i className="mdi mdi-view-list-outline"></i>
                      <span>Instructions</span>
                    </a>
                  </li>
                  <li className={classNames({ active: activeItem === "/activities" })}>
                    <a onClick={() => handleNavigation("/activities")}>
                      <i className="mdi mdi-television-ambient-light"></i>
                      <span>Activities</span>
                    </a>
                  </li>
                  <li className={classNames({ active: activeItem === "/reports" })}>
                    <a onClick={() => handleNavigation("/reports")}>
                      <i className="mdi mdi-chart-bar"></i>
                      <span>Reports</span>
                    </a>
                  </li>
                </>
              )}
              <li className={classNames({ active: activeItem === "/settings" })}>
                <a onClick={() => handleNavigation("/settings")}>
                  <i className="mdi mdi-cog-outline"></i>
                  <span>Master Pages</span>
                </a>
              </li>
              <li className={classNames({ active: activeItem === "/hospitals" })}>
                <a onClick={() => handleNavigation("/hospitals")}>
                  <i className="mdi mdi-hospital-building"></i>
                  <span>Hospitals</span>
                </a>
              </li>
              <li className={classNames({ active: activeItem === "/doctor-profile" })}>
                <a onClick={() => handleNavigation("/doctor-profile")}>
                  <i className="mdi mdi-doctor"></i>
                  <span>Doctor Profile</span>
                </a>
              </li>
              {(user_info?.is_super_admin || user_info?.is_admin) && (
                <li className={classNames({ active: activeItem === "/doctors-list" })}>
                  <a onClick={() => handleNavigation("/doctors-list")}>
                    <i className="mdi mdi-group"></i>
                    <span>Doctors List</span>
                  </a>
                </li>
              )}
            </ul>
          </div>

          <div className="sidebar-bottom">
            <p>Powered By</p>
            <div className="logo-box">
              {Boolean(localStorage.getItem("header")) === true && (
                <><img src={entelLogoNew} className="dark-logo-image" alt="Entel Icl"></img>
                  <img src={entelLightLogoNew} className="light-logo-image" alt="" height="45" /></>
              )}
            </div>
            <div className="logo-box small-logo-box">
              {Boolean(localStorage.getItem("header")) === true && (
                <img src={entelIconNew} alt="Entel Icl" /* height="22" */ />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
