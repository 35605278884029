import { investigation_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IAddInvestigationSubTypesParams, IAddInvestigationTypesParams, IDeleteInvestigationSubTypesParams, IDeleteInvestigationTypesParams, IGetInvestigationSubTypesParams, IGetInvestigationTypesParams, IUpdateInvestigationSubTypesParams, IUpdateInvestigationTypesParams } from "../types/investigation";


export const getInvestigationTypesList = async ({ page_number, per_page }: IGetInvestigationTypesParams) => {

    try {
        const params: { [key: string]: number } = {};

        if (page_number !== undefined) params['page_number'] = page_number;
        if (per_page !== undefined) params['per_page'] = per_page;

        const { data } = await axiosApi.get(investigation_routes.get_investigation_types_list, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}


export const postInvestigationType = async ({ name, sort_order }: IAddInvestigationTypesParams) => {
    try {
        const { data } = await axiosApi.post(investigation_routes.post_investigation_type, { name, sort_order });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const updateInvestigationType = async ({ id, name, sort_order }: IUpdateInvestigationTypesParams) => {
    try {
        const { data } = await axiosApi.put(investigation_routes.put_investigation_type, { id, name, sort_order });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const deleteInvestigationType = async ({ id }: IDeleteInvestigationTypesParams) => {
    try {
        const { data } = await axiosApi.delete(`${investigation_routes.delete_investigation_type}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data?.data;
    }
}

/* INVESTIGATION SUB TYPES */

export const getInvestigationSubTypesList = async ({ page_number, per_page }: IGetInvestigationSubTypesParams) => {
    try {
        const params: { [key: string]: number } = {};

        if (page_number !== undefined) params['page_number'] = page_number;
        if (per_page !== undefined) params['per_page'] = per_page;

        const { data } = await axiosApi.get(investigation_routes.get_investigation_sub_types_list, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}


export const postInvestigationSubType = async ({ name, investigation_type_id, sort_order }: IAddInvestigationSubTypesParams) => {
    try {
        const { data } = await axiosApi.post(investigation_routes.post_investigation_sub_type, { name, investigation_type_id,sort_order });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const updateInvestigationSubType = async ({ id, name, investigation_type_id, sort_order }: IUpdateInvestigationSubTypesParams) => {
    try {
        const { data } = await axiosApi.put(investigation_routes.put_investigation_sub_type, { id, name, investigation_type_id, sort_order });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const deleteInvestigationSubType = async ({ id }: IDeleteInvestigationSubTypesParams) => {
    try {
        const { data } = await axiosApi.delete(`${investigation_routes.delete_investigation_sub_type}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data?.data;
    }
}