import { past_history_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import {
  IAddPastHistorySubTypesParams,
  IAddPastHistoryTypesParams,
  IDeletePastHistorySubTypesParams,
  IDeletePastHistoryTypesParams,
  IGetPastHistorySubTypesParams,
  IGetPastHistoryTypesParams,
  IUpdatePastHistorySubTypesParams,
  IUpdatePastHistoryTypesParams,
} from "../types/pastHistoryTypes";

export const getPastHistoryTypesList = async ({
  page_number,
  per_page,
}: IGetPastHistoryTypesParams) => {
  try {
    const params: { [key: string]: number } = {};

    if (page_number !== undefined) params["page_number"] = page_number;
    if (per_page !== undefined) params["per_page"] = per_page;

    const { data } = await axiosApi.get(
      past_history_routes.get_past_history_types_list,
      { params }
    );
    return data;
  } catch (error: any) {
    return error?.response.data;
  }
};

export const postPastHistoryType = async ({
  name,
}: IAddPastHistoryTypesParams) => {
  try {
    const { data } = await axiosApi.post(
      past_history_routes.post_past_history_type,
      { name }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const updatePastHistoryType = async ({
  id,
  name,
}: IUpdatePastHistoryTypesParams) => {
  try {
    const { data } = await axiosApi.put(
      past_history_routes.put_past_history_type,
      { id, name }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const deletePastHistoryType = async ({
  id,
}: IDeletePastHistoryTypesParams) => {
  try {
    const { data } = await axiosApi.delete(
      `${past_history_routes.delete_past_history_type}/${id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.data;
  }
};

/* PAST HISTORY SUB TYPES */
export const getPastHistorySubTypesList = async ({
  page_number,
  per_page,
}: IGetPastHistorySubTypesParams) => {
  try {
    const params: { [key: string]: number } = {};

    if (page_number !== undefined) params["page_number"] = page_number;
    if (per_page !== undefined) params["per_page"] = per_page;

    const { data } = await axiosApi.get(
      past_history_routes.get_past_history_sub_types_list,
      { params }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.errors;
  }
};

export const postPastHistorySubType = async ({
  name,
  past_history_type_id,
}: IAddPastHistorySubTypesParams) => {
  try {
    const { data } = await axiosApi.post(
      past_history_routes.post_past_history_sub_type,
      { name, past_history_type_id }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const updatePastHistorySubType = async ({
  id,
  name,
  past_history_type_id,
}: IUpdatePastHistorySubTypesParams) => {
  try {
    const { data } = await axiosApi.put(
      past_history_routes.put_past_history_sub_type,
      { id, name, past_history_type_id }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const deletePastHistorySubType = async ({
  id,
}: IDeletePastHistorySubTypesParams) => {
  try {
    const { data } = await axiosApi.delete(
      `${past_history_routes.delete_past_history_sub_type}/${id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.data;
  }
};
