import CustomMUIDatePicker from "../../../../components/UI/CustomMUIDatePicker";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "../../../../utils/toastNotifications";
import {
    postPatientDocuments, getPatientDocumentsByPatientAdmissionId, putPatientDocuments, deletePatientDocuments
} from "../../../../redux/actions/patientDocuments";
import { formatDateToLocaleDateString } from "../../../../helpers/helperFunctions";
import { getDocumentTypesList } from "../../../../redux/actions/documentAction";
import NoRowsOverlay from "../../../../components/DataGrid/NoRowsOverlay";
import { LinearProgress, Select, MenuItem } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowModel } from "@mui/x-data-grid";
import { grid_styles } from "../grid_styles";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSelector } from "react-redux";

interface IDocumentType {
    id: string;
    name: string;
}

export interface IPatientDocuments {
    createElement(arg0: string): unknown;
    body: any;
    id: string;
    document_type?: IDocumentType;
    doc_date: string;
    doc_name: string;
    doc_path: string;
    created_by?: any;
    created_on?: string
}

const PatientDocuments: React.FC<any> = ({ selected_admission }) => {

    const [page_number, setPage_number] = useState(1);
    const [per_page, setPer_page] = useState(0);
    const [docValue, setDocValue] = useState("");
    const [isDocumentOpen, setIsDocumentOpen] = useState<boolean>(false);
    const [documentTypes, setDocumentTypes] = useState<IDocumentType[]>([]);
    const [editData, setEditData] = useState<IPatientDocuments | null>(null);
    const [patient_Documents, setPatientDocuments] = useState<IPatientDocuments[]>([]);
    const [rowId, setrowId] = useState(null);
    const [rows, setRows] = useState([]);
    const processRowUpdate = (newRow: GridRowModel) => newRow;
    const onCellEditCommit = (params: any) => setrowId(params?.id);
    const { user_info } = useSelector((state: any) => state.user);
    const handleRowUpdateError = (error: any) => { }

    const initialValues = {
        id: editData?.id || "",
        document_type: editData?.document_type?.id || "",
        doc_date: editData?.doc_date || new Date().toISOString().split('T')[0],
        doc_name: editData?.doc_name || "",
        doc_path: editData?.doc_path || "",
    };

    const getPatientDocumentByPatientAdmissionId = useCallback(async () => {
        if (selected_admission !== null) {
            try {
                const request = await getPatientDocumentsByPatientAdmissionId(
                    selected_admission.id
                );
                if (request?.statusCode === 200) {
                    const Rows = request?.body?.data.map((row: { id: any; doc_date: string; document_type: { name: any; }; doc_name: any; doc_path: any; }, index: number) => ({
                        id: row?.id,
                        date: formatDateToLocaleDateString(row?.doc_date),
                        documentType: row?.document_type?.name,
                        documentName: row?.doc_name,
                        docPath: row?.doc_path,
                    }));
                    setRows(Rows)
                    // setPatientDocuments(request?.body?.data);
                }
                else { setRows([]); }
            } catch (error) {
                console.error('Error fetching clinical notes:', error);
                setPatientDocuments([]);
            }
        }
    }, [selected_admission]);

    useEffect(() => {
        async function getDocumentTypes() {
            try {
                const { body } = await getDocumentTypesList({
                    per_page: per_page,
                    page_number: page_number,
                });
                if (body) setDocumentTypes(body?.data);
                else setDocumentTypes([]);
            } catch (error) {
                console.error("Error fetching document types:", error);
            }
        }
        getDocumentTypes();
    }, [page_number, per_page]);


    const handleAdmissionDocuments = async ({
        id,
        doc_date,
        doc_name,
        document_type,
        doc_path,
    }: {
        id: string;
        patient_admission_id: string;
        doc_name: string;
        document_type: any;
        doc_date: string;
        doc_path: string;
    }) => {
        try {
            if (document_type?.id.trim() === "")
                return ErrorToast("Document Type is required field!");
            if (doc_date.trim() === "") return ErrorToast("Date is required field!");
            if (doc_path.trim() === "") return ErrorToast("Document is required field!");

            let file = docValue;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('patient_admission_id', selected_admission?.id || '');
            const responseDocument = await fetch(`${process.env.REACT_APP_SERVER_URL}/Upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    'patient_admission_id': selected_admission?.id
                }
            });
            const responseData = await responseDocument.json();
            if (responseData.status === 200) {
                let response;
                if (id) {
                    response = await putPatientDocuments({
                        id,
                        patient_admission_id: selected_admission?.id,
                        doc_date,
                        doc_name,
                        document_type,
                        doc_path,
                    });
                } else {
                    response = await postPatientDocuments({
                        patient_admission_id: selected_admission?.id,
                        doc_date,
                        doc_name,
                        document_type,
                        doc_path: responseData?.url,
                    });
                }
                if (response?.statusCode === 200) {
                    SuccessToast(response?.message);
                    await getPatientDocumentByPatientAdmissionId();
                    setEditData(null);
                    setIsDocumentOpen(false);

                } else if (response?.statusCode === 400)
                    ErrorToast(response?.errors[0]?.msg);
                else ErrorToast("Unknown Error Occurred!");
            }
        } catch (error: any) {
            ErrorToast(error?.response?.data?.errors[0]?.msg);
        }
    };

    const handleDeleteDocuments = useCallback(
        async (id: string, fileName: string) => {
            try {
                const responseDocument = await fetch(`${process.env.REACT_APP_SERVER_URL}/deleteByUrl?url=${fileName}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ url: fileName }),
                });
                const responseData = await responseDocument.json();
                if (responseData.status === 200) {
                    const response = await deletePatientDocuments(id);
                    if (response?.statusCode === 200) {
                        SuccessToast(response?.message);
                        getPatientDocumentByPatientAdmissionId();
                    } else if (response?.statusCode === 400)
                        ErrorToast(response?.errors[0]?.msg);
                    else ErrorToast("Unknown Error Occurred!");
                } else {
                    ErrorToast("Document Not Found");
                }
            } catch (error: any) {
                ErrorToast(error?.response?.data?.errors[0]?.msg);
            }
        },
        [getPatientDocumentByPatientAdmissionId]
    );

    const validationSchema = Yup.object().shape({
        document_type: Yup.string().required('Document Type is required'),
        doc_date: Yup.date().required('Date is required'),
        doc_file: Yup.string().required('Document is required')
    });

    const handleReset = (resetForm: any) => {
        resetForm({
            values: {
                document_type: "",
                doc_date: new Date().toISOString().split('T')[0],
                doc_file: ""
            },
        });
    };

    useEffect(() => {
        getPatientDocumentByPatientAdmissionId();
    }, [getPatientDocumentByPatientAdmissionId]);

    const handleViewDocument = useCallback(async (fileName: any) => {
        try {
            window.open(fileName);
        } catch (error) {
            console.error('Error handling document:', error);
        }
    }, []);

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'date',
                headerName: 'Date',
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                filterable: true,
                sortable: false,
                editable: false,
            },
            {
                field: 'documentType',
                headerName: 'Document Type',
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                filterable: false,
                sortable: false,
                editable: false,
            },
            {
                field: 'documentName',
                headerName: 'Document Name',
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                filterable: false,
                sortable: false,
                editable: false,
            },
            {
                field: "actions",
                headerName: "actions",
                type: "actions",
                width: 150,
                getActions: (params) => {
                    const actions = [
                        <GridActionsCellItem
                            icon={<VisibilityIcon />}
                            label="View"
                            color="success"
                            onClick={(e) => {
                                handleViewDocument(params?.row?.docPath);
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        />
                    ];
                    if (!selected_admission?.discharged) {
                        actions.push(
                            <GridActionsCellItem
                                icon={<DeleteIcon />}
                                label="Delete"
                                color="error"
                                onClick={() => handleDeleteDocuments(params?.row?.id, params?.row?.docPath)}
                            />
                        );
                    }

                    return actions;
                },
            }

        ],
        [] // dependency array for useMemo
    );
    
    return (
        <>
            <div className="add-row-btn">
                {(true || user_info?.hospital_property?.id ===
                    selected_admission?.hospital_property?.id) &&
                    selected_admission?.admitted &&
                    !selected_admission?.discharged && (
                        <button
                            className="btn btn-primary medium-btn mb-2"
                            onClick={() => {
                                setEditData(null);
                                setIsDocumentOpen(true);
                            }}
                        >
                            Add
                        </button>
                    )}
            </div>
            {isDocumentOpen && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => {
                        handleAdmissionDocuments({
                            id: values?.id,
                            patient_admission_id: selected_admission?.id,
                            document_type: documentTypes.find(doc => doc.id === values?.document_type),
                            doc_date: values?.doc_date,
                            doc_path: values?.doc_path,
                            doc_name: values?.doc_name
                        });
                        setIsDocumentOpen(false)
                        resetForm();
                    }}
                >
                    {({
                        isValid,
                        isSubmitting,
                        resetForm,
                        handleSubmit,
                        values,
                        handleChange,
                        errors,
                        touched,
                        handleBlur,
                        dirty,
                        setFieldValue,
                    }) => (
                        <>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if (errors) {
                                        const firstError = Object.values(errors)[0];
                                        ErrorToast(firstError); // Display first error
                                    }
                                    handleSubmit();
                                }}
                            >
                                <div className="right-panel">
                                    <h4>Documents</h4>
                                    <button
                                        className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light"
                                        onClick={() => setIsDocumentOpen(false)}
                                    >
                                        <i className="mdi mdi-close"></i>
                                    </button>
                                    <div className="row">

                                        <div className="mb-3">

                                            <label htmlFor="doc_date">Date</label>
                                            <CustomMUIDatePicker
                                                useDateTimePicker={false}
                                                name="doc_date"
                                                type="formik"
                                                setState={setFieldValue}
                                                value={values?.doc_date}
                                            />
                                            <ErrorMessage name="doc_date" component="div" className="text-danger" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="dr_name">Document Type</label>
                                            <Field
                                                name="document_type"
                                                as="select"
                                                className={`form-control ${errors.document_type && touched.document_type ? 'is-invalid' : ''}`}
                                                value={values?.document_type} // Set the value attribute to the selected value
                                                onChange={(e: any) => {
                                                    const selectedDocumentId = e.target.value;
                                                    // const selectedDocumentObject = documentTypes.find(doc => doc.id === selectedDocumentId);
                                                    handleChange(e);
                                                }}
                                            >
                                                <option value="" disabled>Select a Document Type</option>
                                                {documentTypes && documentTypes.length > 0 && documentTypes.map(doc => (
                                                    <option key={doc?.id} value={doc?.id}>{doc?.name}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="document_type" component="div" className="text-danger" />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="document_upload">Select Document</label>
                                            <Field
                                                name="doc_file"
                                                type="file"
                                                id={selected_admission?.id} // Assuming selected_admission is part of your component state
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    if (e.target?.files && e.target?.files.length > 0) {
                                                        const file = e.target.files[0] as any;
                                                        const fileName = `${selected_admission?.id}-${file?.name?.replace(/\s/g, "")}`;
                                                        const fileType = file.type;

                                                        if (!['image/jpeg', 'image/png', 'image/jpg', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(fileType)) {
                                                            //     // If the file type is not allowed, display an error message
                                                            setFieldValue('doc_file', '');
                                                            ErrorToast('Only JPEG, PNG, JPG, PDF, DOCX, and Excel files are allowed.');
                                                            return; // Abort further processing
                                                        }

                                                        setFieldValue('doc_path', fileName);
                                                        setFieldValue('doc_name', file.name?.replace(/\s/g, ""));
                                                        setDocValue(file);
                                                    }
                                                    handleChange(e);
                                                }}
                                                className={`form-control ${errors.doc_path && touched.doc_path ? 'is-invalid' : ''}`}
                                            />
                                            <ErrorMessage name="doc_file" component="div" className="text-danger" />
                                        </div>

                                        <div className="col-12 text-center">
                                            <button
                                                className="btn btn-primary mr-2 medium-btn"
                                                type="submit"
                                            >
                                                Submit
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary medium-btn ms-2"
                                                onClick={() => handleReset(resetForm)}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>

            )}

            <DataGrid
                rows={rows}
                columns={columns}
                autoHeight={true}
                editMode="cell"
                rowHeight={35}
                columnHeaderHeight={35}
                disableRowSelectionOnClick={true}
                pagination={true}
                processRowUpdate={processRowUpdate}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 5, page: 0 },
                    },
                }}
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                    loadingOverlay: LinearProgress,
                }}
                paginationMode="client"
                sx={grid_styles}
                onCellEditStart={onCellEditCommit}
                onProcessRowUpdateError={handleRowUpdateError}
            />

        </>
    );
};

export default PatientDocuments;