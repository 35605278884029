import { patient_form_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";

export const getPatientInjuriessList = async ({
  page_number,
  per_page,
}: {
  page_number?: number;
  per_page?: number;
}) => {
  try {
    const params: { [key: string]: number } = {};

    if (page_number !== undefined) params["page_number"] = page_number;
    if (per_page !== undefined) params["per_page"] = per_page;

    const { data } = await axiosApi.get(
      patient_form_routes.get_patient_injuries,
      { params }
    );
    return data;
  } catch (error: any) {
    return error?.response.data;
  }
};

export const postPatientInjuries = async ({ name }: { name: string }) => {
  try {
    const { data } = await axiosApi.post(
      patient_form_routes.post_patient_injuries,
      { name }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const updatePatientInjuries = async ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => {
  try {
    const { data } = await axiosApi.put(
      patient_form_routes.put_patient_injury,
      { id, name }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const deletePatientInjuries = async ({ id }: { id: string }) => {
  try {
    const { data } = await axiosApi.delete(
      `${patient_form_routes.delete_patient_injuries}/${id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data?.data;
  }
};
