import { notifications_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";

export interface IGetCountryListParams {
    per_page?: number,
    page_number?: number,
    hospital_property_id?: string,
    start_date?: string,
    end_date?: string,
    sort_order?: string,
}
export const getNotificationsList = async ({ page_number, per_page, hospital_property_id, start_date, end_date, sort_order }: IGetCountryListParams) => {

    try {
        const params: { [key: string]: number | string | Date } = {};

        if (page_number !== undefined) params['page_number'] = page_number;
        if (per_page !== undefined) params['per_page'] = per_page;
        if (hospital_property_id !== undefined) params['hospital_property_id'] = hospital_property_id;
        if (start_date !== undefined) params['start_date'] = start_date;
        if (end_date !== undefined) params['end_date'] = end_date;
        if (sort_order !== undefined) params['sort_order'] = sort_order;


        const { data } = await axiosApi.get(notifications_routes.get_all_notificstions, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}

