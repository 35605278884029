import React, { useCallback, useEffect, useState } from "react";
import { NavLink, Nav, NavItem, TabPane, TabContent } from "reactstrap";
import {
  getAdmissionById,
  getAdmissionsListByPatientId,
  getPatientById,
} from "../../redux/actions/patientAction";
import PatientDetailHistory from "./patientDetailCards/PatientDetailHistory";
import PatientDetailAdmission from "./patientDetailCards/PatientDetailAdmission";
import PatientDetailDashboard from "./patientDetailCards/PatientDetailDashboard";
import PatientDetailBasicInfo from "./patientDetailCards/PatientDetailBasicInfo";
import PatientsDischargeInfo from "./patientDetailCards/patientsDischarge";
import { useSelector } from "react-redux";
import { formatDateToLocaleDateString } from "../../helpers/helperFunctions";
// import PatientHeader from "../../components/Header/PatientHeader";
// import PatientSubHeader from "../../components/Header/PatientSubHeader";

const SinglePatient: React.FC<any> = ({ patient_id, basicInfoTab, isBasicInfoEditable, setIsBasicInfoEditable }) => {
  const [patient, setPatient]: any = useState(null);
  const [subTabSecond, setsubTabSecond] = useState("1");
  const [loader, setLoader] = useState(false);
  const [admissions, setadmissions] = useState([]);
  const [selected_admission, setselected_admission]: any = useState(null);
  const { currentPatientActiveTab } = useSelector((state: any) => state.patientTab);

  const subTabSecondtoggle = (tab: string) => {
    setIsBasicInfoEditable(false);
    if (subTabSecond !== tab) setsubTabSecond(tab);
  };

  const fetch_admission = useCallback(async () => {
    if (patient_id !== undefined) {
      setLoader(true);
      try {
        const response = await getAdmissionsListByPatientId(patient_id);
        if (response?.statusCode === 200) setadmissions(response.body?.data);
        else {
          setadmissions([]);
          setselected_admission(null);
          subTabSecondtoggle("1")
        }
      } catch (error) {
        setadmissions([]);
        setselected_admission(null);
      }
      finally {
        setLoader(false);
      }
    }
  }, [patient_id, currentPatientActiveTab]);

  useEffect(() => {
    const fetch_initial_data = async () => {
      const diff: any = admissions.filter((admission: any) => (admission?.admitted === true && admission.discharged === false) || admission?.admitted === false && admission.discharged === true)[0];
      if (admissions?.length > 0) {
        const request: any = await getAdmissionById(diff?.id);
        if (request?.statusCode === 200) {
          setselected_admission(request?.body);
        } else {
          setselected_admission(null);
        }
      } else {
        setselected_admission(null);
      }
    };
    fetch_initial_data();
  }, [admissions, subTabSecond]);

  const fetchPatientData = useCallback(async () => {
    if (patient_id && patient_id !== "1" && patient_id !== "2" && patient_id !== undefined) {
      setLoader(true);
      try {
        const response = await getPatientById(patient_id);
        if (response?.statusCode === 200) setPatient(response.body?.data);
        else setPatient({});
      } catch (error) {
        console.log('error', error);
        setPatient({});
      }
      finally {
        setLoader(false);
      }
    }

    if (patient_id) {
      fetch_admission();
    }
  }, [patient_id, fetch_admission, subTabSecond]);

  const dasboardDirectlyChanges = async () => {
    if (patient_id !== undefined) {
      setLoader(true);
      try {
        const response = await getAdmissionsListByPatientId(patient_id);
        if (response?.statusCode === 200) setadmissions(response.body?.data);
        else setadmissions([]);
      } catch (error) {
        setadmissions([]);
        setselected_admission(null);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    fetchPatientData();
  }, [fetchPatientData]);

  const selectAdmission = async (e: any) => {
    const request = await getAdmissionById(e.target.value);
    if (request?.statusCode === 200) {
      setselected_admission(request?.body);
    } else {
      setselected_admission(null);
    }
  };

  useEffect(() => {
    if (basicInfoTab) {
      setsubTabSecond(basicInfoTab);
    };
  }, [basicInfoTab]);

  return (
    <>
      {loader && (
        <div className="loader-box">
          <span className="loader"></span>
        </div>
      )}

      <div className="row ">
        <div className="col-12">
          <div className="row">
            <div className="col-12 col">
              <div className="page-title-box common-top-con d-sm-flex align-items-center justify-content-between">
              <div className="d-sm-flex align-items-center justify-content-between">
                {/* <PatientHeader /> */}
                <div className="">
                  {(subTabSecond === "1" || "2") && (
                    <>
                      <label>Select Hospital</label>
                      <select
                        className="form-control"
                        defaultValue={
                          admissions.filter(
                            (admission: any) =>
                              admission?.admitted && !admission.discharged
                          )[0]
                        }
                        onChange={(e) => selectAdmission(e)}
                      >
                        {admissions.map((admission: any, index: number) => {
                          const dischargeDate = new Date(admission?.discharge_date_entry);
                          const offsetMilliseconds = new Date().getTimezoneOffset() * 60000;
                          const adjustedDischargeDate = new Date(dischargeDate.getTime() - offsetMilliseconds);
                          const formattedDischargeDate = adjustedDischargeDate.toISOString().split('T')[0];
                          return (
                            <option key={index} value={admission?.id}>
                              {admission?.hospital_property?.hospital?.name} -{" "}
                              {admission?.hospital_property?.landmark}{" "}
                              {admission?.admitted && !admission.discharged ? (
                                "(Currently Admitted)"
                              ) : (
                                `(${formatDateToLocaleDateString(admission?.admission_date_entry)} - ${formatDateToLocaleDateString(formattedDischargeDate)})`
                              )}
                            </option>
                          );
                        })}
                      </select>
                    </>
                  )}
                </div>
              </div>
              <div className="d-sm-flex align-items-center justify-content-between">
                <div className="page-title-right d-flex">
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        className={subTabSecond === "1" ? "active" : ""}
                        onClick={() => {
                          subTabSecondtoggle("1");
                        }}
                      >
                        <span className="d-none d-sm-block">Dashboard</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={subTabSecond === "3" ? "active" : ""}
                        onClick={() => {
                          subTabSecondtoggle("3");
                        }}
                      >
                        <span className="d-none d-sm-block">Basic Info</span>
                      </NavLink>
                    </NavItem>
                    {admissions?.length > 0 && (
                      <NavItem>
                        <NavLink
                          className={subTabSecond === "2" ? "active" : ""}
                          onClick={() => {
                            subTabSecondtoggle("2");
                          }}
                        >
                          <span className="d-none d-sm-block">
                            Admission Info
                          </span>
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        className={subTabSecond === "5" ? "active" : ""}
                        onClick={() => {
                          subTabSecondtoggle("5");
                        }}
                      >
                        <span className="d-none d-sm-block">Past History</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={subTabSecond === "7" ? "active" : ""}
                        onClick={() => {
                          subTabSecondtoggle("7");
                        }}
                      >
                        <span className="d-none d-sm-block">Discharge Info</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              </div>
            </div>
          </div>

          <TabContent activeTab={subTabSecond} className="text-muted">
            {subTabSecond === "1" &&
              <TabPane tabId="1">
                <PatientDetailDashboard
                  patient={patient}
                  patientChangesFlags={dasboardDirectlyChanges}
                  activeTab={subTabSecond}
                  selected_admission={selected_admission}
                />
              </TabPane>}
            {subTabSecond === "2" &&
              <TabPane tabId="2">
                <PatientDetailAdmission
                  selected_admission={selected_admission}
                  setselected_admission={setselected_admission}
                  patient={patient}
                />
              </TabPane>
            }
            {subTabSecond === "3" &&
              <TabPane tabId="3">
                <PatientDetailBasicInfo
                  activeTab={subTabSecond}
                  patient={patient}
                  isBasicInfoEditable={isBasicInfoEditable}
                  setIsBasicInfoEditable={setIsBasicInfoEditable}
                  basicInfoTab={basicInfoTab}
                  fetchPatientData={fetchPatientData}
                />
              </TabPane>
            }
            {/* <TabPane tabId="4">
              <PatientDetailIllness
                patient={patient}
                fetchPatientData={fetchPatientData}
              />
            </TabPane> */}
            {subTabSecond === "5" &&
              <TabPane tabId="5">
                <PatientDetailHistory
                  patient={patient}
                  fetchPatientData={fetchPatientData}
                />
              </TabPane>
            }
            {/* <TabPane tabId="6">
              <PatientDetailAssessment
                patient={patient}
                fetchPatientData={fetchPatientData}
              />
            </TabPane> */}
            {subTabSecond === "7" &&
              <TabPane tabId="7">
                <PatientsDischargeInfo
                  setselected_admission={setselected_admission}
                  selected_admission={selected_admission}
                  patient={patient}
                  fetchPatientData={fetchPatientData}
                />
              </TabPane>
            }
          </TabContent>
        </div>
      </div >
    </>
  );
};

export default SinglePatient;