import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
//import apexChart from "../AllCharts/apex"
import DashedLinenew from "../AllCharts/apex/dashedLinenew"
import { Card, CardBody, CardTitle, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import "../../assets/scss/teleicu/reports.scss";
import GeneralReports from "./GeneralReports";
import LineListingReports from "./LineListingReports";
import ProgressiveReports from "./ProgressiveReports";
import ReportHeader from "../../components/Header/ReportHeader";
import SideHeader from "../../components/Header/SideHeader";
import { ActionTypes } from "../../redux/types/reportTabsTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import AgeRange from "./AgeRange";
import Header from "../../components/Header/Header";

const Reports = () => {
  const [subTab, setSubTab] = useState("1");
  const { currentActiveTab } = useSelector((state: RootState) => state?.reportHeader);

  const subTabtoggle = useCallback((tab: string) => {
    setSubTab(tab)
  }, [currentActiveTab]);

  useEffect(() => {
    console.log(currentActiveTab)
  }, [currentActiveTab]);
  return (
    <>
      <Header HeaderName="Reports" />
      <Sidebar />
      <div className="main-content">
        <div className="page-content report-main">
        <ReportHeader />
          <div className="container-fluid">
            <TabContent activeTab={currentActiveTab ? currentActiveTab : "1"} className="text-muted">
              <TabPane tabId="1">
                <GeneralReports subTab={subTab} subTabtoggle={subTabtoggle} />
              </TabPane>
              <TabPane tabId="2">
                <LineListingReports />
              </TabPane>
              <TabPane tabId="3">
                <ProgressiveReports />
              </TabPane>
              <TabPane tabId="4">
                <AgeRange subTab={subTab} subTabtoggle={subTabtoggle} />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;