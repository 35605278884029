import React, { useState, FC, MouseEvent, useEffect } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import maleAvatar from "../../../assets/images/users/male_avatar.png";
import femaleAvatar from "../../../assets/images/users/female_avatar.png";
import { useSelector } from "react-redux";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { getAdmissionsListByPatientId, putPatientAdmission } from "../../../redux/actions/patientAction";
import { SuccessToast } from "../../../utils/toastNotifications";
import oxygen from '../../../assets/images/oxygen.png';
import Critical from '../../../assets/images/Critical.png';
import ventilator from '../../../assets/images/ventilator.png';
import SvgFilesIcon from "../../SvgFiles/SvgFiles";
import { Tooltip } from "@mui/material";

const PatientListCardView: FC<any> = ({ patient, handleEdit, handleview, currentPatientActiveTab }) => {
  const { user_info } = useSelector((state: any) => state.user);
  const [menu, setMenu] = useState(false);
  const [patiendData, setPatiendData] = useState<any>([]);

  const is_patient_admitted =
    patient?.current_admitted_hospital_property?.id ===
    user_info?.hospital_property?.id &&
    patient?.discharge === false &&
    patient?.admitted;
  const is_patient_discharged =
    patient?.current_admitted_hospital_property?.id ===
    user_info?.hospital_property?.id &&
    patient?.admitted === false &&
    patient?.discharge;

  const handleEditButton = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    handleEdit(patient);
  };

  const dropdownClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setMenu(!menu);
  };

  const handleCardClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    handleview(patient);
  };

  const hendalIsCritical = async (event: string, successMessage: string) => {
    const payload: any = {};
    payload[event] = !patiendData[0]?.[event]
    if (event === "is_on_ventilator" && payload[event]) {
      payload["is_critical"] = true;
    } else if (event === "is_on_ventilator" && !payload[event]) {
      payload["is_critical"] = false;
    }
    await putPatientAdmission({
      ...payload,
      id: patiendData[0]?.id
    });

    getPatientAdmissionData();
    SuccessToast(successMessage);
  }

  const getPatientAdmissionData = async () => {
    try {
      const response = await getAdmissionsListByPatientId(patient.id);
      if (response?.statusCode === 200 && Array.isArray(response?.body?.data)) {
        setPatiendData(response?.body?.data);
      } else {
        setPatiendData([]);
      }
    } catch (error) {
      console.error("Error fetching investigation types:", error);
    }
  }

  useEffect(() => {
    getPatientAdmissionData();
  }, [currentPatientActiveTab, patient])

  return (
    <><div className="patient-box">
      <a href="javascript:void(0);" onClick={handleCardClick}>
        <div className="patient-title-box">
          <div className="icon-box">
            <SvgFilesIcon iconname={patient?.sex || "Male"} />
          </div>
          <h4>{patient?.first_name} {patient?.middle_name} {patient.last_name}</h4>
        </div>
        {is_patient_admitted && (
          <div className="card-dropdown">
            <div className="demo" onClick={(e) => e.stopPropagation()}>
              <Dropdown isOpen={menu} toggle={dropdownClick} className="d-inline-block drop-menu-right">
                <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
                  <SvgFilesIcon iconname={"more"} />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end more-dropdown">
                  <DropdownItem onClick={handleEditButton} tag="a" className="cursor-pointer">Edit</DropdownItem>
                  {!patiendData[0]?.is_critical && (
                    <DropdownItem className="critical-icon-box" tag="a" onClick={() => hendalIsCritical("is_critical", "Patient marked as Critical")}>Mark As Critical </DropdownItem>
                  )}
                  {patiendData[0]?.is_critical && (
                    <DropdownItem className="critical-icon-box" tag="a" onClick={() => hendalIsCritical("is_critical", "Patient marked as Not Critical")}>Mark As Not Critical </DropdownItem>
                  )}

                  {!patiendData[0]?.is_on_ventilator && (
                    <DropdownItem className="ventilator-icon-box" tag="a" onClick={() => hendalIsCritical("is_on_ventilator", "Patient marked as Ventilator")}>Mark As Ventilator </DropdownItem>
                  )}
                  {patiendData[0]?.is_on_ventilator && (
                    <DropdownItem className="ventilator-icon-box" tag="a" onClick={() => hendalIsCritical("is_on_ventilator", "Patient marked as Not Ventilator")}>Mark As Not Ventilator </DropdownItem>
                  )}

                  {!patiendData[0]?.is_on_oxygen && (
                    <DropdownItem className="oxygen-icon-box" tag="a" onClick={() => hendalIsCritical("is_on_oxygen", "Patient marked as Oxygen")}>Mark As Oxygen </DropdownItem>
                  )}
                  {patiendData[0]?.is_on_oxygen && (
                    <DropdownItem className="oxygen-icon-box" tag="a" onClick={() => hendalIsCritical("is_on_oxygen", "Patient marked as Not Oxygen")}>Mark As Not Oxygen </DropdownItem>
                  )}

                </DropdownMenu>

              </Dropdown>
            </div></div>
        )}
        <div className="contact-box">
          <div className="common-box">
            <span>Contact Person</span>
            <p>{patient?.emergency_contact_name || '-'}</p>
          </div>
          <div className="common-box">
            <span>Contact Number</span>
            <p>{patient?.emergency_contact_no ||
              '-'}</p>
          </div>
        </div>
        <div className="address-box">
          <span>Address</span>
          <p>{patient?.address || " "} {patient?.city?.city || " "} {patient?.state?.state || " "} {patient?.country?.country}</p>
        </div>
        <div className="status-box">
          {/* <div className="gender-box">
            <p>{patient?.sex || '-'}</p>
          </div> */}


          {is_patient_admitted ? (
            <div className="admitted-box">
              <SvgFilesIcon iconname={patient?.sex || "Male"} />
              <p>Admitted</p>
            </div>
          ) : is_patient_discharged ? (
            <div className="discharge-box">
              <SvgFilesIcon iconname={patient?.sex || "Male"} />
              <p>Discharged</p>
            </div>
          ) : (
            <div className="not-admitted-box">
              <SvgFilesIcon iconname={patient?.sex || "Male"} />
              <p>Not Admitted</p>
            </div>
          )}
          <div className="condition-icons-box">
            {is_patient_admitted && (<>
              {
                patiendData[0]?.is_on_oxygen && (
                  <Tooltip title="Oxygen" arrow>
                    <div className="oxygen-icon-box">
                      <SvgFilesIcon iconname={"oxygen"} />
                    </div>
                  </Tooltip>
                )
              }
              {patiendData[0]?.is_critical && (
                <Tooltip title="Critical" arrow>
                  <div className="critical-icon-box">
                    <SvgFilesIcon iconname={"critical"} />
                  </div>
                </Tooltip>
              )}
              {patiendData[0]?.is_on_ventilator && (
                <Tooltip title="Ventilator" arrow>
                  <div className="ventilator-icon-box">
                    <SvgFilesIcon iconname={"ventilator"} />
                  </div>
                </Tooltip>
              )}
            </>
            )}
          </div>

        </div>
      </a>
    </div>


    </>
  );
};

export default PatientListCardView;