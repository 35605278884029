import { patient_admission_observation_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";

export const postPatientAdmissionObservation = async (values: any) => {
    try {
        const { data } = await axiosApi.post(patient_admission_observation_routes.post_patient_admission_observation, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const getPatientAdmissionObservationByPatientAdmissionId = async (patient_admission_id: string) => {
    try {
        const { data } = await axiosApi.get(
            `${patient_admission_observation_routes.get_patient_observation_by_patient_admission_id}?patient_admission_id=${patient_admission_id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const putPatientAdmissionObservation = async (values: any) => {
    try {
        const { data } = await axiosApi.put(
            patient_admission_observation_routes.put_patient_admission_observation,
            values
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const deletePatientAdmissionObservation = async (id: string) => {
    try {
        const { data } = await axiosApi.delete(
            `${patient_admission_observation_routes.delete_patient_admission_observation}/${id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};
