import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody } from "reactstrap";
import DataGridTable from "../../../components/DataGrid/DataGridTable";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowModel,
} from "@mui/x-data-grid";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteMedicineType,
  getMedicineTypesList,
  postMedicineType,
  updateMedicineType,
} from "../../../redux/actions/medicineTypeAction";
import { getMedicineContentsList,
  postMedicineContentsList,
  updateMedicineContentsList,
  deleteMedicineContentsList
} from "../../../redux/actions/medicineTypeAction";
 
import { findTextInString } from "../../../helpers/helperFunctions";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header/Header";
import DeleteConfirmationModal from "../../../components/DeletePopupComponent/DeletePopup";
 
// Define the Row type to match the grid columns
type Row = {
  id: string;
  name: string;
  description: string;
};
 
const Medicine = () => {
  const navigate = useNavigate();
 
  // Initialize rows with the correct type
  const initialRows: Row[] = [{ id: "1", name: "", description: "" }];
 
  const [page_number, setPage_number] = useState(1);
  const [per_page, setPer_page] = useState(0);
  const [rows, setRows] = useState<Row[]>(initialRows);
  const [rowId, setrowId] = useState<string | null>(null);
  const [globalSearch, setGlobalSearch] = useState("");
  const handlePageChange = (params: any) => setPage_number(params.page);
  const handlePageSizeChange = (params: any) => setPer_page(params.pageSize);
  const processRowUpdate = (newRow: GridRowModel) => newRow;
  const onCellEditCommit = (params: any) => setrowId(params?.id);
  const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
 
  const addRow = () => {
    if (rows?.some((row) => row?.id === initialRows[0]?.id)) {
      ErrorToast("Please save last added row.");
      return rows;
    }
    setRows((prevRows) => [
      { id: "1", name: "", description: "" },
      ...prevRows,
    ]);
  };
 
  const handleUpdateMedicine = useCallback(
    async ({
      id,
      name,
      description,
    }: {
      id: string;
      name: string;
      description: string;
    }) => {
      try {
        if (name.trim() === "") return ErrorToast("Content Name is required!");
        let response;
        if (id === initialRows[0]?.id)
          response = await postMedicineContentsList( { name, description, id } );
        else
          response = await updateMedicineContentsList({
            id,
            name,
            description,
          });
 
        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          const  body  = await getMedicineContentsList({
            per_page: per_page,
            page_number: page_number,
          });
          if (body) setRows(body);
          else setRows([]);
          setrowId(null);
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [initialRows, per_page, page_number]
  );
 
 
  const handleDeleteMedicine = useCallback(
    async ({ id }: { id: string }) => {
      try {
        if (id === initialRows[0]?.id)
          return ErrorToast("Record is not saved yet!");
        const response = await deleteMedicineContentsList({ id });
 
        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          const body = await getMedicineContentsList({
            per_page: per_page,
            page_number: page_number,
          });
          if (body) setRows(body);
          else setRows([]);
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [initialRows, per_page, page_number]
  );
 
 
  const handleDeleteConfirmation = useCallback(() => {
    if (deleteRowId) {
      handleDeleteMedicine({ id: deleteRowId });
      setDeleteRowId(null);
      setIsDeleteModalOpen(false);
    }
  }, [deleteRowId, handleDeleteMedicine]);
 
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);
 
  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: "name",
        headerName: "Content Name",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: true,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "description",
        headerName: "Brand Name",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: true,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        headerName: "actions",
        type: "actions",
        width: 150,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            color={"primary"}
            disabled={params?.id !== rowId}
            onClick={() =>
              handleUpdateMedicine({
                id: params.row.id.toString(),
                name: params.row.name,
                description: params.row.description,
              })
            }
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            disabled={params?.id === initialRows[0]?.id}
            color="error"
            onClick={() => {
              setDeleteRowId(params.row.id.toString());
              toggleDeleteModal();
            }}
          />,
        ],
      },
    ],
    [toggleDeleteModal, handleUpdateMedicine, initialRows, rowId]
  );
 
  useEffect(() => {
    async function getMedicines() {
      const  request  = await getMedicineContentsList({
        per_page: per_page,
        page_number: page_number,
      });
      if (request.length>0) {
        setRows(request)  
       
      }
            else setRows([]);
    }
    getMedicines();
  }, [page_number, per_page]);
 
  const filteredRows = useMemo(
    () => rows?.filter(({ name }) => findTextInString(name, globalSearch)),
    [rows, globalSearch]
  );
 
  return (
    <>
      <Header HeaderName="Master Pages" />
      {/* <Sidebar /> */}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        pageName="medicinetype"
        onConfirm={handleDeleteConfirmation}
      />
      <div className="row">
        <div className="col-12 col">
          <div className="page-title-box master-title">
            <h4 className="mb-sm-0 font-size-18">Medicines</h4>
          </div>
        </div>
        <div className="col-12">
          <DataGridTable
            columns={columns}
            rows={filteredRows}
            processRowUpdate={processRowUpdate}
            pageSizeChange={handlePageSizeChange}
            pageChange={handlePageChange}
            addRow={addRow}
            onCellEditCommit={onCellEditCommit}
            setGlobalSearch={setGlobalSearch}
          />
        </div>
      </div>
    </>
  );
};
 
export default Medicine;
 
 