export const registerServiceWorker = () => {
  try {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("firebase-messaging-sw.js")
        .then(function (registration) {
          return registration.scope;
        })
        .catch(function (err) {
          return err;
        });
    }
  } catch (e) {
    console.log("Warning! Notifications are not supported on your browser.");
  }
};
