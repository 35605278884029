import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody } from "reactstrap";
import DataGridTable from "../../../components/DataGrid/DataGridTable";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowModel,
} from "@mui/x-data-grid";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deletePatientBloodDiseases,
  getPatientBloodDiseasessList,
  postPatientBloodDiseases,
  updatePatientBloodDiseases,
} from "../../../redux/actions/patientBloodDiseasesAction";
import { findTextInString } from "../../../helpers/helperFunctions";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header/Header";
import DeleteConfirmationModal from "../../../components/DeletePopupComponent/DeletePopup";

const PatientBloodDiseases = () => {
  const navigate = useNavigate();
  const initialRows = useMemo(() => [{ id: "1", name: "" }], []);
  type Row = (typeof initialRows)[number];
  const [page_number, setPage_number] = useState(1);
  const [per_page, setPer_page] = useState(0);
  const [rows, setRows] = useState<any[]>(initialRows);
  const [rowId, setrowId] = useState(null);
  const [globalSearch, setGlobalSearch] = useState("");
  const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handlePageChange = (params: any) => setPage_number(params.page);
  const handlePageSizeChange = (params: any) => setPer_page(params.pageSize);
  const processRowUpdate = (newRow: GridRowModel) => newRow;
  const onCellEditCommit = (params: any) => setrowId(params?.id);

  const addRow = () => {
    if (rows?.some((row) => row?.id === initialRows[0]?.id)) {
      ErrorToast("Please save last added row.");
      return rows;
    }
    setRows((prevRows) => [{ id: "1", name: "" }, ...prevRows]);
  };

  /* UPDATE - ADD PAST HISTORY TYPE*/
  const handleUpdatePatientBloodDiseases = useCallback(
    async ({ id, name }: { id: string; name: string }) => {
      try {
        if (name.trim() === "")
          return ErrorToast("PatientBloodDiseases is required field!");
        let response;
        if (id === initialRows[0]?.id)
          response = await postPatientBloodDiseases({ name });
        else response = await updatePatientBloodDiseases({ id, name });

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          const { body } = await getPatientBloodDiseasessList({
            per_page: per_page,
            page_number: page_number,
          });
          if (body) setRows(body?.data);
          else setRows([]);

          setrowId(null);
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [initialRows, per_page, page_number]
  );

  /* DELETE PAST HISTORY TYPE TYPE */
  const handleDeletePatientBloodDiseases = useCallback(
    async ({ id }: { id: string }) => {
      try {
        if (id === initialRows[0]?.id)
          return ErrorToast("Record is not saved yet!");
        const response = await deletePatientBloodDiseases({ id });

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          const { body } = await getPatientBloodDiseasessList({
            per_page: per_page,
            page_number: page_number,
          });
          if (body) setRows(body?.data);
          else setRows([]);
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [per_page, page_number, initialRows]
  );

  const handleDeleteConfirmation = useCallback(() => {
    if (deleteRowId) {
      handleDeletePatientBloodDiseases({ id: deleteRowId });
      setDeleteRowId(null);
      setIsDeleteModalOpen(false);
    }
  }, [deleteRowId, handleDeletePatientBloodDiseases]);

  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);


  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: "name",
        headerName: "Name",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: true,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        headerName: "actions",
        type: "actions",
        width: 150,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            color={"primary"}
            disabled={params?.id !== rowId}
            onClick={() =>
              handleUpdatePatientBloodDiseases({
                id: params.row.id.toString(),
                name: params.row.name,
              })
            }
          />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            disabled={params?.id === initialRows[0]?.id}
            color="error"
            onClick={() => {
              setDeleteRowId(params.row.id.toString());
              toggleDeleteModal()
            }}
          />,
        ],
      },
    ],
    [
      toggleDeleteModal,
      handleUpdatePatientBloodDiseases,
      initialRows,
      rowId,
    ]
  );

  useEffect(() => {
    const getPatientBloodDiseasess = async () => {
      const { body } = await getPatientBloodDiseasessList({
        per_page: per_page,
        page_number: page_number,
      });
      if (body) setRows(body?.data);
      else setRows([]);
    };
    getPatientBloodDiseasess();
  }, [page_number, per_page]);

  const filteredRows = useMemo(
    () => rows?.filter(({ name }) => findTextInString(name, globalSearch)),
    [rows, globalSearch]
  );

  return (
    <>
      <Header HeaderName="Master Pages" />
      {/* <Sidebar /> */}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        pageName="patient blood diseases"
        onConfirm={handleDeleteConfirmation}
      />
      <div className="row">
        <div className="col-12 col">
          <div className="page-title-box master-title">
            <h4 className="mb-sm-0 font-size-18">Patient Blood Diseases</h4>
          </div>
        </div>
        <div className="col-12">
          <DataGridTable
            columns={columns}
            rows={filteredRows}
            processRowUpdate={processRowUpdate}
            pageSizeChange={handlePageSizeChange}
            pageChange={handlePageChange}
            addRow={addRow}
            onCellEditCommit={onCellEditCommit}
            setGlobalSearch={setGlobalSearch}
          />
        </div>
      </div>
    </>
  );
};

export default PatientBloodDiseases;
