import { discharge_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IAddDischargeTypesParams, IDeleteDischargeTypesParams, IGetDischargeTypesParams, IUpdateDischargeTypesParams } from "../types/dischargeTypes";


export const getDischargeTypesList = async ({ page_number, per_page }: IGetDischargeTypesParams) => {

    try {
        const params: { [key: string]: number } = {};

        if (page_number !== undefined) params['page_number'] = page_number;
        if (per_page !== undefined) params['per_page'] = per_page;

        const { data } = await axiosApi.get(discharge_routes.get_discharge_types_list, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}


export const postDischargeType = async ({ name }: IAddDischargeTypesParams) => {
    try {
        const { data } = await axiosApi.post(discharge_routes.post_discharge_type, { name });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const updateDischargeType = async ({ id, name }: IUpdateDischargeTypesParams) => {
    try {
        const { data } = await axiosApi.put(discharge_routes.put_discharge_type, { id, name });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const deleteDischargeType = async ({ id }: IDeleteDischargeTypesParams) => {
    try {
        const { data } = await axiosApi.delete(`${discharge_routes.delete_discharge_type}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data?.data;
    }
}