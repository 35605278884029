import { user_types_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IAddUserTypeParams, IDeleteUserTypeParams, IGetUserTypesParams, IUpdateUserTypesParams } from "../types/userTypeTypes";


export const getUserTypesList = async ({ page_number, per_page }: IGetUserTypesParams) => {

    try {
        const params: { [key: string]: number } = {};

        if (page_number !== undefined) params['page_number'] = page_number;
        if (per_page !== undefined) params['per_page'] = per_page;

        const { data } = await axiosApi.get(user_types_routes.get_user_type_list, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}


export const postUserType = async ({ user_type }: IAddUserTypeParams) => {
    try {
        const { data } = await axiosApi.post(user_types_routes.post_user_type, { user_type });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const updateUserType = async ({ id, user_type }: IUpdateUserTypesParams) => {
    try {
        const { data } = await axiosApi.put(user_types_routes.put_user_type, { id, user_type });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const deleteUserType = async ({ id }: IDeleteUserTypeParams) => {
    try {
        const { data } = await axiosApi.delete(`${user_types_routes.delete_user_type}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data?.data;
    }
}