import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { Collapse, Label } from "reactstrap";
import { ErrorToast, SuccessToast } from "../../../../utils/toastNotifications";
import {
  deletePatientAdmissionObservation,
  getPatientAdmissionObservationByPatientAdmissionId,
  postPatientAdmissionObservation,
  putPatientAdmissionObservation,
} from "../../../../redux/actions/patientAdmissionObservation";
import {
  IPatientAdmissionObservationTypes,
  IPatientAdmissionSingleObservation,
} from "../../../../redux/types/patientAdmissionObservationTypes";
import { ErrorMessage, Field, Form, Formik } from "formik";
import CustomMUIDatePicker from "../../../../components/UI/CustomMUIDatePicker";

const Observation: React.FC<any> = ({ selected_admission }) => {
  const [observation, setObservation] = useState<
    IPatientAdmissionObservationTypes[]
  >([]);
  const [isObservationOpen, setIsObservationOpen] = useState<boolean>(false);
  const [editData, setEditData] =
    useState<IPatientAdmissionObservationTypes | null>();
  const [collapsedItems, setCollapsedItems] = useState<boolean[]>([]);

  useEffect(() => {
    setCollapsedItems(new Array(observation?.length).fill(true));
  }, [observation]);

  const toggleCollapse = (index: number) => {
    setCollapsedItems((prevState) =>
      prevState?.map((item, i) => (i === index ? !item : true))
    );
  };
  const validationSchema = Yup.object({
    date: Yup.string().required("Date is required"),
    doctor: Yup.string().required("Doctor is required"),
    remarks: Yup.string().required("Remarks name is required"),
    // document: Yup.string().required("Document Start time is required"),
    patient_admission_id: Yup.string(),
  });

  const initialValues = {
    date: editData?.date || "",
    doctor: editData?.doctor || "",
    remarks: editData?.remarks || "",
    // document: editData?.document || "test.pdf",
    patient_admission_id: editData?.patient_admission_id || "",
  };

  const getObservationByPatientAdmissionId = useCallback(async () => {
    if (selected_admission !== null) {
      const request = await getPatientAdmissionObservationByPatientAdmissionId(
        selected_admission?.id
      );
      if (request?.statusCode === 200) setObservation(request?.body?.data);
      else setObservation([]);
    }
  }, [selected_admission]);

  useEffect(() => {
    getObservationByPatientAdmissionId();
  }, [getObservationByPatientAdmissionId]);

  const submitForm = async (
    values: IPatientAdmissionObservationTypes,
    resetForm: () => void
  ) => {
    try {
      let response;
      if (!editData) {
        response = await postPatientAdmissionObservation({
          ...values,
          patient_admission_id: selected_admission?.id,
        });
      } else {
        response = await putPatientAdmissionObservation({
          ...values,
          id: editData?.id,
        });
      }

      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        getObservationByPatientAdmissionId();
        setIsObservationOpen(false);
        resetForm();
      } else {
        if (response?.errors?.length > 0) {
          ErrorToast(response?.errors[0]?.msg);
        }
      }
    } catch (error) {
      ErrorToast(
        "An error occurred while submitting the form. Please try again."
      );
    }
  };

  const handleDeleteObservation = useCallback(
    async (id: string) => {
      try {
        if (id.trim() === "" || !id) return ErrorToast("Try again!");
        const response = await deletePatientAdmissionObservation(id);

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          getObservationByPatientAdmissionId();
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [getObservationByPatientAdmissionId]
  );

  const handleAddObservation = () => {
    setEditData(null);
    setIsObservationOpen(true);
  };

  const handleEditObservation = (
    e: React.MouseEvent,
    singleObservation: IPatientAdmissionSingleObservation
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setEditData(singleObservation);
    setIsObservationOpen(true);
  };

  return (
    <>
      <div className="add-row-btn">
        <button
          className="btn btn-primary medium-btn mb-2"
          onClick={() => handleAddObservation()}
        >
          {" "}
          Add{" "}
        </button>
      </div>

      <div className="col-12">
        <div className="accordion" id="accordion">
          {observation &&
            observation?.length > 0 &&
            observation?.map((singleObservation: any, index: number) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className={`accordion-button fw-medium ${collapsedItems[index] ? "collapsed" : ""
                      }`}
                    type="button"
                    onClick={() => toggleCollapse(index)}
                    style={{ cursor: "pointer" }}
                  >
                    Date - {singleObservation?.date} Doctor -{" "}
                    {singleObservation?.doctor}
                    {/* {singleObservation?.document}{" "} */}
                    <span className="right-date">
                      <div className="gap-3">
                        <a className="text-success mr-2" href="/#">
                          <i
                            className="mdi mdi-pencil font-size-16"
                            onClick={(e) =>
                              handleEditObservation(e, singleObservation)
                            }
                          ></i>
                        </a>
                        <a
                          className="text-danger"
                          href="/#"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleDeleteObservation(singleObservation?.id);
                          }}
                        >
                          <i className="mdi mdi-delete font-size-16"></i>
                        </a>
                      </div>
                    </span>
                  </button>
                </h2>
                <Collapse
                  isOpen={!collapsedItems[index]}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <div className="table-box">
                      <table className="table">
                        <thead className="table-light">
                          <th>Remarks : </th>
                        </thead>
                        <tbody>
                          <td>{singleObservation?.remarks}</td>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Collapse>
              </div>
            ))}
        </div>
      </div>
      {/* <div className="table-responsive">
                <table className="table">
                    <thead className="table-light">
                        <tr>
                            <th>Date</th>
                            <th>Doctor</th>
                            <th>Remarks</th>
                            <th>Document</th>
                            <th className="action">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {observation && observation.length > 0 && observation.map((singleObservation: any, index: number) => (
                            <tr>
                                <td>{singleObservation?.date}</td>
                                <td>{singleObservation?.doctor}</td>
                                <td>{singleObservation?.remarks}</td>
                                <td>{singleObservation?.document}</td>
                                <td className="action">
                                    <div className="gap-3">
                                        <a className="text-success mr-2" href="/#">
                                            <i className="mdi mdi-pencil font-size-16"
                                                onClick={(e) => handleEditObservation(e, singleObservation)}
                                            ></i>
                                        </a>
                                        <a className="text-danger" href="/#"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                handleDeleteObservation(singleObservation?.id);
                                            }}>
                                            <i className="mdi mdi-delete font-size-16"></i>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div> */}

      {isObservationOpen && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { resetForm }) => {
            submitForm(values, resetForm);
          }}
        >
          {({
            isValid,
            isSubmitting,
            resetForm,
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            dirty,
            setFieldValue,
          }) => (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (errors) {
                    const firstError = Object.values(errors)[0];
                    if (Array.isArray(firstError)) {
                      const errorMessage = firstError.join(", ");
                      ErrorToast(errorMessage);
                    } else ErrorToast(firstError);
                  }
                  handleSubmit();
                }}
              >
                <div className="right-panel">
                  <h4>Observation</h4>
                  <button
                    className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light "
                    onClick={() => setIsObservationOpen(false)}
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Date</Label>
                        <CustomMUIDatePicker
                          name="date"
                          type="formik"
                          setState={setFieldValue}
                          value={values?.date}
                        />
                        <ErrorMessage
                          name="date"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Doctor</Label>
                        <Field
                          name="doctor"
                          className="form-control"
                          type="text"
                          placeholder="Enter Doctor"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="doctor"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Remarks</Label>
                        <Field
                          name="remarks"
                          className="form-control"
                          as="textarea"
                          placeholder="Enter Remarks"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="remarks"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    {/* Upload Image */}
                    {/* <div className="col-12">
                                            <div className="mb-3">
                                                <Label className="form-label">Document</Label>
                                                <Field
                                                    name="document"
                                                    className="form-control"
                                                    type="file"
                                                    placeholder="Enter Document"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                <ErrorMessage
                                                    name="document"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                        </div> */}
                    {/* <div className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Document</Label>
                        <Field
                          name="document"
                          className="form-control"
                          type="text"
                          placeholder="Enter Document"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="document"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div> */}
                    <div className="col-12 text-center">
                      <button
                        className="btn btn-primary mr-2 medium-btn"
                        type="submit"
                      >
                        {editData ? "Update" : "Add"}
                      </button>
                      <button
                        className="btn btn-secondary medium-btn"
                        onClick={() => resetForm()}
                      >
                        Reset{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default Observation;
