import axiosApi from "../../api/apiHelper";
import { patient_admission_habits_routes } from "../api_path";
import { IPatientAdmissionHabitsTypes } from "../types/patientAdmissionHabitsTypes";

export const postPatientAdmissionHabits = async (values: IPatientAdmissionHabitsTypes) => {
    try {
        const { data } = await axiosApi.post(patient_admission_habits_routes.post_patient_admission_habits, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const getPatientAdmissionHabitsByPatientAdmissionId = async (
    patient_admission_id: string,
) => {
    try {
        const { data } = await axiosApi.get(`${patient_admission_habits_routes.get_patient_habits_by_patient_admission_id}?patient_admission_id=${patient_admission_id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const putPatientAdmissionHabits = async (values: any) => {
    try {
        const { data } = await axiosApi.put(patient_admission_habits_routes.put_patient_admission_habits, values);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};

export const deletePatientAdmissionHabits = async (id: string) => {
    try {
        const { data } = await axiosApi.delete(`${patient_admission_habits_routes.delete_patient_admission_habits}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};
