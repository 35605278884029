import { city_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IAddCityParams, IDeleteCityParams, IGetCityByStateListParams, IGetCityListParams, IUpdateCityParams } from "../types/cityTypes";

export const getCityList = async ({ page_number, per_page }: IGetCityListParams) => {
  try {
    const params: { [key: string]: number } = {};

    if (page_number !== undefined) params['page_number'] = page_number;
    if (per_page !== undefined) params['per_page'] = per_page;

    const { data } = await axiosApi.get(city_routes.get_city_list, { params });
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const getCityByStateList = async ({ state_id, page_number, per_page }: IGetCityByStateListParams) => {
  try {
    const { data } = await axiosApi.get(`${city_routes.get_city_by_state}?state_id=${state_id}`);
    return data;
  } catch (error: any) {
    return error?.response?.data?.errors;
  }
};

export const postCity = async ({ country_id, state_id, city, zipcode }: IAddCityParams) => {
  try {
    const { data } = await axiosApi.post(city_routes.post_city, { country_id, state_id, city, zipcode });
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const updateCity = async ({ id, state_id, country_id, city, zipcode }: IUpdateCityParams) => {
  try {
    const { data } = await axiosApi.put(city_routes.put_city, { id, state_id, country_id, city, zipcode });
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const deleteCity = async ({ id }: IDeleteCityParams) => {
  try {
    const { data } = await axiosApi.delete(`${city_routes?.delete_city}/${id}`);
    return data;
  } catch (error: any) {
    return error?.response?.data?.data;
  }
};
