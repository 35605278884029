import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import UserRolesListing from "./components/UserRolesListing";
import "../../assets/scss/teleicu/reports.scss";

const UserRoles = () => {
  return (
    <>
      <Header HeaderName="USER ROLES" />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <UserRolesListing />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserRoles;
