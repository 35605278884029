export enum ActionTypes {
    GET_COUNTRY_LIST_REQUESTED = "GET_COUNTRY_LIST_REQUESTED",
    GET_COUNTRY_LIST_FAILURE = "GET_COUNTRY_LIST_FAILURE",
    GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS",
}

export type Country = {
    id: string;
    country: string;
};

export type CountryType = {
    countryList: Country;
};

export interface CountryListRequest {
    type: typeof ActionTypes.GET_COUNTRY_LIST_REQUESTED
}

export interface CountryListFailure {
    type: typeof ActionTypes.GET_COUNTRY_LIST_FAILURE;
}

export interface CountryListSuccess {
    type: typeof ActionTypes.GET_COUNTRY_LIST_SUCCESS;
    payload: CountryType[];
}

export interface ICountriesProps {
    isActiveTab: boolean;
};

export interface IDeleteCountryParams {
    id: string,
}

export interface IAddCoutryParams {
    country: string;
}

export type CountryListDispatchTypes = CountryListRequest | CountryListFailure | CountryListSuccess;