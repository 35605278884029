import { document_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";
import { IAddDocumentTypesParams, IDeleteDocumentTypesParams, IGetDocumentTypesParams, IUpdateDocumentTypesParams } from "../types/document";


export const getDocumentTypesList = async ({ page_number, per_page }: IGetDocumentTypesParams) => {

    try {
        const params: { [key: string]: number } = {};

        if (page_number !== undefined) params['page_number'] = page_number;
        if (per_page !== undefined) params['per_page'] = per_page;

        const { data } = await axiosApi.get(document_routes.get_document_types_list, { params });
        return data;
    } catch (error: any) {
        return error?.response?.data?.errors;
    }
}


export const postDocumentType = async ({ name }: IAddDocumentTypesParams) => {
    try {
        const { data } = await axiosApi.post(document_routes.post_document_type, { name });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const updateDocumentType = async ({ id, name }: IUpdateDocumentTypesParams) => {
    try {
        const { data } = await axiosApi.put(document_routes.put_document_type, { id, name });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}


export const deleteDocumentType = async ({ id }: IDeleteDocumentTypesParams) => {
    try {
        const { data } = await axiosApi.delete(`${document_routes.delete_document_type}/${id}`);
        return data;
    } catch (error: any) {
        return error?.response?.data?.data;
    }
}