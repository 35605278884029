import { useState, useEffect, useCallback, useMemo } from "react";
import { GridActionsCellItem, GridColDef, GridRowModel, GridSearchIcon } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import DataGridTable from "../../../components/DataGrid/DataGridTable";
import SaveIcon from "@mui/icons-material/Save";
import { deleteCity, getCityList, postCity, updateCity } from "../../../redux/actions/cityAction";
import { getStatesByCountry } from "../../../redux/actions/stateActions";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import { getCountryList } from "../../../redux/actions/countryAction";
import { InputAdornment, ListSubheader, MenuItem, Select, TextField } from "@mui/material";
import { findTextInString } from "../../../helpers/helperFunctions";
import { Country } from "../../../redux/types/countryTypes";
import { ICitiesProps, IDeleteCityParams, IUpdateCityParams } from "../../../redux/types/cityTypes";
import { IStateOptions } from "../../../redux/types/stateTypes";
import DeleteConfirmationModal from "../../DeletePopupComponent/DeletePopup";

const Cities = ({ isActiveTab }: ICitiesProps) => {

  const initialRows = useMemo(() => [{
    id: "1",
    country: { id: "", country: "" },
    state: { id: "", state: "" },
    city: "",
    zipcode: ""
  },], []);
  type Row = (typeof initialRows)[number];

  const [rows, setRows] = useState<Row[]>(initialRows);

  const [page_number, setPage_number] = useState(1);
  const [per_page, setPer_page] = useState(0);
  const [rowId, setrowId] = useState(null);
  const [globalSearch, setGlobalSearch] = useState("");
  const [countryOptions, setCountryOptions] = useState<Country[]>([]);
  const [searchCountry, setSearchCountry] = useState("");
  const [stateOptions, setStateOptions] = useState<IStateOptions[]>([])
  const [searchState, setSearchState] = useState("");
  const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const addRow = () => {
    if (rows?.some((row) => row?.id === initialRows[0]?.id)) {
      ErrorToast("Please save last added row.")
      return rows;
    };
    setRows((prevRows) => [
      {
        id: "1",
        country: { id: "", country: " " },
        state: { id: "", state: " " },
        city: "",
        zipcode: ""
      },
      ...prevRows,
    ])
  };

  useEffect(() => {
    /* FETCH ALL COUNTRIES */
    async function fetchCountryData() {
      const { body } = await getCountryList({});
      const updatedCountryList: Country[] = body?.data?.map((e: any) => ({ country: e?.country, id: e?.id }));
      setCountryOptions(updatedCountryList);
    }

    /* FETCH ALL STATES */
    async function fetchCityData() {
      const { body } = await getCityList({ per_page: per_page, page_number: page_number });
      if (body) setRows(body?.data);
      else setRows([]);
    }

    if (isActiveTab) {
      fetchCountryData();
      fetchCityData();
    }
  }, [isActiveTab, page_number, per_page]);

  const handleUpdateCity = useCallback(
    async ({ id, state_id, zipcode, country_id, city }: IUpdateCityParams) => {
      try {
        if (country_id.trim() === "") return ErrorToast("Country is required field!");
        if (state_id.trim() === "") return ErrorToast("State is required field!");
        if (city.trim() === "") return ErrorToast("City is required field!");
        if (zipcode.trim() === "") return ErrorToast("Zipcode is required field!");

        let response;
        if (id === initialRows[0]?.id) response = await postCity({ country_id, state_id, city, zipcode });
        else response = await updateCity({ id, state_id, country_id, city, zipcode });

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          const { body } = await getCityList({ per_page: per_page, page_number: page_number });
          setRows(body?.data);
          setrowId(null);
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [initialRows, per_page, page_number]
  );

  const handleDeleteCity = useCallback(async ({ id }: IDeleteCityParams) => {
    try {

      if (id === initialRows[0]?.id) return ErrorToast("Record is not saved yet!");
      const response = await deleteCity({ id });

      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        const { body } = await getCityList({ per_page: per_page, page_number: page_number });
        if (body) setRows(body?.data);
        else setRows([]);
      } else if (response?.statusCode === 400)
        ErrorToast(response?.errors[0]?.msg);
      else ErrorToast("Unknown Error Occurred!");
    } catch (error: any) {
      ErrorToast(error?.response?.data?.errors[0]?.msg);
    }
  },
    [initialRows, per_page, page_number]
  );

  const filteredCountryOptions = useMemo(() => countryOptions && countryOptions?.length > 0 ? countryOptions.filter(({ country }) => findTextInString(country, searchCountry)) : [], [countryOptions, searchCountry]);

  const filteredStateOptions = useMemo(() => stateOptions && stateOptions?.length > 0 ? stateOptions.filter(({ state }) => findTextInString(state, searchState)) : [], [searchState, stateOptions]);

  const handleDeleteConfirmation = useCallback(() => {
    if (deleteRowId) {
      handleDeleteCity({ id: deleteRowId });
      setDeleteRowId(null);
      setIsDeleteModalOpen(false);
    }
  }, [deleteRowId, handleDeleteCity]);

  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: "country",
        flex: 1,
        headerName: "Country",
        filterable: true,
        sortable: true,
        editable: false,
        align: "left",
        headerAlign: "left",
        valueOptions: countryOptions,
        renderCell: (params: any) => {

          const handleChange = async (selectedCountryId: string) => {
            setrowId(params?.id);
            const selectedCountry = countryOptions?.find((option) => option.id === selectedCountryId);

            if (!selectedCountry) return;

            const rowIndex = rows?.findIndex((row) => row?.id === params?.id);
            if (rowIndex === -1) return;

            const statesList = await fetchStatesByCountry(selectedCountryId);

            const updatedRow = {
              ...rows[rowIndex],
              country: selectedCountry,
              state: { state: "", id: "" },
              state_list: statesList ? statesList : []
            };
            const updatedRows = [...rows?.slice(0, rowIndex), updatedRow, ...rows?.slice(rowIndex + 1)];
            setRows(updatedRows);
          };


          return (
            <Select
              MenuProps={{ autoFocus: false }}
              onClose={() => setSearchCountry("")}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderWidth: "0 !important",
                },
                '& :focus': {
                  outline: 'none !important',
                  border: "0 !important",
                  boxShadow: 'none !important',
                },
                width: "100%",
                color: "#a6b0cf",
                fontWeight: 400,
                fontSize: "0.875rem",
              }}
              value={params?.value?.country}
              renderValue={() => params?.value?.country.trim() === "" ? "Select Country" : params?.value?.country}
              onChange={(event) => handleChange(event.target.value)}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  autoFocus
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <GridSearchIcon />
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => setSearchCountry(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {filteredCountryOptions && filteredCountryOptions?.length > 0 ? (
                filteredCountryOptions?.map(({ country, id }: Country) => (
                  <MenuItem key={id} value={id}>
                    {country}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>
                  No countries found
                </MenuItem>
              )}
            </Select>
          );
        },
      },
      {
        field: "state",
        flex: 1,
        headerName: "State",
        filterable: true,
        sortable: true,
        editable: false,
        align: "left",
        headerAlign: "left",
        renderCell: (params: any) => {
          const handleChange = async (selectedStateId: string) => {
            setrowId(params.id);

            const selectedState = params?.row?.state_list?.find(({ id }: IStateOptions) => id === selectedStateId);
            if (!selectedState) return;

            const rowIndex = rows?.findIndex((row) => row?.id === params?.id);
            if (rowIndex === -1) return;

            const updatedRow = { ...rows[rowIndex], state: selectedState };
            const updatedRows = [...rows.slice(0, rowIndex), updatedRow, ...rows.slice(rowIndex + 1)];
            setRows(updatedRows);
          };
          return (
            <Select
              MenuProps={{ autoFocus: false }}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderWidth: "0 !important",
                },
                '& :focus': {
                  outline: 'none !important',
                  border: "0 !important",
                  boxShadow: 'none !important',
                },
                width: "100%",
                color: "#a6b0cf",
                fontWeight: 400,
                fontSize: "0.875rem",
              }}
              onOpen={() => setStateOptions(params?.row?.state_list)}
              onClose={() => {
                setSearchCountry("");
                setStateOptions([]);
              }}
              renderValue={() => params.value?.state.trim() === "" ? "Select States" : params.value?.state}
              value={params?.value?.state}
              onChange={(event) => handleChange(event.target.value)}>
              <ListSubheader>
                <TextField
                  size="small"
                  autoFocus
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{ startAdornment: (<InputAdornment position="start">   <GridSearchIcon /> </InputAdornment>) }}
                  onChange={(e) => setSearchState(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              <MenuItem value=" " disabled>
                Select State
              </MenuItem>
              {filteredStateOptions && filteredStateOptions?.length > 0 ? (
                filteredStateOptions?.map(({ state, id }: IStateOptions) => (
                  <MenuItem key={id} value={id}>
                    {state}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>
                  No states found
                </MenuItem>
              )}
            </Select >
          );
        },
      },
      {
        field: "city",
        type: "string",
        flex: 1,
        editable: true,
        headerName: "City",
        align: "left",
        headerAlign: "left",
      },
      {
        field: "zipcode",
        type: "TextField",
        flex: 1,
        headerName: "Zipcode",
        editable: true,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        headerName: "actions",
        type: "actions",
        width: 150,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            color="primary"
            disabled={params?.id !== rowId}
            onClick={() =>
              handleUpdateCity({
                id: params.row.id.toString(),
                country_id: params?.row?.country?.id.toString(),
                state_id: params?.row?.state?.id.toString(),
                city: params?.row?.city,
                zipcode: params?.row?.zipcode?.toString(),
              })
            }
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            disabled={params?.id === initialRows[0]?.id}
            onClick={() => {
              setDeleteRowId(params.row.id.toString());
              toggleDeleteModal()
            }}
          />,
        ],
      },
    ],
    [countryOptions, filteredCountryOptions, rows, filteredStateOptions, rowId, initialRows, handleUpdateCity, toggleDeleteModal]
  );

  const handlePageChange = (params: any) => setPage_number(params.page);

  const handlePageSizeChange = (params: any) => setPer_page(params.pageSize);

  const fetchStatesByCountry = async (selectedCountryId: string) => {
    try {
      const response = await getStatesByCountry({ country_id: selectedCountryId });

      if (response?.statusCode === 200) {
        const updatedStateList: IStateOptions[] = response?.body?.data?.map((e: any) => ({ state: e?.state, id: e?.id, }));
        return updatedStateList;
      } else if (response?.statusCode === 400) {
        ErrorToast(response?.errors[0]?.msg);
        return [];
      } else
        return [];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.errors[0]?.msg);
    }
  };

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => newRow;

  const onCellEditCommit = (params: any) => setrowId(params?.id);
  const filteredRows = useMemo(() => (rows || [])?.filter(({ city, zipcode, country, state, }) =>
    findTextInString(city, globalSearch) ||
    findTextInString(zipcode, globalSearch) ||
    findTextInString(country?.country, globalSearch) ||
    findTextInString(state?.state, globalSearch)
  ), [rows, globalSearch]);

  return (
    <>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        pageName="country"
        onConfirm={handleDeleteConfirmation}
      />
      <DataGridTable
        columns={columns}
        rows={filteredRows}
        processRowUpdate={processRowUpdate}
        pageSizeChange={handlePageSizeChange}
        pageChange={handlePageChange}
        addRow={addRow}
        onCellEditCommit={onCellEditCommit}
        setGlobalSearch={setGlobalSearch}
      />
    </>
  );
};

export default Cities;
