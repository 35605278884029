export enum ActionTypes {
  ADD_Patient_TAB = "add_patient_tab",
  SET_Main_Patient_TAB = "SET_Main_Patient_TAB",
  REMOVE_Patient_TAB = "remove_patient_tab",
  SET_CURRENT_PATIENT_ACTIVE_TAB = "set_current_patient_active_tab",
  SET_Patient_TAB = "set_patient_tab",
  NULL_PATIENT_TABS = "NULL_PATIENT_TABS"
}

interface addPatientTabActionTypes {
  type: ActionTypes.ADD_Patient_TAB;
  payload: {
    id: string;
    name: string;
  };
}

interface setPatientMainTabActionTypes {
  type: ActionTypes.SET_Main_Patient_TAB;
  payload: {
    patientMainTab: any;
  };
}

interface setNullPatientTabs {
  type: ActionTypes.NULL_PATIENT_TABS;
}

interface removePatientTabActionTypes {
  type: ActionTypes.REMOVE_Patient_TAB;
  payload: {
    id: string;
    name: string;
  };
}

interface setCurrentActiveTabActionTypes {
  type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB;
  payload: {
    id: string;
  };
}

interface setPatientTabActionTypes {
  type: ActionTypes.SET_Patient_TAB;
  payload: {
    id: string;
    name: string;
  };
}

export type patientTabActions =
  | setPatientMainTabActionTypes
  | addPatientTabActionTypes
  | removePatientTabActionTypes
  | setCurrentActiveTabActionTypes
  | setPatientTabActionTypes | setNullPatientTabs
